import { breakPoints, BreakpointType } from '../constants/breakpoints';
import { useEffect, useState, useCallback } from 'react';

/**
 * bind and unbid function OnResize event of the window object
 * @param onResize the function that will be executed on resize, must be memorized function to avoid memory Leaks
 * @example
 * const onResize = useCallback(function(event){
 * // do something
 * }, []);
 * useWindowResize(onResize); // execute the function on resize
 */
export const useOnWindowResize = (onResize: (this: Window, ev: UIEvent) => any) => {
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);
};

/**
 * gets the current breakpoint of the window
 * @returns the current breakpoint of the window
 */
export const useBreakPoint = () => {
  const getBreakPoint = useCallback(() => {
    const width = window.innerWidth;
    const [currentBreakpoint] = Object.entries(breakPoints)
      .reverse()
      .find(([, value]) => width >= value) as any;
    return currentBreakpoint as BreakpointType;
  }, []);

  const [breakPoint, setBreakPoint] = useState<BreakpointType>(getBreakPoint());

  const onResize = useCallback(
    function () {
      const newBreakPoint = getBreakPoint();
      setBreakPoint(newBreakPoint);
    },
    [getBreakPoint]
  );

  useOnWindowResize(onResize);
  return breakPoint;
};

/**
 * determines if the current breakpoint is mobile
 * @returns true if the current breakpoint is mobile
 */
export const useIsMobile = () => {
  const breakPoint = useBreakPoint();
  return breakPoint === 'xs' || breakPoint === 'sm';
};
