import { baseApi } from '@rsl/core/src/api/baseApi';
import { invalidateOnSuccess } from '@rsl/core/src/utils/cacheUtils';

export const needlistApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    importDocuments: build.mutation<void, string>({
      query: (loanId) => ({
        url: `/LoanNeedlistEternalUser/${loanId}/import`,
        method: 'POST',
      }),
      invalidatesTags: invalidateOnSuccess(['FileshareNeededList']),
    }),
  }),
  overrideExisting: false,
});

export const { useImportDocumentsMutation } = needlistApi;
