import { useFormikContext } from 'formik';
import { FC, useState, useEffect, useCallback } from 'react';
import { Button, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FormStatus } from '../../constants/formStatusConstants';
import { FlexAlign, Sizes } from '../../constants/alignConstants';
import { VariantType, ICustomFormAction } from '../../types';
import classNames from 'classnames';
import { toSlugId } from '../../utils/stringUtils';

interface FormCancelSaveButtonsProps {
  /**
   * custom action when cancel is pressed
   */
  onCancel?: () => void;
  /**
   * is the current form on Saving State?
   */
  isSaving: boolean;
  /**
   * is the current form on Loading State?
   */
  isLoading: boolean;
  /**
   * do you want the Save Button to be displayed?
   */
  showSaveButton: boolean;
  /**
   * do you want the Cancel Button to be displayed?
   */
  showCancelButton: boolean;
  /**
   * the text displayed for saving
   */
  saveText: ICustomFormAction;

  /**
   * the text displayed for canceling
   */
  cancelText: string;

  /**
   * do you want the Dropdown Save Button to be displayed?
   * NOTE: This is used along with the status prop from the Formik context
   */
  saveAndCloseRoute?: string;

  /**
   * Custom actions to be rendered as buttons nested on Submit button
   */
  customFormActions?: ICustomFormAction[];

  /**
   * Additional actions to be rendered after submit action
   */
  additionalActions?: ICustomFormAction[];

  /**
   * align of the buttons
   */
  buttonsAlign?: FlexAlign;

  /**
   * the variant of the submit button
   */
  variantSubmit?: VariantType;

  /**
   * the size of the buttons
   */
  buttonSize: Sizes;
  /**
   * the size of the save button
   */
  saveButtonClassName?: string;
  /**
   * submit button will not be disabled
   */
  submitAlwaysEnabled?: boolean;
}

const FormCancelSaveButtons: FC<FormCancelSaveButtonsProps> = ({
  isSaving,
  isLoading,
  showCancelButton,
  showSaveButton,
  saveText,
  cancelText,
  onCancel,
  saveAndCloseRoute,
  customFormActions,
  additionalActions,
  buttonsAlign = FlexAlign.end,
  variantSubmit = 'primary',
  buttonSize,
  saveButtonClassName,
  submitAlwaysEnabled = false,
}) => {
  const { isSubmitting, dirty, submitForm, status, setFieldValue, errors } = useFormikContext();
  const [checkStatus, setCheckStatus] = useState<boolean>(false);
  const [doRedirect, setDoRedirect] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (isSubmitting) {
      setCheckStatus(true);
    }

    if (!isSubmitting && checkStatus) {
      setCheckStatus(false);

      if (saveAndCloseRoute && status === FormStatus.Success && doRedirect) {
        // time for let the state update the refferences and reinitialize the initial state and dirsty prop
        setTimeout(() => history.push(saveAndCloseRoute), 30);
      }

      setDoRedirect(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const saveAndClose = () => {
    setDoRedirect(true);
    submitForm();
  };

  const customActionAndSubmit = (action: ICustomFormAction) => {
    action.action?.();
    submitForm();
  };

  const disableButtons = isSubmitting || isLoading || isSaving || !dirty;

  const disableSave = Object.keys(errors).length > 0;

  const updateValues = (keysValues: { key: string; value: string }[]) => {
    keysValues.forEach((keyValue: { key: string; value: string }) => {
      setFieldValue(keyValue.key, keyValue.value);
    });
  };

  const getId = useCallback(toSlugId, []);

  return (
    <div className={`d-flex justify-content-${buttonsAlign}`}>
      {showCancelButton && (
        <Button
          data-cy="clear"
          size={buttonSize}
          type={!!onCancel ? 'button' : 'reset'}
          variant="secondary"
          className={classNames('rounded-pill me-2')}
          disabled={disableButtons}
          onClick={() => onCancel?.()}>
          {cancelText}
        </Button>
      )}
      {showSaveButton && (
        <ButtonGroup>
          <Button
            variant={variantSubmit}
            type="submit"
            data-cy="submit"
            onClick={saveText.action}
            className={classNames(saveAndCloseRoute || customFormActions ? 'rounded-pill-left' : 'rounded-pill', saveButtonClassName)}
            size={buttonSize}
            disabled={submitAlwaysEnabled ? false : disableSave || disableButtons}>
            {saveText.label} {(isSaving || isSubmitting) && <i className="fas fa-spinner fa-spin align-middle"></i>}
          </Button>

          {(saveAndCloseRoute || customFormActions) && (
            <DropdownButton as={ButtonGroup} title="" className="border-start dropdown-rounded-pill-right" disabled={disableButtons} size={buttonSize}>
              {saveAndCloseRoute ? (
                <Dropdown.Item onClick={() => saveAndClose()} disabled={disableButtons}>
                  Save and close
                </Dropdown.Item>
              ) : null}
              {customFormActions?.map((action, index) => (
                <Dropdown.Item key={index} onClick={() => customActionAndSubmit(action)} disabled={disableButtons}>
                  {action.label}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          )}
        </ButtonGroup>
      )}
      {additionalActions?.map((action, index) => (
        <Button
          key={index}
          data-cy={getId(action.label)}
          data-testid={getId(action.label)}
          type={action.type ?? 'button'}
          variant={action.variant ?? 'secondary'}
          className={`rounded-pill ms-2 ${action?.class ?? ''}`}
          disabled={!action.alwaysEnabled && disableButtons}
          onClick={() => {
            action.action?.();

            if (action.setValuesOnClick) {
              updateValues(action.setValuesOnClick);
            }
          }}
          size={buttonSize}>
          {action.label} {action.type === 'submit' && (isSaving || isSubmitting) && <i className="fas fa-spinner fa-spin align-middle"></i>}
        </Button>
      ))}
    </div>
  );
};

export default FormCancelSaveButtons;
