import { FC } from 'react';
import { Button } from 'react-bootstrap';
import downloadFile from '@rsl/core/src/api/downloadFileApi';

interface DownloadLinkProps {
  href: string;
  filename: string;
  className?: string;
  afterDownload?: () => void;
  showIcon?: boolean;
  size?: 'sm' | 'lg';
  token?: string;
  deviceId?: string;
  disabled?: boolean;
  children?: React.ReactNode | ((data: any) => React.ReactNode);
}
const DownloadLink: FC<DownloadLinkProps> = ({ href, filename, className, afterDownload, showIcon, size, token = '', deviceId, children, disabled }) => {
  const handleDownload = async () => {
    await downloadFile(href, null, filename, 'GET', token, deviceId);
    afterDownload && afterDownload();
  };

  return (
    <Button variant="link" onClick={handleDownload} className={className} size={size} disabled={disabled}>
      {showIcon && <i className="align-middle me-2 fas fa-fw fa-paperclip text-dark" />}
      {children || filename}
    </Button>
  );
};

export default DownloadLink;
