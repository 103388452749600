import { Card, Collapse } from 'react-bootstrap';
import { LoanSummaryExternal } from 'app/types';
import { FC, useCallback } from 'react';
import LoanContact from './LoanContact';
import { ReactComponent as ArrowIcon } from 'app/assets/icons/arrow.svg';
import { useCollapseChangeOnMobile } from '@rsl/core/src/hooks/collapseHooks';

interface DashboardLoanContactsProps {
  loan: LoanSummaryExternal;
}

const ContactTypes = ['Officer', 'Processor', 'Underwriter'] as const;
type LoanContactType = typeof ContactTypes[number];

interface LoanContactModel {
  name: string;
  phone?: string;
  email?: string;
  type: `Loan ${LoanContactType}`;
}

// get contacts from loan as LoanOfficer
const getLoanContact =
  (type: LoanContactType) =>
  (loan: LoanSummaryExternal): LoanContactModel | null => {
    if ((loan as any)[`teamLoan${type}Name`]) {
      return {
        name: (loan as any)[`teamLoan${type}Name`],
        phone: (loan as any)[`teamLoan${type}Phone`],
        email: (loan as any)[`teamLoan${type}Email`],
        type: `Loan ${type}`,
      };
    }

    return null;
  };

const getLoanContacts = (loan: LoanSummaryExternal): LoanContactModel[] => {
  const getters = ContactTypes.map((type) => getLoanContact(type));
  const contacts = getters.reduce<LoanContactModel[]>((acc, getter) => {
    const contact = getter(loan);
    if (contact) {
      acc.push(contact);
    }
    return acc;
  }, []);

  return contacts;
};

const DashboardLoanContacts: FC<DashboardLoanContactsProps> = ({ loan }) => {
  const { collapsed, setCollapsed } = useCollapseChangeOnMobile();
  const getContacts = useCallback(() => getLoanContacts(loan), [loan]);

  const contacts = getContacts();

  return (
    <article>
      <div className="d-flex justify-content-between" onClick={setCollapsed}>
        <h4 className="fw-bold mb-3">Contacts</h4>
        <div className={`d-md-none d-flex justify-content-center align-items-center`}>
          <span className={`d-block common-transition ${collapsed ? 'rotate-90n' : 'rotate-0'}`}>
            <ArrowIcon />
          </span>
        </div>
      </div>
      <Collapse in={!collapsed}>
        <Card.Body>
          {contacts.map((contact, i) => (
            <LoanContact key={`loan-contact-${i}-${contact.name}`} name={contact.name} position={contact.type} phone={contact.phone} email={contact.email} />
          ))}
        </Card.Body>
      </Collapse>
    </article>
  );
};

export default DashboardLoanContacts;
