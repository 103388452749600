import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import MessageIndicator from '../../../shared/MessageIndicator';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core';

const B10 = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();
  return (
    <>
      <MessageIndicator>
        The purpose of the Supplemental Consumer Information Form (SCIF) is to collect information on homeownership education and housing counseling and/or language preference to
        help lenders better understand the needs of borrowers during the home buying process.
      </MessageIndicator>
      <Question label="Have you completed homeownership education within the last 12 months?" required={values.SharedB1e_1 === QuestionValues.Yes}>
        <Row>
          <RadioField
            className="mb-2"
            name="SharedB10_1"
            optionsClassName="pt-1 w-50 w-md-10"
            options={[
              { label: QuestionValues.Yes, value: QuestionValues.Yes },
              { label: QuestionValues.No, value: QuestionValues.No },
            ]}
          />
        </Row>
      </Question>
    </>
  );
};

export default B10;
