import { useEffect } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { useFormikContext } from 'formik';

const GC5 = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance || values.reoProperties.length) {
      setFieldValue('SharedGC5_1', QuestionValues.Yes);
    }

    if (values.SharedGC5_1 !== QuestionValues.Yes) {
      setFieldValue('SharedGC20_1', '');
      setFieldValue('SharedGC20_2', '');
    }
  }, [setFieldValue, values.loanPurposeType, values.reoProperties, values.SharedGC5_1]);

  return (
    <Question label="Have you had an ownership interest in another property in the last three years?">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedGC5_1"
          optionsClassName="pt-1 w-50 w-md-10"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default GC5;
