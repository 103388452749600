import * as Yup from 'yup';
import { Col, Row, Alert, Container, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TextField from '@rsl/core/src/components/form/TextField';
import { useLogin } from 'app/hooks/authHooks';
import { LoginType } from 'features/pin/types';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import { useAppParams } from '@rsl/core/src/hooks/routeHooks';
import { RegexConstants } from '@rsl/core/src/constants';
import ExtendedFormik from '@rsl/core/src/components/form/ExtendedFormik';
import { ValidationErrors } from '@rsl/core/src/constants/errors';
import { useSetLayoutSettings } from 'app/hooks/layoutHooks';
import { ReactComponent as InfoIcon } from 'app/assets/icons/info.svg';

const values: LoginType = {
  email: '',
  password: '',
};

const signInFormSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string()
    .required(ValidationErrors.EmptyPassword)
    .min(8, ValidationErrors.PasswordShort)
    .max(15, ValidationErrors.PasswordLong)
    .matches(RegexConstants.Password, ValidationErrors.PasswordFormat),
});

const SignIn = () => {
  const login = useLogin();
  const { existing } = useAppParams<{ existing: boolean }>();

  const doSubmitForm = async (values: LoginType) => {
    const response = await login(values);
    throwErrorProp(response);
  };

  useSetLayoutSettings({
    subTitle: 'Sign In',
    isMainScreen: true,
  });

  return (
    <>
      {existing && (
        <Alert variant="light  px-2 py-2 mx-2 text-center bg-gray flexible-box">
          <Container>
            <Row>
              <Col>
                <div className="text-black py-2">
                  <InfoIcon />
                </div>
                <p className="mb-0 text-primary fw-bold pt-1">
                  <strong>Existing Account</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-center text-black">We found an existing email in our system, please sign in below.</p>
              </Col>
            </Row>
          </Container>
        </Alert>
      )}
      <Card className="mx-2 text-center py-4 flexible-box">
        <Card.Body>
          <ExtendedFormik
            validationSchema={signInFormSchema}
            initialValues={values}
            onSubmit={doSubmitForm}
            variantSubmit="primary-gradient"
            showCancelButton={false}
            saveText="SIGN IN"
            buttonSize="lg"
            lockFormOnChanges={false}
            saveButtonClassName="px-4"
            buttonsAlign="center">
            {() => (
              <>
                <h2 className="fw-bold">Buying or refinancing a property?</h2>
                <div>We can get your application started in just a few minutes!</div>
                <Row className="mt-4">
                  <TextField sm={12} className="mb-3" name="email" required placeholder="Email" />
                  <TextField sm={12} type="password" className="mb-3" name="password" required placeholder="Password" />
                </Row>
                <Row>
                  <Col className="text-center mb-3 text-decoration-underline">
                    <Link to="/apply-now/reset-password-request" className="mx-1 text-info">
                      <small>
                        <strong>Reset password</strong>
                      </small>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <small className="mb-3 d-flex justify-content-between">
                      <span className="form-label mx-1">Don't have an account?</span>
                      <Link to="/apply-now/register" className="mx-1 text-info text-decoration-underline">
                        <strong>Create One</strong>
                      </Link>
                    </small>
                  </Col>
                </Row>
              </>
            )}
          </ExtendedFormik>
        </Card.Body>
      </Card>
    </>
  );
};

export default SignIn;
