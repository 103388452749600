import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Col, Container, Row, Spinner } from 'react-bootstrap';

import { useAppParams } from '@rsl/core/src/hooks/routeHooks';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import { useCurrentLoan } from 'app/hooks/loanHooks';
import { useSendMessageMutation } from 'app/api/externalSessionApi';
import { useFetchLoanCommLogRecipientLookupQuery } from 'features/communication/inbox-sent/api/commLogApi';
import { ICommunicationLog } from '@rsl/core/src/types/communication.types';
import MessageForm from '@rsl/core/src/components/communication/MessageForm';
import { useLookup } from '@rsl/core/src/hooks/lookupsHooks';
import { INotificationCategoryType } from '@rsl/core';
import { useHistory } from 'react-router-dom';
import { ContactTypeConstants } from '@rsl/core/src/constants';

interface NewMessageProps {
  title?: string;
  className?: string;
  chatForm?: boolean;
}

const NewMessage: FC<NewMessageProps> = ({ title = 'New message', className = '', chatForm = false }) => {
  const { loanId } = useAppParams<{ loanId: string }>();
  const history = useHistory();
  const { data: categories, isLoading: isLoadingCategories } = useLookup<INotificationCategoryType>('commLogCategories');
  const currentLoan = useCurrentLoan();
  const [sendMessage] = useSendMessageMutation();
  const { data: loanContactsLookup = [], isLoading: isLoadingLoanContacts } = useFetchLoanCommLogRecipientLookupQuery(loanId);

  // ToDo - Create a selector to get different kind of LoanContacts (and maybe update API to add an extra property to each kind)
  const internalContacts = useMemo(() => {
    return loanContactsLookup?.filter((contact) => contact.contactType === ContactTypeConstants.LoanOfficer || contact.contactType === ContactTypeConstants.LoanProcessor);
  }, [loanContactsLookup]);

  const doSubmitForm = async (values: ICommunicationLog) => {
    values.isExternalReply = true;
    throwErrorProp(await sendMessage(values));
    toast.success('Message sent!');
    history.push(`/loan/${loanId}/messages`);
  };

  const initialState: ICommunicationLog = useMemo(
    () => ({
      communicationLogId: 0,
      loanNumber: currentLoan?.loanNumber ?? '',
      body: '',
      referenceId: loanId,
      notificationCategoryTypeId: categories?.find((c) => c.name === 'General')?.notificationCategoryTypeId ?? 0, // NotificationCategory.General
      recipients: [],
      attachments: [],
      additionalRecipients: '',
      borrowerLastName: '',
      loanContact: '',
    }),
    [categories, currentLoan?.loanNumber, loanId]
  );

  return !chatForm ? (
    <Container>
      <Row>
        <Col className={className || 'p-5'}>
          {!isLoadingLoanContacts && !isLoadingCategories ? (
            <MessageForm
              title={title}
              key="new"
              messageData={initialState}
              loanContactsLookup={internalContacts}
              doSubmitForm={doSubmitForm}
              isNew={true}
              isLoading={isLoadingLoanContacts}
              hideSendButton={false}
              disableCategory={true}
              disableAttachments={true}
              disableAdditionalRecipients={true}
            />
          ) : (
            <div className="text-center pt-5 m-5">
              <Spinner animation="border" />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  ) : (
    <MessageForm
      title={title}
      key="new"
      messageData={initialState}
      loanContactsLookup={internalContacts}
      doSubmitForm={doSubmitForm}
      isNew={true}
      isLoading={isLoadingLoanContacts}
      hideSendButton={false}
      disableCategory={true}
      disableAttachments={true}
      disableAdditionalRecipients={true}
      chatForm
    />
  );
};

export default NewMessage;
