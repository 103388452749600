import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import Asset from './Asset';

const A1a = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  const show = (event: any) => {
    if (event.target.checked) {
      setFieldValue('SharedA1a_Accounts', [{ amount: '', institutionName: '' }]);
    }
  };

  return (
    <div className="border-bottom py-2 border-gray-dark">
      <Row>
        <CheckboxField className="fw-bold lg-label" name="ShowSharedA1a_" label="Checking accounts" horizontal={true} onClick={show} />
      </Row>
      {!!values.ShowSharedA1a_ && <Asset namePrefix="SharedA1a_" multiple />}
    </div>
  );
};

export default A1a;
