import { useFieldIds } from '@rsl/core/src/hooks/fieldHooks';
import { useField } from 'formik';
import { FC } from 'react';
import { IMinuteField } from '../../types/form.types';
import MinuteField from './MinuteField';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';

interface IRadioOptions {
  label: string;
  value: string | number;
  smallLabel?: string | string[];
  labelEmphasis?: string;
  disabled?: boolean;
  errorPlace?: 'top' | 'bottom';
}

interface IRadioField {
  options: IRadioOptions[];
  optionsClassName?: string;
  smallLabelClassName?: string;
  optionsContainerClassName?: string;
}

const RadioField: FC<IMinuteField<string> & IRadioField> = ({
  options,
  optionsClassName,
  smallLabelClassName,
  optionsContainerClassName = '',
  errorPlace = 'bottom',
  ...props
}) => {
  const { name } = useFieldIds(props);
  const [field, , { setValue }] = useField({ name });

  const onChange = (newValue: any) => {
    setValue(newValue.target.value);
  };

  return (
    <MinuteField {...props}>
      <div className={`d-flex flex-column ${errorPlace === 'top' && 'flex-column-reverse'}`}>
        <div className={optionsContainerClassName}>
          {options.map((option, index) => (
            <div key={`radio-${name}-${index}`} className={classNames(optionsClassName)}>
              <Form.Check
                id={`radio-${name}-${index}`}
                data-testid={`radio-${name}-${index}`}
                type="radio"
                name={name}
                label={
                  <>
                    {option.label}
                    {!!option.labelEmphasis && <em> ({option.labelEmphasis})</em>}
                  </>
                }
                value={option.value}
                disabled={option.disabled || props.disabled}
                onChange={onChange as any}
                checked={field.value === option.value}
              />
              {option.smallLabel &&
                (Array.isArray(option.smallLabel) ? (
                  option.smallLabel.map((value: string) => (
                    <p className="mb-0">
                      <small className={classNames(smallLabelClassName, 'mx-4 ')}>{value}</small>
                    </p>
                  ))
                ) : (
                  <small className={classNames(smallLabelClassName, 'mx-4 ')}>{option.smallLabel}</small>
                ))}
            </div>
          ))}
        </div>
      </div>
    </MinuteField>
  );
};

export default RadioField;
