import { currentIntendedRouteSel } from 'app/store/selectors/routeManagerSelectors';
import { setIntendedRoute } from 'app/store/slices/routeManagerSlice';
import { ExternalUserSessionResponse } from 'app/types';
import { pinActionRouteMap } from 'features/pin/constants/pinActionsConstants';
import { PinActionEnum } from 'features/pin/types';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './hooks';
import { useCallbackRoute } from './routeHooks';

/**
 * manage routes from state
 */
export const useRouteManager = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const intendedRoute = useAppSelector(currentIntendedRouteSel);

  useEffect(() => {
    if (intendedRoute) {
      dispatch(setIntendedRoute(null));
      history.replace(intendedRoute);
    }
  }, [dispatch, history, intendedRoute]);
};

/**
 * return a function that can be used after the user is authenticated
 */
export const useRedirectUser = () => {
  const dispatch = useAppDispatch();
  const callbackRoute = useCallbackRoute();

  return useCallback(
    (sessionUser: ExternalUserSessionResponse, from = '') => {
      let finalRoute = from || callbackRoute;
      const isEmptyCallback = !finalRoute;
      if (isEmptyCallback) {
        finalRoute = '/';
      }

      // in case the device is not verified, then redirect to the pin page
      if (!sessionUser.isDeviceVerified) {
        finalRoute = `${pinActionRouteMap(PinActionEnum.RegisterDevice)}?callback=${finalRoute}`;
      }

      if (from !== finalRoute) {
        dispatch(setIntendedRoute(finalRoute));
      }
    },
    [dispatch, callbackRoute]
  );
};
