import { Row } from 'react-bootstrap';
import Question from '../../Question';
import RadioField from '@rsl/core/src/components/form/RadioField';
import MaskedField from '@rsl/core/src/components/form/MaskedField';
import TextField from '@rsl/core/src/components/form/TextField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';
import { shortTimestamp } from '@rsl/core/src/utils/dateUtils';
import CreditAuthorizationAgreement from './CreditAuthorizationAgreement';

const Z1a = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  return (
    <Question label={`Credit Authorization for ${values.SharedC1_1}`} labelSize="lg">
      <Row>
        <CreditAuthorizationAgreement>
          <p className="text-black mb-1">
            <strong>I authorize this Credit Check.</strong>&nbsp;
            <span className="text-danger">*</span>
          </p>
          <div className="d-flex flex-column align-items-center">
            <RadioField
              className="mb-2"
              name="SharedZ1a_1"
              optionsClassName="pt-1"
              options={[
                { label: 'I agree', value: QuestionValues.Agree },
                { label: 'I do not agree', value: QuestionValues.Disagree },
              ]}
              errorPlace="top"
            />
          </div>
          {values.SharedZ1a_1 === QuestionValues.Agree && (
            <>
              <Row>
                <MaskedField className="mb-2" label="Social security number" name="SharedZ1a_2" mask="999-99-9999" mutation={/[^0-9]+/g} required />
              </Row>
              <Row>
                <TextField className="mb-2" type="date" name="SharedZ1a_3" placeholder="MM/DD/YYYY" max={shortTimestamp()} required label="Date of birth" />
              </Row>
            </>
          )}
        </CreditAuthorizationAgreement>
      </Row>
    </Question>
  );
};

export default Z1a;
