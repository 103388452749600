import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';

const W3 = () => {
  return (
    <Question label="How do you intend to use the home?">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedW3_1"
          optionsClassName="pt-1"
          options={[
            { label: 'Primary residence', value: QuestionValues.HomeIntendPrimary },
            { label: 'Second home', value: QuestionValues.HomeIntendSecond },
            { label: 'Investment', value: QuestionValues.HomeIntendInvestment },
          ]}
        />
      </Row>
    </Question>
  );
};

export default W3;
