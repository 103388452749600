import Question from '../../Question';
import { Row } from 'react-bootstrap';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import TextField from '@rsl/core/src/components/form/TextField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';

const C12 = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  const resetOtherLanguageField = (value: string) => {
    setFieldValue('SharedC12_2', '');
  };

  return (
    <>
      <Question label="Your preferred language (optional)?">
        <Row>
          <LookupSelect className="mb-2" placeholder="Language" name="SharedC12_1" lookup="posLanguagePreferenceTypes" onChange={resetOtherLanguageField} required />
        </Row>
      </Question>
      {values.SharedC12_1 === QuestionValues.OtherIndicator && (
        <Question label="Other language preference (optional)">
          <Row>
            <TextField className="mb-2" placeholder="Type language" name="SharedC12_2" required />
          </Row>
        </Question>
      )}
    </>
  );
};

export default C12;
