import { FC, useEffect, useState } from 'react';
import PosNavbar from './navbar/PosNavbar';

import { useLayoutSettings } from 'app/hooks/layoutHooks';
import Footer from 'features/cobranding-page/components/footer';
import SidebarNew from '../sidebar/SidebarNew';
import { useIsMobile } from '@rsl/core/src/hooks/windowEventHooks';

const MainExternalLayout: FC = ({ children }) => {
  const isMObile = useIsMobile();
  const [toggled, setToggled] = useState(isMObile);
  const onToggleSidebar = () => setToggled(!toggled);

  const { isMainScreen, subTitle } = useLayoutSettings();

  useEffect(() => {
    setToggled(true);
  }, [isMObile]);

  return (
    <div>
      <div className="wrapper">
        <SidebarNew toggled={toggled} setToggled={setToggled} />
        <div className="main d-flex">
          <PosNavbar onToggleSidebar={onToggleSidebar} />
          <main className="flex-grow-1 d-flex flex-column">
            {!!subTitle && <h3 className="text-primary bg-black text-center py-3 m-0">{subTitle}</h3>}
            <section className={`d-flex flex-column flex-grow-1 pb-2 ${isMainScreen ? 'main-screen justify-content-center align-items-center' : ''}`}>{children}</section>
            <Footer />
          </main>
        </div>
      </div>
    </div>
  );
};

export default MainExternalLayout;
