import {
  INotificationTemplateLookup,
  IOption,
  ISecurityRoleLookup,
  ITeamLookup,
  IDepartmentLookup,
  IUserLookup,
  ITriggerFieldTypeLookup,
  LookupKey,
  LookupMapper,
  LookupType,
  INotificationCategoryType,
  IApplicationLookup,
  IMarketLookup,
  ILoanModelColumnLookup,
  IKeyValueLookup,
  INeedsListItemLookup,
  INeedsListTemplateLookup,
  IActivityPlanTemplateLookup,
  IPosTypes,
  ILoanDisclosurePackageLookup,
  ILoanContactTypeLookup,
} from '@rsl/core/src/types';
import { createCachedSelector } from 're-reselect';

const activityPlanTemplateMapper = (activityPlanTemplate: IActivityPlanTemplateLookup) => ({
  label: activityPlanTemplate.name,
  value: activityPlanTemplate.activityPlanTemplateId,
});

const securityRoleMapper = (role: ISecurityRoleLookup) => ({
  label: role.name,
  value: role.securityRoleTypeId,
});

const triggerFieldMapper = (trigger: ITriggerFieldTypeLookup) => ({
  label: trigger.name,
  value: trigger.triggerFieldTypeId,
});

const notificationsMapper = (template: INotificationTemplateLookup) => ({
  label: template.name,
  value: template.notificationTemplateId,
});

const teamsMapper = (template: ITeamLookup) => ({
  label: template.name,
  value: template.teamId,
});

const departmentsMapper = (template: IDepartmentLookup) => ({
  label: template.name,
  value: template.departmentId,
});

const usersMapper = (template: IUserLookup) => ({
  label: template.firstName + ' ' + template.lastName,
  value: template.userId,
});

const notificationCategoryMapper = (category: INotificationCategoryType) => ({
  label: category.name,
  value: category.notificationCategoryTypeId,
});

const applicationsMapper = (template: IApplicationLookup) => ({
  label: template.name,
  value: template.applicationId,
});

const marketsMapper = (template: IMarketLookup) => ({
  label: template.name,
  value: template.marketId,
});

const loanModelColumnsMapper = (template: ILoanModelColumnLookup) => ({
  label: template.displayName,
  value: template.columnName,
});

const keyValueMapper = (template: IKeyValueLookup) => ({
  label: template.value,
  value: template.key,
});

const needsListItemsMapper = (template: INeedsListItemLookup) => ({
  label: template.documentation,
  value: template.needsListItemId,
});

const needsListTemplatesMapper = (template: INeedsListTemplateLookup) => ({
  label: template.name,
  value: template.needsListTemplateId,
});

const posTypesMapper = (template: IPosTypes) => ({
  label: template.value,
  value: template.key,
});

const loanDisclosureMapper = (template: ILoanDisclosurePackageLookup) => ({
  label: template.name,
  value: template.disclosurePackageId,
});

const loanContactTypeMapper = (template: ILoanContactTypeLookup) => ({
  label: template.description,
  value: template.name,
});

export const defaultMapper = (option: string) => ({
  label: option,
  value: option,
});

const lookupMappers = new Map<LookupKey | 'default', LookupMapper>([
  [LookupKey.activityPlanTemplates, activityPlanTemplateMapper],
  [LookupKey.loanRoles, securityRoleMapper],
  [LookupKey.securityRoles, securityRoleMapper],
  [LookupKey.triggerFieldTypes, triggerFieldMapper],
  [LookupKey.notificationTemplates, notificationsMapper],
  [LookupKey.teams, teamsMapper],
  [LookupKey.commLogCategories, notificationCategoryMapper],
  [LookupKey.departments, departmentsMapper],
  [LookupKey.users, usersMapper],
  [LookupKey.applications, applicationsMapper],
  [LookupKey.markets, marketsMapper],
  [LookupKey.loanModelColumns, loanModelColumnsMapper],
  [LookupKey.ratePricingCreditScores, keyValueMapper],
  [LookupKey.ratePricingPropertyUsages, keyValueMapper],
  [LookupKey.ratePricingPropertyTypes, keyValueMapper],
  [LookupKey.needsListItems, needsListItemsMapper],
  [LookupKey.needsListTemplates, needsListTemplatesMapper],
  [LookupKey.posCitizenshipResidencyTypes, posTypesMapper],
  [LookupKey.disclosurePackages, loanDisclosureMapper],
  [LookupKey.ratePricingMortgageTypes, keyValueMapper],
  [LookupKey.ratePricingProgramTypes, keyValueMapper],
  [LookupKey.loanContactTypes, loanContactTypeMapper],
  [LookupKey.posLanguagePreferenceTypes, keyValueMapper],
  ['default', defaultMapper],
]);

export const lookupsModuleSel = (state: any) => state.lookup;
export const lookupsSel = (state: any) => lookupsModuleSel(state).lookups;
export const pendingFetchLookupsSel = (state: any) => lookupsModuleSel(state).pendingFetchLookups;
export const getLookupOptionSel = createCachedSelector(
  lookupsSel,
  (_: any, lookupName: LookupKey) => lookupName,
  (lookups: Record<LookupKey, LookupType>, lookupName: LookupKey): IOption<string | number>[] => {
    const lookup = lookups[lookupName];
    // get the lookup maper for the given lookup key
    const mapper = lookupMappers.get(lookupName) ?? lookupMappers.get('default');
    // return the options as result of the mapper
    return lookup?.map(mapper as LookupMapper) ?? [];
  }
)((_, lookupName) => lookupName);
