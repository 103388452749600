import { useAppParams } from '@rsl/core/src/hooks/routeHooks';
import { Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { useFetchFileShareInfoQuery } from './api/fileshareApi';

const FileShareOutlet = () => {
  const { recipientId = '' } = useAppParams<{ recipientId: string }>();
  const { data, isFetching, isSuccess } = useFetchFileShareInfoQuery(recipientId);

  if (isFetching) {
    return <Spinner animation="border" variant="primary" />;
  }

  return isSuccess ? <Redirect to={`/loan/${data.loanId}/fileshares`} /> : null;
};

export default FileShareOutlet;
