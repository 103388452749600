import { useEffect } from 'react';
import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType, IEmploymentType } from '@rsl/core/src/types';
import AddButtonText from 'app/components/buttons/AddButtonText';
import MessageIndicator from '../../../shared/MessageIndicator';
import Question from '../../Question';
import { getCurrencyFormatted } from '@rsl/core/src/utils/numberUtils';
import { ValidationErrors } from '@rsl/core/src/constants/errors';

const B6a = () => {
  const { values, setFieldValue, submitForm, errors } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    setFieldValue('SharedB6a_1', null);
    setFieldValue('SharedE1_1', '');
    setFieldValue('SharedE1_2', '');
    setFieldValue('SharedE1_3', '');
    setFieldValue('SharedE1_4', '');
    setFieldValue('SharedE1_5', '');
    setFieldValue('SharedE1_6', '');
    setFieldValue('SharedE1_7', '');
    setFieldValue('SharedE1_8', '');
    setFieldValue('SharedE1_9', '');
    setFieldValue('SharedE1_10', '');
    setFieldValue('SharedE1_11', '');
    setFieldValue('SharedE1_12', '');
    setFieldValue('SharedE1_13', '');
    setFieldValue('SharedE1_14', '');
  }, [setFieldValue]);

  const addEmployment = () => {
    setFieldValue('SharedB6a_1', -1);
    setTimeout(() => {
      submitForm();
    }, 0);
  };

  const editEmployment = (employmentIndex: number) => {
    setFieldValue('SharedB6a_1', employmentIndex);

    const employment = values.employment[employmentIndex] as IEmploymentType;
    setFieldValue('SharedE1_1', employment.employerName);
    setFieldValue('SharedE1_2', employment.addressStreetLine1);
    setFieldValue('SharedE1_3', employment.unitNumber);
    setFieldValue('SharedE1_4', employment.addressCity);
    setFieldValue('SharedE1_5', employment.addressState);
    setFieldValue('SharedE1_6', employment.addressPostalCode);
    setFieldValue('SharedE1_7', employment.phoneNumber);
    setFieldValue('SharedE1_8', employment.positionDescription);
    setFieldValue('SharedE1_9', employment.employmentStartDate);
    setFieldValue('SharedE1_10', employment.timeInLineOfWorkYears);
    setFieldValue('SharedE1_11', employment.basePayAmount);
    setFieldValue('SharedE1_12', employment.overtimeAmount);
    setFieldValue('SharedE1_13', employment.commissionsAmount);
    setFieldValue('SharedE1_14', employment.bonusAmount);

    submitForm();
  };

  const removeEmployment = (employmentIndex: number) => {
    setFieldValue(
      'employment',
      values.employment.filter((el, index) => index !== employmentIndex)
    );
  };

  const employmentSubtotal = (employment: IEmploymentType) => {
    return (employment.basePayAmount ?? 0) + (employment.overtimeAmount ?? 0) + (employment.commissionsAmount ?? 0) + (employment.bonusAmount ?? 0);
  };

  const isCurrentEmployment = (employment: IEmploymentType) => !!employment.currentEmploymentIndicator && !employment.selfEmployedIndicator;

  const hasEmployments = () => {
    return values.employment.some((employment: IEmploymentType) => isCurrentEmployment(employment));
  };

  const hasLimitEmployments = () => {
    return values.employment.filter((employment: IEmploymentType) => isCurrentEmployment(employment)).length >= 4;
  };

  return (
    <div className="border-bottom py-2 border-gray-dark">
      <Row>
        <CheckboxField name="ShowSharedB6a_" label="Salary or hourly wages" horizontal={true} className="fw-bold lg-label" xs={12} disabled={hasEmployments()} />
      </Row>
      {errors.atLeastOneBorrowerEmployment && (
        <div className="text-danger mb-3 mx-1">
          <small>{ValidationErrors.AtLeastOneEmployment}</small>
        </div>
      )}
      {!!values.ShowSharedB6a_ && (
        <>
          <MessageIndicator>If you file a 1099 tax form or pay yourself a salary from a business you own, please mark your income as self-employed.</MessageIndicator>
          <Question label="">
            {values.employment.map((employment: IEmploymentType, index: number) =>
              isCurrentEmployment(employment) ? (
                <Row className={`mb-2`}>
                  <Col md={6}>
                    <h6 className="text-primary mb-0 fw-bold">{employment.employerName}</h6>
                  </Col>
                  <Col md={6}>
                    <h6 className="text-primary mb-0 fw-bold text-end">{getCurrencyFormatted(employmentSubtotal(employment) ?? 0)}</h6>
                  </Col>
                  <Col md={4}>
                    <Button variant="link" onClick={() => editEmployment(index)} className="ps-0 mid-small text-decoration-underline">
                      Edit
                    </Button>
                    <Button variant="link" onClick={() => removeEmployment(index)} className="mx-2 ps-0 mid-small text-decoration-underline">
                      Remove
                    </Button>
                  </Col>
                </Row>
              ) : null
            )}
            <AddButtonText onClick={addEmployment} className="mid-small px-0" disabled={hasLimitEmployments()}>
              Add Employment
            </AddButtonText>
          </Question>
        </>
      )}
    </div>
  );
};

export default B6a;
