import { IMinuteRoute } from '../types';
import { toSlugId } from './stringUtils';

/**
 * replace params from the given Path
 * @param path the string path that will have replacements
 * @param params the objects params as collection that will have the values
 * @example replaceParams("/base/:loanId/:loanSection", {loanId: "123", loanSection: "loan"}); // "/base/123/loan"
 */
export const replacePathParams = (path: string, params: Record<string, string>) => {
  Object.entries(params).forEach(([paramName, paramValue]) => {
    const regex = new RegExp(`:${paramName}\\??`, 'g');
    path = path.replace(regex, paramValue as string);
  });
  return path;
};

/**
 * Build the routes
 * @param routesArray the array of routes
 */
export const routeBuilder = <T>(routesArray: IMinuteRoute[]) => {
  const flatRoutesArray: Array<T> = [];

  const routes: T[] = routesArray
    // completes the routes, this could be a generated file instead of map but left this iteration for now
    .map((route) => completeRoute(route)) as T[];

  function completeRoute(route: IMinuteRoute, parentRoute?: IMinuteRoute): IMinuteRoute {
    const title = typeof route.title === 'string' ? route.title : '';
    const itemSlug = route.slug ?? toSlugId(title);
    const path = `${parentRoute?.path ?? ''}/${itemSlug}`;
    const autoroute = route.autoroute ?? true;
    const breadcrumbDisplay = route.breadcrumbDisplay ?? route.title;

    const newRoute = {
      ...route,
      slug: itemSlug,
      path,
      parentRoute,
      autoroute,
      exact: route.exact ?? !route.isCategoryMenu,
      breadcrumbDisplay,
    };
    // register in the general flatRoutesArray, so can get metadata whetever we need based on the current Route
    if (!route.isCategoryMenu) {
      flatRoutesArray.push(newRoute as any);
    }

    newRoute.childs = route.childs?.map((childRoute: IMinuteRoute) => completeRoute(childRoute, newRoute));
    return newRoute;
  }

  return { routes, flatRoutesArray };
};
