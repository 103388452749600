import { FC, useEffect } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import TextField from '@rsl/core/src/components/form/TextField';
import { Row } from 'react-bootstrap';
import { useFetchZipCodeDetailQuery } from '@rsl/core/src/api/lookupsApi';
import { useFormikContext } from 'formik';

const W2a: FC<IQuestionType> = ({ showIf }) => {
  const { values, setValues } = useFormikContext<any>();
  const searchZip = (values.zipcode?.length ?? 0) >= 5;
  const { data: zipCodeDetail = null } = useFetchZipCodeDetailQuery(values.zipcode, { skip: !searchZip });

  useEffect(() => {
    if (zipCodeDetail) {
      setValues({ ...values, SharedW2a_1: zipCodeDetail.city, SharedW2a_2: zipCodeDetail.stateAbbrv });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValues, zipCodeDetail]);

  return (
    <Question label="What is the city and state of the new property?" showIf={showIf}>
      <Row>
        <TextField className="mb-2" placeholder="Zip" name="zipcode" required xs={6} />
      </Row>
      <Row>
        <TextField className="mb-2" placeholder="City" name="SharedW2a_1" required />
        <LookupSelect className="mb-2" placeholder="State" name="SharedW2a_2" lookup="licensedStates" required enableDefault={false} />
      </Row>
    </Question>
  );
};

export default W2a;
