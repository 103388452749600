import { FC } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row, Col } from 'react-bootstrap';
import TextField from '@rsl/core/src/components/form/TextField';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';
import PlacesAutoComplete, { PlaceType } from '../../../fields/PlacesAutocomplete';
import { IPlaceAutocompleteResponse } from '../../../fields/fields.types';
import { useLookup } from '@rsl/core/src/hooks/lookupsHooks';

const W2: FC<IQuestionType> = ({ showIf }) => {
  const { values, setValues } = useFormikContext<ILoanQuestionsMapType>();
  const { data: licensedStates } = useLookup<string>('licensedStates');

  return (
    <Question
      label={values.loanPurposeType === QuestionValues.LoanPurposeTypePurchase ? 'What is the address of the new property?' : 'What is your property address?'}
      showIf={showIf}>
      <Row>
        <Col xs={9}>
          <PlacesAutoComplete
            placeholder="Street Address"
            name="SharedW2_1"
            types={[PlaceType.ADDRESS]}
            className="mb-2 places-autocomplete"
            availableStates={licensedStates}
            onChange={(res: IPlaceAutocompleteResponse) =>
              setValues({
                ...values,
                SharedW2_1: res.address ?? values.SharedW2_1,
                SharedW2_2: res.city ?? values.SharedW2_2,
                SharedW2_3: res.state ?? values.SharedW2_3,
                SharedW2_4: res.zipCode ?? values.SharedW2_4,
              })
            }
          />
        </Col>
        <TextField xs={3} className="mb-2" placeholder="Unit" name="SharedW2_5" />
      </Row>
      <Row>
        <TextField className="mb-2" placeholder="City" name="SharedW2_2" required xs={6} md={4} />
        <LookupSelect className="mb-2" placeholder="State" name="SharedW2_3" lookup="licensedStates" required xs={6} md={4} enableDefault={false} />
        <TextField className="mb-2" placeholder="Zip" name="SharedW2_4" required xs={6} md={4} />
      </Row>
    </Question>
  );
};

export default W2;
