import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { ValidationErrors } from '@rsl/core/src/constants/errors';

const C6 = () => {
  const { values, errors } = useFormikContext<ILoanQuestionsMapType>();

  return (
    <>
      <h2 className="fw-bold text-center">
        Tell us about your monthly income for <span className="text-primary">{values.SharedC1_1}</span>.
      </h2>
      {errors.atLeastOneChecked && (
        <div className="text-danger">
          <small>{ValidationErrors.AtLeastOneChecked}</small>
        </div>
      )}
    </>
  );
};

export default C6;
