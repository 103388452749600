import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';

const L2a = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  return (
    <div className="border-bottom py-2 border-gray-dark">
      <Row>
        <CheckboxField className="fw-bold" name="ShowSharedL2a_" label="Alimony" horizontal={true} />
      </Row>
      {!!values.ShowSharedL2a_ && (
        <>
          <Row className="pt-1">
            <CurrencyField className="mb-2" label="How much do you pay monthly?" name="SharedL2a_1" xs={12} isInlineLabel />
          </Row>
        </>
      )}
    </div>
  );
};

export default L2a;
