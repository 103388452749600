import Question from '../../Question';
import { Row } from 'react-bootstrap';
import TextField from '@rsl/core/src/components/form/TextField';
import MaskedField from '@rsl/core/src/components/form/MaskedField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';

const E2 = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  return values.PurchaseE1_1 === QuestionValues.Yes ? (
    <Question label="Please provide your realtor contact info?">
      <Row>
        <TextField className="mb-2" xs={12} md={6} placeholder="First name" name="PurchaseE2_1" required />
        <TextField className="mb-2" xs={12} md={6} placeholder="Last name" name="PurchaseE2_2" required />
      </Row>
      <Row>
        <TextField className="mb-2" xs={12} md={6} placeholder="Email" name="PurchaseE2_3" required />
        <MaskedField className="mb-2" placeholder="Mobile number" name="PurchaseE2_4" mask="(999) 999-9999" mutation={/[^0-9]+/g} nullable />
      </Row>
    </Question>
  ) : null;
};

export default E2;
