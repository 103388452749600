import { FC } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';
import { IQuestionType } from '@rsl/core/src/types';
import { useEditingFormikField } from '@rsl/core/src/hooks/fieldHooks';

const C1i: FC<IQuestionType> = ({ showIf }) => {
  const editingProps = useEditingFormikField();

  return (
    <Question label="What is your rent amount?" showIf={showIf}>
      <Row>
        <CurrencyField className="mb-2" placeholder="Rent amount" name="SharedC1i_1" required {...editingProps} />
      </Row>
    </Question>
  );
};

export default C1i;
