/**
 * ParseJWt Token and return it's props
 * @param token current Token
 * @returns parsed token
 */
export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return console.error('Invalid JWT token');
  }
};
