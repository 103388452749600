import { IPublicRoute } from '@rsl/core/src/types';
import DeviceVerification from './pos/DeviceVerification';
import PasswordChange from './pos/PasswordChange';
import Pos from './pos/Pos';
import ResetPasswordRequest from './pos/ResetPasswordRequest';
import SignIn from './pos/SignIn';
import Register from './pos/Register';
import { Redirect } from 'react-router-dom';

const routes: IPublicRoute = {
  title: 'Apply Now',
  displayOnMenu: false,
  exact: true,
  render: () => <Redirect to="/apply-now/register" />,
  childs: [
    {
      title: 'Sign In',
      slug: `sign-in/:existing?`,
      displayOnMenu: false,
      render: () => <SignIn />,
    },
    {
      title: 'Register',
      slug: `register/:referralSource?`,
      displayOnMenu: false,
      render: () => <Register />,
    },
    {
      title: 'Edit',
      slug: 'edit/:loanPosApplicationId/:posStep?',
      render: () => <Pos />,
    },
    {
      title: '',
      slug: 'reset-password-request',
      displayOnMenu: false,
      render: () => <ResetPasswordRequest />,
    },
    {
      title: 'Reset Password',
      displayOnMenu: false,
      render: () => <PasswordChange isFirstTime={false} />,
    },
    {
      title: 'First Time Password',
      displayOnMenu: false,
      render: () => <PasswordChange isFirstTime={true} />,
    },
    {
      title: 'Register Device',
      displayOnMenu: false,
      render: () => <DeviceVerification />,
    },
    {
      title: 'Apply now',
      slug: ':loanPosApplicationId/:posStep?',
      render: () => <Pos />,
    },
  ],
};

export default routes;
