import { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';

const FormikCollectTouchedFiles: FC<{ onTouchedChange: (fields: string[]) => void }> = ({ onTouchedChange }) => {
  const { touched, isSubmitting, initialValues, values } = useFormikContext<any>();

  useEffect(() => {
    if (!isSubmitting) {
      const newTouchedIds = Object.keys(touched).reduce<string[]>((touchedIds, id) => [...touchedIds, ...(values[id] !== initialValues[id] ? [id] : [])], []);
      onTouchedChange(newTouchedIds);
    }
  }, [initialValues, touched, onTouchedChange, isSubmitting, values]);

  return <></>;
};

export default FormikCollectTouchedFiles;
