const EConsentAgreement = () => {
  return (
    <>
      <p>
        You are providing information electronically via this mobile or web-based app. The purpose of this eDisclosure is to obtain your consent to receive certain information via
        this mobile or web-based technology in electronic format rather than in paper form.
      </p>
      <p>
        Before we can engage in this transaction electronically, it is important that you understand your rights and responsibilities. Please read the following and affirm your
        consent to conduct business with us electronically. For purposes of this eDisclosure Agreement, 'Disclosures' means the disclosures provided to you above regarding sharing
        of information and Credit Check Authorization as well as any Loan
      </p>
      <p>
        Documents related to a transaction or subsequent loan application that may arise from your loan inquiry that are provided electronically. The words 'you' and 'your' mean
        the borrower(s) under any loan that results from this inquiry to which such Loan Documents apply, and 'we', 'our' and 'us' mean the applicable mortgage broker(s), loan
        processor(s) or mortgage banker(s) with whom you may transact business with for such loan(s).
      </p>
      <p> Please read the following and affirm your consent to conduct business with us electronically via the methods described above.</p>
      <p>
        <u>
          <strong>YOUR CONSENT</strong>
        </u>
      </p>
      <ul>
        <li>
          You consent to participate in the sharing of information with us electronically and this will apply to all paperwork and information shared with us using this technology.
          If you provide your consent by clicking the “I agree” I agree button at the bottom of the page, we will conduct business with you electronically as it pertains to the use
          of this app and the sharing of information via this app. Should this loan inquiry become a formal loan application, we will conduct that transaction with you
          electronically, instead of providing you with Loan Documents in paper form.
        </li>
        <li>
          {' '}
          If your loan inquiry results in a full loan application and a document related to that loan is not available in electronic form, a paper copy will be provided to you free
          of charge.
        </li>
        <li>
          {' '}
          Conducting the loan inquiry (or any loan transaction as a result of your loan inquiry) electronically is an option. If you choose not to accept receipt of information via
          this electronic format, you will be provided other options at no cost to you.
        </li>
      </ul>
      <p>
        <u>
          <strong>WITHDRAWAL OF CONSENT</strong>
        </u>
      </p>
      <ul>
        <li>
          You have the right to withdraw your consent at any time. By declining or revoking your consent to do business electronically or to receive eDislosures, we will provide
          you with the disclosures in paper form.
        </li>
        <li>
          {' '}
          If you originally consent to receive eDislcosures, but later decide to withdraw your consent, you can do so by clicking on the “I do not agree” button or by notifying us
          at:
          <br />
          Phone: (602) 617-4244
          <br />
          Address: 8388 E Hartford Dr Scottsdale, AZ, 85255
        </li>
        <li>
          After your consent is given, you may request from us (at the above set forth contact information) paper copies of your loan documents. If you request paper copies of any
          documents: You will not be required to pay a fee for receiving paper copies of the documents.
        </li>
      </ul>
      <p>
        <u>
          <strong>SYSTEM_REQUIREMENTS</strong>
        </u>
      </p>
      <ul>
        <li>
          In order to receive eDisclosures, you must have a computer with Internet access and an Internet email account and address; an Internet browser using 128-bit encryption or
          higher, Adobe Acrobat 7.0 or higher, SSL encryption and access to a printer or the ability to download information in order to keep copies of your eDisclosures for your
          records.
        </li>
        <li>
          If the software or hardware requirements change in the future, and you are unable to continue receiving eDisclosures, paper copies of such Loan Documents will be mailed
          to you once you notify us that you are no longer able to access the eDisclosures because of the changed requirements. We will use commercially reasonable efforts to
          notify you before such requirements change. If you choose to withdraw your consent upon notification of the change, you will be able to do so without penalty.
        </li>
      </ul>
      <p>
        <u>
          <strong>HOW WE CAN REACH YOU</strong>
        </u>
      </p>
      <ul>
        <li>
          You must promptly notify us if there is a change in your email address or in other information needed to contact you electronically. You can contact us at: Phone: (602)
          617-4244
          <br />
          Address: 8388 E Hartford Dr Scottsdale, AZ, 85255
        </li>
        <li>
          We will not assume liability for nonreceipt of notification of the availability of eDisclosures in the event your email address on file is invalid; your email or Internet
          service provider filters the notification as 'spam' or 'junk mail'; there is a malfunction in your computer, browser, Internet service and/or software; or for other
          reasons beyond our control.
        </li>
      </ul>
    </>
  );
};

export default EConsentAgreement;
