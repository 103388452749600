import { FC, useMemo, useState } from 'react';
import { ILoanNeedsListItem } from '@rsl/core/src/types';
import { useFetchNeededListQuery } from '../api/fileshareApi';
import { useCurrentLoanId } from 'app/hooks/loanHooks';
import UploadShareModal from './UploadShareModal';
import FilesNeedList from './FilesNeedList';
import { getFilesGroup } from '../utils/filterFileUtils';
import { useExternalAuth } from 'app/hooks/authHooks';

const FilesNeeded: FC = () => {
  const loanId = useCurrentLoanId();
  const { email } = useExternalAuth();
  const [currentNeededList, setCurrentNeededList] = useState<ILoanNeedsListItem | null>(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { data: neededFiles } = useFetchNeededListQuery(loanId, { skip: !loanId });

  const unSubmittedFiles = useMemo(() => getFilesGroup(email, false, neededFiles), [neededFiles, email]);
  const submittedFiles = useMemo(() => getFilesGroup(email, true, neededFiles), [neededFiles, email]);

  const popupModal = (neddList: ILoanNeedsListItem) => {
    setShowUploadModal(true);
    setCurrentNeededList(neddList);
  };

  const hideModal = () => {
    setShowUploadModal(false);
  };

  return (
    <>
      <FilesNeedList
        neededFiles={neededFiles}
        isSubmittedStatus={false}
        popupModal={popupModal}
        title="Un-Submitted"
        files={unSubmittedFiles}
        showTitle={!!unSubmittedFiles.length}
      />
      <FilesNeedList
        neededFiles={neededFiles}
        isSubmittedStatus={true}
        popupModal={popupModal}
        title="Submitted"
        files={submittedFiles}
        showTitle={!!unSubmittedFiles.length && !!submittedFiles.length}
      />

      {currentNeededList && (
        <UploadShareModal
          title={currentNeededList.documentation}
          size="lg"
          modalShow={showUploadModal}
          onClose={hideModal}
          needList={currentNeededList}
          key={currentNeededList.loanNeedsListId}
        />
      )}
    </>
  );
};

export default FilesNeeded;
