import { FC } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import TextField from '@rsl/core/src/components/form/TextField';
import { IQuestionType, ILoanQuestionsMapType } from '@rsl/core/src/types';
import { useFormikContext } from 'formik';

const C4: FC<IQuestionType> = ({ showIf }) => {
  const { setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  const onChange = () => {
    setFieldValue('SharedC4a_1', '');
  };

  return (
    <Question
      label="How many dependents are living with you?"
      small="All dependents must be between 0 and 18 years old, or must be financially dependent upon you."
      showIf={showIf}>
      <Row>
        <TextField type="number" className="mb-2" placeholder="#" name="SharedC4_1" onChange={onChange} required />
      </Row>
    </Question>
  );
};

export default C4;
