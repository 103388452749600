import { FC, useState } from 'react';

import { capitalizeNameInitials } from '../../utils/stringUtils';
import { VariantType } from '../../types/common.types';

import style from './NameBadge.module.scss';

interface NameBadgeProps {
  name: string;
  className?: string;
  variant?: VariantType;
  src?: string;
}
const NameBadge: FC<NameBadgeProps> = ({ name, className, variant = 'primary', src }) => {
  const initials = useState(capitalizeNameInitials(name));
  return (
    <div className={`d-inline-block ${className}`}>
      <div className={`${style.badge} bg-${variant}`}>{src ? <img src={src} alt={name} className="w-100 rounded-circle" /> : <span>{initials}</span>}</div>
    </div>
  );
};

export default NameBadge;
