import LoanRoleSecure from 'app/components/auth/LoanRoleSecure';
import SidebarDocumentLink from 'app/components/sidebar/SidebarDocumentLink';
import { LoanRoles, LoanSummaryExternal } from 'app/types';
import { useFetchLsuDocumentQuery, useFetchPreQualDocumentQuery } from 'features/loan/api/loanPreQualLsuApi';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as DownloadIcon } from 'app/assets/icons/download.svg';
import { QuestionValues } from '@rsl/core/src/constants';

interface PrequalLinkProps {
  loan: LoanSummaryExternal;
}
const PrequalLink: FC<PrequalLinkProps> = ({ loan }) => {
  return (
    <>
      <SidebarDocumentLink
        filename="Pre-qualification Letter"
        useFetchHook={useFetchPreQualDocumentQuery}
        to={(data) => `/LoanPreQualLsuExternal/${loan.loanId}/PreQual/LoanDocuments/${data.loanDocumentId}/download`}
        icon={DownloadIcon}
        editAction={
          <LoanRoleSecure roles={[LoanRoles.BuyersAgent]}>
            <Link to={`/loan/${loan.loanId}/pre-qual`} className="py-0">
              <i className="align-middle fas fa-fw fa-pencil-alt text-secondary" />
            </Link>
          </LoanRoleSecure>
        }
        loanId={loan.loanId}>
        DOWNLOAD PREQUALIFICATION LETTER
      </SidebarDocumentLink>
      {loan.propertyLoanPurposeType === QuestionValues.LoanPurposeTypePurchase && (
        <LoanRoleSecure roles={[LoanRoles.SellersAgent, LoanRoles.BuyersAgent]} currentRole={loan.role}>
          <SidebarDocumentLink
            filename="Pre-qualification Letter"
            useFetchHook={useFetchLsuDocumentQuery}
            to={(data) => `/LoanPreQualLsuExternal/${data.loanId}/LSU/OnDemand`}
            icon={DownloadIcon}
            loanId={loan.loanId}>
            Loan Status Update
          </SidebarDocumentLink>
        </LoanRoleSecure>
      )}
    </>
  );
};

export default PrequalLink;
