import { useFieldIds } from '@rsl/core/src/hooks/fieldHooks';
import { Field, useFormikContext } from 'formik';
import { FC, useMemo, useState, useEffect, useCallback, KeyboardEvent } from 'react';
import { IMinuteField, TextFieldTypeTypes } from '../../types/form.types';
import { IIntellisenseValue } from '@rsl/core/src/types';
import MinuteField from './MinuteField';
import Tribute from 'tributejs';
import classNames from 'classnames';

interface ITextField {
  type?: TextFieldTypeTypes;
  placeholder?: string;
  rows?: number;
  cols?: number;
  textarea?: boolean;
  maxLength?: number;
  intellisenseTrigger?: string;
  intellisenseValues?: IIntellisenseValue[];
  intellisenseSelectTemplate?: (item: any) => string;
  max?: string | number;
  min?: string | number;
  innerRef?: (el: HTMLElement | null) => void;
  className?: string;
}

const TextField: FC<IMinuteField<string> & ITextField> = ({
  rows,
  cols,
  type = 'text',
  placeholder,
  textarea,
  maxLength,
  intellisenseTrigger = '',
  intellisenseValues = [],
  intellisenseSelectTemplate,
  max,
  min,
  innerRef,
  className = '',
  ...props
}) => {
  const { id, name } = useFieldIds(props);
  const { values, setFieldValue, handleBlur } = useFormikContext();
  const [node, setNode] = useState<HTMLElement | null>(null);
  const [dateInputFocused, setDateInputFocused] = useState<boolean>(false);

  useEffect(() => {
    if (node && intellisenseTrigger && intellisenseValues.length) {
      const tribute = new Tribute({
        trigger: intellisenseTrigger,
        containerClass: 'intellisense-container',
        selectClass: 'intellisense-highlight',
        values: intellisenseValues,
        menuItemLimit: 15,
        selectTemplate: intellisenseSelectTemplate,
      });
      // For better performance try to set menuContainer prop to MinuteField element
      tribute.attach(node as Element);
    }
  }, [node, intellisenseSelectTemplate, intellisenseTrigger, intellisenseValues]);

  const onFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setDateInputFocused(true);
    },
    [setDateInputFocused]
  );

  // TODO: Remove this mutation, this should be modified on Save, not here
  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      handleBlur(event);
      const trimmedValue = (event.target.value || '').trim();
      setFieldValue(name, trimmedValue);
      props.onBlur?.(event);
      setDateInputFocused(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleBlur, setFieldValue, name, setDateInputFocused]
  );

  const dateInputHasValue = useCallback(() => {
    return type === 'date' && !!(values as any)[name];
  }, [name, type, values]);

  return useMemo(() => {
    return (
      <MinuteField {...props} fieldNode={node}>
        <Field
          as={textarea ? 'textarea' : undefined}
          type={type}
          disabled={props.disabled}
          onKeyDown={(e: KeyboardEvent) => props.readonly && e.preventDefault()}
          className={classNames('form-control', className, !dateInputFocused && !dateInputHasValue() && 'date-input-placeholder')}
          id={id}
          data-testid={id}
          placeholder={placeholder}
          name={name}
          rows={rows}
          cols={cols}
          innerRef={(el: HTMLElement) => {
            setNode(el);
            innerRef?.(el);
          }}
          maxLength={maxLength}
          onBlur={onBlur}
          max={max ? max : type === 'date' ? '2199-12-31' : undefined}
          min={min ? min : type === 'date' ? '1900-01-01' : undefined}
          onFocus={onFocus}
        />
      </MinuteField>
    );
  }, [id, name, node, rows, cols, type, placeholder, textarea, maxLength, props, onBlur, max, min, innerRef, className, dateInputFocused, dateInputHasValue, onFocus]);
};

export default TextField;
