import { baseApi } from '@rsl/core/src/api/baseApi';
import { ApiResponse } from '@rsl/core/src/types';
import { ICommunicationLog } from '@rsl/core/src/types/communication.types';
import { invalidateOnSuccess } from '@rsl/core/src/utils/cacheUtils';
import { ExternalUserInventoryCount, LoanSummaryExternal, LoanLiteFilter, ExternalUserSessionResponse } from 'app/types';
import { PagedContainer } from 'app/types/common.types';

export const externalSessionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchExternalLoan: build.query<PagedContainer<LoanSummaryExternal>, LoanLiteFilter | void>({
      query: (filter) =>
        `/externaluser/paged-loans${
          filter
            ? `?${Object.entries(filter)
                .map(([key, value]) => `${key}=${value}`)
                .join('&')}`
            : ''
        }`,
      transformResponse: (response: ApiResponse<PagedContainer<LoanSummaryExternal>>) => response.data,
      providesTags: ['ExternalUserLoans'],
    }),
    // Messages
    fetchExternalMessages: build.query<ICommunicationLog[], string>({
      query: (loanId) => `/externaluser/${loanId}/messages?byOlderFirst=true`,
      transformResponse: (response: ApiResponse<ICommunicationLog[]>) => response.data,
      providesTags: ['Communication'],
    }),
    messageAcknowledge: build.mutation<ICommunicationLog, number>({
      query: (commLogId) => ({ url: `/externaluser/messages/${commLogId}/acknowledge`, method: 'POST' }),
      transformResponse: (response: ApiResponse<ICommunicationLog>) => response.data,
      invalidatesTags: invalidateOnSuccess(['Communication']),
    }),
    sendMessage: build.mutation<ICommunicationLog, Omit<ICommunicationLog, 'communicationLogId'>>({
      query: (body) => ({ url: 'externaluser/messages/send', method: 'POST', body: body }),
      invalidatesTags: invalidateOnSuccess(['Communication']),
    }),
    // Loan Summary
    fetchExternalLoanSummary: build.query<LoanSummaryExternal, string>({
      query: (loanId) => `/externaluser/${loanId}/summary`,
      transformResponse: (response: ApiResponse<LoanSummaryExternal>) => response.data,
      providesTags: ['LoanPosDashboardItems'],
    }),
    fetchExternalLoanInventoryCount: build.query<ExternalUserInventoryCount, string>({
      query: (loanId) => `/externaluser/${loanId}/inventoryCount`,
      transformResponse: (response: ApiResponse<ExternalUserInventoryCount>) => response.data,
    }),
    exhangeOtp: build.mutation<ExternalUserSessionResponse, { externalUserId: string; otp: string }>({
      query: (body) => ({
        url: `/externaluser/exchange`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ApiResponse<ExternalUserSessionResponse>) => response.data,
      invalidatesTags: invalidateOnSuccess(['Communication']),
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchExternalLoanQuery,
  useFetchExternalMessagesQuery,
  useMessageAcknowledgeMutation,
  useSendMessageMutation,
  useFetchExternalLoanSummaryQuery,
  useFetchExternalLoanInventoryCountQuery,
  useExhangeOtpMutation,
} = externalSessionApi;
