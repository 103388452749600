import { useEffect } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import MessageIndicator from '../../../shared/MessageIndicator';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { useFormikContext } from 'formik';

const B7 = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.SharedB7_1 !== QuestionValues.ApplyingWithPerson) {
      setFieldValue('SharedB8_1', undefined);
      setFieldValue('SharedB8a_1', undefined);
    }

    if (values.SharedB7_1 === QuestionValues.ApplyingWithPerson && values.SharedB3_1 !== QuestionValues.Married) {
      setFieldValue('SharedB8_1', QuestionValues.No);
      setFieldValue('SharedB8a_1', QuestionValues.No);
    }

    if (values.isCoborrowerInvite && values.SharedB7_1 === QuestionValues.ApplyingWithPerson) {
      setFieldValue('SharedB8_1', QuestionValues.Yes);
    }
  }, [values.SharedB7_1, values.isCoborrowerInvite, setFieldValue]);

  return (
    <Question label="Are you applying on your own, or jointly with another person?">
      <MessageIndicator>If applying with another person, we'll refer to that person as your co-borrower throughout the application.</MessageIndicator>
      <Row>
        <RadioField
          className="mb-2"
          name="SharedB7_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.ApplyingMyOwn, value: QuestionValues.ApplyingMyOwn },
            { label: values.isCoborrowerInvite ? 'Applying with my legal spouse' : QuestionValues.ApplyingWithPerson, value: QuestionValues.ApplyingWithPerson },
          ]}
        />
      </Row>
    </Question>
  );
};

export default B7;
