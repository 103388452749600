import { strEnum } from '../utils/enumUtils';
import { IMinuteAttachment } from './fileShare.types';

export interface ILoanNeedsListItem {
  loanId: string;
  loanNeedsListId: number;
  needsListItemId?: number;
  documentation: string;
  description: string;
  createdDateTimeUtc?: Date;
  createdBy?: string;
  loanNeedsListBorrowers: ILoanNeedsListBorrowerExt[];
  fileShareUploads: ILoanNeedsListUpload[];
}

export interface ILoanNeedsListBorrower {
  borrowerType: string;
  email: string;
  name: string;
  displayName: string;
}

export interface ILoanNeedsListBorrowerExt extends ILoanNeedsListBorrower {
  loanNeedsListBorrowerId: number;
  loanNeedsListId: number;
  status: LoanNeedsListBorrowerStatus;
  clearedDateTimeUtc?: Date;
  reRequestedDateTimeUtc?: Date;
  updatedDateTimeUtc?: string;
}

export interface ILoanNeedsListUpload extends IMinuteAttachment {
  fileshareUploadId: number;
  loanNeedsListId: number;
  sessionLogId: string;
  createdDateTimeUtc: Date;
  fileShareUploadBorrowers?: ILoanNeedsListBorrowerExt[];
}

export enum LoanNeedsListBorrowerStatus {
  Created = 'Created',
  Requested = 'Requested',
  ReRequested = 'Re-Requested',
  Uploaded = 'Uploaded',
  Cleared = 'Cleared',
  Waived = 'Waived',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Imported = 'Imported',
  ImportedPending = 'Import Pending',
}
