import { IProtectedRoute } from '@rsl/core';
import MinuteTabRoutes from '@rsl/core/src/components/router/MinuteTabRoutes';
import { useCurrentLoanId } from 'app/hooks/loanHooks';
import FileShared from 'features/fileshare/components/FileShared';
import FilesNeeded from 'features/fileshare/components/FilesNeeded';
import Inbox from 'features/loan/messages/Inbox';
import MyTasks from 'features/loan/tasks/MyTasks';
import DashboardTransaction from './DashboardTransaction';
import { useIsMobile } from '@rsl/core/src/hooks/windowEventHooks';
import { ReactComponent as TransactionIcon } from 'app/assets/icons/transaction.svg';
import { ReactComponent as TaskIcon } from 'app/assets/icons/task.svg';
import { ReactComponent as InboxIcon } from 'app/assets/icons/messages-single.svg';
import { ReactComponent as FilesNeededIcon } from 'app/assets/icons/filesNeededIcon.svg';
import { ReactComponent as FilesSharedIcon } from 'app/assets/icons/filesSharedIcon.svg';
import SidebarItemNotification from 'app/components/sidebar/SidebarItemNotification';
import { useFetchExternalLoanSummaryQuery } from 'app/api/externalSessionApi';
import { useEffect, useState } from 'react';
import { LoanRoles } from 'app/types';
import { cleanRoutesByRole } from 'app/utils/loanRoleUtils';

const tabRoutes: IProtectedRoute[] = [
  {
    title: '',
    breadcrumbDisplay: 'Transaction',
    slug: 'transaction',
    autoroute: false,
    displayOnMenu: true,
    render: () => <DashboardTransaction />,
  },
  {
    title: '',
    breadcrumbDisplay: 'Tasks',
    slug: 'tasks',
    autoroute: false,
    displayOnMenu: true,
    render: () => <MyTasks />,
  },
  {
    title: '',
    breadcrumbDisplay: 'Messages',
    slug: 'messages',
    autoroute: false,
    displayOnMenu: true,
    render: () => <Inbox />,
  },
  {
    title: '',
    breadcrumbDisplay: 'Documents Needed',
    slug: 'filesNeeded',
    autoroute: false,
    displayOnMenu: true,
    restrictedRoles: [LoanRoles.Borrower, LoanRoles.CoBorrower],
    render: () => <FilesNeeded />,
  },
  {
    title: '',
    breadcrumbDisplay: 'Shared Documents',
    slug: 'sharedResources',
    autoroute: false,
    displayOnMenu: true,
    render: () => <FileShared />,
  },
];

const DashboardDetailTabs = () => {
  const loanId = useCurrentLoanId();
  const { data: loan } = useFetchExternalLoanSummaryQuery(loanId ?? '', { skip: !loanId, refetchOnMountOrArgChange: true });

  const [finalRoutes, setFinalRoutes] = useState<IProtectedRoute[]>([]);

  const isMobile = useIsMobile();

  useEffect(() => {
    const [{ ...transaction }, { ...tasks }, { ...messages }, { ...filesNeeded }, { ...sharedResources }] = tabRoutes;

    transaction.titleElement = <SidebarItemNotification title="Transaction" icon={TransactionIcon} linkClassName="fw-bold text-reset" className="py-0" hideTitleOnMobile />;
    tasks.titleElement = (
      <SidebarItemNotification title="Tasks" notificationNumber={loan?.tasksCount} icon={TaskIcon} linkClassName="fw-bold text-reset" className="py-0" hideTitleOnMobile />
    );
    messages.titleElement = (
      <SidebarItemNotification title="Messages" notificationNumber={loan?.messageCount} icon={InboxIcon} linkClassName="fw-bold text-reset" className="py-0" hideTitleOnMobile />
    );
    filesNeeded.titleElement = (
      <SidebarItemNotification
        title="Documents Needed"
        notificationNumber={loan?.needslistCount}
        icon={FilesNeededIcon}
        linkClassName="fw-bold text-reset"
        className="py-0"
        hideTitleOnMobile
      />
    );
    sharedResources.titleElement = (
      <SidebarItemNotification
        title="Shared Documents"
        notificationNumber={loan?.fileshareCount}
        icon={FilesSharedIcon}
        linkClassName="fw-bold text-reset"
        className="py-0"
        hideTitleOnMobile
      />
    );

    const routesBeforeCleaning: IProtectedRoute[] = [transaction, tasks, messages, filesNeeded, sharedResources];
    const cleanRoutes = cleanRoutesByRole(routesBeforeCleaning, loan?.role as any);

    setFinalRoutes(cleanRoutes);
  }, [loan?.tasksCount, loan?.messageCount, loan?.needslistCount, loan?.fileshareCount, loan?.role, isMobile]);

  return <MinuteTabRoutes paramName="loanSection" subParamName="" tabs={finalRoutes} baseRoute={`/loan/${loanId}`} defaultTab="transaction" />;
};

export default DashboardDetailTabs;
