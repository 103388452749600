const usdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const numberFormatter = new Intl.NumberFormat('en-US');

/**
 * get formatted number as currency
 * @param value the number to format
 */
export const getCurrencyFormatted = (value: number): string => {
  return usdFormatter.format(value);
};

export const getNumberFormatted = (value: number): string => {
  return numberFormatter.format(value);
};
