import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import Asset from './Asset';

const A2d = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  const show = (event: any) => {
    if (event.target.checked) {
      setFieldValue('SharedA2d_Accounts', [{ amount: '', institutionName: '' }]);
    }
  };

  return (
    <div className="border-bottom py-2 border-gray-dark">
      <Row>
        <CheckboxField
          className="fw-bold lg-label"
          name="ShowSharedA2d_"
          label="Stock and bonds (brokerage account)"
          horizontal={true}
          disabled={values.ShowSharedA2_}
          onClick={show}
        />
      </Row>
      {!!values.ShowSharedA2d_ && <Asset namePrefix="SharedA2d_" multiple />}
    </div>
  );
};

export default A2d;
