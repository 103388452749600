import { FC } from 'react';
import { Button } from 'react-bootstrap';

import { useArchiveFileMutation } from '../api/fileshareApi';
import { ILoanNeedsListBorrower, ILoanNeedsListBorrowerExt, ILoanNeedsListUpload } from '@rsl/core/src/types';
import BorrowersOverview from './BorrowersOverview';

import { ReactComponent as TrashIcon } from 'app/assets/icons/trash.svg';
import { ReactComponent as ClipIcon } from 'app/assets/icons/clip.svg';
import { ReactComponent as UserIcon } from 'app/assets/icons/user.svg';

import styles from './FileUploads.module.scss';
import { formatDateTime } from '@rsl/core/src/utils/dateUtils';

interface FileUploadsProps {
  uploads?: ILoanNeedsListUpload[];
  borrowers?: ILoanNeedsListBorrower[];
  canDelete: boolean;
}

const FileUploads: FC<FileUploadsProps> = ({ uploads = [], borrowers, canDelete }) => {
  const [deleteFile] = useArchiveFileMutation();

  return (
    <ul className={`d-flex ps-2 flex-column list-unstyled ${styles.files} ms-4`}>
      {uploads.map((file: ILoanNeedsListUpload) => (
        <li className="d-flex py-2 justify-content-between border-bottom" key={`file-${file.serverFilename}`}>
          <div>
            <div>
              <span className="me-md-2">
                <ClipIcon />
              </span>{' '}
              <small>
                {file.filename} ({formatDateTime(file.createdDateTimeUtc)})
              </small>
            </div>
            <div>
              <span className="me-1 me-md-2">
                <UserIcon />
              </span>
              <small>
                <BorrowersOverview allBorrowers={borrowers as ILoanNeedsListBorrowerExt[]} borrowers={file.fileShareUploadBorrowers} />
              </small>
            </div>
          </div>
          <div className="d-flex align-items-center">
            {canDelete && (
              <Button variant="link" className="p-0 pe-md-3" onClick={() => deleteFile(file.fileshareUploadId)}>
                <TrashIcon />
              </Button>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FileUploads;
