import { IBorrowerType } from '../../types';
import { QuestionValues } from './questionValues.constants';
import { emptyResidenceConstant } from './emptyResidence.constants';
import { emptyMailingAddressConstant } from './emptyMailingAddress.constants';

export const emptyLoanBorrowerConstant: IBorrowerType = {
  applicationTakenMethodType: 'Internet',
  emailAddressText: '',
  firstName: '',
  hmdaOtherHispanicLatinoOrigin: '',
  hmdaAmericanIndianTribe: '',
  hmdaOtherAsianRace: '',
  hmdaOtherPacificIslanderRace: '',
  homePhoneNumber: '',
  isBorrower: true,
  lastName: '',
  middleName: '',
  mobilePhone: '',
  priorPropertyTitleType: '',
  priorPropertyUsageType: '',
  urla2020CitizenshipResidencyType: '',
  employment: [],
  mailingAddress: {
    ...emptyMailingAddressConstant,
  },
  residences: [
    {
      ...emptyResidenceConstant,
      residencyType: 'Current',
    },
  ],
  incomes: [
    { amount: 0, incomeType: 'Base', otherDescription: '' },
    { amount: 0, incomeType: 'SelfEmployed', otherDescription: '' },
    { amount: 0, incomeType: 'SocialSecurity', otherDescription: '' },
    { amount: 0, incomeType: 'Pension', otherDescription: '' },
    { amount: 0, incomeType: 'Disability', otherDescription: '' },
    { amount: 0, incomeType: 'ChildSupport', otherDescription: '' },
    { amount: 0, incomeType: 'Alimony', otherDescription: '' },
    { amount: 0, incomeType: QuestionValues.Other, otherDescription: '' },
  ],
  assets: [],
  otherLiabilities: [],
  uiCopyAddressFromBorrower: false,
};
