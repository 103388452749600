import classNames from 'classnames';
import { FC } from 'react';
import styles from './MilestoneCheck.module.scss';
import { ReactComponent as CheckIcon } from 'app/assets/icons/milestone.svg';
import { ReactComponent as CheckIconCheck } from 'app/assets/icons/milestone-checked.svg';
interface MilestoneCheckProps {
  active?: boolean;
  className?: string;
}
const MilestoneCheck: FC<MilestoneCheckProps> = ({ active = false, className }) => {
  return (
    <div className={classNames('d-inline-block ms-0 mt-0 me-2 bg-light position-relative align-middle', className, styles.check)}>
      {active ? <CheckIconCheck /> : <CheckIcon />}
    </div>
  );
};

export default MilestoneCheck;
