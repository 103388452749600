import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RouteManagerState {
  /**
   * route that is pending to be redirected by The app
   */
  intendedRoute: string | null;
}

const initialState: RouteManagerState = {
  intendedRoute: null,
};

export const routeManagerSlice = createSlice({
  name: 'routeManager',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIntendedRoute: (state, { payload }: PayloadAction<string | null>) => {
      state.intendedRoute = payload;
    },
  },
});

export const { setIntendedRoute } = routeManagerSlice.actions;

export default routeManagerSlice.reducer;
