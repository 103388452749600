import { Children, createElement, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Form, FormikConfig, FormikProps, FormikProvider, FormikValues, isEmptyChildren, isFunction, useFormik } from 'formik';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { wrapSubmitCommon } from '@rsl/core/src/utils/formUtils';
import FormCancelSaveButtons from './FormCancelSaveButtons';
import FormikCollectTouchedFiles from './FormikCollectTouchedFiles';
import LockFormOnChanges from './LockFormOnChanges';
import { FlexAlign, Sizes } from '../../constants/alignConstants';
import { VariantType, ICustomFormAction } from '../../types';

/**
 * Most of the Code is taken from the Formik library. original definition at: https://github.com/jaredpalmer/formik/blob/e677bea8181f40e6762fc7e7fb009122384500c6/packages/formik/src/Formik.tsx#L988
 */

interface ExtendedFormikProps {
  /**
   * callback function when some fields changed after been touched
   */
  onTouchedChange?: (fields: string[]) => void;
  /**
   * is the current form on Saving State?
   * @default false
   */
  isSaving?: boolean;
  /**
   * is the current form on Loading State?
   * @default false
   */
  isLoading?: boolean;
  /**
   * do you want the Save Button to be displayed?
   * @default true
   */
  showSaveButton?: boolean;
  /**
   * do you want the Cancel Button to be displayed?
   * @default true
   */
  showCancelButton?: boolean;

  /**
   * the text displayed for saving
   * @default 'Save'
   */
  saveText?: string | ICustomFormAction;

  /**
   * the text displayed for canceling
   * @default 'Cancel'
   */
  cancelText?: string;
  /**
   * custom action when cancel is pressed
   */
  onCancel?: () => void;
  /**
   * do you want the Save and Close Button to be displayed?
   * NOTE: This is used along with the status prop from the Formik context
   * @default ''
   */
  saveAndCloseRoute?: string;
  /**
   * message that will be displayed when succeeded
   */
  successMessage?: string;

  /**
   * the formId if needed
   */
  formId?: string;

  /**
   * custom submit actions that supports pre-submit operations
   */
  customFormActions?: ICustomFormAction[];

  /**
   * Additional action buttons
   */
  additionalActions?: ICustomFormAction[];

  /**
   * if the changes are locked
   */
  lockFormOnChanges?: boolean;

  /**
   * align of the buttons
   */
  buttonsAlign?: FlexAlign;

  /**
   * the variant of the submit button
   */
  variantSubmit?: VariantType;

  /**
   * submit button will not be disabled
   */
  submitAlwaysEnabled?: boolean;
  /**
   * the size of the buttons
   */
  buttonSize?: Sizes;
  /**
   * the size of the save button
   */
  saveButtonClassName?: string;
}
/**
 * extended version of the Formik Element just with custom general additions for the current app
 * @param props
 */
function ExtendedFormik<Values extends FormikValues = FormikValues & ExtendedFormikProps, ExtraProps = {}>({
  onTouchedChange,
  isLoading = false,
  isSaving = false,
  showCancelButton = true,
  showSaveButton = true,
  saveText = 'Save',
  cancelText = 'Cancel',
  enableReinitialize = true, // change reinitialize to tru by default
  onCancel,
  saveAndCloseRoute = '',
  customFormActions,
  additionalActions,
  initialValues,
  successMessage = '',
  formId,
  lockFormOnChanges = true,
  buttonsAlign = FlexAlign.end,
  variantSubmit = 'primary',
  buttonSize = Sizes.sm,
  saveButtonClassName,
  submitAlwaysEnabled,
  ...props
}: FormikConfig<Values> & ExtraProps & ExtendedFormikProps) {
  const [internalInitialValues, setInternalInitialValues] = useState(initialValues);
  // after the value is automatically saved we set the initialValues as the initial State until we receive new values
  const onSuccess = useCallback(
    (values: any) => {
      setTimeout(() => setInternalInitialValues(values), 0);
      !!successMessage && toast.success(successMessage);
    },
    [successMessage]
  );

  const formikbag = useFormik<Values>({ ...props, enableReinitialize, initialValues: internalInitialValues, onSubmit: wrapSubmitCommon(props.onSubmit, onSuccess) });
  const { component, children, innerRef, render } = props;

  // This allows folks to pass a ref to <Formik />
  useImperativeHandle(innerRef, () => formikbag);

  // still hearing for initial values changes in the outside
  useEffect(() => {
    setInternalInitialValues(initialValues);
  }, [initialValues]);

  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <FormikProvider value={formikbag}>
          <Form onSubmit={formikbag.handleSubmit} id={formId}>
            {lockFormOnChanges && <LockFormOnChanges />}
            {!!onTouchedChange && <FormikCollectTouchedFiles onTouchedChange={onTouchedChange} />}
            {component
              ? createElement(component as any, formikbag)
              : render
              ? render(formikbag)
              : children // children come last, always called
              ? isFunction(children)
                ? (children as (bag: FormikProps<Values>) => React.ReactNode)(formikbag as FormikProps<Values>)
                : !isEmptyChildren(children)
                ? Children.only(children)
                : null
              : null}
            <FormCancelSaveButtons
              isLoading={isLoading}
              isSaving={isSaving}
              showCancelButton={showCancelButton}
              showSaveButton={showSaveButton}
              saveText={typeof saveText === 'string' ? ({ label: saveText } as ICustomFormAction) : saveText}
              cancelText={cancelText}
              onCancel={onCancel}
              saveAndCloseRoute={saveAndCloseRoute}
              customFormActions={customFormActions}
              additionalActions={additionalActions}
              buttonsAlign={buttonsAlign}
              variantSubmit={variantSubmit}
              buttonSize={buttonSize}
              saveButtonClassName={saveButtonClassName}
              submitAlwaysEnabled={submitAlwaysEnabled}
            />
          </Form>
        </FormikProvider>
      )}
    </>
  );
}

export default ExtendedFormik;
