import { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useAppParams } from '@rsl/core/src/hooks/routeHooks';
import { toast } from 'react-toastify';
import { useFetchExternalReplyMessageQuery, useFetchLoanCommLogRecipientLookupQuery, useSaveCommLogMutation } from 'features/communication/inbox-sent/api/commLogApi';
import MessageForm from '@rsl/core/src/components/communication/MessageForm';
import { ICommunicationLog, ILoanCommLogRecipientLookup } from '@rsl/core/src/types/communication.types';

const ExternalReplyMessage = () => {
  const [sendMessage] = useSaveCommLogMutation();
  const { commLogRecipientId: id } = useAppParams<{ commLogRecipientId: string }>();
  const { data, isLoading } = useFetchExternalReplyMessageQuery(id);
  const { data: loanContactsLookup } = useFetchLoanCommLogRecipientLookupQuery(data?.referenceId ?? '', { skip: !data?.referenceId });

  let loanContacts = loanContactsLookup ?? ([] as ILoanCommLogRecipientLookup[]);

  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false);

  const doSubmitForm = async (values: ICommunicationLog) => {
    const formData = new FormData();
    formData.append('dto', JSON.stringify(values));
    const files = values.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i], files[i].name);
      }
    }
    await sendMessage(formData);
    toast.success('Message sent!');
    setDisableSubmitButton(true);
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <Container>
          <Row>
            <Col>
              <Card>
                <Card.Header className="d-flex justify-content-between pb-4">
                  <h1>Reply Message</h1>
                </Card.Header>
                <Card.Body>
                  {data && (
                    <MessageForm
                      title="Loan"
                      key="reply"
                      messageData={data}
                      loanContactsLookup={loanContacts}
                      isLoading={isLoading}
                      doSubmitForm={doSubmitForm}
                      isNew={false}
                      disableLoanContacts
                      disableAttachments
                      disableAdditionalRecipients
                      disableSubmitButton={disableSubmitButton}
                      hideSendButton={false}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ExternalReplyMessage;
