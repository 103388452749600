import { useEffect, useState } from 'react';

/**
 * check if at least one time the value have been true
 * @param value the changing value that must be checked
 * @example const hasBeenSucceeded = useHasBeenTrue(success);
 */
export const useHasBeenTrue = (value: boolean) => {
  const [hasBeenTrue, setHasBeenTrue] = useState(false);

  useEffect(() => {
    if (!hasBeenTrue) {
      setHasBeenTrue(value);
    }
  }, [hasBeenTrue, value, setHasBeenTrue]);

  return hasBeenTrue;
};
