import { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import DashboardLoanSummary from '../DashboardItem/DashboardLoanSummary';
import { useCurrentLoanId } from 'app/hooks/loanHooks';
import { useFetchExternalLoanSummaryQuery } from 'app/api/externalSessionApi';
import DashboardLoanContacts from '../DashboardItem/DashboardLoanContacts';

const DashboardTransaction: FC = () => {
  const loanId = useCurrentLoanId();
  const { data: loan } = useFetchExternalLoanSummaryQuery(loanId ?? '', { skip: !loanId, refetchOnMountOrArgChange: true });

  return (
    <Row>
      <Col xs={12} lg={8}>
        {loan && <DashboardLoanSummary loan={loan} />}
      </Col>
      <Col xs={12} lg={4}>
        {loan && <DashboardLoanContacts loan={loan} />}
      </Col>
    </Row>
  );
};

export default DashboardTransaction;
