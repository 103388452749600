import { useEffect, useState } from 'react';
import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';

const C6f = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (values.ShowSharedC6a_ || values.ShowSharedC6b_ || values.ShowSharedC6c_ || values.ShowSharedC6d_) {
      setDisabled(true);
      setFieldValue('ShowSharedC6f_', false);
    } else {
      setDisabled(false);
    }
  }, [values.ShowSharedC6a_, values.ShowSharedC6b_, values.ShowSharedC6c_, values.ShowSharedC6d_, setFieldValue]);

  return (
    <div className="border-bottom py-2 border-gray-dark">
      <Row>
        <CheckboxField name="ShowSharedC6f_" label="Not currently earning money" horizontal={true} className="fw-bold lg-label" xs={12} disabled={disabled} />
      </Row>
    </div>
  );
};

export default C6f;
