import { FC } from 'react';
import { Card } from 'react-bootstrap';

interface IQuestionProps {
  showBackground?: boolean;
}

interface IQuestionLayout {
  Body: typeof Body;
}

const QuestionLayout: FC<IQuestionProps> & IQuestionLayout = ({ children, showBackground = true }) => {
  return <Card className={`mb-2 ${showBackground ? 'rounded-card' : 'bg-transparent shadow-none'}`}>{children}</Card>;
};

const Body: FC<IQuestionProps> = ({ children, showBackground = true }) => {
  return <Card.Body className={`pt-2 pb-0 bg-white ${showBackground ? 'rounded-card-body rounded-card-footer' : 'bg-transparent'}`}>{children}</Card.Body>;
};

QuestionLayout.Body = Body;

export default QuestionLayout;
