import Question from '../../Question';
import { Collapse, Row } from 'react-bootstrap';
import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import TextField from '@rsl/core/src/components/form/TextField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { ValidationErrors } from '@rsl/core/src/constants/errors';

const GC2 = () => {
  const { values, setFieldValue, errors } = useFormikContext<ILoanQuestionsMapType>();

  const notHispanicOrLatino = () => {
    setFieldValue('SharedGC2_2', false);
    setFieldValue('SharedGC2_3', false);
    setFieldValue('SharedGC2_4', false);
    setFieldValue('SharedGC2_5', false);
    setFieldValue('SharedGC2_6', false);
    setFieldValue('SharedGC2_7', '');
    setFieldValue('SharedGC2_8', false);
  };

  const hispanicOrLatino = () => {
    setFieldValue('SharedGC2_1', false);
    setFieldValue('SharedGC2_8', false);

    if (values.SharedGC2_2) {
      setFieldValue('SharedGC2_3', false);
      setFieldValue('SharedGC2_4', false);
      setFieldValue('SharedGC2_5', false);
      setFieldValue('SharedGC2_6', false);
      setFieldValue('SharedGC2_7', '');
    }
  };

  const hispanicOrLatinoOption = () => {
    setFieldValue('SharedGC2_1', false);
    setFieldValue('SharedGC2_2', true);
    setFieldValue('SharedGC2_8', false);
  };

  const doNotWishToProvideThisInformation = () => {
    setFieldValue('SharedGC2_1', false);
    setFieldValue('SharedGC2_2', false);
    setFieldValue('SharedGC2_3', false);
    setFieldValue('SharedGC2_4', false);
    setFieldValue('SharedGC2_5', false);
    setFieldValue('SharedGC2_6', false);
    setFieldValue('SharedGC2_7', '');
  };

  return (
    <Question label="What is your ethnicity? (Check one or more)">
      {errors.atLeastOneEthnicityChecked && (
        <div className="text-danger">
          <small>{ValidationErrors.AtLeastOneAndSubChecked}</small>
        </div>
      )}
      <Row>
        <CheckboxField className="mb-0 checkbox-lite" name="SharedGC2_1" label="Not Hispanic or Latino" horizontal={true} onClick={notHispanicOrLatino} />
      </Row>
      <Row>
        <CheckboxField className="mb-0 checkbox-lite" name="SharedGC2_2" label="Hispanic or Latino" horizontal={true} onClick={hispanicOrLatino} />
      </Row>
      <Collapse in={values.SharedGC2_2}>
        <div>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC2_3" label="Mexican" horizontal={true} onClick={hispanicOrLatinoOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC2_4" label="Puerto Rican" horizontal={true} onClick={hispanicOrLatinoOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC2_5" label="Cuban" horizontal={true} onClick={hispanicOrLatinoOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC2_6" label="Other Hispanic or Latino" horizontal={true} onClick={hispanicOrLatinoOption} />
          </Row>
          <Row>
            <TextField className="mb-2 mx-3 checkbox-lite" xs={8} label="Optional" name="SharedGC2_7" onClick={hispanicOrLatinoOption} />
          </Row>
        </div>
      </Collapse>
      <Row>
        <CheckboxField
          className="mb-2 checkbox-lite"
          name="SharedGC2_8"
          label="I do not wish to provide this information"
          horizontal={true}
          onClick={doNotWishToProvideThisInformation}
        />
      </Row>
    </Question>
  );
};

export default GC2;
