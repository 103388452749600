import { ApiResponse } from '@rsl/core/src/types';
import { IActivityTemplateLookups, LookupKey } from '@rsl/core/src/types/lookup.types';
import { baseApi } from './baseApi';

export const lookupsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchLookups: build.query<IActivityTemplateLookups, LookupKey[]>({
      query: (ids: LookupKey[]) => `/lookups?${ids.map((id) => `types=${id}`).join('&')}`,
      transformResponse: (response: ApiResponse<IActivityTemplateLookups>) => response.data,
      providesTags: ['Lookups'],
    }),
    fetchZipCodeDetail: build.query<any, number>({
      query: (zip) => `/lookups/zip/${zip}`,
      transformResponse: (response: ApiResponse<any>) => response.data,
    }),
  }),
  overrideExisting: false,
});

export const { useFetchLookupsQuery, useFetchZipCodeDetailQuery } = lookupsApi;
