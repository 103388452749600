import { IResidenceType } from '../../types';

export const emptyResidenceConstant: IResidenceType = {
  residencyType: '',
  addressCity: '',
  addressPostalCode: '',
  addressState: '',
  addressStreetLine1: '',
  countryCode: '',
  residencyBasisType: '',
};
