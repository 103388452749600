import { Row } from 'react-bootstrap';
import Question from '../../Question';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';

const G11 = () => {
  return (
    <Question
      label="Are you a co-signer or guarantor on any debt or loan that is not disclosed on this 
application?">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedG11_1"
          optionsClassName="pt-1 w-50 w-md-10"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default G11;
