import { strEnum } from '@rsl/core/src/utils/enumUtils';

export const UserPermissions = strEnum([
  'Admin_SecurityRole_Edit',
  'Admin_SecurityRole_View',
  'Admin_Market_Edit',
  'Admin_Market_View',
  'Loan_Detail_CommLog',
  'Loan_Detail_Contact',
  'Loan_Detail_View',
  'Loan_Pipeline_Admin',
  'Loan_Pipeline_Edit',
  'Loan_Pipeline_View',
  'Setup_ActivityPlanTemplate_Edit',
  'Setup_ActivityPlanTemplate_View',
  'Setup_ActivityPlanTemplate_Admin',
  'Setup_NotificationTemplate_Edit',
  'Setup_NotificationTemplate_View',
  'Setup_NotificationTemplate_Edit_ActivityPlan',
  'Setup_NotificationTemplate_Edit_LoanUpdate',
  'Setup_NotificationTemplate_Edit_BackgroundJob',
  'Setup_NeedsListTemplate_Edit',
  'Setup_NeedsListTemplate_View',
  'Setup_DailyRate_View',
  'Setup_DailyRate_Edit',
  'User_Impersonation',
  'User_List_View',
  'User_Profile_Edit',
  'User_Profile_View',
  'User_SecurityRole_Assignment',
  'User_SecurityRole_View',
  'User_Settings_Edit',
  'User_Settings_View',
  'User_Team_Archive',
  'User_Team_Edit',
  'User_Team_View',
  'CommLog_Folder_Inbox',
  'CommLog_Folder_Sent',
  'Reports_ReportBuilder',
  'Reports_ReportBuilder_Admin',
  'Fileshare_Access',
  'Loan_Detail_ImportantDates_View',
  'Loan_Detail_ImportantDates_Edit',
  'Loan_Application_View',
  'Loan_Application_Edit',
  'Consumer_Prospect_Edit',
  'Consumer_Prospect_View',
  'Consumer_Scenario_Quote',
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UserPermissions = keyof typeof UserPermissions;
