import classNames from 'classnames';
import { FC } from 'react';
import styles from './CheckBadge.module.scss';

interface CheckBadgeComponentProps {
  checked: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
}

/**
 * This component shows a checked or empoty badge, the width and the height are equal to the font size
 * @param props the props od the component
 */
const CheckBadge: FC<CheckBadgeComponentProps> = ({ checked, className, onChange }) => {
  const classes = checked ? 'bg-success' : 'border border-dark';
  const onClick = () => {
    onChange?.(!checked);
  };

  return (
    <span
      onClick={onClick}
      className={`${classNames(styles.root, className, classes)} d-inline-block p-1 border border-light rounded-circle d-inline-flex justify-content-center align-items-center`}>
      {checked && <i className={`fas fa-check text-white ${styles.icon}`}></i>}
    </span>
  );
};

export default CheckBadge;
