import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

/**
 * Compute a number sum based on propNames of the given object type and cinditionif needed
 * @param computeTuple a tuple of ['PropId', [Function_to_check_values]]
 * @returns the sum of prop Values
 */
export const useSumCompute = <T>(computeTuple: ([keyof T, (values: T) => boolean] | [keyof T])[]): number => {
  const [computeTupleCached] = useState(computeTuple); // just cache the fields to review
  const { values } = useFormikContext<T>();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    setTotal(0);
    const total =
      computeTupleCached?.reduce<number>((total, [id, condition]) => {
        let value = 0;
        // only get the value when the condition is true or not condition is defined
        if (!condition || condition?.(values)) {
          value = parseFloat((values as any)?.[id] ?? 0);
        }

        return total + (isNaN(value) ? 0 : value);
      }, 0) ?? 0;

    setTotal(total);
  }, [computeTupleCached, values]);

  return total;
};
