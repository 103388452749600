import { useState, useMemo, useCallback } from 'react';
import Question from '../../Question';
import TextField from '@rsl/core/src/components/form/TextField';
import MaskedField from '@rsl/core/src/components/form/MaskedField';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { Row, Col } from 'react-bootstrap';
import PlacesAutoComplete, { PlaceType } from '../../../fields/PlacesAutocomplete';
import { IPlaceAutocompleteResponse } from '../../../fields/fields.types';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { shortTimestamp } from '@rsl/core/src/utils/dateUtils';
import { QuestionValues } from '@rsl/core/src/constants';
import MessageIndicator from '../../../shared/MessageIndicator';
import { inputDateIsLessThanTwoYears } from '../../../../utils/loanMapperUtils';

const EC3 = () => {
  const { values, setValues, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  const [showSharedEC3a] = useState<boolean>(true);
  const [showSharedEC3b, setShowSharedEC3b] = useState<boolean>(inputDateIsLessThanTwoYears(values.SharedEC3a_9));
  const [showSharedEC3c, setShowSharedEC3c] = useState<boolean>(inputDateIsLessThanTwoYears(values.SharedEC3b_9));

  const previousEmploymentKeys = useMemo(() => ['SharedEC3a', 'SharedEC3b', 'SharedEC3c'], []);

  const clearPreviousEmploymentByKey = useCallback(
    (key: string) => {
      [...Array(13)].forEach((v: any, i: number) => {
        setFieldValue(`${key}_${i + 1}`, '');
      });
    },
    [setFieldValue]
  );

  const handleHavePreviousEmploymentChange = (event: any, key: string) => {
    if (key === 'SharedEC3a' && event.target.value === QuestionValues.No) {
      previousEmploymentKeys.forEach((key: string) => clearPreviousEmploymentByKey(key));
      setFieldValue('SharedEC3a_12', QuestionValues.No);
      setShowSharedEC3b(false);
      setShowSharedEC3c(false);
    }

    if (key === 'SharedEC3b' && event.target.value === QuestionValues.No) {
      previousEmploymentKeys.slice(1).forEach((key: string) => clearPreviousEmploymentByKey(key));
      setFieldValue('SharedEC3b_12', QuestionValues.No);
      setShowSharedEC3c(false);
    }

    if (key === 'SharedEC3c' && event.target.value === QuestionValues.No) {
      previousEmploymentKeys.slice(2).forEach((key: string) => clearPreviousEmploymentByKey(key));
      setFieldValue('SharedEC3c_12', QuestionValues.No);
    }
  };

  const handleStartDateChange = (key: string) => {
    if (key === 'SharedEC3a' && values.SharedEC3a_9) {
      if (!inputDateIsLessThanTwoYears(values.SharedEC3a_9)) {
        previousEmploymentKeys.slice(1).forEach((key: string) => clearPreviousEmploymentByKey(key));
        setShowSharedEC3b(false);
        setShowSharedEC3c(false);
      } else {
        setShowSharedEC3b(true);
      }
    }

    if (key === 'SharedEC3b' && values.SharedEC3b_9) {
      if (!inputDateIsLessThanTwoYears(values.SharedEC3b_9)) {
        previousEmploymentKeys.slice(2).forEach((key: string) => clearPreviousEmploymentByKey(key));
        setShowSharedEC3c(false);
      } else {
        setShowSharedEC3c(true);
      }
    }
  };

  const validateDates = (key: string) => {
    if (new Date((values as any)[`${key}_9`]) > new Date((values as any)[`${key}_11`])) {
      setFieldValue(`${key}_11`, '');
    }
  };

  const show = (key: string) => {
    if (key === 'SharedEC3a') {
      return showSharedEC3a;
    }

    if (key === 'SharedEC3b') {
      return showSharedEC3b;
    }

    if (key === 'SharedEC3c') {
      return showSharedEC3c;
    }
  };

  const showForm = (key: string) => {
    return (values as any)[`${key}_12`] !== QuestionValues.No;
  };

  const addressMap = (key: string, res: IPlaceAutocompleteResponse) => {
    if (key === 'SharedEC3a') {
      return {
        SharedEC3a_2: res.address ?? values.SharedEC3a_2,
        SharedEC3a_4: res.city ?? values.SharedEC3a_4,
        SharedEC3a_5: res.state ?? values.SharedEC3a_5,
        SharedEC3a_6: res.zipCode ?? values.SharedEC3a_6,
      };
    }

    if (key === 'SharedEC3b') {
      return {
        SharedEC3b_2: res.address ?? values.SharedEC3b_2,
        SharedEC3b_4: res.city ?? values.SharedEC3b_4,
        SharedEC3b_5: res.state ?? values.SharedEC3b_5,
        SharedEC3b_6: res.zipCode ?? values.SharedEC3b_6,
      };
    }

    if (key === 'SharedEC3c') {
      return {
        SharedEC3c_2: res.address ?? values.SharedEC3c_2,
        SharedEC3c_4: res.city ?? values.SharedEC3c_4,
        SharedEC3c_5: res.state ?? values.SharedEC3c_5,
        SharedEC3c_6: res.zipCode ?? values.SharedEC3c_6,
      };
    }
  };

  return (
    <>
      {previousEmploymentKeys.map((key: string, index: number) =>
        show(key) ? (
          <>
            {index > 0 && <hr />}
            <MessageIndicator>It {index > 0 && 'still'} appears you haven't added two years of employment history. Please add additional employment history.</MessageIndicator>
            <Question label="Do you have any previous employment history?">
              <Row>
                <RadioField
                  className="mb-2"
                  name={`${key}_12`}
                  optionsClassName="pt-1 w-50 w-md-10"
                  options={[
                    { label: QuestionValues.Yes, value: QuestionValues.Yes },
                    { label: QuestionValues.No, value: QuestionValues.No },
                  ]}
                  onChange={(event: any) => handleHavePreviousEmploymentChange(event, key)}
                />
              </Row>
            </Question>
            {showForm(key) && (
              <>
                <Question label="Tell us about your coborrower's previous employment" labelSize="lg">
                  <Row className="mb-2 align-items-center">
                    <TextField placeholder="Employer Name" name={`${key}_1`} required />
                  </Row>
                  <Row>
                    <Col xs={9}>
                      <PlacesAutoComplete
                        placeholder="Street Address"
                        name={`${key}_2`}
                        types={[PlaceType.STREET_ADDRESS]}
                        className="mb-2"
                        onChange={(res: IPlaceAutocompleteResponse) =>
                          setValues({
                            ...values,
                            ...addressMap(key, res),
                          })
                        }
                      />
                    </Col>
                    <TextField xs={3} className="mb-2" placeholder="Unit" name={`${key}_3`} />
                  </Row>
                  <Row>
                    <TextField className="mb-2" placeholder="City" name={`${key}_4`} required />
                    <LookupSelect className="mb-2" placeholder="State" name={`${key}_5`} lookup="states" required enableDefault={false} />
                    <TextField className="mb-2" placeholder="Zip" name={`${key}_6`} required />
                  </Row>
                  <Row>
                    <MaskedField
                      className="mb-2"
                      placeholder="Employer phone"
                      name={`${key}_7`}
                      mask="(999) 999-9999"
                      mutation={/[^0-9]+/g}
                      required
                      inputProps={{
                        autocomplete: 'tel-national',
                      }}
                    />
                  </Row>
                  <Row>
                    <TextField className="mb-2" placeholder="Job Title" name={`${key}_8`} required />
                  </Row>
                </Question>
                <Question label="When did you start working here?">
                  <Row>
                    <TextField
                      className="mb-2"
                      readOnly={true}
                      type="date"
                      name={`${key}_9`}
                      placeholder="MM/DD/YYYY"
                      max={shortTimestamp()}
                      onBlur={() => {
                        validateDates(key);
                        handleStartDateChange(key);
                      }}
                      required
                    />
                  </Row>
                </Question>
                <Question label="When did you end this employment?">
                  <Row>
                    <TextField
                      className="mb-2"
                      readOnly={true}
                      type="date"
                      name={`${key}_11`}
                      placeholder="MM/DD/YYYY"
                      max={shortTimestamp()}
                      onBlur={() => {
                        validateDates(key);
                      }}
                      required
                    />
                  </Row>
                </Question>
                <Question label="Number of years in line of work?">
                  <Row>
                    <TextField className="mb-2" type="number" placeholder="#" name={`${key}_10`} required />
                  </Row>
                </Question>
                <Question label="Number of months in line of work?">
                  <Row>
                    <TextField className="mb-2" type="number" placeholder="#" name={`${key}_13`} required />
                  </Row>
                </Question>
              </>
            )}
          </>
        ) : null
      )}
    </>
  );
};

export default EC3;
