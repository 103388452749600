import { ApiResponse, LoanDocumentType } from '@rsl/core/src/types';
import { baseApi } from '@rsl/core/src/api/baseApi';
import { ILoanPreQualEdit } from '@rsl/core/src/types/loanDocuments.types';
import { invalidateOnSuccess } from '@rsl/core/src/utils/cacheUtils';

export const loanPreQualLsuApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchLsuDocument: build.query<LoanDocumentType, string>({
      query: (loanId) => `/LoanPreQualLsuExternal/${loanId}/Lsu/LoanDocuments/current`,
      transformResponse: (response: ApiResponse<LoanDocumentType>) => response.data,
    }),
    fetchPreQualDocument: build.query<LoanDocumentType, string>({
      query: (loanId) => `/LoanPreQualLsuExternal/${loanId}/PreQual/LoanDocuments/current`,
      transformResponse: (response: ApiResponse<LoanDocumentType>) => response.data,
      providesTags: ['LoanDocuments'],
    }),
    fetchPreQualDto: build.query<ILoanPreQualEdit, string>({
      query: (loanId) => `/LoanPreQualLsuExternal/${loanId}/PreQual`,
      transformResponse: (response: ApiResponse<ILoanPreQualEdit>) => response.data,
    }),
    generatePreQual: build.mutation<ILoanPreQualEdit, ILoanPreQualEdit>({
      query: (params) => ({
        url: `/LoanPreQualLsuExternal/${params.loanId}/PreQual/Generate`,
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: ApiResponse<ILoanPreQualEdit>) => response.data,
      invalidatesTags: invalidateOnSuccess(['LoanDocuments']),
    }),
  }),
  overrideExisting: false,
});

export const { useFetchLsuDocumentQuery, useFetchPreQualDocumentQuery, useFetchPreQualDtoQuery, useGeneratePreQualMutation } = loanPreQualLsuApi;
