import { FC, useMemo } from 'react';
import { useFieldIds } from '@rsl/core/src/hooks/fieldHooks';
import { useField } from 'formik';
import { IMinuteField, IOption } from '../../types/form.types';
import MinuteField from './MinuteField';
import Select from 'react-select';
import classNames from 'classnames';

interface ISelectField {
  options: IOption<string | number>[];
  /**
   * for async data
   */
  isLoading?: boolean;

  isMulti?: boolean;

  /**
   * id true, the selected values will not be displayed
   */
  hideValues?: boolean;
}

export type MinuteSelectFieldType = IMinuteField<string | number> & ISelectField;

const SelectField: FC<MinuteSelectFieldType> = ({ placeholder, disabled, options, isLoading, isMulti, hideValues, onChange: onChangeHandler, ...props }) => {
  const { name, id } = useFieldIds(props);
  const [field, { error, touched }, { setValue, setTouched }] = useField({ name });

  const onChange = useMemo(
    () =>
      (newValue: any, { action }: any) => {
        const value = isMulti ? newValue.map(({ value }: IOption<any>) => value) : newValue.value;
        setTouched(true, true);
        if (hideValues && action === 'select-option') {
          setValue([...value, ...(field.value ?? [])]);
        } else {
          setValue(value);
        }
        onChangeHandler?.(value);
      },
    [field.value, hideValues, isMulti, onChangeHandler, setTouched, setValue]
  );

  const filteredOptions = useMemo(() => (!hideValues ? options : options.filter((option) => !(field.value?.includes(option.value) ?? false))), [field.value, options, hideValues]);

  const selected = useMemo(() => {
    if (isMulti) {
      return options?.filter(({ value }) => field.value?.includes(value));
    }

    return options?.find(({ value }) => field.value === value);
  }, [field.value, options, isMulti]);

  return useMemo(
    () => (
      <MinuteField {...props}>
        <Select
          id={id}
          data-test-id={id}
          classNamePrefix="minute-select"
          className={classNames('minute-select', !!error && touched && 'is-invalid')}
          name={name}
          onChange={onChange as any}
          value={hideValues ? null : selected ?? null}
          isMulti={isMulti}
          placeholder={isLoading ? 'Loading...' : placeholder}
          isLoading={isLoading}
          loadingMessage={() => 'data is Loading...'}
          inputId={`${id}-input`}
          theme={(theme) => {
            return {
              ...theme,
              spacing: {
                ...theme.spacing,
                controlHeight: 0,
              },
            };
          }}
          options={filteredOptions}
          isDisabled={disabled}
        />
      </MinuteField>
    ),
    [props, id, error, touched, name, onChange, hideValues, selected, isMulti, isLoading, placeholder, filteredOptions, disabled]
  );
};

export default SelectField;
