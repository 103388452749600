import { strEnum } from '@rsl/core/src/utils/enumUtils';
import { IOption } from './form.types';

export interface ITriggerFieldTypeLookup {
  triggerFieldTypeId: number;
  name: string;
}

export interface ISecurityRoleLookup {
  securityRoleTypeId: number;
  name: string;
}

export interface INotificationTemplateLookup {
  notificationTemplateId: number;
  name: string;
  emailSubject: string;
  emailMessageBody: string;
  smsMessageBody: string;
  ruleExpression: string;
  triggerType: string;
}

export interface ITeamLookup {
  teamId: number;
  name: string;
}

export interface IEncompassRoles {
  loanRoleAbbreviation: string;
  loanRoleColumnName: string;
  loanAccessLevel: string;
}

export interface IDepartmentLookup {
  departmentId: number;
  name: string;
}

export interface IUserLookup {
  userId: number;
  firstName: string;
  lastName: string;
}

export interface INotificationCategoryType {
  notificationCategoryTypeId: number;
  name: string;
}

export interface IApplicationLookup {
  applicationId: number;
  name: string;
}

export interface IMarketLookup {
  marketId: number;
  name: string;
}

export interface ILoanModelColumnLookup {
  columnName: string;
  displayName: string;
}

export interface IKeyValueLookup {
  key: number;
  value: string;
}

export interface INeedsListItemLookup {
  needsListItemId: number;
  documentation: string;
}

export interface INeedsListTemplateLookup {
  needsListTemplateId: number;
  name: string;
}

export interface IActivityPlanTemplateLookup {
  activityPlanTemplateId: number;
  name: string;
}

export interface IPosTypes {
  value: string;
  key: string;
}

export interface ILoanDisclosurePackageLookup {
  disclosurePackageId: number;
  docsPlanId: number;
  name: string;
  deliveryTypeOptions: string[];
  orderSeq: number;
  allowAutoDelivery: boolean;
  orderType: 'opening' | 'forms' | 'closing';
}

export interface ILoanContactTypeLookup {
  loanContactTypeId: number;
  name: string;
  description: string;
  isExternalRole: boolean;
}

export const LookupKey = strEnum([
  'activityPlanTemplates',
  'activityTypes',
  'activityStatus',
  'loanTypes',
  'channels',
  'loanPurposeTypes',
  'loanRoles',
  'securityRoles',
  'dayTypes',
  'dayOperationTypes',
  'reminderOperationTypes',
  'triggerFieldTypes',
  'screens',
  'notificationTriggerTypes',
  'notificationTemplates',
  'milestones',
  'teams',
  'commLogCategories',
  'encompassRoles',
  'securityPermission',
  'departments',
  'users',
  'userStatuses',
  'states',
  'licensedStates',
  'userLicenseStatuses',
  'applications',
  'userAccountStatuses',
  'markets',
  'loanModelColumns',
  'ratePricingCreditScores',
  'ratePricingPropertyUsages',
  'ratePricingPropertyTypes',
  'ratePricingPointsOptions',
  'needsListItems',
  'needsListTemplates',
  'needsListStatuses',
  'needsListItemRuleModels',
  'dailyRateSourceTypes',
  'posCitizenshipResidencyTypes',
  'disclosurePackages',
  'loanDisclosureStatus',
  'ratePricingMortgageTypes',
  'ratePricingProgramTypes',
  'loanContactTypes',
  'posLanguagePreferenceTypes',
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LookupKey = keyof typeof LookupKey;

export interface IActivityTemplateLookups {
  [LookupKey.activityPlanTemplates]: IActivityPlanTemplateLookup[];
  [LookupKey.activityTypes]: string[];
  [LookupKey.activityStatus]: string[];
  [LookupKey.loanTypes]: string[];
  [LookupKey.channels]: string[];
  [LookupKey.loanPurposeTypes]: string[];
  [LookupKey.loanRoles]: ISecurityRoleLookup[];
  [LookupKey.securityRoles]: ISecurityRoleLookup[];
  [LookupKey.dayTypes]: string[];
  [LookupKey.dayOperationTypes]: string[];
  [LookupKey.reminderOperationTypes]: string[];
  [LookupKey.triggerFieldTypes]: ITriggerFieldTypeLookup[];
  [LookupKey.screens]: string[];
  [LookupKey.notificationTriggerTypes]: string[];
  [LookupKey.notificationTemplates]: INotificationTemplateLookup[];
  [LookupKey.milestones]: string[];
  [LookupKey.teams]: ITeamLookup[];
  [LookupKey.commLogCategories]: INotificationCategoryType[];
  [LookupKey.encompassRoles]: IEncompassRoles[];
  [LookupKey.securityPermission]: string[];
  [LookupKey.departments]: IDepartmentLookup[];
  [LookupKey.users]: IUserLookup[];
  [LookupKey.userStatuses]: string[];
  [LookupKey.states]: string[];
  [LookupKey.licensedStates]: string[];
  [LookupKey.userLicenseStatuses]: string[];
  [LookupKey.applications]: IApplicationLookup[];
  [LookupKey.userAccountStatuses]: string[];
  [LookupKey.markets]: IMarketLookup[];
  [LookupKey.loanModelColumns]: ILoanModelColumnLookup[];
  [LookupKey.ratePricingCreditScores]: IKeyValueLookup[];
  [LookupKey.ratePricingPropertyUsages]: IKeyValueLookup[];
  [LookupKey.ratePricingPropertyTypes]: IKeyValueLookup[];
  [LookupKey.ratePricingPointsOptions]: number[];
  [LookupKey.needsListItems]: INeedsListItemLookup[];
  [LookupKey.needsListTemplates]: INeedsListTemplateLookup[];
  [LookupKey.needsListStatuses]: string[];
  [LookupKey.needsListItemRuleModels]: string[];
  [LookupKey.dailyRateSourceTypes]: string[];
  [LookupKey.posCitizenshipResidencyTypes]: IPosTypes[];
  [LookupKey.disclosurePackages]: ILoanDisclosurePackageLookup[];
  [LookupKey.loanDisclosureStatus]: string[];
  [LookupKey.ratePricingMortgageTypes]: IKeyValueLookup[];
  [LookupKey.ratePricingProgramTypes]: IKeyValueLookup[];
  [LookupKey.loanContactTypes]: ILoanContactTypeLookup[];
  [LookupKey.posLanguagePreferenceTypes]: IKeyValueLookup[];
}

export type LookupMapper = (data: any) => IOption<any>;

export type LookupType = string[] | ISecurityRoleLookup[] | ITriggerFieldTypeLookup[] | INotificationTemplateLookup[] | IEncompassRoles[];

export interface LookupState {
  lookups: Record<LookupKey, any>;
  pendingFetchLookups: LookupKey[];
  isLoading: boolean;
}
