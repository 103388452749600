import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoanPosApplicationType } from '@rsl/core/src/types/loanPosApplication.types';
import { parseJwt } from '@rsl/core/src/utils/jwtUtils';
import { tokenCookieName } from 'app/constants';
import { ExternalUserRoles, ExternalUserSessionResponse } from 'app/types';
import cookie from 'react-cookies';

export interface IExternalAuthState {
  recipientId: string;
  token: string;
  isLoggedIn: boolean;
  deviceId: string;
  isDeviceVerified: boolean;
  fullName?: string;
  mainPage: string;
  email: string;
  pendingPosApplication?: ILoanPosApplicationType | null;
  isPartner: boolean;
}

const initialState: IExternalAuthState = {
  token: '',
  isLoggedIn: false,
  email: '',
  recipientId: '',
  deviceId: '',
  isDeviceVerified: false,
  fullName: '',
  mainPage: '/apply-now',
  pendingPosApplication: null,
  isPartner: false,
};

export const externalAuthSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLogoutState: (state) => {
      state.isLoggedIn = false;
      state.token = '';
      state.mainPage = '/apply-now';
      state.email = '';
      state.isDeviceVerified = false;
      state.isPartner = false;
      state.pendingPosApplication = null;
      state.recipientId = '';
      state.fullName = '';
      //TODO: revise this logout should be on the action ?
      cookie.remove(tokenCookieName, { path: '/' });
    },
    setDeviceId: (state, { payload }: PayloadAction<string>) => {
      state.deviceId = payload;
    },
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    setIsDeviceVerified: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeviceVerified = payload;
    },
    setIsLoggedIn: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoggedIn = payload;
    },
    setRecipientId: (state, { payload }: PayloadAction<string>) => {
      state.recipientId = payload;
    },
    setSessionData: (state, { payload }: PayloadAction<ExternalUserSessionResponse>) => {
      state.token = payload.token;
      state.isDeviceVerified = payload.isDeviceVerified;
      state.isLoggedIn = true;
      const tokenData = parseJwt(payload.token);
      if (payload.role === ExternalUserRoles.Partner) {
        state.isPartner = true;
      }
      state.mainPage = payload.pendingLoanPosApplication ? `/apply-now/${payload.pendingLoanPosApplication?.loanPosApplicationId}` : `/dashboard`;

      state.fullName = tokenData.family_name || `${tokenData.unique_name} ${tokenData.given_name}`.trim();
      state.pendingPosApplication = payload.pendingLoanPosApplication;
      state.email = tokenData.email;
    },
    setMainPage: (state, { payload }: PayloadAction<string>) => {
      state.mainPage = payload;
    },
    setPendingPosApplication: (state, { payload }: PayloadAction<ILoanPosApplicationType>) => {
      state.pendingPosApplication = payload;
    },
    cleanPendingPosApplication: (state) => {
      state.pendingPosApplication = null;
    },
  },
});

export const {
  setRecipientId,
  setDeviceId,
  setToken,
  setIsLoggedIn,
  setLogoutState,
  setIsDeviceVerified,
  setSessionData,
  setMainPage,
  setPendingPosApplication,
  cleanPendingPosApplication,
} = externalAuthSlice.actions;

export default externalAuthSlice.reducer;
