import { DocumentSignTask, ESigners, WetSign, TaskType, TaskTypes } from 'app/types';
import { FC } from 'react';
import ESignTaskComponent from './ESignTask';
import WetSignComponent from './WetSign';
import EConsentTaskComponent from './EConsentTask';
import CreditAuthorizationTaskComponent from './CreditAuthorizationTask';
import { getDefaultMap } from '@rsl/core/src/utils/mapUtils';

const GetTaskMap = getDefaultMap<TaskType, FC<any>>([
  [TaskTypes.eSign, ESignTaskComponent],
  [TaskTypes.eConsent, EConsentTaskComponent],
  [TaskTypes.CreditAuthorization, CreditAuthorizationTaskComponent],
  ['default', WetSignComponent],
]);

interface TaskOutletProps {
  task: DocumentSignTask<ESigners | WetSign>;
}

const TaskOutlet: FC<TaskOutletProps> = ({ task }) => {
  const TaskComponent = GetTaskMap(task.taskType);

  return <TaskComponent task={task as any} />;
};

export default TaskOutlet;
