import { useEffect, useState } from 'react';
import { ILoanQuestionsMapType } from '@rsl/core';
import { getCurrencyFormatted } from '@rsl/core/src/utils/numberUtils';
import { useFormikContext } from 'formik';

const L1Total = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const totalLiabilities = ['SharedL1a_1', 'SharedL1b_1', 'SharedL1c_1', 'SharedL1d_2'].reduce((accum: number, namePrefix: string) => {
      if ((values as any)[`Show${namePrefix}`.slice(0, -1)] && (values as any)[`${namePrefix}`]) {
        return +(values as any)[`${namePrefix}`] + accum;
      }

      return accum;
    }, 0);

    setTotal(totalLiabilities);
  }, [values, setTotal]);

  return (
    <div className="text-end pt-2">
      Total <strong className="text-primary">{getCurrencyFormatted(total)}</strong>
    </div>
  );
};

export default L1Total;
