import { useEffect, useState } from 'react';
import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';

const A2i = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (
      values.ShowSharedA2a_ ||
      values.ShowSharedA2b_ ||
      values.ShowSharedA2c_ ||
      values.ShowSharedA2d_ ||
      values.ShowSharedA2e_ ||
      values.ShowSharedA2f_ ||
      values.ShowSharedA2g_
    ) {
      setDisabled(true);
      setFieldValue('ShowSharedA2i_', false);
    } else {
      setDisabled(false);
    }
  }, [
    values.ShowSharedA2a_,
    values.ShowSharedA2b_,
    values.ShowSharedA2c_,
    values.ShowSharedA2d_,
    values.ShowSharedA2e_,
    values.ShowSharedA2f_,
    values.ShowSharedA2g_,
    setFieldValue,
  ]);

  return (
    <div className="border-bottom py-2 border-gray-dark">
      <Row>
        <CheckboxField className="fw-bold lg-label" name="ShowSharedA2i_" label="None" horizontal={true} disabled={disabled || values.ShowSharedA2_} />
      </Row>
    </div>
  );
};

export default A2i;
