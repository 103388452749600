import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { pinActionRouteMap } from '../constants/pinActionsConstants';
import { PinActionEnum } from '../types';
interface RedirectOnPinErrorProps {
  action: PinActionEnum;
  pinId: string;
  callbackRoute: string;
}

const RedirectOnPinError: FC<RedirectOnPinErrorProps> = ({ action, pinId, callbackRoute }) => {
  return <Redirect to={`${pinActionRouteMap(action)}?callback=${callbackRoute}`} />;
};

export default RedirectOnPinError;
