import { ILoanPosApplicationType } from '@rsl/core/src/types/loanPosApplication.types';

/**
 * available roles for external user
 */
export enum ExternalUserRoles {
  Applicant = 'Applicant',
  Partner = 'Partner',
}

export interface ExternalUserSessionResponse {
  /**
   * The Session Token
   */
  token: string;
  /**
   * At the moment the token is Generated the current device is Validated?
   */
  isDeviceVerified: boolean;
  /**
   * In Case there is a current LoanPosApplication
   */
  pendingLoanPosApplication?: ILoanPosApplicationType;

  /**
   * The user Role in the app
   */
  role: ExternalUserRoles;
}

export interface ExternalUserInventoryCount {
  messageCount: number;
  fileshareCount: number;
  needslistCount: number;
  tasksCount: number;
}
