import { FC } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useAppParams } from '@rsl/core/src/hooks/routeHooks';
import { useHistory } from 'react-router-dom';
import ProcessingSubMenu from '../ProcessingSubMenu/ProcessingSubMenu';
import { IProtectedRoute } from '@rsl/core/src/types';
import { getBreadCrumbTitle } from '@rsl/core/src/utils/stateUtils';
import { useSelector } from 'react-redux';

interface ExternalLink {
  title: string;
  route: string;
}

interface MinuteTabRoutesProps {
  baseRoute: string;
  paramName: string;
  subParamName?: string;
  tabs: IProtectedRoute[];
  defaultTab?: string;
  externalLinks?: ExternalLink[];
  className?: string;
}

const MinuteTabRoutes: FC<MinuteTabRoutesProps> = ({ baseRoute, subParamName, paramName, tabs, defaultTab, externalLinks, className }) => {
  const { [paramName]: section = defaultTab, [subParamName ?? '']: subsection } = useAppParams<{ [key: string]: string }>();
  const completeSlug = `${section}${subsection ? `-${subsection}` : ''}`;
  const state = useSelector((state) => state);

  const history = useHistory();

  const onMainTabSelect = (key: string | null) => {
    const currentCompleteTab = tabs.find((tab) => tab.slug === key);
    !currentCompleteTab?.childs && history.push(`${baseRoute}/${key}`);
  };

  const onSubmenuTabSelect = (...keys: string[]) => {
    history.push(`${baseRoute}/${keys.join('/')}`);
  };

  return (
    <Tabs activeKey={section} className={`mb-3 ${className}`} onSelect={onMainTabSelect}>
      {externalLinks?.map((external, index) => (
        <Tab
          key={`external-tab-route-${index}`}
          title={
            <a href={external.route} target="_blank" rel="external noreferrer">
              {external.title}
            </a>
          }
        />
      ))}
      {tabs.map(({ breadcrumbDisplay, title, slug, render, childs, titleElement }) => {
        const tabTitle = getBreadCrumbTitle(state, title, breadcrumbDisplay) ?? '';
        return (
          <Tab
            eventKey={slug}
            title={!childs?.length ? titleElement ?? tabTitle : <ProcessingSubMenu title={tabTitle} items={childs ?? []} onSubmenuClick={onSubmenuTabSelect} tabKey={slug ?? ''} />}
            key={`${slug}`}>
            {render?.({} as any) ?? (
              <Tabs activeKey={subsection} className="mb-3 d-none">
                {childs?.map(({ breadcrumbDisplay, title, slug: subRouteSlug, render: renderSubcomponent }) => (
                  <Tab eventKey={subRouteSlug} title={getBreadCrumbTitle(state, title, breadcrumbDisplay) ?? ''} key={`${slug}-${subRouteSlug}`}>
                    {/* only render content of the tab that is on route */}
                    {completeSlug === `${slug}-${subRouteSlug}` && renderSubcomponent?.({} as any)}
                  </Tab>
                ))}
              </Tabs>
            )}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default MinuteTabRoutes;
