import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { ValidationErrors } from '@rsl/core/src/constants/errors';
import MessageIndicator from '../../../shared/MessageIndicator';

const A1 = () => {
  const { errors, values } = useFormikContext<ILoanQuestionsMapType>();

  return (
    <>
      <h3 className="fw-bold text-center">
        Tell us about the assets <span className="text-primary">{values.SharedB1_1}</span> has available.
      </h3>
      <MessageIndicator>
        Please share information about the assets you have available for use towards your down payment, closing costs, and any potential reserve requirements, typically these
        assets are your checking and savings accounts.
      </MessageIndicator>
      {errors.atLeastOneChecked && (
        <div className="text-danger mb-3 mx-1">
          <small>{ValidationErrors.AtLeastOneChecked}</small>
        </div>
      )}
    </>
  );
};

export default A1;
