import { FC, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import SyncField from '@rsl/core/src/components/form/SyncField';

import TextField from '@rsl/core/src/components/form/TextField';
import { useEditingFormikField } from '@rsl/core/src/hooks/fieldHooks';
import { useFormikContext } from 'formik';

const PreQualOnDemandForm: FC = () => {
  const inputProps = useEditingFormikField();
  const { errors } = useFormikContext();

  // Principle & Interest
  const getPrincipleInterest = useCallback((values: (string | number)[]): number => {
    const [loanAmountValue, loanTerm, interestRate] = values;
    const pi = (+loanAmountValue * (Math.pow(1 + +interestRate / 100 / 12, +loanTerm) * (+interestRate / 100))) / (12 * (Math.pow(1 + +interestRate / 100 / 12, +loanTerm) - 1));
    return Math.round(pi);
  }, []);

  return (
    <Row className="mx-0 mt-3">
      <Col xs={12} lg={6}>
        <h5 className="mb-4">Loan Term</h5>
        <TextField type="hidden" name="maxLoanAmount" className="mb-3" />
        <TextField type="hidden" name="maxTotalMonthlyExpenses" className="mb-3" />
        <TextField type="number" label="Purchase Price" name="purchasePrice" className="mb-3" />
        <Row>
          <TextField type="number" label="Down Payment %" placeholder="%" name="downPaymentPercent" className="mb-3" disabled />
          <SyncField name="downPaymentAmount" fields={['purchasePrice', 'downPaymentPercent']} type="percentValue" />
          <TextField type="number" label="Down Payment $" placeholder="D$" name="downPaymentAmount" className="mb-3" disabled />
        </Row>
        <Row>
          <SyncField name="loanAmount" fields={['purchasePrice', 'downPaymentAmount']} type="subtract" />
          <TextField type="number" label="Loan Amount (Max)" name="maxLoanAmount" className="mb-3" disabled />
          <TextField type="number" label="Loan Amount" name="loanAmount" className="mb-3" disabled />
        </Row>
        <TextField type="number" label="Term" name="loanTerm" className="mb-3" disabled />
        <TextField type="number" label="Rate" name="interestRate" className="mb-3" disabled />
        <TextField type="date" label="Expiration Date" name="preQualExpirationDate" className="mb-3" disabled />
      </Col>
      <Col xs={12} lg={6}>
        <h5 className="mb-4">Proposed Monthly Expense</h5>
        <SyncField name="monthlyExpensesPrincipleAndInterest" fields={['loanAmount', 'loanTerm', 'interestRate']} type="custom" custom={getPrincipleInterest} />
        <TextField type="number" label="P & I" name="monthlyExpensesPrincipleAndInterest" className="mb-3" disabled />
        <TextField type="number" label="Other Financing P & I" name="monthlyExpensesOtherFinancingPrincipleAndInterest" className="mb-3" />
        <Row>
          <TextField type="number" xs={6} label="Tax %" name="monthlyExpensesRealEstateTaxes" className="mb-3" />
          <SyncField name="monthlyExpensesRealEstateTaxesAmount" fields={['purchasePrice', 100, 'monthlyExpensesRealEstateTaxes', 12]} type="ruleOfThreeDivided" />
          <TextField type="number" xs={6} label="Tax Estate Amount" placeholder="" name="monthlyExpensesRealEstateTaxesAmount" className="mb-3 hidden-label" disabled />
        </Row>
        <TextField type="number" label="Hazard Insurance" name="monthlyExpensesHazardInsurance" className="mb-3" />
        <TextField type="number" label="HOA" name="monthlyExpensesHOADues" className="mb-3" {...inputProps} />
        <Row>
          <TextField type="number" label="Mortgage Insurance %" name="monthlyExpensesMortgageInsurance" className="mb-3" />
          <SyncField name="monthlyExpensesMortgageInsuranceAmount" fields={['monthlyExpensesMortgageInsurance', 100, 'loanAmount', 12]} type="ruleOfThreeDivided" />
          <TextField type="number" label="Insurance Amount" name="monthlyExpensesMortgageInsuranceAmount" className="mb-3 hidden-label" disabled />
        </Row>
        <SyncField
          name="monthlyExpensesTotal"
          fields={[
            'monthlyExpensesMortgageInsuranceAmount',
            'monthlyExpensesHOADues',
            'monthlyExpensesHazardInsurance',
            'monthlyExpensesRealEstateTaxesAmount',
            'monthlyExpensesPrincipleAndInterest',
            'monthlyExpensesOtherFinancingPrincipleAndInterest',
          ]}
          type="addition"
          defaultValuesToZero
        />
        <TextField type="number" label="Total" name="monthlyExpensesTotal" className="mb-3" disabled />
        <div className="text-danger">
          <small>{(errors as any)?.['monthlyExpensesTotal']}</small>
        </div>
      </Col>
    </Row>
  );
};

export default PreQualOnDemandForm;
