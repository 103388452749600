import DownloadLink from '@rsl/core/src/components/link/DownloadLink';
import { useExternalAuth } from 'app/hooks/authHooks';
import { ESigners, DocumentSignTask } from 'app/types';
import { ExternalTaskStatus } from 'app/types/externalTask.status';
import { FC } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Task from './Task';

interface MyTaskProps {
  task: DocumentSignTask<ESigners[]>;
}

const MyTasks: FC<MyTaskProps> = ({ task }) => {
  const { token, deviceId, email } = useExternalAuth();
  const isAssignedTask = task.assignedTo.includes(email);

  return (
    <Task name={task.name} status={task.status}>
      {task.viewModelJson.map((data) => (
        <Row>
          <Col xs={9} md={true}>
            <Row className="mt-2 pb-2 border-bottom no-last-border-bottom">
              <Col xs={12} md={true}>
                <Card.Text className="fw-bold text-tertiary mb-0 d-md-none">
                  <small>Document</small>
                </Card.Text>
                <Card.Text className="fw-bold mb-0">Initial Disclosure</Card.Text>
              </Col>
              <Col xs={12} md={true}>
                <Card.Text className="fw-bold text-tertiary mt-2 mb-0 d-md-none">
                  <small>Signer</small>
                </Card.Text>
                <Card.Text className="mb-0">{data.SignerName}</Card.Text>
              </Col>
              <Col xs={12} md={true} className="d-none d-md-block">
                {task.status === ExternalTaskStatus.Completed &&
                  data.LoanDocumentId !== null &&
                  (isAssignedTask ? (
                    <DownloadLink
                      className="btn btn-outline-primary text-primary rounded-circle p-2"
                      deviceId={deviceId}
                      token={token}
                      filename={``}
                      href={`/ExternalLoanTasks/${task.loanId}/${task.externalTaskId}/esign/download`}>
                      <i className="fas fa-download fs-2"></i>
                    </DownloadLink>
                  ) : (
                    <Button variant="outline-primary" disabled className="text-primary rounded-circle p-2">
                      <i className="fas fa-download fs-2"></i>
                    </Button>
                  ))}
                {task.status === ExternalTaskStatus['Not Completed'] && (
                  <a
                    href={isAssignedTask ? data.ESignLink : '#'}
                    target="_blank"
                    className={`btn btn-outline-primary rounded-circle p-2 ms-2 ${isAssignedTask ? '' : 'disabled'}`}
                    rel="noreferrer"
                    style={{ width: '42px' }}>
                    <i className="fas fa-signature fs-2"></i>
                  </a>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={3} className="mt-3 d-flex flex-column align-items-center d-md-none">
            {task.status === ExternalTaskStatus.Completed &&
              data.LoanDocumentId !== null &&
              (isAssignedTask ? (
                <DownloadLink
                  className="btn btn-outline-primary text-primary rounded-circle p-2 mb-2"
                  deviceId={deviceId}
                  token={token}
                  filename={``}
                  href={`/ExternalLoanTasks/${task.loanId}/${task.externalTaskId}/esign/download`}>
                  <i className="fas fa-download fs-2"></i>
                </DownloadLink>
              ) : (
                <Button variant="outline-primary" disabled className="text-primary rounded-circle p-2">
                  <i className="fas fa-download fs-2"></i>
                </Button>
              ))}
            {task.status === ExternalTaskStatus['Not Completed'] && (
              <a
                href={isAssignedTask ? data.ESignLink : '#'}
                target="_blank"
                className={`btn btn-outline-primary rounded-circle p-2 ${isAssignedTask ? '' : 'disabled'}`}
                rel="noreferrer"
                style={{ width: '42px' }}>
                <i className="fas fa-signature fs-2"></i>
              </a>
            )}
          </Col>
        </Row>
      ))}
    </Task>
  );
};

export default MyTasks;
