import { Row } from 'react-bootstrap';
import Question from '../../Question';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';

const GC7 = () => {
  return (
    <Question
      label="Are you borrowing any money for this real estate transaction (e.g., money for your 
closing costs or down payment) or obtaining any money from another party, such as 
the seller or realtor, that you have not disclosed on this loan application?">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedGC7_1"
          optionsClassName="pt-1 w-50 w-md-10"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default GC7;
