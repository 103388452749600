import { FC } from 'react';
import { Container, Row, Col, Button, Card, Image } from 'react-bootstrap';
import { ILoanOfficerPartnerType } from '../types/loanOfficerPartner.types';
import { ReactComponent as MinutemortgageLogo } from 'app/assets/img/cobranding-page/minutemortgage_logo.svg';
import { ReactComponent as Homesmart } from 'app/assets/img/cobranding-page/homesmart.svg';
import classNames from 'classnames';
import styles from './presentationCard.module.scss';
import { formatPhoneNumber } from '@rsl/core/src/utils/stringUtils';
import { useProfileImageByEmail, usePartnerProfileImageByEmail } from 'features/profileImage/hooks/profileImageHooks';

interface PresentationCardProps {
  applyEmail: string;
  loanOfficerPartner?: ILoanOfficerPartnerType;
  showPartner?: boolean;
}

const PresentationCard: FC<PresentationCardProps> = ({ applyEmail, loanOfficerPartner, showPartner }) => {
  const loProfileImage = useProfileImageByEmail(loanOfficerPartner?.loEmail);
  const partnerProfileImage = usePartnerProfileImageByEmail(loanOfficerPartner?.partnerEmail);

  return (
    <>
      <div className={classNames('cobranding-page-bg position-absolute start-0 end-0 top-15', styles.presentationCardBg)}></div>

      <Container className="mb-4">
        <div className="d-flex justify-content-center mt-7">
          <Card className={classNames('bg-white rounded-5', styles.presentationCardWidth)}>
            <Row className="g-0 py-5">
              <Col md={5} className="border-end px-4 px-md-6 d-flex align-items-center text-center text-md-start">
                <div>
                  <h1 className="fw-bold">Score a Loan That's a Perfect Fit.</h1>
                  <h3 className="mb-5">Get approval in minutes with our simple, online application process.</h3>
                  <Button variant="primary-gradient rounded-pill" size="lg" className="px-5 py-3 mb-5 mb-md-3" href={`apply-now/register/${applyEmail}`}>
                    APPLY
                  </Button>
                  <div className="d-md-none">
                    <hr className="mb-5" />
                    <h2 className="fw-bold text-primary">{showPartner ? "We're " : "I'm "}Here to Help!</h2>
                  </div>
                </div>
              </Col>
              <Col md={7}>
                <Card.Body className="px-md-5">
                  <Row>
                    <Col className="text-start text-md-center" xs={12} md={6}>
                      <Row>
                        <Col xs={4} md={12}>
                          <div className={classNames('d-flex justify-content-center', styles.profileImageHeightMd)}>
                            <Image thumbnail roundedCircle src={loProfileImage} className="mb-4 border-info border-3 d-none d-md-block" alt="Loan Officer" />
                            <Image
                              thumbnail
                              roundedCircle
                              src={loProfileImage}
                              className={classNames('mb-4 border-info border-3 d-md-none', styles.profileImageHeightSm)}
                              alt="Loan Officer"
                            />
                          </div>
                        </Col>
                        <Col xs={8} md={12}>
                          <h3 className="fw-bold mb-0 text-dark">{`${loanOfficerPartner?.loFirstName} ${loanOfficerPartner?.loLastName}`}</h3>
                          <p className="fw-bold mb-0 text-tertiary">
                            <small>{loanOfficerPartner?.loTitle}</small>
                          </p>
                          <p className="mb-3">
                            <small>
                              NMLS#{loanOfficerPartner?.loNmlsNumber} | {loanOfficerPartner?.loLicenseState} {loanOfficerPartner?.loLicenseNumber}
                            </small>
                          </p>
                          {loanOfficerPartner?.loMobilePhone && <p className="mb-0 text-dark">Cell: {formatPhoneNumber(loanOfficerPartner.loMobilePhone)}</p>}
                          {loanOfficerPartner?.loWorkPhone && <p className="mb-0 text-dark">Work: {formatPhoneNumber(loanOfficerPartner.loWorkPhone)}</p>}
                          <p className="mb-0 fw-bold text-info">{loanOfficerPartner?.loEmail}</p>
                          <div className="text-md-center mt-4">
                            <MinutemortgageLogo height="50" />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    {showPartner && (
                      <Col className="text-start text-md-center" xs={12} md={6}>
                        <Row>
                          <Col xs={4} md={12}>
                            <div className={classNames('d-flex justify-content-center', styles.profileImageHeightMd)}>
                              {partnerProfileImage && (
                                <>
                                  <Image thumbnail roundedCircle src={partnerProfileImage} className="mb-4 border-info border-3 d-none d-md-block" alt="Partner" />
                                  <Image
                                    thumbnail
                                    roundedCircle
                                    src={partnerProfileImage}
                                    className={classNames('mb-4 border-info border-3 d-md-none', styles.profileImageHeightSm)}
                                    alt="Partner"
                                  />
                                </>
                              )}
                            </div>
                          </Col>
                          <Col xs={8} md={12}>
                            <h3 className="fw-bold mb-0 text-dark">{`${loanOfficerPartner?.partnerFirstName} ${loanOfficerPartner?.partnerLastName}`}</h3>
                            <p className="fw-bold mb-0 text-tertiary">
                              <small>REALTOR&reg;</small>
                            </p>
                            <p className="mb-3">
                              <small>License# {loanOfficerPartner?.partnerLicenseNumber}</small>
                            </p>
                            {loanOfficerPartner?.partnerMobilePhone && <p className="mb-0 text-dark">Cell: {formatPhoneNumber(loanOfficerPartner.partnerMobilePhone)}</p>}
                            {loanOfficerPartner?.partnerWorkPhone && <p className="mb-0 text-dark">Work: {formatPhoneNumber(loanOfficerPartner.partnerWorkPhone)}</p>}
                            <p className="mb-0 fw-bold text-info">{loanOfficerPartner?.partnerEmail}</p>
                            {loanOfficerPartner?.partnerUrlWebsite && (
                              <a className="mb-0 text-secondary" href={loanOfficerPartner?.partnerUrlWebsite} target="_blank" rel="noreferrer">
                                <small>My Website</small>
                              </a>
                            )}
                            {loanOfficerPartner?.partnerCompanyName?.toLowerCase() === 'homesmart' ? (
                              <div className="text-md-center mt-4">
                                <Homesmart height="50" />
                              </div>
                            ) : (
                              loanOfficerPartner?.partnerLogoImage && (
                                <div className="text-md-center mt-4">
                                  <Image src={`data:image/jpeg;base64,${loanOfficerPartner.partnerLogoImage}`} className={classNames(styles.logoImageHeight)} alt="Partner Logo" />
                                </div>
                              )
                            )}
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default PresentationCard;
