import { Placeholder, Row } from 'react-bootstrap';
import QuestionLayout from '../layouts/QuestionLayout';

const FAKE_ROWS = new Array(3).fill(null);

const Skeleton = () => (
  <section className="py-2 px-3">
    <Placeholder as={Row} className="my-2" animation="glow">
      <Placeholder xs={12} md={6} size="lg" bg="primary" />
    </Placeholder>
    <Placeholder as={Row} className="d-flex flex-column mb-4" animation="glow">
      <Placeholder xs={8} md={4} className="mb-2" size="lg" bg="secondary" />
      <Placeholder xs={8} md={4} className="mb-2" size="lg" bg="secondary" />
      <Placeholder xs={8} md={4} className="mb-2" size="lg" bg="secondary" />
    </Placeholder>
  </section>
);

const QuestionSkeleton = () => {
  return (
    <>
      {FAKE_ROWS.map((_, index) => (
        <QuestionLayout>
          <QuestionLayout.Body>
            <Skeleton key={`question-skeleton-${index}`} />
          </QuestionLayout.Body>
        </QuestionLayout>
      ))}
    </>
  );
};

export default QuestionSkeleton;
