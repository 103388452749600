import { createSlice } from '@reduxjs/toolkit';
import { pinVerificationApi } from 'features/pin/api/pinVerificationApi';
import { PinSliceState } from 'features/pin/types';

const initialState: PinSliceState = {
  currentPinAction: null,
};

export const pinSlice = createSlice({
  name: 'pin',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    flushPinAction: (state) => {
      state.currentPinAction = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(pinVerificationApi.endpoints.fetchPinAction.matchFulfilled, (state, { payload }) => {
      state.currentPinAction = payload;
    });
  },
});

export const { flushPinAction } = pinSlice.actions;

export default pinSlice.reducer;
