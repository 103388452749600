import { Card, Container } from 'react-bootstrap';
import PartnerEdit from './components/PartnerEdit';

const PartnerProfile = () => {
  return (
    <Container fluid>
      <h1 className="my-4 text-primary">My Profile</h1>
      <Card>
        <Card.Body>
          <PartnerEdit />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PartnerProfile;
