import { IPublicRoute } from '@rsl/core/src/types';

const routes: IPublicRoute = {
  title: 'Tools & Utils',
  slug: 'tools',
  displayOnMenu: true,
  isCategoryMenu: true,
  childs: [
    {
      title: 'Refinance Calculator',
      slug: 'refinance-calculator',
      displayOnMenu: true,
      externalLink: `${window.location.origin}/tools/refinance-calc`,
    },
  ],
};

export default routes;
