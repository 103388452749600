import { ILoanNeedsListItem, LoanNeedsListBorrowerStatus } from '@rsl/core';

const needlistBorrowerSubmittedStatuses = [
  LoanNeedsListBorrowerStatus.Imported,
  LoanNeedsListBorrowerStatus.Cleared,
  LoanNeedsListBorrowerStatus.Rejected,
  LoanNeedsListBorrowerStatus.Waived,
  LoanNeedsListBorrowerStatus.ImportedPending,
];

export const getFilesGroup = (email: string, isSubmittedStatus: boolean, files?: ILoanNeedsListItem[]) => {
  if (!files) return [];

  const filteredFiles = files.filter((needList) => {
    const needlistBorrower = needList.loanNeedsListBorrowers.find((borrower) => borrower.email === email);

    // if the current user is not in the needlist, then we want to check if all the borrowers are in the submitted status or not
    if (!needlistBorrower) {

      return needList.loanNeedsListBorrowers.every((borrower) => needlistBorrowerSubmittedStatuses.includes(borrower.status as any)) === isSubmittedStatus;
    }

    return needlistBorrowerSubmittedStatuses.includes(needlistBorrower?.status as any) === isSubmittedStatus;
  });

  filteredFiles.sort((needListA, needListB) => {
    const needlistBorrowerA = needListA.loanNeedsListBorrowers[0]?.updatedDateTimeUtc;
    const needlistBorrowerB = needListB.loanNeedsListBorrowers[0]?.updatedDateTimeUtc;

    if (!needlistBorrowerA && !needlistBorrowerB) return 0;

    if (!needlistBorrowerA && !!needlistBorrowerB) {
      return -1;
    }

    if (!needlistBorrowerB && !!needlistBorrowerA) {
      return 1;
    }
    const parsedDateA = new Date(needlistBorrowerA ?? new Date());
    const parsedDateB = new Date(needlistBorrowerB ?? new Date());

    return parsedDateB.getTime() - parsedDateA.getTime();
  });

  return filteredFiles;
};
