import { ReactComponent as AddIcon } from 'app/assets/icons/add.svg';
import { FC } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

const AddButtonText: FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button variant="link" {...props}>
      <span className="icon-image">
        <AddIcon />
      </span>
      <span className="fw-bold align-middle d-none d-lg-inline">{children}</span>
    </Button>
  );
};

export default AddButtonText;
