import { IPublicRoute } from '@rsl/core/src/types';
import ExchangeOtp from './components/ExchangeOtp';

const routes: IPublicRoute = {
  title: 'Otp',
  slug: 'exchange/:externalUserId/:otp',
  displayOnMenu: false,
  render: () => <ExchangeOtp />,
};

export default routes;
