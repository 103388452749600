import { FC } from 'react';
import { useLookup } from '@rsl/core/src/hooks/lookupsHooks';
import SelectField, { MinuteSelectFieldType } from './SelectField';
import { LookupKey } from '@rsl/core/src/types/lookup.types';
import { IOption } from '@rsl/core/src/types';

interface ILookupSelect {
  lookup: LookupKey;
  exclude?: string[];
  prependOptions?: IOption<any>[];
  overrideDefault?: IOption<any>;
  enableDefault?: boolean;
}

const LookupSelect: FC<Omit<MinuteSelectFieldType, 'options'> & ILookupSelect> = ({ lookup, exclude = [], prependOptions, overrideDefault, enableDefault = true, ...props }) => {
  const { options: data, isLoading } = useLookup(lookup);
  let options = exclude.length ? data.filter((item: any) => !exclude.includes(String(item.value))) : data;

  // Prepend options
  if (prependOptions) {
    options = [...prependOptions, ...options];
  }

  // Add default value
  if (!props.isMulti && options.length && enableDefault) {
    const defaultOption = overrideDefault || ({ label: 'Select an option', value: '' } as IOption<string>);
    options = [defaultOption, ...options];
  }

  return <SelectField options={options} isLoading={isLoading} {...props} />;
};

export default LookupSelect;
