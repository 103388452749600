import { FC } from 'react';
import DashboardItemHeader from '../DashboardItem/DashboardItemHeader';
import DashboardLayout from '../DashboardLayout';
import { Card } from 'react-bootstrap';
import DashboardMilestone from '../DashboardItem/DashboardMilestone';
import { useCurrentLoanId } from 'app/hooks/loanHooks';
import { useFetchExternalLoanSummaryQuery } from 'app/api/externalSessionApi';
import DashboardDetailTabs from './DashboardDetailTabs';
import PrequalLink from '../DashboardItem/PrequalLink';
import Unauthorized from 'features/error-pages/Unauthorized';

const DashboardDetail: FC = () => {
  const loanId = useCurrentLoanId();
  const { data: loan, error, isLoading } = useFetchExternalLoanSummaryQuery(loanId ?? '', { skip: !loanId, refetchOnMountOrArgChange: true });

  return (
    <DashboardLayout title="Your Dashboard" titleHeader="Dashboard">
      {!!loan && (
        <section>
          <DashboardItemHeader loan={loan} isDetail />
          <Card>
            <Card.Body>
              <DashboardMilestone loan={loan} />
              <hr />
              <DashboardDetailTabs />
            </Card.Body>
          </Card>
          <div className="d-flex justify-content-center align-items-center d-lg-none">
            <PrequalLink loan={loan} />
          </div>
        </section>
      )}
      {!!isLoading && <div>loading....</div>}
      {(error as any)?.status === 403 && <Unauthorized />}
    </DashboardLayout>
  );
};

export default DashboardDetail;
