import { FC } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';

const P2: FC<IQuestionType> = ({ showIf }) => {
  return (
    <Question label="What is the expected monthly rental income?" showIf={showIf}>
      <Row>
        <CurrencyField className="mb-2" name="SharedP2_1" placeholder="Amount" required />
      </Row>
    </Question>
  );
};

export default P2;
