import { IProtectedRoute } from '@rsl/core';
import DashboardDetail from 'features/dashboard/DashoardDetail/DashboardDetail';
import NewMessage from './messages/NewMessage';
import PreQualOnDemand from './pre-qual/PreQualOnDemand';

const routes: IProtectedRoute[] = [
  {
    title: 'New message',
    slug: `loan/:loanId/messages/new`,
    displayOnMenu: false,
    render: () => <NewMessage />,
  },
  {
    slug: 'loan/:loanId/pre-qual',
    displayOnMenu: false,
    render: () => <PreQualOnDemand />,
  },
  {
    title: 'Loan',
    slug: 'loan/:loanId/:loanSection?',
    displayOnMenu: false,
    exact: true,
    render: () => <DashboardDetail />,
  },
];
export default routes;
