import { FC } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';

const C5c: FC<IQuestionType> = ({ showIf }) => {
  return (
    <Question label="Have you had a VA loan previously?" showIf={showIf}>
      <Row>
        <RadioField
          className="mb-2"
          name="SharedC5c_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default C5c;
