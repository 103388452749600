export enum AssetTypes {
  CheckingAccount = 'CheckingAccount',
  SavingsAccount = 'SavingsAccount',
  RetirementFund = 'RetirementFund',
  Stock = 'Stock',
  GiftOfCash = 'GiftOfCash',
  BridgeLoanNotDeposited = 'BridgeLoanNotDeposited',
  PendingNetSaleProceedsFromRealEstateAssets = 'PendingNetSaleProceedsFromRealEstateAssets',
  Other = 'Other',
}

export const maxAssetAccounts = 4;
export const borrowerAssetNamePrefixes = ['SharedA1a_', 'SharedA1b_', 'SharedA1c_', 'SharedA1d_', 'SharedA1e_', 'SharedA1f_', 'SharedA1g_'];
export const coborrowerAssetNamePrefixes = ['SharedA2a_', 'SharedA2b_', 'SharedA2c_', 'SharedA2d_', 'SharedA2e_', 'SharedA2f_', 'SharedA2g_'];
