import RadioField from '@rsl/core/src/components/form/RadioField';
import { defaultMapper } from '@rsl/core/src/store/selectors/lookupSelectors';
import { OwnerTypes } from 'app/types/owner.types';
import { Col, Row } from 'react-bootstrap';

const ownerTypesArray: OwnerTypes[] = Object.values(OwnerTypes);
const ownerTypeOptions = ownerTypesArray.map(defaultMapper);

interface OwnerTypeSelectionProps {
  prefix: string;
}

const OwnerTypeSelection = (props: OwnerTypeSelectionProps) => {
  return (
    <Row className="py-3">
      <Col md={4}>
        <small>Who does this account belong to?</small>
      </Col>
      <Col md={8}>
        <RadioField name={`${props.prefix}.owner`} options={ownerTypeOptions} optionsClassName="super-small ms-2 flex-grow-1" optionsContainerClassName="d-flex mt-3" />
      </Col>
    </Row>
  );
};

export default OwnerTypeSelection;
