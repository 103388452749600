import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { OwnerTypes } from 'app/types';
import { defaultMapper } from '@rsl/core/src/store/selectors/lookupSelectors';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';
import TextField from '@rsl/core/src/components/form/TextField';

const ownerTypesArray: OwnerTypes[] = Object.values(OwnerTypes);
const ownerTypeOptions = ownerTypesArray.map(defaultMapper);

const L2d = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  return (
    <div className="border-bottom py-2 border-gray-dark">
      <Row>
        <CheckboxField className="fw-bold" name="ShowSharedL2d_" label="Other" horizontal={true} />
      </Row>
      {!!values.ShowSharedL2d_ && (
        <>
          <Row className="py-3">
            <Col md={4}>
              <small>Who does this liability belong to?</small>
            </Col>
            <Col md={8}>
              <RadioField name="SharedL2d_3" options={ownerTypeOptions} optionsClassName="super-small ms-2 flex-grow-1" optionsContainerClassName="d-flex mt-3" />
            </Col>
          </Row>
          <Row className="pt-1">
            <TextField className="mb-2" placeholder="Description" name="SharedL2d_1" xs={12} md={7} />
            <CurrencyField className="mb-2" placeholder="Monthly payment" name="SharedL2d_2" xs={12} md={5} />
          </Row>
        </>
      )}
    </div>
  );
};

export default L2d;
