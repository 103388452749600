import { IReoPropertyType } from '../../types';

export const emptyReoPropertyConstant: IReoPropertyType = {
  dispositionStatusType: '',
  propertyUsageType: '',
  futurePropertyUsageType: '',
  streetAddress: '',
  city: '',
  state: '',
  postalCode: '',
  unitNumber: '',
  owner: '',
  type: 'Other',
};
