import { FC, useEffect, useState } from 'react';
import { IQuestionType, ILoanQuestionsMapType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import { useFormikContext } from 'formik';

const C3: FC<IQuestionType> = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();
  const [disable, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (values.SharedB8_1 === QuestionValues.Yes) {
      setFieldValue('SharedC3_1', QuestionValues.Married);
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [values.SharedB8_1, setFieldValue]);

  return (
    <Question label="What's your current marital status?">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedC3_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.Married, value: QuestionValues.Married },
            { label: `${QuestionValues.Unmarried} (single, divorced, or widowed)`, value: QuestionValues.Unmarried, disabled: disable },
            { label: QuestionValues.Separated, value: QuestionValues.Separated, disabled: disable },
          ]}
          disable
        />
      </Row>
    </Question>
  );
};

export default C3;
