import SelectField from '@rsl/core/src/components/form/SelectField';
import { useDebouncedFunction } from '@rsl/core/src/hooks/actionsHooks';
import { Formik } from 'formik';
import { Dispatch } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';

const options = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Closed',
    value: 'Closed',
  },
  {
    label: 'Adverse',
    value: 'Adverse',
  },
];

const DashboardFilter = (props: { initialFilters: any; setFilters: Dispatch<any> }) => {
  const onsubmit = (values: any) => {
    console.log(values);
  };

  const setDebouncedFilters = useDebouncedFunction(props.setFilters, 500);

  return (
    <Formik initialValues={props.initialFilters} onSubmit={onsubmit}>
      {() => {
        return (
          <Row>
            <Col xs={8}>
              <InputGroup className="mb-3">
                <InputGroup.Text id="search-iccon-addon">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Type text to quck search"
                  aria-label="search"
                  aria-describedby="search-iccon-addon"
                  onChange={(e) =>
                    setDebouncedFilters((filter: any) => ({
                      ...filter,
                      word: e.target.value,
                    }))
                  }
                />
              </InputGroup>
            </Col>
            <Col xs={2}>
              <SelectField
                name="actionTaken"
                label="Filter by"
                options={options}
                hiddenLabel
                onChange={(Value) => {
                  setDebouncedFilters((filter: any) => ({
                    ...filter,
                    actionTaken: Value,
                  }));
                }}
              />
            </Col>
          </Row>
        );
      }}
    </Formik>
  );
};

export default DashboardFilter;
