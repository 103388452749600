import { sendFormWithFiles } from '../utils/formUtils';
import { useCallback, useMemo, useState } from 'react';

type FileTuple = [string, File];
type PayloadBuilder = (files: File[]) => any;
type FileListOptions = { limit?: number };

/**
 * manage list of files to upload
 * @param uploadFiles the Mutation funtion that performs the upload Action
 */
export const useFileList = (uploadFiles: Function, options: FileListOptions = {}) => {
  const [currentFiles, setCurrentFiles] = useState<FileTuple[]>([]);

  /**
   * variable to determine if the max file limit has been reached only when the file limit is provided
   */
  const limitReached = useMemo(() => !!options.limit && currentFiles.length >= options.limit, [options.limit, currentFiles.length]);

  /**
   * callBack for adding files into the list
   */
  const addFiles = useCallback(
    (files: File[]) => {
      setCurrentFiles((currentFiles) => {
        const newFiles = new Map(currentFiles);
        files.forEach((file) => {
          const limitReached = !!options.limit && newFiles.size >= options.limit;
          !newFiles.has(file.name) && !limitReached && newFiles.set(file.name, file);
        });

        return Array.from(newFiles.entries());
      });
    },
    [setCurrentFiles, options.limit]
  );

  /**
   * send the files to the server with the form data
   * @params params the params usually sent to the server
   * @param payloadBuilder a function that builds the payload to be sent to the server
   */
  const sendFormFiles = useCallback(
    async (params: Record<string, any>, payloadBuilder: PayloadBuilder) => {
      if (currentFiles.length > 0) {
        const files = currentFiles.map(([, file]) => file);
        const payload = payloadBuilder(files);
        await sendFormWithFiles(payload, files, params, uploadFiles);
      }
    },
    [uploadFiles, currentFiles]
  );

  /**
   * hanlder for the remove file button
   */
  const deleteFileButtonAction = useCallback(
    ({ currentTarget }: any) => {
      const newFiles = new Map(currentFiles);
      // TODO: find the correct type for using getAttribute
      newFiles.delete(currentTarget.getAttribute('data-file-name'));
      setCurrentFiles(Array.from(newFiles.entries()));
    },
    [currentFiles]
  );

  /**
   * handler for the remove all files
   */
  const deleteAllFiles = useCallback(() => {
    setCurrentFiles([]);
  }, []);

  return {
    currentFiles,
    setCurrentFiles,
    addFiles,
    sendFormFiles,
    deleteFileButtonAction,
    limitReached,
    deleteAllFiles,
  };
};
