import { CSSProperties, FC, ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface IFormModal {
  modalTitle: string | ReactNode;
  modalShow: boolean;
  size?: 'sm' | 'lg' | 'xl' | undefined;
  onAccept?: () => void;
  acceptLabel?: string;
  acceptDisabled?: boolean;
  isSubmitting?: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  cancelLabel?: string;
  cancelDisabled?: boolean;
  actions?: IFormAction[];
  acceptType?: 'submit' | 'button' | 'reset' | undefined;
  acceptForm?: string;
}

interface IFormAction {
  label: string;
  onClick: () => any;
  variant: string;
  width?: number;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset' | undefined;
  form?: string;
  submitting?: boolean;
}

const getActionButtonStyles = (action: IFormAction): CSSProperties => {
  const styles: CSSProperties = {};
  if (action.width) styles['minWidth'] = `${action.width}px`;
  return styles;
};

const FormModal: FC<IFormModal> = ({
  modalTitle,
  modalShow,
  size = undefined,
  children,
  onAccept,
  acceptLabel = 'Ok',
  acceptDisabled = false,
  isSubmitting = false,
  onCancel,
  onClose,
  cancelLabel = 'Cancel',
  cancelDisabled = false,
  actions = [],
  acceptType,
  acceptForm,
}) => {
  if (onCancel) actions.push({ label: cancelLabel, onClick: onCancel, variant: 'secondary', width: 70, disabled: cancelDisabled });
  if (onAccept)
    actions.push({ label: acceptLabel, onClick: onAccept, variant: 'primary', width: 70, disabled: acceptDisabled, type: acceptType, form: acceptForm, submitting: isSubmitting });

  const handleClose = () => {
    if (onClose) {
      onClose?.();
      return;
    }
    onCancel?.();
  };

  return (
    <>
      <Modal aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" show={modalShow} onHide={handleClose} size={size}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {actions.length > 0 && (
          <Modal.Footer>
            {actions.map((action, i) => (
              <Button
                type={action.type}
                form={action.form}
                key={i}
                onClick={action.onClick}
                variant={action.variant}
                style={getActionButtonStyles(action)}
                disabled={action.disabled ?? false}>
                {action.label} {action.type === 'submit' && action.submitting && <i className="fas fa-spinner fa-spin align-middle"></i>}
              </Button>
            ))}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default FormModal;
