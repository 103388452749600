export const isEmailInvalid = (email: string) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regex.test(email)) return false;
  return true;
};
export const isExtensionNotValid = (file: any) => {
  const allowedFileExtensions = /(\.jpg|\.jpeg|\.png|\.doc|\.docx|\.xls|\.xlsx|\.pdf|\.xml)$/i;
  if (!allowedFileExtensions.test(file?.name ?? '')) return false;
  return true;
};

export const isFileSizeExceeded = (file: any) => {
  const fileSize = file ? file.size / 1024 / 1024 : 0;
  if (fileSize > 20) return false;
  return true;
};

export const isAdditionalRecipientsValid = (value: string | undefined) => {
  const result = value
    ?.split(';')
    .map((token) => token.trim())
    .filter((token) => token)
    .some((token) => isEmailInvalid(token));

  return !value || !result;
};
