export interface ApiResponse<T> {
  status: ResponseStatus;
  data: T;
  message: null;
}

enum ResponseStatus {
  Success = 0,
  Error = 1,
}
