import { utcTimestamp } from '@rsl/core/src/utils/dateUtils';
import fetchIp from 'app/api/fetchIpApi';

let ip = '';

export const creditAuthSignTimeStamp = (name: string, email: string) => {
  return `${name} registered with email address ${email}, gave authorization at ${utcTimestamp()}.`;
};

export const eConsentSignTimeStamp = (name: string, email: string) => {
  return `${name} registered with email address ${email}, accepted e-consent at ${utcTimestamp()}.`;
};

export const certAuthSignTimeStamp = (name: string, email: string) => {
  return `${name} registered with email address ${email}, accepted certification agreement at ${utcTimestamp()}.`;
};

export const machineStamp = async () => {
  if (!ip) {
    ip = await fetchIp();
  }
  return `${ip}, ${navigator.userAgent}`;
};
