import { useEffect } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType, ILoanOfficerContactType } from '@rsl/core/src/types';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import { useFetchLoanOfficersQuery } from '../../../../api/loanPosApplicationApi';
import { formatPhoneNumber } from '@rsl/core/src/utils/stringUtils';

const T0 = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  const { data, isSuccess } = useFetchLoanOfficersQuery(values.loanPosApplicationId as string);

  useEffect(() => {
    if (values.SharedT0_1 === QuestionValues.No && isSuccess && data.length) {
      setFieldValue('loanOfficerEmail', data[0].email);
    } else if (values.SharedT0_1 === QuestionValues.Yes) {
      setFieldValue('loanOfficerEmail', '');
    }
  }, [values.SharedT0_1, data, isSuccess, setFieldValue]);

  return (
    <>
      <Question label="Are you already working with someone from Minute Mortgage?">
        <Row>
          <RadioField
            className="mb-2"
            name="SharedT0_1"
            optionsClassName="pt-1"
            options={[
              { label: QuestionValues.Yes, value: QuestionValues.Yes },
              { label: QuestionValues.No, value: QuestionValues.No },
            ]}
          />
        </Row>
      </Question>
      {isSuccess && values.SharedT0_1 === QuestionValues.Yes && (
        <Question label="Who are you working with?">
          <Row>
            <RadioField
              className="mb-2"
              name="loanOfficerEmail"
              smallLabelClassName="mx-5 text-tertiary"
              options={data.map((loanOfficerContact: ILoanOfficerContactType) => {
                return {
                  label: `${loanOfficerContact.preferredName}${loanOfficerContact.nmlsNumber ? ', NMLS# ' + loanOfficerContact.nmlsNumber : ''}`,
                  value: loanOfficerContact.email,
                  smallLabel: [
                    `${loanOfficerContact.businessAddressCity}, ${loanOfficerContact.state}, License# ${loanOfficerContact.licenseNumber}`,
                    `${loanOfficerContact.email}`,
                    `${loanOfficerContact.workPhone ? 'Office ' + formatPhoneNumber(loanOfficerContact.workPhone) : ''}
                     ${loanOfficerContact.workPhone && loanOfficerContact.mobilePhone ? ', ' : ''}
                     ${loanOfficerContact.mobilePhone ? 'Cell ' + formatPhoneNumber(loanOfficerContact.mobilePhone) : ''}
                    `,
                  ],
                };
              })}
            />
          </Row>
        </Question>
      )}
    </>
  );
};

export default T0;
