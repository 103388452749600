import { useEffect } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';

const R3 = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.SharedR3_1 === QuestionValues.No) {
      setFieldValue('SharedR3a_1', undefined);
      setFieldValue('SharedR3a_2', undefined);
      setFieldValue('SharedR3a_3', undefined);
      setFieldValue('SharedR3a_4', undefined);

      setFieldTouched('SharedR3a_1', false);
      setFieldTouched('SharedR3a_2', false);
      setFieldTouched('SharedR3a_3', false);
      setFieldTouched('SharedR3a_4', false);
    }
  }, [values.SharedR3_1, setFieldValue, setFieldTouched]);

  return (
    <Question label={`Are you currently making monthly payments on this property?`}>
      <Row>
        <RadioField
          className="mb-2"
          name="SharedR3_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default R3;
