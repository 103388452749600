import classNames from 'classnames';
import { FC } from 'react';
import { ReactComponent as Unchecked } from 'app/assets/icons/milestone-new.svg';
import { ReactComponent as Checked } from 'app/assets/icons/milestone-new-checked.svg';

interface CheckBadgeComponentProps {
  checked?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
}

/**
 * This component shows a checked or empoty badge, the width and the height are equal to the font size
 * @param props the props od the component
 */
const NewCheckBadge: FC<CheckBadgeComponentProps> = ({ checked = false, className, onChange }) => {
  const onClick = () => {
    onChange?.(!checked);
  };

  return (
    <span onClick={onClick} className={`${classNames(className)}`}>
      {checked ? <Checked /> : <Unchecked />}
    </span>
  );
};

export default NewCheckBadge;
