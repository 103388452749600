import { useFieldIds } from '@rsl/core/src/hooks/fieldHooks';
import { Field, useField } from 'formik';
import { FC, useState, useEffect } from 'react';
import { IMinuteField } from '../../types/form.types';
import MinuteField from './MinuteField';
import InputMask from 'react-input-mask';

interface IMaskedField {
  rows?: number;
  cols?: number;
  mask: string;
  mutation: RegExp;
  inputProps?: any;
}

const MaskedField: FC<IMinuteField<string> & IMaskedField> = ({ rows, cols, mask, mutation, inputProps, ...props }) => {
  const { id, name } = useFieldIds(props);
  const [field, { error, touched }, { setValue }] = useField({ name });
  const [node, setNode] = useState<any>();

  const handleChange = (ref: any) => {
    setValue(ref.target.value.replace(mutation, ''));
  };

  const handleBlur = (ref: any) => {
    setValue(ref.target.value.replace(mutation, ''));
  };

  useEffect(() => {
    if (node) {
      node.setCustomValidity((touched && error) || '');
    }
  }, [error, node, touched]);

  return (
    <MinuteField {...props}>
      <Field
        name={name}
        rows={rows}
        cols={cols}
        render={() => (
          <InputMask
            id={id}
            data-testid={id}
            inputRef={(el) => setNode(el)}
            name={name}
            type="text"
            className="form-control"
            mask={mask}
            onChange={handleChange}
            onBlur={handleBlur}
            value={field.value}
            alwaysShowMask={false}
            placeholder={props.placeholder}
            {...inputProps}
          />
        )}
      />
    </MinuteField>
  );
};

export default MaskedField;
