import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';
import { ValidationErrors } from '@rsl/core/src/constants/errors';

const B6d = () => {
  const { values, setFieldValue, errors } = useFormikContext<ILoanQuestionsMapType>();

  const onChange = (event: any) => {
    if (!event.target.checked) {
      setFieldValue('SharedB6d_1', '');
      setFieldValue('SharedB6d_2', '');
      setFieldValue('SharedB6d_3', '');
    }
  };

  return (
    <div className="border-bottom pt-2 border-gray-dark">
      <Row>
        <CheckboxField className="mb-0 fw-bold lg-label" name="ShowSharedB6d_" label="Other" horizontal={true} onChange={onChange} />
      </Row>
      {!!values.ShowSharedB6d_ && (
        <>
          <Row>
            <Col>
              {errors.atLeastOneB6dIncome && (
                <div className="text-danger mb-3">
                  <small>{ValidationErrors.AtLeastOneIncome}</small>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <CurrencyField className="mb-2" label="Monthly child support" name="SharedB6d_1" nullable placeholder="$0" xs={12} md={6} isInlineLabel />
            <CurrencyField className="mb-2" label="Monthly alimony" name="SharedB6d_2" nullable placeholder="$0" xs={12} md={6} isInlineLabel />
            <CurrencyField className="mb-2" label="Monthly other" name="SharedB6d_3" nullable placeholder="$0" xs={12} md={6} isInlineLabel />
          </Row>
        </>
      )}
    </div>
  );
};

export default B6d;
