import { Container } from 'react-bootstrap';

const Banner = () => {
  return (
    <>
      <Container className="text-center">
        <hr className="mb-7 mt-6" />
        <h1 className="fw-light mb-5">
          Want to learn more about what we offer? Check out our <span className="fw-bold text-info">loan products</span> or our many{' '}
          <span className="fw-bold text-info">available programs</span> to assist different types of borrowers!
        </h1>
        <h1 className="text-primary mb-6 fw-bold">MinuteMortgage.com</h1>
        <p className="fw-light mb-4">
          <small>*Successful turnaround and closing times are based on the expedient input of all required information from all parties involved.</small>
        </p>
      </Container>
    </>
  );
};

export default Banner;
