import Question from '../../Question';
import { Collapse, Row } from 'react-bootstrap';
import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import TextField from '@rsl/core/src/components/form/TextField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { ValidationErrors } from '@rsl/core/src/constants/errors';

const GC3 = () => {
  const { setFieldValue, errors, values } = useFormikContext<ILoanQuestionsMapType>();

  const american = () => {
    setFieldValue('SharedGC3_20', false);
  };

  const americanOption = () => {
    setFieldValue('SharedGC3_1', true);
    setFieldValue('SharedGC3_20', false);
  };

  const asian = () => {
    setFieldValue('SharedGC3_20', false);
  };

  const asianOption = () => {
    setFieldValue('SharedGC3_3', true);
    setFieldValue('SharedGC3_20', false);
  };

  const hawaiian = () => {
    setFieldValue('SharedGC3_20', false);
  };

  const hawaiianOption = () => {
    setFieldValue('SharedGC3_12', true);
    setFieldValue('SharedGC3_20', false);
  };

  const black = () => {
    setFieldValue('SharedGC3_20', false);
  };

  const white = () => {
    setFieldValue('SharedGC3_20', false);
  };

  const doNotWishToProvideThisInformation = () => {
    setFieldValue('SharedGC3_1', false);
    setFieldValue('SharedGC3_2', '');
    setFieldValue('SharedGC3_3', false);
    setFieldValue('SharedGC3_4', false);
    setFieldValue('SharedGC3_5', false);
    setFieldValue('SharedGC3_6', false);
    setFieldValue('SharedGC3_7', false);
    setFieldValue('SharedGC3_8', false);
    setFieldValue('SharedGC3_9', false);
    setFieldValue('SharedGC3_10', false);
    setFieldValue('SharedGC3_11', '');
    setFieldValue('SharedGC3_12', false);
    setFieldValue('SharedGC3_13', false);
    setFieldValue('SharedGC3_14', false);
    setFieldValue('SharedGC3_15', false);
    setFieldValue('SharedGC3_16', false);
    setFieldValue('SharedGC3_17', '');
    setFieldValue('SharedGC3_18', false);
    setFieldValue('SharedGC3_19', false);
  };

  return (
    <Question label="What is your race? (Check one or more)">
      {errors.atLeastOneRaceChecked && (
        <div className="text-danger">
          <small>{ValidationErrors.AtLeastOneAndSubChecked}</small>
        </div>
      )}
      <Row>
        <CheckboxField className="mb-0 checkbox-lite" name="SharedGC3_1" label="American Indian or Alaska Native" horizontal={true} onClick={american} />
      </Row>
      <Collapse in={values.SharedG3_1}>
        <Row>
          <TextField className="mb-2 me-3 ms-5" xs={8} placeholder="Enter Tribe (Optional)" name="SharedGC3_2" onClick={americanOption} />
        </Row>
      </Collapse>
      <Row>
        <CheckboxField className="mb-0 checkbox-lite" name="SharedGC3_3" label="Asian" horizontal={true} onClick={asian} />
      </Row>
      <Collapse in={values.SharedGC3_3}>
        <div>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC3_4" label="Asian Indian" horizontal={true} onClick={asianOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC3_5" label="Chinese" horizontal={true} onClick={asianOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC3_6" label="Filipino" horizontal={true} onClick={asianOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC3_7" label="Japanese" horizontal={true} onClick={asianOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC3_8" label="Korean" horizontal={true} onClick={asianOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC3_9" label="Vietnamese" horizontal={true} onClick={asianOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC3_10" label="Other Asian" horizontal={true} onClick={asianOption} />
          </Row>
          <Row>
            <TextField className="mb-2 mx-3 checkbox-lite" xs={8} label="Optional" name="SharedGC3_11" onClick={asianOption} />
          </Row>
        </div>
      </Collapse>
      <Row>
        <CheckboxField className="mb-0 checkbox-lite" name="SharedGC3_12" label="Native Hawaiian or Other Pacific Islander" horizontal={true} onClick={hawaiian} />
      </Row>
      <Collapse in={values.SharedGC3_12}>
        <div>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC3_13" label="Native Hawaiian" horizontal={true} onClick={hawaiianOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC3_14" label="Guamanian or Chamorro" horizontal={true} onClick={hawaiianOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC3_15" label="Samoan" horizontal={true} onClick={hawaiianOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedGC3_16" label="Other Pacific Islander" horizontal={true} onClick={hawaiianOption} />
          </Row>
          <Row>
            <TextField className="mb-2 me-3 ms-6" xs={8} placeholder="Optional" name="SharedGC3_17" onClick={hawaiianOption} />
          </Row>
        </div>
      </Collapse>
      <Row>
        <CheckboxField className="mb-0 checkbox-lite" name="SharedGC3_18" label="Black or African American" horizontal={true} onClick={black} />
      </Row>
      <Row>
        <CheckboxField className="mb-0 checkbox-lite" name="SharedGC3_19" label="White" horizontal={true} onClick={white} />
      </Row>
      <Row>
        <CheckboxField
          className="mb-2 checkbox-lite"
          name="SharedGC3_20"
          label="I do not wish to provide this information"
          horizontal={true}
          onClick={doNotWishToProvideThisInformation}
        />
      </Row>
    </Question>
  );
};

export default GC3;
