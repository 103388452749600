import { DefaultKeyType } from '../types';

/**
 * creates a MapObject and return a function that will return the value of the key, but will return the default Object if the maapping do not exists
 * @param maps the array Of arrays that contains the touple of values, used as key and value
 * @example
 * const getValue = getDefaultMap<string, string>([
 *  ['customKey', 'custom Value'],
 *  ['default', 'default Value'],
 * ]);
 *
 * getValue('customKey') // 'custom Value'
 * getValue('default') // 'default Value'
 * getValue('unknownKey') // 'default Value'
 */
export const getDefaultMap = <T extends string | number, T2>(maps: [T | DefaultKeyType, T2][]) => {
  const map = new Map<T | DefaultKeyType, T2>(maps);

  return (key: T | DefaultKeyType) => {
    const currentValue = map.get(key) ?? map.get('default');
    if (!currentValue) {
      throw new Error(`No value found for key: ${key}, and there is no default implementation for it`);
    }
    return currentValue;
  };
};
