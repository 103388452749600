export const onlyText = (text: string) =>
  text
    .toLowerCase()
    .trim()
    .replace(/[^a-z]/g, '');

export const capitalize = (text: string) => `${text.charAt(0).toUpperCase()}${text.substr(1)}`;

export const toCamelCase = (text: string) =>
  text
    .trim()
    .split(' ')
    .map((textPart, index) => (index ? capitalize(onlyText(textPart)) : onlyText(textPart)))
    .join('');

export const toSlugId = (text: string) =>
  text
    .trim()
    .toLocaleLowerCase()
    .replace(/[^a-z0-9]/g, '-');

export const caseInsensitiveSort = (a: string, b: string): number => a.localeCompare(b);

/**
 * capitalize the first letter of a name snd just return them
 * @param name the fullname
 * @example capitalizeNameInitials('john doe') // 'JD'
 */
export const capitalizeNameInitials = (name: string) => {
  return name
    .trim()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('');
};

/**
 * format a phone number to (xxx) xxx-xxxx
 * @param phone number
 * @example formatPhoneNumber('1234567890') // '(123) 456-7890'
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return '';
};
