import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';

const B1c = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();
  const isPrimaryResidenceAndRefinance: boolean = values.SharedW3_1 === QuestionValues.HomeIntendPrimary && values.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance;

  return (
    <Question label="Do you currently rent or own?">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedB1c_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.CurrentlyOwn, value: QuestionValues.CurrentlyOwn },
            { label: QuestionValues.CurrentlyRent, value: QuestionValues.CurrentlyRent, disabled: isPrimaryResidenceAndRefinance },
            { label: QuestionValues.CurrentlyRentFree, value: QuestionValues.CurrentlyRentFree, disabled: isPrimaryResidenceAndRefinance },
          ]}
        />
      </Row>
    </Question>
  );
};

export default B1c;
