import { FC } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';

const R2a: FC<IQuestionType> = ({ showIf }) => {
  return (
    <Question label="" showIf={showIf}>
      <Row>
        <CurrencyField className="mb-2" label="Expected monthly real income" name="SharedR2a_1" />
      </Row>
    </Question>
  );
};

export default R2a;
