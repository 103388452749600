import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { setLogoutState, setSessionData } from 'app/store/slices/authSlice';
import { externalAuthSel } from 'app/store/selectors/userSelector';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useChangePasswordMutation, useLoginMutation } from 'features/pin/api/pinVerificationApi';
import { useCurrentPinAction } from 'features/pin/hooks/pinHooks';
import { LoginType, PinActionEnum } from 'features/pin/types';
import { useRedirectUser } from './useRouteManager';
import { ExternalUserSessionResponse, LoanRoles } from 'app/types';
import { parseJwt } from '@rsl/core/src/utils/jwtUtils';
import cookie from 'react-cookies';
import { tokenCookieName } from 'app/constants';
import { baseApi } from '@rsl/core/src/api/baseApi';
import { pinActionRouteMap } from 'features/pin/constants/pinActionsConstants';
import { setIntendedRoute } from 'app/store/slices/routeManagerSlice';
import { currentLoanSelector } from 'app/store/selectors/loanSelector';

/**
 * hook to external User auth information
 */
export const useExternalAuth = () => {
  return useSelector(externalAuthSel);
};

/**
 * handle change Password
 */
export const useChangePassword = () => {
  const [changePasswordAction] = useChangePasswordMutation();
  const setSession = useSetSessionData();

  const currentPinAction = useCurrentPinAction();

  const changePassword = useCallback(
    async (password: string, pin: string) => {
      const response: any = await changePasswordAction({
        pinId: currentPinAction?.pinId as string,
        pin,
        payload: password,
      });

      if (!response.error) {
        setSession(response.data);
      }

      return response;
    },
    [changePasswordAction, currentPinAction?.pinId, setSession]
  );

  return changePassword;
};

/**
 * handle login
 */
export const useLogin = () => {
  const [request] = useLoginMutation();
  const setSession = useSetSessionData();

  const login = useCallback(
    async (credentials: LoginType) => {
      const response: any = await request(credentials);
      if (!response.error) {
        setSession(response.data);
      }
      return response;
    },
    [request, setSession]
  );

  return login;
};

/**
 * handle sing out functionality
 */
export const useSignOut = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    dispatch(setLogoutState());
    setTimeout(() => {
      dispatch(setIntendedRoute(`${pinActionRouteMap(PinActionEnum.Login)}`));
      dispatch(baseApi.util.invalidateTags(['ExternalUserLoans', 'PartnerProfileImage', 'Partner', 'PartnerLogoImage']));
      document.location.href = '/';
    }, 0);
  }, [dispatch]);
};

export const useSetSessionData = () => {
  const dispatch = useAppDispatch();
  const redirect = useRedirectUser();

  return useCallback(
    (sessionData: ExternalUserSessionResponse, from?: string, persistToken = true) => {
      dispatch(setSessionData(sessionData));
      if (persistToken) {
        const tokenData = parseJwt(sessionData.token);
        cookie.save(tokenCookieName, sessionData.token, { path: '/', expires: new Date(tokenData.exp * 1000), secure: true, sameSite: true });
      }
      redirect(sessionData, from);
    },
    [dispatch, redirect]
  );
};

/**
 * return if the current loan has valid access based on lorels
 * @param roles the sllowed roles
 */
export const useLoanRoleSecureHasAccess = (roles: LoanRoles[], currentRole?: LoanRoles) => {
  const [cachedRoles] = useState(roles);
  const loan = useAppSelector(currentLoanSelector);
  const roleToCheck = currentRole ?? loan?.role ?? '';
  const checkIsValid = useCallback(() => cachedRoles.map((role) => role.toLocaleLowerCase()).includes((roleToCheck as any).toLocaleLowerCase()), [roleToCheck, cachedRoles]);
  return checkIsValid();
};
