import { useEffect } from 'react';
import { Prompt, useLocation } from 'react-router-dom';

import { setLockedRoute } from '../../store/slices/routeSlice';
import { routeModuleSel } from '../../store/selectors/routeSelectors';
import { useDispatch, useSelector } from 'react-redux';

const LockedRouteMessageContainer = () => {
  const { lockedRouteMessage, isLockedRoute } = useSelector(routeModuleSel) as any;
  let location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLockedRoute({ isLockedRoute: false }));
  }, [dispatch, location]);

  // forces close window to show a message
  useEffect(() => {
    window.isLockedClose = isLockedRoute;
  }, [isLockedRoute]);

  return <Prompt when={isLockedRoute} message={lockedRouteMessage} />;
};

export default LockedRouteMessageContainer;
