import React from 'react';
import { ColProps } from 'react-bootstrap/esm/Col';
import { strEnum } from '../utils/enumUtils';

export interface IMinuteField<T> extends ColProps {
  /**
   * the identifier of the field, if emtpy, the it will be generated from the name
   */
  id?: string;
  /**
   * the name of the field if empty it will be generated from the label
   */
  name?: string;
  /**
   * id the field required
   */
  required?: boolean;
  /**
   * id the field nullable
   */
  nullable?: boolean;
  /**
   * the label of the field
   */
  label?: string;
  /**
   * additional text to label
   */
  labelEmphasis?: string;
  /**
   * the value on the field
   */
  value?: T;

  /**
   * The current class Name
   */
  className?: string;

  /**
   * is the field disabled ?
   */
  disabled?: boolean;

  /**
   * special function to render custom label content
   */
  renderLabel?: (label?: string) => React.ReactNode;

  /**
   * is the label hidden ?
   */
  hiddenLabel?: boolean;

  /**
   * is an input checkbox ?
   */
  isCheckbox?: boolean;

  /**
   * isLoading ?
   */
  isLoading?: boolean;
  /**
   * is this field diplayed as inline label
   */
  isInlineLabel?: boolean;

  /**
   * the form group className
   */
  groupClassName?: string;
}

export interface IOption<T> {
  label: string;
  value: T;
}

export const TextFieldTypeTypes = strEnum(['text', 'email', 'date', 'datetime', 'time', 'password', 'number', 'hidden']);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TextFieldTypeTypes = keyof typeof TextFieldTypeTypes;
