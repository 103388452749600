import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface LoadingButtonProps extends React.ComponentProps<typeof Button> {
  isLoading?: boolean;
  children: React.ReactNode;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({ isLoading = false, children, ...props }) => {
  return (
    <Button {...props} disabled={isLoading || props.disabled}>
      {children}
      {isLoading ? <Spinner animation="border" size="sm" className="ms-1" /> : null}
    </Button>
  );
};

export default LoadingButton;
