import Question from '../../Question';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { Row } from 'react-bootstrap';
import { QuestionValues } from '@rsl/core/src/constants';

const W1 = () => {
  return (
    <Question label="Why do you want to refinance?">
      <Row>
        <RadioField
          className="mb-2"
          name="RefinanceW1_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.LowerMyMonthlyPayment, value: QuestionValues.LowerMyMonthlyPayment },
            {
              label: QuestionValues.TakeCashOutToPayOtherExpenses,
              value: QuestionValues.TakeCashOutToPayOtherExpenses,
              labelEmphasis: 'home improvement, emergency fund, college tuition',
            },
          ]}
        />
      </Row>
    </Question>
  );
};

export default W1;
