import { IProtectedRoute } from '@rsl/core';
import MyLoans from './Myloans';

const routes: IProtectedRoute = {
  title: 'My Loans',
  slug: 'loans',
  displayOnMenu: false,
  exact: true,
  render: () => <MyLoans />,
};
export default routes;
