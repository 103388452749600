import { useFetchProfileImageQuery, useFetchProfileImagePartnerQuery } from '../api/profileImageApi';

export const useProfileImageByEmail = (email?: string) => {
  const { data } = useFetchProfileImageQuery(email ?? '', { skip: !email });

  return !!data?.profileImage ? `data:image/jpeg;base64,${data.profileImage}` : undefined;
};

export const usePartnerProfileImageByEmail = (email?: string) => {
  const { data } = useFetchProfileImagePartnerQuery(email ?? '', { skip: !email });

  return !!data?.profileImage ? `data:image/jpeg;base64,${data.profileImage}` : undefined;
};
