import { IProtectedRoute } from '@rsl/core';
import { LoanRoles } from 'app/types';

/**
 * clean the routes by the giving roles
 * @param routes the routes to be cleaned
 * @param roles the allowed roles
 */
export const cleanRoutesByRole = (routes: IProtectedRoute[], role: LoanRoles) => {
  return routes.reduce<IProtectedRoute[]>((cleanRoutes, route) => {
    const lowerCaseRoles = route.restrictedRoles?.map((r) => r.toLowerCase());
    if (!lowerCaseRoles || lowerCaseRoles?.includes(role.toLowerCase())) {
      return [...cleanRoutes, route];
    }

    return cleanRoutes;
  }, []);
};
