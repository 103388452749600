import { FC } from 'react';
import { Card, Container, ProgressBar, Row, Col } from 'react-bootstrap';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import Config from '../config/Config';
import { UriConstants, breakPoints } from '@rsl/core/src/constants';
import { useCurrentPos } from '../hooks/posHooks';

interface IApplication {
  isEditing: boolean;
  currentStep: number;
}

const Application: FC<IApplication> = ({ isEditing, currentStep }) => {
  const { currentPos, savePos } = useCurrentPos();

  const values = currentPos as ILoanQuestionsMapType;
  const isLastStep = currentStep === Config.steps(values.loanPurposeType);
  const title = Config.title(values, currentStep);

  return (
    <>
      {!isLastStep && (
        <>
          <Container fluid className="mt-4 text-dark d-none d-md-block">
            <Row className="mb-3">
              <Col xs={12} className="d-flex align-items-center justify-content-around px-0 mx-0">
                <Container fluid className="px-2 mx-0">
                  <ProgressBar now={title.progress} variant="pos-progress" style={{ height: '4px' }} />
                </Container>
                <span className="text-lime">
                  <strong>{title.progress}%</strong>
                </span>
              </Col>
            </Row>
          </Container>
          <ProgressBar now={title.progress} label={`${title.progress}%`} variant="pos-progress" className="d-md-none" />
        </>
      )}
      <h2 className="text-center mb-0 py-2 fw-bold">{title.hide ? '' : title.name || title.getName?.(values)}</h2>
      {isEditing && (
        <Container fluid>
          <Card>
            <Card.Header>
              <p className="text-dark mt-2">
                You're now editing the loan application for <strong>{values.SharedW0_1}</strong> | <strong>{values.SharedW0_3}</strong>
                <br />
                <a className="btn btn-primary" href={UriConstants.RslProspects}>
                  Back to prospects
                </a>
              </p>
            </Card.Header>
          </Card>
        </Container>
      )}
      <Container style={{ maxWidth: isLastStep ? '' : breakPoints.sm }}>{Config.render(values, savePos, currentStep)}</Container>
    </>
  );
};

export default Application;
