import * as Yup from 'yup';
import { OwnerTypes } from 'app/types/owner.types';
import { QuestionValues } from '@rsl/core/src/constants';

const ownerTypesArray: OwnerTypes[] = Object.values(OwnerTypes);

export const getAssetValidation = (institution: boolean) => {
  const accountValidation: any = {
    amount: Yup.number().min(1).max(10000000).required(),
  };

  if (institution) {
    accountValidation.institutionName = Yup.string().required();
  }

  const accountWithOwnerValidation = {
    ...accountValidation,
    owner: Yup.string().required().oneOf(ownerTypesArray, 'Plese select one!'),
  };

  return {
    is: true,
    then: Yup.mixed().when('SharedB8_1', {
      is: (jointAssetLiabilityReportingIndicator: string) => jointAssetLiabilityReportingIndicator === QuestionValues.Yes,
      then: Yup.array().of(Yup.object(accountWithOwnerValidation)),
      otherwise: Yup.array().of(Yup.object(accountValidation)),
    }),
  };
};
