import { format, formatDistanceToNow, isDate, addDays as fAddDays, differenceInDays, parseJSON, differenceInYears } from 'date-fns';

/**
 * Internal function to wrap up the date format
 * @param date The date you want to transform
 * @param dateFormat the string you want to use as format
 * @returns A string with the format applied to the date passed
 */
const _format = (date: Date, dateFormat: string) => {
  if (isDate(date)) {
    return format(date, dateFormat);
  }
  return '';
};

/**
 * transforms the input to a date (doesn't validate)
 * @param date The date you want to transform
 * @returns A parsed Date, will return invalid date if we cannot parse it.
 */
export const parseDate = (date: string | Date): Date => {
  return parseJSON(date);
};

/**
 * Formats the date to get only the Date part of a ISO string date (excluding time)
 * @param date The date you want to transform
 * @returns A string with the date part of a ISO string date
 */
export const getDate = (input: Date | string | undefined) => {
  if (!input) return '';
  const d = parseDate(input);
  return d.toISOString().split('T')[0];
};
/**
 * Formats the date in local time
 * @param date The date you want to transform
 * @returns A string with the local time (excluding date)
 */
export const getTime = (input: Date | string | undefined) => {
  if (!input) return '';
  const d = parseDate(input);
  return _format(d, 'p');
};

/**
 * Formats the date in a timestamp
 * @returns A string with the local date and time presented as a timestamp
 */
export const utcTimestamp = () => {
  const d = new Date();
  return _format(d, 'yyyy-MM-dd HH:mm:ss XX');
};

/**
 * Formats the date in a short timestamp
 * @returns A string with the local date as a timestamp
 */
export const shortTimestamp = () => {
  const d = new Date();
  return _format(d, 'yyyy-MM-dd');
};

/**
 * Formats the date in local relative date time
 * @param dateTime The date you want to transform
 * @param addSuffix A boolean for whether you want to use the string suffix 'ago'
 * @returns A string with the local date and time presented in relative form (3 minutes ago, 3 days ago, etc)
 */
export const getDistance = (dateTime: Date | string | undefined, addSuffix = true) => {
  if (!dateTime) return '';
  const d = parseDate(dateTime);
  return formatDistanceToNow(d, { addSuffix });
};

/**
 * Formats the date in local date (excluding time)
 * @param date The date you want to transform
 * @returns A string with the local date
 */
export const formatDate = (date: string | Date | undefined) => {
  if (!date) return '';
  const d = parseDate(date);
  return _format(d, 'P');
};

/**
 * Formats the date in a friendly local date (excluding time) // Apr 12th
 * @param date The date you want to transform
 * @returns A string with the local date
 */
export const formatDateFriendly = (date: string | Date | undefined) => {
  if (!date) return '';
  const d = parseDate(date);
  return _format(d, 'MMM do');
};

/**
 * Formats the date in a full friendly local date (excluding time) // April 29th, 1453
 * @param date The date you want to transform
 * @returns A string with the local date
 */
export const formatFullDateFriendly = (date: string | Date | undefined) => {
  if (!date) return '';
  const d = parseDate(date);
  return _format(d, 'PPP');
};

/**
 * Formats the date in local date time
 * @param date The date you want to transform
 * @returns A string with the local date and time
 */
export const formatDateTime = (date: string | Date | undefined) => {
  if (!date) return '';
  const d = parseDate(date);
  return _format(d, 'Pp');
};

/**
 * Formats the date to be used in Date type Text fields
 * @param date The date you want to parse
 * @returns a string in the format 'YYYY-MM-DD'
 */
export const formatToDateTextField = (date: string | Date | undefined) => {
  if (!date) return '';
  const d = parseDate(date);
  return _format(d, 'yyyy-MM-dd');
};

/**
 * Add days to a given date
 * @param date the date you want to increase or decrease days
 * @param days The number of days you want to add (negative value to substract)
 * @returns the new date with the given days added.
 */
export const addDays = (date: string | Date, days: number): Date => {
  if (!date) return new Date();
  const d = parseDate(date);
  return fAddDays(d, days);
};

/**
 * Returns the difference in days between two dates
 * @param baseDate the date you want to use a base (usually the one you get from service)
 * @param date The date you want to compare to (usually today's date)
 * @returns number of days of differente
 */
export const daysDiff = (baseDate: string | Date, date: string | Date): number => {
  if (!baseDate || !date) return 0;
  const base = parseDate(baseDate);
  const d = parseDate(date);
  return differenceInDays(base, d);
};

/**
 * Return dateTime in format [Moth] [Day]
 * @param date the date to get formatted
 * @example shortDayFormat(new Date('2020-01-01')) // returns 'Jan 1'
 */
export const shortDayFormat = (date: string | Date | null) => {
  if (!!date) {
    return format(parseDate(date), 'LLL d');
  }
  return '';
};

/**
 * returns true if the given valida date is before today
 * @param date the date to compare
 */
export const isDateBeforeToday = (date: string | Date | null) => {
  if (!!date) {
    return parseDate(date) < new Date();
  }
  return false;
};

/**
 * returns true if the given valid date is less than n years
 * @param date to compare and n years
 */
export const dateIsLessThanYears = (date: Date, years: number): boolean => {
  return differenceInYears(new Date(), date) < years;
};
