import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';

const L1 = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  return <h3 className="fw-bold text-center">{`${values.SharedB1_1}, Do you have any additional liabilities or expenses listed below?`}</h3>;
};

export default L1;
