import * as Yup from 'yup';
import PurchaseConfig from './PurchaseConfig';
import RefinanceConfig from './RefinanceConfig';
import Step from '../components/Step';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';

const Config = {
  steps: (loanPurposeType: QuestionValues.LoanPurposeTypePurchase | QuestionValues.LoanPurposeTypeRefinance) => {
    switch (loanPurposeType) {
      case QuestionValues.LoanPurposeTypePurchase:
        return PurchaseConfig.steps.length - 1;
      case QuestionValues.LoanPurposeTypeRefinance:
        return RefinanceConfig.steps.length - 1;
    }
  },
  render: (values: ILoanQuestionsMapType, setValues: any, currentStep: number) => {
    let validationSchema = Yup.object().shape({});
    let questions = <></>;

    switch (values.loanPurposeType) {
      case QuestionValues.LoanPurposeTypePurchase:
        validationSchema = Yup.object().shape(PurchaseConfig.steps[currentStep].validations);
        questions = PurchaseConfig.steps[currentStep].questions;
        break;
      case QuestionValues.LoanPurposeTypeRefinance:
        validationSchema = Yup.object().shape(RefinanceConfig.steps[currentStep].validations);
        questions = RefinanceConfig.steps[currentStep].questions;
        break;
    }

    return (
      <Step key={`step-${currentStep}`} validationSchema={validationSchema} values={values} setValues={setValues} currentStep={currentStep}>
        {questions}
      </Step>
    );
  },
  nextStep: (values: ILoanQuestionsMapType, currentStep: number) => {
    const steps =
      values.loanPurposeType === QuestionValues.LoanPurposeTypePurchase
        ? PurchaseConfig.steps
        : values.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance
        ? RefinanceConfig.steps
        : [];

    if (!!steps[currentStep].jumpToStep) {
      return steps[currentStep].jumpToStep?.(values) || 0;
    }

    const next = currentStep + 1;

    for (let step = next; step < steps.length; step++) {
      if (steps[step].skip?.(values)) {
        continue;
      }

      return step;
    }

    return 0;
  },
  previousStep: (values: ILoanQuestionsMapType, currentStep: number) => {
    const steps =
      values.loanPurposeType === QuestionValues.LoanPurposeTypePurchase
        ? PurchaseConfig.steps
        : values.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance
        ? RefinanceConfig.steps
        : [];

    if (!!steps[currentStep].backToStep) {
      return steps[currentStep].backToStep?.(values) || 0;
    }

    const previous = currentStep - 1;

    for (let step = previous; step >= 0; step--) {
      if (steps[step].skip?.(values)) {
        continue;
      }

      return step;
    }

    return 0;
  },
  // TODO: remove title from form render, it should not be part of it
  title: (values: ILoanQuestionsMapType, currentStep: number) => {
    switch (values.loanPurposeType) {
      case QuestionValues.LoanPurposeTypePurchase:
        return PurchaseConfig.steps[currentStep].title;
      case QuestionValues.LoanPurposeTypeRefinance:
        return RefinanceConfig.steps[currentStep].title;
    }

    return { name: '', progress: 0 };
  },
};

export default Config;
