import { IPublicRoute } from '@rsl/core/src/types';
import PinAction from './components/PinAction';

const routes: IPublicRoute = {
  title: 'Pin',
  slug: 'action/:pinId',
  displayOnMenu: false,
  render: () => <PinAction />,
};

export default routes;
