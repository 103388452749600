export const RegexConstants = {
  AtLeastOneLetter: /[A-Za-z]/,
  Entity: /^[a-zA-Z\s]+$/,
  Name: /^[a-zA-Z]+$/,
  Nmls: /^[0-9]{4,12}$/,
  Phone: /^[0-9]{10}$/,
  Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/,
  Zip: /^[0-9]{5}$/,
  Ages: /^\d+(?:,\d+)*$/,
  LettersNumbersAndSpaces: /^[a-zA-Z0-9\s]+$/,
};
