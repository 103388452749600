import { useEffect } from 'react';
import Question from '../../Question';
import TextField from '@rsl/core/src/components/form/TextField';
import MaskedField from '@rsl/core/src/components/form/MaskedField';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { Row, Col } from 'react-bootstrap';
import PlacesAutoComplete, { PlaceType } from '../../../fields/PlacesAutocomplete';
import { IPlaceAutocompleteResponse } from '../../../fields/fields.types';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { shortTimestamp } from '@rsl/core/src/utils/dateUtils';

const E2 = () => {
  const { values, setFieldValue, setValues } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.SharedB6b_1 === -1) {
      setFieldValue('SharedB6b_1', values.employment.length);
    }
  }, [values.SharedB6b_1, setFieldValue, values.employment]);

  return (
    <>
      <Question label="Tell us about your business" labelSize="lg">
        <Row>
          <TextField className="mb-2" placeholder="Business Name" name="SharedE2_1" required />
        </Row>
        <Row>
          <Col xs={9}>
            <PlacesAutoComplete
              placeholder="Street Address"
              name="SharedE2_2"
              types={[PlaceType.STREET_ADDRESS, PlaceType.POINT_OF_INTEREST]}
              className="mb-2"
              onChange={(res: IPlaceAutocompleteResponse) =>
                setValues({
                  ...values,
                  SharedE2_2: res.address ?? values.SharedE2_2,
                  SharedE2_4: res.city ?? values.SharedE2_4,
                  SharedE2_5: res.state ?? values.SharedE2_5,
                  SharedE2_6: res.zipCode ?? values.SharedE2_6,
                })
              }
            />
          </Col>
          <TextField xs={3} className="mb-2" placeholder="Unit" name="SharedE2_3" />
        </Row>
        <Row>
          <TextField className="mb-2" placeholder="City" name="SharedE2_4" required />
          <LookupSelect className="mb-2" placeholder="State" name="SharedE2_5" lookup="states" required enableDefault={false} />
          <TextField className="mb-2" placeholder="Zip" name="SharedE2_6" required />
        </Row>
        <Row>
          <MaskedField
            className="mb-2"
            placeholder="Business phone"
            name="SharedE2_7"
            mask="(999) 999-9999"
            mutation={/[^0-9]+/g}
            nullable
            inputProps={{
              autocomplete: 'tel-national',
            }}
          />
        </Row>
        <Row>
          <TextField className="mb-2" placeholder="Job Title" name="SharedE2_8" required />
        </Row>
      </Question>
      <Question label="When did you start your business?">
        <Row>
          <TextField className="mb-2" type="date" name="SharedE2_9" placeholder="MM/DD/YYYY" max={shortTimestamp()} required />
        </Row>
      </Question>
      <Question label="Number of years in line of work?">
        <Row>
          <TextField className="mb-2" type="number" placeholder="#" name="SharedE2_10" required />
        </Row>
      </Question>
      <Question label="Number of months in line of work?">
        <Row>
          <TextField className="mb-2" type="number" placeholder="#" name="SharedE2_13" required />
        </Row>
      </Question>
      <Question label="What % of the company do you own?">
        <Row>
          <RadioField
            className="mb-2"
            name="SharedE2_11"
            optionsClassName="pt-1"
            options={[
              { label: 'I have an ownership share of less than 25%', value: 'less than 25%' },
              { label: 'I have an ownership share of 25% or more', value: '25% or more' },
            ]}
          />
        </Row>
      </Question>
      <Question label="What is your monthly income before taxes?">
        <Row>
          <CurrencyField className="mb-2" name="SharedE2_12" placeholder="$" />
        </Row>
      </Question>
    </>
  );
};

export default E2;
