import Question from '../../Question';
import { Row } from 'react-bootstrap';
import TextField from '@rsl/core/src/components/form/TextField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';

const C1d = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  return (
    <Question label="How long have you lived at your current address?">
      <Row>
        <TextField className="mb-2" type="number" name="SharedC1d_1" placeholder="Years" min={0} max={99} required disabled={values.SharedC1a_1} />
        <TextField className="mb-2" type="number" name="SharedC1d_2" placeholder="Months" min={0} max={11} required disabled={values.SharedC1a_1} />
      </Row>
    </Question>
  );
};

export default C1d;
