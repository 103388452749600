import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFetchLoanOfficerPartnerQuery } from './api/cobrandingPageApi';
import Header from './components/header';
import PresentationCard from './components/presentationCard';
import Body from './components/body';
import Banner from './components/banner';
import Footer from './components/footer';

const LandingPage = () => {
  const { agentEmail } = useParams<{ agentEmail: string }>();
  const { data, isLoading, isError } = useFetchLoanOfficerPartnerQuery(agentEmail);
  const history = useHistory();
  const [showPartner, setShowPartner] = useState<boolean>(false);

  useEffect(() => {
    if (isError || !agentEmail || (!!data?.loStatus && data?.loStatus !== 'Active')) {
      history.push('/');
    }

    if (!!data?.partnerId && data?.partnerStatus !== 'Inactive' && data?.loEmail !== agentEmail) {
      setShowPartner(true);
    }
  }, [isError, history, data?.partnerId, data?.partnerStatus, data?.loEmail, agentEmail, setShowPartner, data?.loStatus]);

  return isLoading ? null : (
    <>
      <Header />
      <PresentationCard loanOfficerPartner={data} showPartner={showPartner} applyEmail={agentEmail} />
      <Body />
      <Banner />
      <Footer />
    </>
  );
};

export default LandingPage;
