import { useEffect } from 'react';
import Question from '../../Question';
import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';

const C1a = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.SharedC1a_1) {
      // copy address from borrower if radio is true
      setFieldValue('SharedC1b_1', values.SharedB1b_1);
      setFieldValue('SharedC1b_2', values.SharedB1b_2);
      setFieldValue('SharedC1b_3', values.SharedB1b_3);
      setFieldValue('SharedC1b_4', values.SharedB1b_4);
      setFieldValue('SharedC1b_5', values.SharedB1b_5);
      setFieldValue('SharedC1c_1', values.SharedB1c_1);
      setFieldValue('SharedC1d_1', values.SharedB1d_1);
      setFieldValue('SharedC1d_2', values.SharedB1d_2);
      setFieldValue('SharedC1e_1', values.SharedB1e_1);
      setFieldValue('SharedC1i_1', values.SharedB1i_1);
      setFieldValue('SharedC1fa_1', values.SharedB1fa_1);
      setFieldValue('SharedC1fa_2', values.SharedB1fa_2);
      setFieldValue('SharedC1fa_3', values.SharedB1fa_3);
      setFieldValue('SharedC1fa_4', values.SharedB1fa_4);
      setFieldValue('SharedC1fa_5', values.SharedB1fa_5);
      setFieldValue('SharedC1fa_6', values.SharedB1fa_6);
      setFieldValue('SharedC1fa_7', values.SharedB1fa_7);
      setFieldValue('SharedC1fa_8', values.SharedB1fa_8);
      setFieldValue('SharedC1fb_1', values.SharedB1fb_1);
      setFieldValue('SharedC1fb_2', values.SharedB1fb_2);
      setFieldValue('SharedC1fb_3', values.SharedB1fb_3);
      setFieldValue('SharedC1fb_4', values.SharedB1fb_4);
      setFieldValue('SharedC1fb_5', values.SharedB1fb_5);
      setFieldValue('SharedC1fb_6', values.SharedB1fb_6);
      setFieldValue('SharedC1fb_7', values.SharedB1fb_7);
      setFieldValue('SharedC1fb_8', values.SharedB1fb_8);
      setFieldValue('SharedC1fc_1', values.SharedB1fc_1);
      setFieldValue('SharedC1fc_2', values.SharedB1fc_2);
      setFieldValue('SharedC1fc_3', values.SharedB1fc_3);
      setFieldValue('SharedC1fc_4', values.SharedB1fc_4);
      setFieldValue('SharedC1fc_5', values.SharedB1fc_5);
      setFieldValue('SharedC1fc_6', values.SharedB1fc_6);
      setFieldValue('SharedC1fc_7', values.SharedB1fc_7);
      setFieldValue('SharedC1fc_8', values.SharedB1fc_8);
    } else if (values.SharedC1a_1 === false) {
      // clean address when radio is false
      setFieldValue('SharedC1b_1', '');
      setFieldValue('SharedC1b_2', '');
      setFieldValue('SharedC1b_3', '');
      setFieldValue('SharedC1b_4', '');
      setFieldValue('SharedC1b_5', '');
      setFieldValue('SharedC1c_1', '');
      setFieldValue('SharedC1d_1', '');
      setFieldValue('SharedC1d_2', '');
      setFieldValue('SharedC1e_1', null);
      setFieldValue('SharedC1i_1', '');
      setFieldValue('SharedC1fa_1', '');
      setFieldValue('SharedC1fa_2', '');
      setFieldValue('SharedC1fa_3', '');
      setFieldValue('SharedC1fa_4', '');
      setFieldValue('SharedC1fa_5', '');
      setFieldValue('SharedC1fa_6', '');
      setFieldValue('SharedC1fa_7', '');
      setFieldValue('SharedC1fa_8', '');
      setFieldValue('SharedC1fb_1', '');
      setFieldValue('SharedC1fb_2', '');
      setFieldValue('SharedC1fb_3', '');
      setFieldValue('SharedC1fb_4', '');
      setFieldValue('SharedC1fb_5', '');
      setFieldValue('SharedC1fb_6', '');
      setFieldValue('SharedC1fb_7', '');
      setFieldValue('SharedC1fb_8', '');
      setFieldValue('SharedC1fc_1', '');
      setFieldValue('SharedC1fc_2', '');
      setFieldValue('SharedC1fc_3', '');
      setFieldValue('SharedC1fc_4', '');
      setFieldValue('SharedC1fc_5', '');
      setFieldValue('SharedC1fc_6', '');
      setFieldValue('SharedC1fc_7', '');
      setFieldValue('SharedC1fc_8', '');
    }
  }, [
    setFieldValue,
    values.SharedB1b_1,
    values.SharedB1b_2,
    values.SharedB1b_3,
    values.SharedB1b_4,
    values.SharedB1b_5,
    values.SharedB1c_1,
    values.SharedB1d_1,
    values.SharedB1d_2,
    values.SharedB1e_1,
    values.SharedB1i_1,
    values.SharedC1a_1,
    values.SharedB1fa_1,
    values.SharedB1fa_2,
    values.SharedB1fa_3,
    values.SharedB1fa_4,
    values.SharedB1fa_5,
    values.SharedB1fa_6,
    values.SharedB1fa_7,
    values.SharedB1fa_8,
    values.SharedB1fb_1,
    values.SharedB1fb_2,
    values.SharedB1fb_3,
    values.SharedB1fb_4,
    values.SharedB1fb_5,
    values.SharedB1fb_6,
    values.SharedB1fb_7,
    values.SharedB1fb_8,
    values.SharedB1fc_1,
    values.SharedB1fc_2,
    values.SharedB1fc_3,
    values.SharedB1fc_4,
    values.SharedB1fc_5,
    values.SharedB1fc_6,
    values.SharedB1fc_7,
    values.SharedB1fc_8,
  ]);

  return (
    <Question label="">
      <Row>
        <div className="d-flex align-items-center">
          <h2 className="mb-2 fw-bold fs-5">Copy from borrower?</h2>
          <CheckboxField className="mb-2 mx-2" name="SharedC1a_1" />
        </div>
      </Row>
    </Question>
  );
};

export default C1a;
