import { IPublicRoute } from '@rsl/core/src/types';
import { useExternalAuth } from 'app/hooks/authHooks';
import { pinActionRouteMap } from 'features/pin/constants/pinActionsConstants';
import { PinActionEnum } from 'features/pin/types';
import { FC } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

// TODO: create IExternalProtectedRoute interface
const ProtectedRoute: FC<IPublicRoute> = ({ children, render, ...rest }) => {
  const { isLoggedIn } = useExternalAuth();
  const { pathname } = useLocation();
  return <Route {...rest}>{isLoggedIn ? children ?? render?.({} as any) : <Redirect to={`${pinActionRouteMap(PinActionEnum.Login)}?callback=${pathname}`} />}</Route>;
};

export default ProtectedRoute;
