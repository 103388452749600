import { useSumCompute } from '@rsl/core/src/hooks/computeHooks';
import { getCurrencyFormatted } from '@rsl/core/src/utils/numberUtils';
import { ILoanQuestionsMapType, IEmploymentType } from '@rsl/core/src/types';
import { useFormikContext } from 'formik';

const B6eCompute = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  const total =
    useSumCompute<ILoanQuestionsMapType>([
      ['SharedB6c_1', (values) => !!values.ShowSharedB6c_],
      ['SharedB6c_2', (values) => !!values.ShowSharedB6c_],
      ['SharedB6c_3', (values) => !!values.ShowSharedB6c_],
      ['SharedB6d_1', (values) => !!values.ShowSharedB6d_],
      ['SharedB6d_2', (values) => !!values.ShowSharedB6d_],
      ['SharedB6d_3', (values) => !!values.ShowSharedB6d_],
    ]) +
    values.employment.reduce(
      (sum: number, employment: IEmploymentType) =>
        sum + (employment.basePayAmount ?? 0) + (employment.commissionsAmount ?? 0) + (employment.bonusAmount ?? 0) + (employment.overtimeAmount ?? 0),
      0
    );

  return (
    <div className="text-end pt-2">
      Total <strong className="text-primary">{getCurrencyFormatted(total)}</strong>
    </div>
  );
};

export default B6eCompute;
