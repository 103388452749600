import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as Tagline } from 'app/assets/img/cobranding-page/tagline.svg';
import { ReactComponent as Dollarsign } from 'app/assets/img/cobranding-page/dollarsign.svg';
import { ReactComponent as Clock } from 'app/assets/img/cobranding-page/clock.svg';
import { ReactComponent as Digitalprocess } from 'app/assets/img/cobranding-page/digitalprocess.svg';
import { ReactComponent as Service } from 'app/assets/img/cobranding-page/service.svg';
import { ReactComponent as TwentyFourclock } from 'app/assets/img/cobranding-page/24clock.svg';

const Body = () => {
  return (
    <>
      <Container className="text-center">
        <Row>
          <Col>
            <h1 className="mb-n2">Mortgage in</h1>
            <div className="d-flex justify-content-center">
              <Tagline height="113" />
            </div>
            <h2 className="mt-4 mb-6">Here's how we rock the mortgage process:</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} className="mb-5">
            <Dollarsign height="146" />
            <h4 className="text-primary my-4 fw-bold">Competitive Low Rates</h4>
            <p className="fs-4 text-dark">Cutting out unnecessary steps for a more efficient loan experience means lower rates!</p>
          </Col>
          <Col xs={12} md={4} className="mb-5">
            <Clock height="146" />
            <h4 className="text-primary my-4 fw-bold">Approval in Minutes</h4>
            <p className="fs-4 text-dark">Our online approval process saves time and gives applicants immediate feedback.</p>
          </Col>
          <Col xs={12} md={4} className="mb-5">
            <Digitalprocess height="146" />
            <h4 className="text-primary my-4 fw-bold">Seamless Digital Process</h4>
            <p className="fs-4 text-dark">Integrated tech speeds transaction processing and eliminates hassle.</p>
          </Col>
          <Col xs={12} md={true} className="mb-5">
            <Service height="146" />
            <h4 className="text-primary my-4 fw-bold">Personalized Expert Service</h4>
            <p className="fs-4 text-dark">Loan experts are standing by to lend a hand. And some money.</p>
          </Col>
          <Col xs={12} md={true}>
            <TwentyFourclock height="146" />
            <h4 className="text-primary my-4 fw-bold">“24/21” Fast Loan Closings</h4>
            <p className="fs-4 text-dark">Streamlined processes tailored to 24-hr cycles lead to faster loan closings — typically only 21 calendar days or less!*</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Body;
