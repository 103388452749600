import Question from '../../Question';
import { Row } from 'react-bootstrap';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';
import { useEditingFormikField } from '@rsl/core/src/hooks/fieldHooks';

const D1 = () => {
  const editingProps = useEditingFormikField();
  return (
    <Question label="What purchase price are you targeting?">
      <Row>
        <CurrencyField className="mb-2" placeholder="Estimated purchase price" name="PurchaseD1_1" required {...editingProps} />
      </Row>
    </Question>
  );
};

export default D1;
