import download from 'downloadjs';
import { toast } from 'react-toastify';

const downloadFile = async (url: string, body: any, filename: string = '', method: string = 'POST', token: string = '', deviceId = '') => {
  const extraParams: any = deviceId ? { 'X-DeviceId': deviceId } : {};

  return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...extraParams,
    },
    ...(method !== 'GET' && { body: JSON.stringify(body) }),
  })
    .then((resp) => {
      if (resp.ok) {
        return resp.blob();
      } else if (resp.status === 404) {
        toast.error('File not found');
        return;
      }
    })
    .then((blob) => {
      if (blob) {
        if (filename) download(blob, filename);
        else download(blob);
      }
    });
};

export default downloadFile;
