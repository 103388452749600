import Question from '../../Question';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';

const P1 = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  return (
    <Question label={`What are your plans for your ${values.SharedB1b_1} property?`}>
      <Row>
        <RadioField
          className="mb-2"
          name="PurchaseP1_1"
          optionsClassName="pt-1"
          options={[
            { label: 'Sell it', value: QuestionValues.PlanForPendingSale },
            { label: 'Keep it', value: QuestionValues.PlanForRetainForRental },
          ]}
        />
      </Row>
    </Question>
  );
};

export default P1;
