import { IStepTitleType } from '@rsl/core';
import { titlesMapConstants, titlesSections } from '@rsl/core/src/constants';
import { useEffect } from 'react';
import { FC, useState } from 'react';
import { useCurrentPos } from '../../hooks/posHooks';
import PosIndicator from '../PosIndicator';

const CurrentPosIndicator: FC = () => {
  const [, setCurrentStep] = useState<IStepTitleType | null>(null);
  const { currentPos } = useCurrentPos();
  const steps = titlesMapConstants.get(currentPos?.loanPurposeType);

  useEffect(() => {
    if (!!steps && currentPos?.uiCurrentStep !== undefined) {
      const currentStep = steps[currentPos.uiCurrentStep];
      setCurrentStep(currentStep);
    }
  }, [currentPos?.uiCurrentStep, steps]);

  return (
    <section>
      <div className="ms-3 mt-3">
        <h6 className="text-uppercase mb-3">Loan Application</h6>
        <div>
          <ul>
            {titlesSections.map((section, index) => {
              if (section?.hideSection?.(currentPos)) {
                return null;
              }

              return <PosIndicator title={section.name} key={`pos-index-${index}`} goesTo={section.step} subsections={section.subsections} />;
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default CurrentPosIndicator;
