import { IEmploymentType } from '../../types';

export const emptyEmployerConstant: IEmploymentType = {
  employerName: '',
  addressCity: '',
  addressPostalCode: '',
  addressState: '',
  addressStreetLine1: '',
  unitNumber: '',
  phoneNumber: '',
  positionDescription: '',
  employmentStartDate: '',
  timeInLineOfWorkYears: 0,
  currentEmploymentIndicator: true,
  selfEmployedIndicator: false,
};

export const emptyPreviousEmployerConstant: IEmploymentType = {
  employerName: '',
  addressCity: '',
  addressPostalCode: '',
  addressState: '',
  addressStreetLine1: '',
  unitNumber: '',
  phoneNumber: '',
  positionDescription: '',
  employmentStartDate: '',
  timeInLineOfWorkYears: 0,
  currentEmploymentIndicator: false,
  selfEmployedIndicator: false,
};

export const emptyBusinessConstant: IEmploymentType = {
  employerName: '',
  addressCity: '',
  addressPostalCode: '',
  addressState: '',
  addressStreetLine1: '',
  unitNumber: '',
  phoneNumber: '',
  positionDescription: '',
  employmentStartDate: '',
  timeInLineOfWorkYears: 0,
  percentOwn: '',
  currentEmploymentIndicator: false,
  selfEmployedIndicator: true,
};
