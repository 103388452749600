import { FC } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import TextField from '@rsl/core/src/components/form/TextField';
import { Row } from 'react-bootstrap';

const D3a: FC<IQuestionType> = ({ showIf }) => {
  return (
    <Question label="" showIf={showIf}>
      <Row>
        <TextField className="mb-2" label="Please describe" name="PurchaseD3a_1" />
      </Row>
    </Question>
  );
};

export default D3a;
