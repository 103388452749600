import DownloadLink from '@rsl/core/src/components/link/DownloadLink';
import { useExternalAuth } from 'app/hooks/authHooks';
import { DocumentSignTask, WetSign } from 'app/types';
import { FC, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Task from './Task';
import UploadDocumentModal from './UploadModal';
import { formatDate } from '@rsl/core/src/utils/dateUtils';

interface MyTaskProps {
  task: DocumentSignTask<WetSign[]>;
}

const MyWetSignTasks: FC<MyTaskProps> = ({ task }) => {
  const { token, deviceId, email } = useExternalAuth();
  const [show, setShow] = useState(false);
  const [loanDisclosureDocumentId, setLoandDisclosureDocumentId] = useState('');
  const handleShow = (loanDisclosureDocumentId: string) => {
    setShow(true);
    setLoandDisclosureDocumentId(loanDisclosureDocumentId);
  };
  const handleClose = () => setShow(false);

  const isAssignedTask = task.assignedTo.includes(email);

  return (
    <Task name={task.name} status={task.status}>
      <div>
        <UploadDocumentModal
          modalShow={show}
          title={'Upload Document'}
          onClose={handleClose}
          externalTaskId={task.externalTaskId}
          loanDisclosureDocumentId={loanDisclosureDocumentId}
          key={`LoanDisclouseDocumentId-${loanDisclosureDocumentId}`}
        />
        {task.viewModelJson.map((data) => (
          <Row>
            <Col xs={9} md={true}>
              <Row className="mt-2 mb-2 border-bottom no-last-border-bottom">
                <Col xs={12} md={true}>
                  <Card.Text className="fw-bold text-tertiary mb-0 d-md-none">
                    <small>Document</small>
                  </Card.Text>
                  <Card.Text className="fw-bold">{data.Name}</Card.Text>
                </Col>
                <Col xs={12} md={true}>
                  <Card.Text className="fw-bold text-tertiary mt-2 mb-0 d-md-none">
                    <small>Signer</small>
                  </Card.Text>
                  {data.SigningParties.map((s) => (
                    <Card.Text className="mb-0"> {s.Name}</Card.Text>
                  ))}
                </Col>
                <Col xs={12} md={true} className="d-none d-md-block">
                  {isAssignedTask ? (
                    <DownloadLink
                      className="btn btn-outline-primary text-primary rounded-circle p-2"
                      deviceId={deviceId}
                      token={token}
                      filename={``}
                      href={`/ExternalLoanTasks/${task.loanId}/${task.externalTaskId}/wetsign/${data.LoanDisclosureDocumentId}/download`}>
                      <i className="fas fa-print fs-2"></i>
                    </DownloadLink>
                  ) : (
                    <Button variant="outline-primary" className="rounded-circle p-2" disabled>
                      <i className="fas fa-print fs-2"></i>
                    </Button>
                  )}
                  <Button
                    variant="outline-primary"
                    className="rounded-circle p-2 ms-2"
                    onClick={() => handleShow(data.LoanDisclosureDocumentId)}
                    disabled={!isAssignedTask || !!data.UploadedDateTimeUtc}>
                    <i className="fas fa-upload fs-2"></i>
                  </Button>
                </Col>
                {data.UploadedDateTimeUtc !== null && <p className="mt-3 mb-0 small">{`Uploaded: ${formatDate(data.UploadedDateTimeUtc)}`}</p>}
              </Row>
            </Col>
            <Col xs={3} className="mt-3 d-flex flex-column align-items-center d-md-none">
              {isAssignedTask ? (
                <DownloadLink
                  className="btn btn-outline-primary text-primary rounded-circle p-2 mb-2"
                  deviceId={deviceId}
                  token={token}
                  filename={``}
                  href={`/ExternalLoanTasks/${task.loanId}/${task.externalTaskId}/wetsign/${data.LoanDisclosureDocumentId}/download`}>
                  <i className="fas fa-print fs-2"></i>
                </DownloadLink>
              ) : (
                <Button variant="outline-primary" className="rounded-circle p-2" disabled>
                  <i className="fas fa-print fs-2"></i>
                </Button>
              )}
              <Button
                variant="outline-primary"
                className="rounded-circle p-2"
                onClick={() => handleShow(data.LoanDisclosureDocumentId)}
                disabled={!isAssignedTask || !!data.UploadedDateTimeUtc}>
                <i className="fas fa-upload fs-2"></i>
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    </Task>
  );
};

export default MyWetSignTasks;
