import { useCallbackRoute } from 'app/hooks/routeHooks';
import NotFound from 'features/error-pages/NotFound';
import { FC } from 'react';
import { Spinner } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom';
import { useFetchPinActionQuery } from '../api/pinVerificationApi';
import RedirectOnPinError from './RedirectOnPinError';

const PinAction: FC = () => {
  const { pinId } = useParams<{ pinId: string }>();
  var callbackRoute = useCallbackRoute();

  const { data: pinAction, isFetching, isError, isUninitialized } = useFetchPinActionQuery(pinId, { refetchOnMountOrArgChange: true });

  if (isUninitialized || isFetching) {
    return <Spinner variant="primary" animation="border" />;
  }

  if (isError || !pinAction) {
    return <NotFound />;
  }

  if (pinAction?.hasValidated) {
    return <Redirect to={callbackRoute} />;
  }

  return <RedirectOnPinError action={pinAction.action} pinId={pinId} callbackRoute={callbackRoute} />;
};

export default PinAction;
