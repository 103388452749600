import { ILoanPosApplicationType, ILoanType } from '@rsl/core';
import { routeParamsSel } from '@rsl/core/src/store/selectors/routeSelectors';
import { externalSessionApi } from 'app/api/externalSessionApi';
import { LoanSummaryExternal } from 'app/types';
import { createSelector } from 'reselect';
import { RootState } from '../store';
import { externalUserPendingPosSel } from './userSelector';
import { MAX_DISPLAY } from 'app/constants';

export const currentLoanIdSel = (state: RootState): string | undefined => routeParamsSel(state).loanId as string | undefined;
export const currentPosIdSel = (state: RootState): string | undefined => routeParamsSel(state).loanPosApplicationId as string | undefined;

export const lastUiPosSel = (state: RootState) => state.pos.lastUiStep;

export const currentPosAsLoanSel = createSelector(externalUserPendingPosSel, (pendingPos: ILoanPosApplicationType | null | undefined) => {
  if (pendingPos) {
    const loan = {
      ...(pendingPos as any),
      currentMilestone: pendingPos.status,
      propertyLoanPurposeType: pendingPos.loanPurposeType,
    } as ILoanType;

    return loan as any;
  }
});

export const currentSummaryAsLoanListItem = createSelector(
  (state: RootState) => {
    const loanId = currentLoanIdSel(state) ?? '';
    return externalSessionApi.endpoints.fetchExternalLoanSummary.select(loanId)(state)?.data;
  },
  (loanSummary): LoanSummaryExternal | null => {
    return !!loanSummary
      ? ({
          ...loanSummary,
        } as any)
      : null;
  }
);

export const myLoansSel = createSelector(
  (state: RootState) => {
    const currentLoanId = currentLoanIdSel(state);
    const loans = externalSessionApi.endpoints.fetchExternalLoan.select({ take: MAX_DISPLAY, currentLoanId })(state)?.data?.rows ?? null;
    return loans;
  },
  currentPosAsLoanSel,
  currentLoanIdSel,
  currentSummaryAsLoanListItem,
  (loans, posLoan, currentLoanId, summaryLoan) => {
    const newLoans: LoanSummaryExternal[] = [...(loans ?? [])];
    if (!!summaryLoan && !newLoans?.find((loan) => loan.loanId === currentLoanId)) {
      newLoans.push(summaryLoan);
    }
    return [...newLoans, ...(posLoan ? [posLoan] : [])];
  }
);

// TODO: base current loan on Routes, but for now just get the first one
export const currentLoanSelector = createSelector(
  (state: RootState): string => routeParamsSel(state).loanId,
  myLoansSel,
  (routeLoanId, loans): LoanSummaryExternal | null => loans?.find((loan) => loan.loanId === routeLoanId) ?? null
);

export const otherLoansSel = createSelector(currentLoanSelector, myLoansSel, (currentLoan, loans) => loans?.filter((loan) => loan.loanId !== currentLoan?.loanId)) ?? [];
