import { ApiResponse } from '@rsl/core';
import { invalidateOnSuccess } from '@rsl/core/src/utils/cacheUtils';
import { baseApi } from '@rsl/core/src/api/baseApi';
import { IPartnerLogoImageType, IPartnerProfileImageType, IUserPartner } from '../types';

export const partnersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchPartnerProfile: build.query<IUserPartner, void>({
      query: () => `/externalpartners`,
      transformResponse: (response: ApiResponse<IUserPartner>) => response.data,
      providesTags: ['Partner'],
    }),
    fetchPartnerProfileImage: build.query<IPartnerProfileImageType, void>({
      query: () => `/externalpartners/profileimage`,
      transformResponse: (response: ApiResponse<IPartnerProfileImageType>) => response.data,
      providesTags: ['PartnerProfileImage'],
    }),
    fetchPartnerLogoImage: build.query<IPartnerLogoImageType, void>({
      query: () => `/externalpartners/logoimage`,
      transformResponse: (response: ApiResponse<IPartnerLogoImageType>) => response.data,
      providesTags: ['PartnerLogoImage'],
    }),
    updatePartner: build.mutation<IUserPartner, IUserPartner>({
      query: (body) => ({
        url: '/externalpartners',
        method: 'POST',
        body,
      }),
      transformResponse: (response: ApiResponse<IUserPartner>) => response.data,
      invalidatesTags: invalidateOnSuccess(['PartnerList', 'Partner', 'UserPartner']),
    }),
    uploadPartnerProfileImage: build.mutation<IPartnerProfileImageType, IPartnerProfileImageType>({
      query: (body) => ({
        url: `/externalpartners/profileimage`,
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: ApiResponse<IPartnerProfileImageType>) => response.data,
      invalidatesTags: invalidateOnSuccess(['PartnerProfileImage']),
    }),
    uploadPartnerLogoImage: build.mutation<IPartnerLogoImageType, IPartnerLogoImageType>({
      query: (body) => ({
        url: `/externalpartners/logoimage`,
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: ApiResponse<IPartnerLogoImageType>) => response.data,
      invalidatesTags: invalidateOnSuccess(['PartnerLogoImage']),
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchPartnerProfileQuery,
  useFetchPartnerProfileImageQuery,
  useUploadPartnerProfileImageMutation,
  useFetchPartnerLogoImageQuery,
  useUploadPartnerLogoImageMutation,
  useUpdatePartnerMutation,
} = partnersApi;
