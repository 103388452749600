import Question from '../../Question';
import { Row, Col } from 'react-bootstrap';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';

const G19 = () => {
  return (
    <Question label="What is your citizenship status?">
      <Row>
        <Col>
          <LookupSelect className="mb-2" placeholder="Citizenship" name="SharedG19_1" lookup="posCitizenshipResidencyTypes" required enableDefault={false} />
        </Col>
      </Row>
    </Question>
  );
};

export default G19;
