import { FC, useEffect } from 'react';
import { IQuestionType, ILoanQuestionsMapType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import { useFormikContext } from 'formik';

const B8: FC<IQuestionType> = ({ showIf }) => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.SharedB8_1 === QuestionValues.Yes) {
      setFieldValue('SharedC4_1', '');
      setFieldValue('SharedC4a_1', '');
      setFieldValue('SharedB8a_1', QuestionValues.No);
    }
  }, [values.SharedB8_1, setFieldValue]);

  return (
    <Question label="Is the co-borrower your legal spouse?" showIf={showIf}>
      <Row>
        <RadioField
          className="mb-2"
          name="SharedB8_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default B8;
