import { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import CustomDropdownToggle from '@rsl/core/src/components/util/CustomDropdownToggle';
import { IProtectedRoute } from '@rsl/core/src/types';
import { getBooleanFromStateOrValue, getBreadCrumbTitle } from '@rsl/core/src/utils/stateUtils';
import { useSelector } from 'react-redux';

interface ProcessingSubMenuProps {
  items: IProtectedRoute[];
  title: string;
  tabKey: string;
  onSubmenuClick: (tabKey: string, submenuKey: string) => void;
}

const ProcessingSubMenu: FC<ProcessingSubMenuProps> = ({ items, title, tabKey, onSubmenuClick }) => {
  const state = useSelector((state) => state);
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomDropdownToggle}>
        <span>{title}</span> <i className="fa fa-chevron-down ps-2"></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {items.map(({ title, breadcrumbDisplay, slug, displayOnMenu }) => {
          const display = getBooleanFromStateOrValue(state, displayOnMenu);
          return display ? (
            <Dropdown.Item onClick={() => onSubmenuClick(tabKey, slug ?? '')} key={`${tabKey}-${slug}`}>
              {getBreadCrumbTitle(state, title, breadcrumbDisplay)}
            </Dropdown.Item>
          ) : null;
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProcessingSubMenu;
