import { useEffect, useState } from 'react';
import { ILoanQuestionsMapType } from '@rsl/core';
import { getCurrencyFormatted } from '@rsl/core/src/utils/numberUtils';
import { borrowerAssetNamePrefixes } from '@rsl/core/src/constants';
import { IAssetType } from '@rsl/core/src/types';
import { useFormikContext } from 'formik';

const A1hCompute = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();
  const [total, setTotal] = useState<number>(0);
  const assetNamePrefixes = borrowerAssetNamePrefixes;

  useEffect(() => {
    const totalAssets = assetNamePrefixes.reduce((accum: number, namePrefix: string) => {
      if ((values as any)[`Show${namePrefix}`] && (values as any)[`${namePrefix}Accounts`]?.length) {
        const totalAccount = (values as any)[`${namePrefix}Accounts`].reduce((sum: number, account: IAssetType) => {
          return sum + +(account.amount ?? 0);
        }, 0);

        return accum + totalAccount;
      }

      return accum;
    }, 0);

    setTotal(totalAssets);
  }, [values, assetNamePrefixes, setTotal]);

  return (
    <div className="text-end pt-2">
      Total <strong className="text-primary">{getCurrencyFormatted(total)}</strong>
    </div>
  );
};

export default A1hCompute;
