import { Row } from 'react-bootstrap';
import Question from '../../Question';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';

const R2 = () => {
  return (
    <Question label="How do you use this property?">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedR2_1"
          optionsClassName="pt-1"
          options={[
            { label: 'Second Home', value: QuestionValues.HomeIntendSecond },
            { label: 'Investment', value: QuestionValues.HomeIntendInvestment },
          ]}
        />
      </Row>
    </Question>
  );
};

export default R2;
