import { useExternalAuth, useSignOut } from 'app/hooks/authHooks';
import classNames from 'classnames';
import { useCurrentPos } from 'features/apply-now/pos/hooks/posHooks';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as DashboardIcon } from 'app/assets/icons/dashboard.svg';

import { ReactComponent as UserIcon } from 'app/assets/icons/user.svg';
import { ReactComponent as LogoutIcon } from 'app/assets/icons/logout.svg';
import { Button } from 'react-bootstrap';
import { useBreakPoint } from '@rsl/core/src/hooks/windowEventHooks';
import CurrentPosIndicator from 'features/apply-now/pos/components/widgets/CurrentPosIndicator';

interface ISidebar {
  toggled: boolean;
  setToggled: Dispatch<SetStateAction<boolean>>;
}

const SidebarNew: FC<ISidebar> = ({ toggled, setToggled }) => {
  const { isLoggedIn, isDeviceVerified, isPartner } = useExternalAuth();
  const logout = useSignOut();
  const breakpoint = useBreakPoint();
  const { currentPos } = useCurrentPos();

  const closeMenuValidated = useCallback(() => {
    if (['xs', 'sm', 'md'].includes(breakpoint ?? '')) {
      setToggled(false);
    }
  }, [breakpoint, setToggled]);

  return (
    <nav id="sidebar" className={classNames('sidebar', toggled && 'toggled')}>
      <div className="sidebar-content pt-3">
        {isLoggedIn && isDeviceVerified && (
          <>
            <h6 className="text-uppercase ms-3">Home</h6>
            <ul>
              <li>
                <Link to={'/dashboard'} className="sidebar-link" onClick={closeMenuValidated}>
                  <DashboardIcon className="me-2" /> Dashboard
                </Link>
              </li>
            </ul>
            <hr />

            <h6 className="text-uppercase ms-3">Account</h6>
            <ul>
              {isPartner && (
                <li>
                  <Link to={'/partner/myprofile'} className="sidebar-link" onClick={closeMenuValidated}>
                    <UserIcon className="me-2" /> Profile
                  </Link>
                </li>
              )}
              <li>
                <Button
                  variant="link"
                  className="sidebar-link"
                  onClick={() => {
                    logout();
                    closeMenuValidated();
                  }}>
                  <LogoutIcon className="me-2" /> Logout
                </Button>
              </li>
            </ul>
          </>
        )}
        {!!currentPos && <CurrentPosIndicator />}
      </div>
    </nav>
  );
};

export default SidebarNew;
