import { FC } from 'react';
import NewCheckBadge from 'app/components/badges/NewCheckBadge';
import styles from './DashboardMilestoneItem.module.scss';
import classNames from 'classnames';

interface DashboardMilestoneItemProps {
  checked?: boolean;
  className?: string;
  title: React.ReactNode;
  dotOnEnd?: boolean;
  dotOnStart?: boolean;
}

const DashboardMilestoneItem: FC<DashboardMilestoneItemProps> = ({ className, checked, title, dotOnEnd, dotOnStart }) => {
  return (
    <li className={classNames('flex-grow-1 text-center position-relative', dotOnStart ? styles.dotStart : '', dotOnEnd ? styles.dotEnd : '', className)}>
      <NewCheckBadge checked={checked} />
      <small className="d-block mt-2 mx-4 text-nowrap">
        <span className="mx-1">{title}</span>
      </small>
    </li>
  );
};

export default DashboardMilestoneItem;
