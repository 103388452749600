import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { useFormikContext } from 'formik';
import Config from '../config/Config';

interface IStepper {
  values: ILoanQuestionsMapType;
  previous: () => any;
  isLoading: boolean;
  disableBackButton?: boolean;
  currentStep: number;
}

const Stepper: FC<IStepper> = ({ values, previous, isLoading, disableBackButton, currentStep }) => {
  const { isValid, errors, setFieldTouched } = useFormikContext<ILoanQuestionsMapType>();

  const lastStep = Config.steps(values.loanPurposeType);
  const validate = () => {
    if (!isValid) {
      const fieldNames = Object.keys(errors);
      // console log for easy debug on production
      console.log(errors);
      fieldNames.forEach((fieldName: string) => setFieldTouched(fieldName));
    }

    return isValid;
  };

  return currentStep !== lastStep ? (
    <section className="text-center pt-2 pb-5">
      <Button
        type="button"
        data-cy="back-button"
        data-testid="back-button"
        variant="dark"
        className="mx-2 text-white rounded-pill btn-common-size"
        onClick={previous}
        disabled={currentStep === 0 || isLoading || disableBackButton}
        size="lg">
        BACK
      </Button>
      <Button
        data-testid="submit"
        data-cy="submit"
        type="submit"
        variant="primary-gradient"
        className="mx-2 rounded-pill btn-common-size"
        onClick={validate}
        disabled={currentStep === lastStep || isLoading}
        size="lg">
        NEXT
      </Button>
    </section>
  ) : null;
};

export default Stepper;
