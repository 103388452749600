import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'app/assets/img/brands/logo_horizontal.svg';
import { ReactComponent as HamburguerIcon } from 'app/assets/icons/hamburger.svg';
import { useExternalAuth } from 'app/hooks/authHooks';
import { useFetchPartnerProfileImageQuery } from 'features/partner/profile/api/partnersApi';
import NameBadge from '@rsl/core/src/components/badges/NameBadge';

interface PosNavbarProps {
  onToggleSidebar: () => void;
}

const PosNavbar: FC<PosNavbarProps> = ({ onToggleSidebar }) => {
  const { fullName, isPartner } = useExternalAuth();
  const { data: profileImageType } = useFetchPartnerProfileImageQuery(undefined, { skip: !isPartner });

  const profileImage = profileImageType?.profileImage && `data:image/jpeg;base64,${profileImageType?.profileImage}`;

  return (
    <Navbar collapseOnSelect expand={true} className="navbar-theme ps-0 d-flex justify-content-between position-fixed start-0 end-0 top-0" bg="dark">
      <Button variant="link" title="toggle menu" className="hamburger-button sidebar-toggle p-4 m-0 rounded-0 position-relative border-0" onClick={onToggleSidebar} role="button">
        <div className="position-absolute top-50 start-50 translate-middle">
          <HamburguerIcon className="hamburger" />
        </div>
      </Button>
      <div>
        <Link to="/" className="sidebar-brand">
          <Logo />
        </Link>
      </div>
      <div>
        <NameBadge name={fullName ?? ''} src={profileImage} className="fs-1" />
      </div>
    </Navbar>
  );
};

export default PosNavbar;
