import { useFieldIds } from '@rsl/core/src/hooks/fieldHooks';
import { useField } from 'formik';
import { FC, useState } from 'react';
import { IMinuteField } from '../../types/form.types';
import MinuteField from './MinuteField';
import CurrencyInput from 'react-currency-input-field';

interface ICurrencyField {
  rows?: number;
  cols?: number;
  prefix?: string;
  suffix?: string;
  innerRef?: (el: HTMLInputElement | null) => void;
}

const CurrencyField: FC<IMinuteField<string> & ICurrencyField> = ({ rows, cols, prefix = '$', suffix = '', innerRef, placeholder, ...props }) => {
  const { id, name } = useFieldIds(props);
  const [field, , { setValue, setTouched }] = useField({ name });
  const [node, setNode] = useState<HTMLInputElement | null>(null);

  const handleChange = (value: string | undefined) => {
    setValue(value);
    setTouched(!!value, false);
  };

  return (
    <MinuteField {...props} fieldNode={node}>
      <CurrencyInput
        id={id}
        name={name}
        className="form-control"
        decimalsLimit={2}
        onValueChange={handleChange}
        defaultValue={field.value}
        prefix={prefix}
        suffix={suffix}
        decimalSeparator="."
        groupSeparator=","
        maxLength={10}
        ref={(el: HTMLInputElement | null) => {
          setNode(el);
          innerRef?.(el);
        }}
        placeholder={placeholder || '$0'}
        disabled={props.disabled}
        transformRawValue={(value) => (/\d/.test(value) ? value : '')}
        value={field.value}
      />
    </MinuteField>
  );
};

export default CurrencyField;
