import { useState } from 'react';
import Question from '../../Question';
import { Row, Button } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';
import MessageIndicator from '../../../shared/MessageIndicator';

const GC1 = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <>
      <Question label={`The U.S. Government requires us to ask these questions to your co-borrower.`} labelSize="lg" />
      <MessageIndicator>
        <strong>The purpose of collecting this information</strong> is to help ensure that all applicants are treated fairly and that the housing needs of communities and
        neighborhoods are being fulfilled.
        {showMore && (
          <>
            {' '}
            For residential mortgage lending, Federal law requires that we ask applicants for their demographic information (ethnicity, sex, and race) in order to monitor our
            compliance with equal credit opportunity, fair housing, and home mortgage disclosure laws. You are not required to provide this information, but are encouraged to do
            so. You may select one or more designations for "ethnicity" and one or more designations for "Race." <strong>The law provides that we may not discriminate</strong> on
            the basis of this information, or on whether you choose to provide it. However, if you choose not to provide the information and you have made this application in
            person, Federal regulations require us to note your ethnicity, sex, and race on the basis of visual observation or surname. The law also provides that we may not
            discriminate on the basis of age or marital status information you provide in this application.
          </>
        )}
        <Button variant="link" size="sm" className="p-0 mx-1 mt-n1" onClick={() => setShowMore(!showMore)}>
          <u>{showMore ? 'less' : 'more'}</u>
        </Button>
      </MessageIndicator>
      <Question label={`${values.SharedC1_1}, What is your sex?`}>
        <Row>
          <RadioField
            className="mb-2"
            name="SharedGC1_1"
            optionsClassName="pt-1"
            options={[
              { label: 'Male', value: QuestionValues.GenderMaleIndicator },
              { label: 'Female', value: QuestionValues.GenderFemaleIndicator },
              { label: 'I do not wish to provide this information', value: QuestionValues.GenderNotApplicableIndicator },
            ]}
          />
        </Row>
      </Question>
    </>
  );
};

export default GC1;
