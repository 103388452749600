import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoanQuestionsMapType } from '@rsl/core';
import { setLogoutState } from 'app/store/slices/authSlice';

export interface PosState {
  currentPos: ILoanQuestionsMapType | null;
  lastUiStep: number;
}

const initialState: PosState = {
  currentPos: null,
  lastUiStep: 0,
};

export const posSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCurrentPos: (state, { payload }: PayloadAction<ILoanQuestionsMapType | null>) => {
      state.currentPos = payload;
      if ((payload?.uiCurrentStep ?? 0) > state.lastUiStep) {
        state.lastUiStep = payload?.uiCurrentStep ?? 0;
      }
    },
    cleanCurrentPos: (state) => {
      state.currentPos = null;
      state.lastUiStep = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setLogoutState, (state) => {
      state.currentPos = null;
    });
  },
});

export const { setCurrentPos, cleanCurrentPos } = posSlice.actions;

export default posSlice.reducer;
