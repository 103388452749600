import { strEnum } from '../utils/enumUtils';

export const FlexAlign = strEnum(['start', 'end', 'center', 'between', 'around']);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FlexAlign = keyof typeof FlexAlign;

export const Sizes = strEnum(['sm', 'lg']);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Sizes = keyof typeof Sizes;
