import { FC } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';
import { IQuestionType } from '@rsl/core/src/types';
import { useShowQuestion } from '../../Question';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';

const L1: FC<IQuestionType> = ({ showIf }) => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();
  const show = useShowQuestion(showIf, values);

  return show ? (
    <Question label="How much cash do you want to take out?">
      <Row>
        <CurrencyField className="mb-2" placeholder="Amount" name="RefinanceL1_1" />
      </Row>
    </Question>
  ) : null;
};

export default L1;
