import { useEffect } from 'react';
import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType, IEmploymentType } from '@rsl/core/src/types';
import AddButtonText from 'app/components/buttons/AddButtonText';
import Question from '../../Question';
import { getCurrencyFormatted } from '@rsl/core/src/utils/numberUtils';
import { ValidationErrors } from '@rsl/core/src/constants/errors';

const C6b = () => {
  const { values, setFieldValue, submitForm, errors } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    setFieldValue('SharedC6b_1', null);
    setFieldValue('SharedEC2_1', '');
    setFieldValue('SharedEC2_2', '');
    setFieldValue('SharedEC2_3', '');
    setFieldValue('SharedEC2_4', '');
    setFieldValue('SharedEC2_5', '');
    setFieldValue('SharedEC2_6', '');
    setFieldValue('SharedEC2_7', '');
    setFieldValue('SharedEC2_8', '');
    setFieldValue('SharedEC2_9', '');
    setFieldValue('SharedEC2_10', '');
    setFieldValue('SharedEC2_11', '');
    setFieldValue('SharedEC2_12', '');
  }, [setFieldValue]);

  const addEmployment = () => {
    setFieldValue('SharedC6b_1', -1);
    setTimeout(() => {
      submitForm();
    }, 0);
  };

  const editEmployment = (employmentIndex: number) => {
    setFieldValue('SharedC6b_1', employmentIndex);

    const employment = values.coborrowerEmployment[employmentIndex] as IEmploymentType;
    setFieldValue('SharedEC2_1', employment.employerName);
    setFieldValue('SharedEC2_2', employment.addressStreetLine1);
    setFieldValue('SharedEC2_3', employment.unitNumber);
    setFieldValue('SharedEC2_4', employment.addressCity);
    setFieldValue('SharedEC2_5', employment.addressState);
    setFieldValue('SharedEC2_6', employment.addressPostalCode);
    setFieldValue('SharedEC2_7', employment.phoneNumber);
    setFieldValue('SharedEC2_8', employment.positionDescription);
    setFieldValue('SharedEC2_9', employment.employmentStartDate);
    setFieldValue('SharedEC2_10', employment.timeInLineOfWorkYears);
    setFieldValue('SharedEC2_11', employment.percentOwn);
    setFieldValue('SharedEC2_12', employment.basePayAmount);

    submitForm();
  };

  const removeEmployment = (employmentIndex: number) => {
    setFieldValue(
      'coborrowerEmployment',
      values.coborrowerEmployment.filter((el, index) => index !== employmentIndex)
    );
  };

  const employmentSubtotal = (employment: IEmploymentType) => {
    return (employment.basePayAmount ?? 0) + (employment.overtimeAmount ?? 0) + (employment.commissionsAmount ?? 0) + (employment.bonusAmount ?? 0);
  };

  const isSelfEmployment = (employment: IEmploymentType) => !employment.currentEmploymentIndicator && !!employment.selfEmployedIndicator;

  const hasSelfEmployments = () => {
    return values.coborrowerEmployment.some((employment: IEmploymentType) => isSelfEmployment(employment));
  };

  const hasLimitSelfEmployments = () => {
    return values.coborrowerEmployment.filter((employment: IEmploymentType) => isSelfEmployment(employment)).length >= 4;
  };

  return (
    <div className="border-bottom py-2 border-gray-dark">
      <Row>
        <CheckboxField name="ShowSharedC6b_" label="Self-employment" horizontal={true} className="fw-bold lg-label" xs={12} disabled={hasSelfEmployments()} />
      </Row>
      {errors.atLeastOneCoBorrowerSelfEmployment && (
        <div className="text-danger mb-3 mx-1">
          <small>{ValidationErrors.AtLeastOneSelfEmployment}</small>
        </div>
      )}
      {!!values.ShowSharedC6b_ && (
        <>
          <Question label="">
            {values.coborrowerEmployment.map((employment: IEmploymentType, index: number) =>
              isSelfEmployment(employment) ? (
                <>
                  <Row className={`mb-2`}>
                    <Col md={6}>
                      <h6 className="text-primary mb-0 fw-bold">{employment.employerName}</h6>
                    </Col>
                    <Col md={6}>
                      <h6 className="text-primary mb-0 fw-bold text-end">{getCurrencyFormatted(employmentSubtotal(employment) ?? 0)}</h6>
                    </Col>
                    <Col md={4}>
                      <Button variant="link" onClick={() => editEmployment(index)} className="ps-0 mid-small text-decoration-underline">
                        Edit
                      </Button>
                      <Button variant="link" onClick={() => removeEmployment(index)} className="mx-2 ps-0 mid-small text-decoration-underline">
                        Remove
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : null
            )}
            <AddButtonText onClick={addEmployment} className="mid-small px-0" disabled={hasLimitSelfEmployments()}>
              Add Employment
            </AddButtonText>
          </Question>
        </>
      )}
    </div>
  );
};

export default C6b;
