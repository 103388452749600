const CreditAuthorizationAgreement = ({ children }: any) => {
  return (
    <>
      <p>
        During the mortgage loan process, this institution performs one or more credit checks that require us to obtain and confirm information regarding your personal and
        financial background. This credit check includes, but is not limited to, your credit score, current and past employers, current and past consumer credit accounts, current
        and past residential addresses and your mortgage and/or rental history. By agreeing below and providing your social security number you are consenting to this credit check.
      </p>
      {children}
      <p className="text-danger mt-3">
        If your credit is currently frozen please contact all three credit reporting bureaus (Equifax, Transunion, Experian) to have the freezes temporarily removed. We suggest
        lifting the credit freeze for a minimum of one week. Once completed, please alert your loan officer.
      </p>
    </>
  );
};

export default CreditAuthorizationAgreement;
