import * as Yup from 'yup';
import { Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TextField from '@rsl/core/src/components/form/TextField';
import MaskedField from '@rsl/core/src/components/form/MaskedField';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import {
  RegexConstants,
  QuestionValues,
  PurchaseStepTitles,
  RefinanceStepTitles,
  emptyLoanApplicationConstant,
  emptyLoanBorrowerConstant,
  PurposeType,
  emptyReoPropertyConstant,
} from '@rsl/core/src/constants';
import ExtendedFormik from '@rsl/core/src/components/form/ExtendedFormik';
import { ValidationErrors } from '@rsl/core/src/constants/errors';
import { useCreateLoanPosApplicationMutation } from './api/loanPosApplicationApi';
import { useSetLayoutSettings } from 'app/hooks/layoutHooks';
import { useSetSessionData } from 'app/hooks/authHooks';
import { PinActionEnum } from 'features/pin/types';
import { pinActionRouteMap } from 'features/pin/constants/pinActionsConstants';
import { useAppDispatch } from 'app/hooks/hooks';
import { setIntendedRoute } from 'app/store/slices/routeManagerSlice';
import { cloneDeep } from 'lodash';
import { useAppParams } from '@rsl/core/src/hooks/routeHooks';
import { useState } from 'react';

interface RegisterType {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  loanPurposeType?: string;
}

const values: RegisterType = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  password: '',
  confirmPassword: '',
};

interface RegisterButtonsType {
  loanPurposeType: string;
}

const registerButtonsInitialValues: RegisterButtonsType = {
  loanPurposeType: '',
};

const registerFormSchema = Yup.object().shape({
  firstName: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
  lastName: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
  phone: Yup.string().matches(RegexConstants.Phone, ValidationErrors.Phone).nullable(),
  email: Yup.string().email().required(),
  password: Yup.string()
    .required(ValidationErrors.EmptyPassword)
    .min(8, ValidationErrors.PasswordShort)
    .max(15, ValidationErrors.PasswordLong)
    .matches(RegexConstants.Password, ValidationErrors.PasswordFormat),
  confirmPassword: Yup.string()
    .required(ValidationErrors.EmptyPassword)
    .min(8, ValidationErrors.PasswordShort)
    .max(15, ValidationErrors.PasswordLong)
    .matches(RegexConstants.Password, ValidationErrors.PasswordFormat)
    .oneOf([Yup.ref('password'), null], ValidationErrors.ConfirmPassword),
});

const Register = () => {
  const [createApplication] = useCreateLoanPosApplicationMutation();
  const setSessionData = useSetSessionData();
  const dispatch = useAppDispatch();
  const { referralSource } = useAppParams<{ referralSource: string }>();

  const [usageTypeValues, setUsageTypeValues] = useState<RegisterButtonsType>(registerButtonsInitialValues);
  const [isTypeSet, setIsTypeSet] = useState(false);

  const setUsageType = (rValues: RegisterButtonsType) => {
    setUsageTypeValues(rValues as any);
    setIsTypeSet(true);
  };

  const doSendInformation = async (formValues: RegisterType) => {
    const values = { ...formValues, ...usageTypeValues };
    const response: any = await createApplication({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      loan: {
        ...cloneDeep({
          ...emptyLoanApplicationConstant,
          reoProperties: values.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance ? [{ ...emptyReoPropertyConstant, type: 'SubjectProperty' }] : [],
        }),
        loanPurposeType: values.loanPurposeType as PurposeType,
        borrower: {
          ...cloneDeep(emptyLoanBorrowerConstant),
          firstName: values.firstName,
          lastName: values.lastName,
          mobilePhone: values.phone,
          emailAddressText: values.email,
        },
        uiCurrentStep: 0,
        uiCurrentStepTitle: values.loanPurposeType === QuestionValues.LoanPurposeTypePurchase ? PurchaseStepTitles[0].name : RefinanceStepTitles[0].name,
        referralSource,
      },
    });

    if (response.error) {
      const loginRoute = `${pinActionRouteMap(PinActionEnum.Login)}/1`;
      dispatch(setIntendedRoute(loginRoute));
    }
    throwErrorProp(response);
    setSessionData(response.data);
  };

  useSetLayoutSettings({
    subTitle: `Let's Begin`,
    isMainScreen: true,
  });

  return (
    <Card className="mx-2 text-center py-4 mt-3 flexible-box">
      <Card.Body>
        {!isTypeSet ? (
          <ExtendedFormik
            initialValues={registerButtonsInitialValues}
            onSubmit={setUsageType}
            variantSubmit="primary-gradient"
            showCancelButton={false}
            lockFormOnChanges={false}
            saveButtonClassName="px-4"
            buttonsAlign="center"
            showSaveButton={false}
            additionalActions={[
              {
                label: 'BUYING',
                type: 'submit',
                variant: 'primary-gradient',
                class: 'btn-common-size',
                alwaysEnabled: true,
                action: () => {},
                setValuesOnClick: [{ key: 'loanPurposeType', value: QuestionValues.LoanPurposeTypePurchase }],
              },
              {
                label: 'REFINANCING',
                type: 'submit',
                variant: 'primary-gradient',
                class: 'btn-common-size',
                alwaysEnabled: true,
                action: () => {},
                setValuesOnClick: [{ key: 'loanPurposeType', value: QuestionValues.LoanPurposeTypeRefinance }],
              },
            ]}>
            {() => (
              <div className="pb-4">
                <h2 className="fw-bold">Buying or refinancing a property?</h2>
                <div>We can get your application started in just a few minutes!</div>
              </div>
            )}
          </ExtendedFormik>
        ) : (
          <ExtendedFormik
            validationSchema={registerFormSchema}
            initialValues={values}
            onSubmit={doSendInformation}
            variantSubmit="primary-gradient"
            showCancelButton={false}
            saveText="Register"
            buttonSize="lg"
            lockFormOnChanges={false}
            saveButtonClassName="px-4"
            buttonsAlign="center">
            {() => (
              <div className="pb-2">
                <h2 className="fw-bold">Buying or refinancing a property?</h2>
                <div>We can get your application started in just a few minutes!</div>
                <Row className="mt-4">
                  <TextField sm={12} className="mb-3" placeholder="Legal First Name" name="firstName" required />
                  <TextField sm={12} className="mb-3" placeholder="Legal Last Name" name="lastName" required />
                </Row>
                <Row>
                  <MaskedField
                    sm={12}
                    className="mb-3"
                    placeholder="Mobile phone"
                    name="phone"
                    mask="(999) 999-9999"
                    mutation={/[^0-9]+/g}
                    nullable
                    inputProps={{
                      autoComplete: 'tel-national',
                    }}
                  />
                  <TextField sm={12} className="mb-3" placeholder="Email" name="email" required />
                </Row>
                <Row>
                  <TextField sm={12} type="password" className="mb-3" placeholder="Password" name="password" required />
                  <TextField sm={12} type="password" className="mb-3" placeholder="Confirm Password" name="confirmPassword" required />
                </Row>
              </div>
            )}
          </ExtendedFormik>
        )}
        <div>
          <small className="mt-4 d-flex justify-content-center">
            <span className="mx-1">Already have an account?</span>
            <Link to="/apply-now/sign-in" className="mx-1 text-info text-decoration-underline" data-cy="signIn" data-test-id="signIn">
              <strong>Sign In</strong>
            </Link>
          </small>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Register;
