import { FC, useEffect } from 'react';
import { IQuestionType, ILoanQuestionsMapType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import { QuestionValues } from '@rsl/core/src/constants';
import { useFormikContext } from 'formik';
import RadioField from '@rsl/core/src/components/form/RadioField';
import MessageIndicator from '../../../shared/MessageIndicator';

const B8a: FC<IQuestionType> = ({ showIf }) => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.SharedB8a_1 !== QuestionValues.Yes) {
      setFieldValue('SharedB8b_1', undefined);
      setFieldValue('SharedB8b_2', undefined);
      setFieldValue('SharedB8b_3', undefined);
      setFieldValue('SharedB8b_4', undefined);
      setFieldValue('SharedB8b_5', undefined);
      setFieldValue('SharedB8b_6', undefined);
    }
  }, [values.SharedB8a_1, setFieldValue]);

  return (
    <Question label="Would you like to invite the co-borrower to fill in the loan application?" showIf={showIf}>
      <MessageIndicator>
        We'll need to collect personal, income, and asset information about the co-borrower. We'll also need authorization to provide information and consent on behalf of the
        co-borrower.
      </MessageIndicator>
      <Row>
        <RadioField
          className="mb-2"
          name="SharedB8a_1"
          optionsClassName="pt-1"
          options={[
            { label: 'Yes, please send invite email', value: QuestionValues.Yes },
            { label: 'No, I have authorization on behalf of the co-borrower', value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default B8a;
