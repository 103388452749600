import { Card, Collapse } from 'react-bootstrap';
import { LoanSummaryExternal } from 'app/types';
import { FC } from 'react';
import { getCurrencyFormatted } from '@rsl/core/src/utils/numberUtils';
import { ReactComponent as ArrowIcon } from 'app/assets/icons/arrow.svg';
import styles from './DashboardItemHelper.module.scss';
import { useCollapseChangeOnMobile } from '@rsl/core/src/hooks/collapseHooks';
import { isNil } from 'lodash';
import { formatDate } from '@rsl/core/src/utils/dateUtils';

interface DashboardLoanSummaryProps {
  loan: LoanSummaryExternal;
}

const DashboardLoanSummary: FC<DashboardLoanSummaryProps> = ({ loan }) => {
  const { collapsed, setCollapsed } = useCollapseChangeOnMobile();

  return (
    <article>
      <div className={`d-flex justify-content-between mb-3 py-2 py-md-0 ${styles.itemTitleHeader}`} onClick={setCollapsed}>
        <h4 className="fw-bold">Loan Summary</h4>
        <div className={`d-md-none d-flex justify-content-center align-items-center`}>
          <span className={`d-block common-transition ${collapsed ? 'rotate-90n' : 'rotate-0'}`}>
            <ArrowIcon />
          </span>
        </div>
      </div>
      <Collapse in={!collapsed}>
        <Card>
          <Card.Body>
            <ul>
              <li className="d-flex px-2 py-2 justify-content-between align-items-center border-bottom">
                <span>Loan Amount</span>
                <span>{getCurrencyFormatted(loan.borrowerRequestedLoanAmount ?? 0)}</span>
              </li>

              <li className="d-flex px-2 py-2 justify-content-between align-items-center border-bottom">
                <span>Loan Type</span>
                <span>{loan.loanType}</span>
              </li>

              <li className="d-flex px-2 py-2 justify-content-between align-items-center border-bottom">
                <span>Loan Term</span>
                <span>{loan.loanAmortizationTermMonths} Months</span>
              </li>

              <li className="d-flex px-2 py-2 justify-content-between align-items-center border-bottom">
                <span>Interest Rate</span>
                <span>{loan.requestedInterestRatePercent}%</span>
              </li>

              <li className="d-flex px-2 py-2 justify-content-between align-items-center border-bottom">
                <span>Monthly Payment</span>
                <li>{isNil(loan.proposedHousingExpenseTotal) ? 'TBD' : getCurrencyFormatted(loan.proposedHousingExpenseTotal)}</li>
              </li>

              <li className="d-flex px-2 py-2 justify-content-between align-items-center">
                <span>Estimated Closing Date</span>
                <li>{!!loan.loanScheduledClosingDate ? formatDate(loan.loanScheduledClosingDate) : 'TBD'}</li>
              </li>
            </ul>
          </Card.Body>
        </Card>
      </Collapse>
    </article>
  );
};

export default DashboardLoanSummary;
