import { Col, Row } from 'react-bootstrap';
import DashboardLoanSummary from './DashboardLoanSummary';
import DashboardNotifications from './DashboardNotifications';
import { LoanSummaryExternal } from 'app/types';
import { FC } from 'react';
import DashboardLoanContacts from './DashboardLoanContacts';

interface DashboardItemInformationProps {
  loan: LoanSummaryExternal;
}

const DashboardItemInformation: FC<DashboardItemInformationProps> = ({ loan }) => {
  return (
    <Row className="mt-4">
      <Col xs={12} lg={4}>
        <DashboardNotifications loan={loan} />
      </Col>
      <Col xs={12} lg={4}>
        <DashboardLoanSummary loan={loan} />
      </Col>
      <Col xs={12} lg={4}>
        <DashboardLoanContacts loan={loan} />
      </Col>
    </Row>
  );
};

export default DashboardItemInformation;
