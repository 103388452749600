import { LoanSummaryExternal } from 'app/types';
import { Card } from 'react-bootstrap';
import DashboardItemHeader from './DashboardItemHeader';
import DashboardItemInformation from './DashboardItemInformation';
import DashboardMilestone from './DashboardMilestone';
import { FC } from 'react';

interface DashboardItemProps {
  loan: LoanSummaryExternal;
}

const DashboardItem: FC<DashboardItemProps> = ({ loan }) => {
  return (
    <section>
      <DashboardItemHeader loan={loan} />
      <Card>
        <Card.Body>
          <DashboardMilestone loan={loan} />
          {loan.currentMilestone !== 'Pending' && (
            <>
              <hr />
              <DashboardItemInformation loan={loan} />
            </>
          )}
        </Card.Body>
      </Card>
    </section>
  );
};

export default DashboardItem;
