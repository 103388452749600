import { FC, useCallback } from 'react';
import styles from './PosIndicator.module.scss';
import classNames from 'classnames';
import MilestoneCheck from 'features/dashboard/milestones/MIlestoneCheck';
import { useCurrentPos } from '../hooks/posHooks';
import { StepOnType, StepSectionType } from '@rsl/core';
import { useAppSelector } from 'app/hooks/hooks';
import { lastUiPosSel } from 'app/store/selectors/loanSelector';
import { useHistory } from 'react-router-dom';

interface PosIndicatorProps {
  title?: string;
  color?: string;
  goesTo: StepOnType;
  subsections?: StepSectionType[];
}

const PosIndicator: FC<PosIndicatorProps> = ({ title, color = 'primary', goesTo, subsections }) => {
  const { currentPos } = useCurrentPos();
  const lastUiStep = useAppSelector(lastUiPosSel);

  const getStepNumberToGo = useCallback((stepToGo: StepOnType) => (stepToGo as any)[currentPos?.loanPurposeType], [currentPos?.loanPurposeType]);
  const isCurrentAtive = lastUiStep >= getStepNumberToGo(goesTo);
  const history = useHistory();

  const updateCurrentStep = (stepToGo: StepOnType) => (e: Event) => {
    e.stopPropagation();
    const gotoStep = getStepNumberToGo(stepToGo);
    // only goes to active steps
    if (lastUiStep >= gotoStep) {
      history.push(`/apply-now/${currentPos?.loanPosApplicationId}/${gotoStep}`);
    }
  };

  return (
    <li className={classNames(`text-${color} m-0 position-relative text-nowrap w-100 pb-3 cursor-pointer`, styles.item)}>
      <div onClick={updateCurrentStep(goesTo) as any}>
        <MilestoneCheck active={isCurrentAtive} />
        <small className="align-middle">{title}</small>
      </div>
      {!!subsections && (
        <ul className="text-primary ps-4 ms-2 pt-2">
          {subsections?.map((subsection, index) => (
            <li key={`subsection-pos-indicator-${goesTo}-${index}`} onClick={updateCurrentStep(subsection.step) as any}>
              <small>{subsection.name}</small>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default PosIndicator;
