import { DEFAULT_UNSAVED_CHANGES_MESSAGE } from '@rsl/core/src/constants';

/**
 * promp a message when the window try to close only if there are iunsaved changes
 */
window.onbeforeunload = function (e: BeforeUnloadEvent) {
  delete e.returnValue;
  if (window.isLockedClose) {
    // only when supported, right now the browser is not supporting custome message
    e.returnValue = DEFAULT_UNSAVED_CHANGES_MESSAGE;
  }
};

export {};
