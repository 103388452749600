import { useEffect, useState } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';

const GC4 = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();
  const [isHomeIntendPrimary, setHomeIntendPrimary] = useState<boolean>(false);

  useEffect(() => {
    if (values.SharedW3_1 === QuestionValues.HomeIntendPrimary) {
      setFieldValue('SharedGC4_1', QuestionValues.Yes);
      setHomeIntendPrimary(true);
    }
  }, [values.SharedW3_1, setFieldValue]);

  return (
    <>
      <h2 className="pt-2 text-center fw-bold">
        Declaration for <span className="d-block text-primary">{values.SharedC1_1}</span>
      </h2>
      <Question label="Will you occupy the property as your primary residence?">
        <Row>
          <RadioField
            className="mb-2"
            name="SharedGC4_1"
            optionsClassName="pt-1 w-50 w-md-10"
            options={[
              { label: QuestionValues.Yes, value: QuestionValues.Yes },
              { label: QuestionValues.No, value: QuestionValues.No, disabled: isHomeIntendPrimary },
            ]}
          />
        </Row>
      </Question>
    </>
  );
};

export default GC4;
