const CertificationAgreement = () => {
  return (
    <>
      <p className="mt-2">
        <strong>Confirmation & information release authorization</strong>
      </p>

      <p className="mt-2">
        <strong>Certification</strong>
      </p>

      <p>The undersigned certify the following:</p>
      <div>
        <ol>
          <li>
            I/We have applied for a mortgage loan from Minute Mortgage . In applying for the loan I/We completed a loan application containing various information on the purposes
            of the loan, the amount and source of the down payment, employment and income information, and assets and liabilities. I/We certify that all of the information is true
            and complete. I/We made no misrepresentations in the loan application with the employer and/or other documents, nor did I/We omit any pertinent information.
          </li>
          <li>
            I/We understand and agree that Minute Mortgage reserves the right to change the mortgage loan review process to a full documentation program. This may include verifying
            the information provided on the application with the employer and/or the financial institution.
          </li>
          <li>
            I/We fully understand that it is a Federal crime punishable by fine or imprisonment, or both, to knowingly make any false statements when applying for this mortgage, as
            applicable under the provisions of Title 18, United States Code, Section 1014.
          </li>
        </ol>
      </div>

      <p className="mt-2">
        <strong>Authorization to Release Information</strong>
      </p>

      <p>To Whom It May Concern:</p>
      <div>
        <ol>
          <li>
            I/We have applied for a mortgage loan from Minute Mortgage.
            <br />
            As part of the application process, Minute Mortgage may verify information contained in my/our loan application and in other documents required in connection with the
            loan, either before the loan is closed or as part of its quality control program.
          </li>
          <li>
            I/We understand and agree that Minute Mortgage reserves the right to change the mortgage loan review process to a full documentation program. This may include verifying
            the information provided on the application with the employer and/or the financial institution.
          </li>
          <li>Minute Mortgage or any investor that purchases the mortgage may address this authorization to any party names in the loan application.</li>
          <li>A copy of this authorization may be accepted as an original.</li>
          <li>Your prompt reply to Minute Mortgage or the investor that purchased the mortgage is appreciated.</li>
        </ol>
      </div>
    </>
  );
};

export default CertificationAgreement;
