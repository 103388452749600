export enum QuestionValues {
  Yes = 'Yes',
  No = 'No',
  Other = 'Other',
  OtherIndicator = 'OtherIndicator',
  Agree = 'Agree',
  Disagree = 'Disagree',
  CurrentlyOwn = 'Own',
  CurrentlyRent = 'Rent',
  CurrentlyRentFree = 'Live rent free',
  HouseHunting = "I'm house hunting",
  MakingOffers = "I'm making offers",
  InContract = "I'm in contract",
  ApplyingMyOwn = 'Applying on my own',
  ApplyingWithPerson = 'Applying with another person',
  GenderMaleIndicator = 'hmdaGendertypeMaleIndicator',
  GenderFemaleIndicator = 'hmdaGendertypeFemaleIndicator',
  GenderNotApplicableIndicator = 'hmdaGendertypeNotApplicableIndicator',
  LoanPurposeTypePurchase = 'Purchase',
  LoanPurposeTypeRefinance = 'Refinance',
  DetachedPropertyType = 'Detached',
  AttachedPropertyType = 'Attached',
  CondominiumPropertyType = 'Condominium',
  CooperativePropertyType = 'Cooperative',
  ManufacturedHousingPropertyType = 'ManufacturedHousing',
  PlanForPendingSale = 'PendingSale',
  PlanForRetainForRental = 'RetainForRental',
  HomeIntendPrimary = 'PrimaryResidence',
  HomeIntendSecond = 'SecondHome',
  HomeIntendInvestment = 'Investor',
  CreditScoreExcellentLabel = 'Excellent (740+)',
  CreditScoreGoodLabel = 'Good (700 - 739)',
  CreditScoreAverageLabel = 'Average (640 - 699)',
  CreditScoreFairLabel = 'Fair (580 - 639)',
  CreditScoreLowLabel = 'Needs Improvement (less than 580)',
  CreditScoreExcellentValue = '740',
  CreditScoreGoodValue = '700',
  CreditScoreAverageValue = '640',
  CreditScoreFairValue = '580',
  CreditScoreLowValue = '560',
  LowerMyMonthlyPayment = 'Lower my monthly payment',
  TakeCashOutToPayOtherExpenses = 'Take cash out to pay for other expenses',
  Married = 'Married',
  Unmarried = 'Unmarried',
  Separated = 'Separated',
  None = 'None',
}

export type PurposeType = QuestionValues.LoanPurposeTypePurchase | QuestionValues.LoanPurposeTypeRefinance;
