/**
 * By receiving a base64 image, this function will resize it to the given max width and height.
 * @param base64Image the base64 image to resize
 * @param maxWidth the max width of the resized image
 * @param maxHeight the max height of the resized image
 * @returns
 */
export function resizeImage(base64Image: string, maxWidth = 300, maxHeight = 300): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;

        if (width > height) {
          width = maxWidth;
          height = Math.floor(width / aspectRatio);
        } else {
          height = maxHeight;
          width = Math.floor(height * aspectRatio);
        }
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, width, height);

      const fileExtension = base64Image.match(/image\/(.+);base64/)?.[1] || 'jpeg';
      const resizedImage = canvas.toDataURL(`image/${fileExtension}`);
      resolve(resizedImage);
    };

    img.onerror = () => {
      reject(new Error('Failed to load image.'));
    };
    img.src = base64Image;
  });
}
