import { LoanSummaryExternal } from 'app/types';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import PrequalLink from './PrequalLink';

interface DashboardItemHeaderProps {
  loan: LoanSummaryExternal;
  isDetail?: boolean;
}
const DashboardItemHeader: FC<DashboardItemHeaderProps> = ({ loan, isDetail = false }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <ul className="d-flex flex-wrap">
        <li className="w-100 w-lg-auto flex-fill">
          <h6>
            <small>{loan.propertyLoanPurposeType}</small>
          </h6>
          <p className="fw-bold fs-4">
            {loan.propertyStreetAddress || 'TBD'}, {loan.propertyCity} {loan.propertyState}
          </p>
        </li>
        <li className="fw-bold fs-4 ps-lg-5 flex-fill">
          <h6>
            <small>Loan</small>
          </h6>
          <p>
            {!!loan.loanNumber ? (
              <Link className="text-black" to={`/loan/${loan.loanId}`}>
                {loan.loanNumber}
              </Link>
            ) : (
              <Link className="text-black" to={`/`}>
                N/A
              </Link>
            )}
          </p>
        </li>
        <li className="fw-bold fs-4 ps-5 flex-fill">
          <h6>
            <small>Status</small>
          </h6>
          <p className={`${loan.currentMilestone === 'Started' ? 'text-info' : loan.currentMilestone === 'Approved' ? 'text-success' : 'text-tertiary'}`}>
            {loan.currentMilestone}
          </p>
        </li>
      </ul>
      {isDetail ? (
        <div className="d-none d-lg-block">
          <PrequalLink loan={loan} />
        </div>
      ) : null}
    </div>
  );
};

export default DashboardItemHeader;
