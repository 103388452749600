import { FC } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import TextField from '@rsl/core/src/components/form/TextField';
import MaskedField from '@rsl/core/src/components/form/MaskedField';

const B8b: FC<IQuestionType> = ({ showIf }) => {
  return (
    <Question label="Tell us about your co-borrower" showIf={showIf}>
      <Row>
        <TextField className="mb-2" placeholder="Legal first name" name="SharedB8b_1" required />
        <TextField className="mb-2" placeholder="Middle Name" name="SharedB8b_2" nullable />
      </Row>
      <Row>
        <TextField className="mb-2" placeholder="Legal last name" name="SharedB8b_3" required />
      </Row>
      <Row>
        <MaskedField
          className="mb-2"
          placeholder="Cell Phone"
          name="SharedB8b_4"
          mask="(999) 999-9999"
          mutation={/[^0-9]+/g}
          nullable
          inputProps={{
            autocomplete: 'tel-national',
          }}
        />
        <MaskedField
          className="mb-2"
          placeholder="Home Phone"
          name="SharedB8b_6"
          mask="(999) 999-9999"
          mutation={/[^0-9]+/g}
          nullable
          inputProps={{
            autocomplete: 'tel-national',
          }}
        />
      </Row>
      <Row>
        <TextField className="mb-2" placeholder="Email" name="SharedB8b_5" required />
      </Row>
    </Question>
  );
};

export default B8b;
