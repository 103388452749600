import { FC, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { toBase64 } from '@rsl/core/src/utils/fileUtils';
import { resizeImage } from '@rsl/core/src/utils/imageUtils';
import NameBadge from '@rsl/core/src/components/badges/NameBadge';

import styles from './LogoImageUpload.module.scss';

const allowedExtensions = ['jpg', 'jpeg', 'png'];
const acceptExtensionsString = allowedExtensions.map((ext) => `.${ext}`).join(',');

interface LogoImageUploadProps {
  firstName?: string;
  lastName?: string;
  defaultImageBase64?: string;
  hideSuccessMessage?: boolean;
  onSubmit: (image: string, fileName: string) => Promise<string>;
}

const LogoImageUpload: FC<LogoImageUploadProps> = ({ defaultImageBase64 = '', onSubmit, firstName, lastName, hideSuccessMessage }) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [newImage, setNewImage] = useState<string>('');
  const imageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultImageBase64) {
      setNewImage(`data:image/png;base64,${defaultImageBase64}`);
    }
  }, [defaultImageBase64]);

  const selectNewImage = () => {
    fileInput?.current?.click();
  };

  const changeNewImage = async (event: any) => {
    if (event.target.files[0]) {
      const fileExt = event.target.files[0].name.split('.').pop().toLowerCase();

      if (!allowedExtensions.includes(fileExt)) {
        return;
      }

      const base64 = (await toBase64(event.target.files[0])) as string;
      const resizedImage = await resizeImage(base64, 200, 200);
      const contentImage = resizedImage.split(',')[1];
      const fileName = event.target.files[0].name;

      setNewImage(resizedImage);
      await onSubmit(contentImage, fileName);
      if (!hideSuccessMessage) {
        toast.success('The image has successfully updated!');
      }

      event.target.value = '';
    }
  };
  return (
    <>
      <div className="d-flex justify-content-center align-items-center my-2" ref={imageContainerRef}>
        {newImage ? (
          <img src={newImage} className={styles.filledImage} alt="logo" />
        ) : (
          <NameBadge name={`${firstName ?? ''} ${lastName ?? ''}`} className={`${styles.defaultImage} d-block mx-auto`} />
        )}
      </div>
      <Button variant="secondary" onClick={selectNewImage} className="mb-2">
        Upload New Image
      </Button>
      <div className="d-none">
        <input ref={fileInput} type="file" accept={acceptExtensionsString} onChange={(event: any) => changeNewImage(event)} placeholder="file" />
      </div>
    </>
  );
};

export default LogoImageUpload;
