import { Row } from 'react-bootstrap';
import Question from '../../Question';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import CertificationAgreement from './CertificationAgreement';

const Z3a = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  return (
    <Question label={`E-Consent Agreement for ${values.SharedC1_1}`} labelSize="lg">
      <Row>
        <CertificationAgreement />
        <p className="text-black mb-1 mt-2">
          <strong>"I Agree" to certification agreement.</strong>&nbsp;
          <span className="text-danger">*</span>
        </p>
        <div className="d-flex flex-column align-items-center">
          <RadioField
            className="mb-2"
            name="SharedZ3a_1"
            optionsClassName="pt-1"
            options={[
              { label: 'I agree', value: QuestionValues.Agree },
              { label: 'I do not agree', value: QuestionValues.Disagree },
            ]}
            errorPlace="top"
          />
        </div>
      </Row>
    </Question>
  );
};

export default Z3a;
