import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';

const NotFound = () => {
  const history = useHistory();

  return (
    <div>
      <main className="main h-100 w-100">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center">
                  <h1 className="display-1 fw-bold">404</h1>
                  <p className="h1">Page not found.</p>
                  <p className="h2 fw-normal mt-3 mb-4">The page you are looking for might have been removed.</p>
                  <Button variant="primary-gradient" onClick={(e: any) => history.goBack()} className="rounded-pill" size="lg">
                    Return to website
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <svg width={0} height={0} style={{ position: 'absolute' }}>
        <defs>
          <symbol viewBox="0 0 512 512" id="ion-ios-pulse-strong">
            <path d="M448 273.001c-21.27 0-39.296 13.999-45.596 32.999h-38.857l-28.361-85.417a15.999 15.999 0 0 0-15.183-10.956c-.112 0-.224 0-.335.004a15.997 15.997 0 0 0-15.049 11.588l-44.484 155.262-52.353-314.108C206.535 54.893 200.333 48 192 48s-13.693 5.776-15.525 13.135L115.496 306H16v31.999h112c7.348 0 13.75-5.003 15.525-12.134l45.368-182.177 51.324 307.94c1.229 7.377 7.397 11.92 14.864 12.344.308.018.614.028.919.028 7.097 0 13.406-3.701 15.381-10.594l49.744-173.617 15.689 47.252A16.001 16.001 0 0 0 352 337.999h51.108C409.973 355.999 427.477 369 448 369c26.511 0 48-22.492 48-49 0-26.509-21.489-46.999-48-46.999z"></path>
          </symbol>
        </defs>
      </svg>
    </div>
  );
};

export default NotFound;
