import { ILoanQuestionsMapType, IEmploymentType } from '@rsl/core/src/types';
import { useFormikContext } from 'formik';
import { useSumCompute } from '@rsl/core/src/hooks/computeHooks';
import { getCurrencyFormatted } from '@rsl/core/src/utils/numberUtils';

const C6eCompute = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  const total =
    useSumCompute<ILoanQuestionsMapType>([
      ['SharedC6c_1', (values) => !!values.ShowSharedC6c_],
      ['SharedC6c_2', (values) => !!values.ShowSharedC6c_],
      ['SharedC6c_3', (values) => !!values.ShowSharedC6c_],
      ['SharedC6d_1', (values) => !!values.ShowSharedC6d_],
      ['SharedC6d_2', (values) => !!values.ShowSharedC6d_],
      ['SharedC6d_3', (values) => !!values.ShowSharedC6d_],
    ]) +
    values.coborrowerEmployment.reduce(
      (sum: number, employment: IEmploymentType) =>
        sum + (employment.basePayAmount ?? 0) + (employment.commissionsAmount ?? 0) + (employment.bonusAmount ?? 0) + (employment.overtimeAmount ?? 0),
      0
    );

  return (
    <div className="text-end pt-2">
      Total <strong className="text-primary">{getCurrencyFormatted(total)}</strong>
    </div>
  );
};

export default C6eCompute;
