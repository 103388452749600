import { IMinuteField } from '../types/form.types';
import { toCamelCase } from '../utils/stringUtils';
import { useCallback, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';

export const useFieldIds = ({ name, label, id }: IMinuteField<any>) => {
  const [finalName] = useState(name ?? toCamelCase(label ?? ''));
  const [finalId] = useState(id ?? finalName);

  return { name: finalName, id: finalId };
};

export const useEditingFormikField = () => {
  const { setFieldValue } = useFormikContext<any>();
  const setIsEditing: any = useCallback((field: string) => setFieldValue('isFormikEditingField', field), [setFieldValue]);
  const leaveIsEditing: any = useCallback(() => setFieldValue('isFormikEditingField', null), [setFieldValue]);

  return useMemo(
    () => ({
      onFocus: (event: React.FocusEvent<HTMLInputElement>) => setIsEditing(event.target?.name ?? ''),
      onBlur: (event: React.FocusEvent<HTMLInputElement>) => leaveIsEditing(),
    }),
    [setIsEditing, leaveIsEditing]
  );
};
