import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LayoutSettingsState {
  isMainScreen: boolean;
  subTitle: string;
}

const initialState: LayoutSettingsState = {
  isMainScreen: false,
  subTitle: '',
};

export const layoutSettingsSlice = createSlice({
  name: 'layoutSettings',
  initialState,
  reducers: {
    setLayoutSettings: (state, { payload }: PayloadAction<LayoutSettingsState>) => {
      state.isMainScreen = payload.isMainScreen;
      state.subTitle = payload.subTitle;
    },
  },
});

export const { setLayoutSettings } = layoutSettingsSlice.actions;

export default layoutSettingsSlice.reducer;
