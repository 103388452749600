import { ReactNode, FC } from 'react';

interface DashboardLayoutProps {
  title: string;
  titleHeader: string;
  children: ReactNode;
  toolbar?: ReactNode;
}

const DashboardLayout: FC<DashboardLayoutProps> = ({ title, titleHeader, toolbar, children }) => {
  return (
    <section className="py-3 px-3 px-lg-5 dashboard">
      <h6>{titleHeader}</h6>
      <div className="d-flex justify-content-between">
        <h1 className="text-primary fw-bold">{title}</h1>
        <div>{toolbar}</div>
      </div>
      <hr />
      {children}
    </section>
  );
};

export default DashboardLayout;
