import { ApiResponse } from '@rsl/core/src/types';
import { baseApi } from '@rsl/core/src/api/baseApi';
import { LoginType, PinActionRequest, PinActionType } from '../types/pin.types';
import { ExternalUserSessionResponse } from 'app/types';

export const pinVerificationApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchPinAction: build.query<PinActionType, string>({
      query: (pinId) => `/externalUser/pinAction/${pinId}`,
      transformResponse: (response: ApiResponse<PinActionType>) => response.data,
    }),
    changePassword: build.mutation<ExternalUserSessionResponse, PinActionRequest<string>>({
      query: (body) => ({
        url: '/externalUser/ChangePassword',
        method: 'POST',
        body,
      }),
      transformResponse: (response: ApiResponse<ExternalUserSessionResponse>) => response.data,
    }),
    changePasswordRequest: build.mutation<boolean, string>({
      query: (email) => ({
        url: `/externalUser/ChangePassword/request`,
        method: 'POST',
        body: { email },
      }),
      transformResponse: (response: ApiResponse<boolean>) => response.data,
    }),
    registerDeviceRequest: build.mutation<PinActionType, void>({
      query: () => ({
        url: `/externalUser/RegisterDevice/request`,
        method: 'POST',
      }),
      transformResponse: (response: ApiResponse<PinActionType>) => response.data,
    }),
    registerDevice: build.mutation<boolean, PinActionRequest<string>>({
      query: (body) => ({
        url: `/externalUser/RegisterDevice`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ApiResponse<boolean>) => response.data,
    }),
    login: build.mutation<ExternalUserSessionResponse, LoginType>({
      query: (body) => ({
        url: `/externalUser/login`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ApiResponse<ExternalUserSessionResponse>) => response.data,
    }),
    renew: build.mutation<ExternalUserSessionResponse, void>({
      query: () => ({
        url: `/externalUser/renew`,
        method: 'POST',
      }),
      transformResponse: (response: ApiResponse<ExternalUserSessionResponse>) => response.data,
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchPinActionQuery,
  useChangePasswordMutation,
  useChangePasswordRequestMutation,
  useRegisterDeviceMutation,
  useRegisterDeviceRequestMutation,
  useLoginMutation,
  useRenewMutation,
} = pinVerificationApi;
