import { FC, useState, useMemo, useCallback } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import TextField from '@rsl/core/src/components/form/TextField';
import RadioField from '@rsl/core/src/components/form/RadioField';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import PlacesAutoComplete, { PlaceType } from '../../../fields/PlacesAutocomplete';
import { IPlaceAutocompleteResponse } from '../../../fields/fields.types';
import MessageIndicator from '../../../shared/MessageIndicator';
import { QuestionValues } from '@rsl/core/src/constants';
import { isLessThan24Months } from '../../../../utils/loanMapperUtils';

const C1f: FC<IQuestionType> = () => {
  const { values, setValues, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  const [showSharedC1fa] = useState<boolean>(true);
  const [showSharedC1fb, setShowSharedC1fb] = useState<boolean>(!!values.SharedC1fb_1);
  const [showSharedC1fc, setShowSharedC1fc] = useState<boolean>(!!values.SharedC1fc_1);

  const previousAddressKeys = useMemo(() => ['SharedC1fa', 'SharedC1fb', 'SharedC1fc'], []);

  const clearPreviousAddressByKey = useCallback(
    (key: string) => {
      [...Array(8)].forEach((v: any, i: number) => {
        setFieldValue(`${key}_${i + 1}`, '');
      });
    },
    [setFieldValue]
  );

  const handleStartDateChange = (key: string) => {
    if (key === 'SharedC1fa' && (!!values.SharedC1fa_7 || values.SharedC1fa_7 === 0) && (!!values.SharedC1fa_8 || values.SharedC1fa_8 === 0)) {
      if (!isLessThan24Months([values.SharedB1d_1, values.SharedC1fa_7], [values.SharedB1d_2, values.SharedC1fa_8])) {
        previousAddressKeys.slice(1).forEach((key: string) => clearPreviousAddressByKey(key));
        setShowSharedC1fb(false);
        setShowSharedC1fc(false);
      } else {
        setShowSharedC1fb(true);
      }
    }

    if (key === 'SharedC1fb' && (!!values.SharedC1fb_7 || values.SharedC1fb_7 === 0) && (!!values.SharedC1fb_8 || values.SharedC1fb_8 === 0)) {
      if (!isLessThan24Months([values.SharedB1d_1, values.SharedC1fa_7, values.SharedC1fb_7], [values.SharedB1d_2, values.SharedC1fa_8, values.SharedC1fb_8])) {
        previousAddressKeys.slice(2).forEach((key: string) => clearPreviousAddressByKey(key));
        setShowSharedC1fc(false);
      } else {
        setShowSharedC1fc(true);
      }
    }
  };

  const show = (key: string) => {
    if (key === 'SharedC1fa') {
      return showSharedC1fa;
    }

    if (key === 'SharedC1fb') {
      return showSharedC1fb;
    }

    if (key === 'SharedC1fc') {
      return showSharedC1fc;
    }
  };

  const addressMap = (key: string, res: IPlaceAutocompleteResponse) => {
    if (key === 'SharedC1fa') {
      return {
        SharedC1fa_1: res.address ?? values.SharedC1fa_1,
        SharedC1fa_2: res.city ?? values.SharedC1fa_2,
        SharedC1fa_3: res.state ?? values.SharedC1fa_3,
        SharedC1fa_4: res.zipCode ?? values.SharedC1fa_4,
      };
    }

    if (key === 'SharedC1fb') {
      return {
        SharedC1fb_1: res.address ?? values.SharedC1fb_1,
        SharedC1fb_2: res.city ?? values.SharedC1fb_2,
        SharedC1fb_3: res.state ?? values.SharedC1fb_3,
        SharedC1fb_4: res.zipCode ?? values.SharedC1fb_4,
      };
    }

    if (key === 'SharedC1fc') {
      return {
        SharedC1fc_1: res.address ?? values.SharedC1fc_1,
        SharedC1fc_2: res.city ?? values.SharedC1fc_2,
        SharedC1fc_3: res.state ?? values.SharedC1fc_3,
        SharedC1fc_4: res.zipCode ?? values.SharedC1fc_4,
      };
    }
  };

  return (
    <>
      {previousAddressKeys.map((key: string, index: number) =>
        show(key) ? (
          <>
            {index > 0 && <hr />}
            <MessageIndicator>It appears you’ve been at your current address for less than two years. Please provide your previous address.</MessageIndicator>
            <Question label="What is your previous address?">
              <Row>
                <Col xs={9}>
                  <PlacesAutoComplete
                    placeholder="Street Address"
                    name={`${key}_1`}
                    types={[PlaceType.ADDRESS]}
                    className="mb-2 places-autocomplete"
                    onChange={(res: IPlaceAutocompleteResponse) =>
                      setValues({
                        ...values,
                        ...addressMap(key, res),
                      })
                    }
                  />
                </Col>
                <TextField xs={3} className="mb-2" placeholder="Unit" name={`${key}_5`} />
              </Row>
              <Row>
                <TextField className="mb-2" placeholder="City" name={`${key}_2`} required />
                <LookupSelect className="mb-2" placeholder="State" name={`${key}_3`} lookup="states" required enableDefault={false} />
                <TextField className="mb-2" placeholder="Zip" name={`${key}_4`} required />
              </Row>
            </Question>
            <Question label="Did you rent or own your previous address?">
              <Row>
                <RadioField
                  className="mb-2"
                  name={`${key}_6`}
                  optionsClassName="pt-1"
                  options={[
                    { label: QuestionValues.CurrentlyOwn, value: QuestionValues.CurrentlyOwn },
                    { label: QuestionValues.CurrentlyRent, value: QuestionValues.CurrentlyRent },
                    { label: QuestionValues.CurrentlyRentFree, value: QuestionValues.CurrentlyRentFree },
                  ]}
                />
              </Row>
            </Question>
            <Question label="When did you start living at the previous address?">
              <Row>
                <TextField className="mb-2" type="number" name={`${key}_7`} placeholder="Years" min={0} max={99} required onBlur={() => handleStartDateChange(key)} />
                <TextField className="mb-2" type="number" name={`${key}_8`} placeholder="Months" min={0} max={11} required onBlur={() => handleStartDateChange(key)} />
              </Row>
            </Question>
          </>
        ) : null
      )}
    </>
  );
};

export default C1f;
