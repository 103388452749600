import { IProtectedRoute } from '@rsl/core';
import PartnerProfile from './profile/PartnerProfile';

const routes: IProtectedRoute = {
  title: 'Partner',
  slug: 'partner',
  displayOnMenu: false,
  exact: true,
  childs: [
    {
      title: 'Profile',
      slug: 'myprofile',
      displayOnMenu: false,
      exact: true,
      render: () => <PartnerProfile />,
    },
  ],
};
export default routes;
