import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';

const GC18 = () => {
  return (
    <Question label="Have you declared bankruptcy within the past 7 years?">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedGC18_1"
          optionsClassName="pt-1 w-50 w-md-10"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default GC18;
