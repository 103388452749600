import { FC } from 'react';
import * as Yup from 'yup';
import { IUserPartner } from '../types';
import { useFetchPartnerLogoImageQuery, useFetchPartnerProfileImageQuery, useUploadPartnerLogoImageMutation, useUploadPartnerProfileImageMutation } from '../api/partnersApi';
import { Col, Row } from 'react-bootstrap';
import PartnerImage from './PartnerImage';
import { RegexConstants, ValidationErrors } from '@rsl/core/src/constants';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import TextField from '@rsl/core/src/components/form/TextField';
import MaskedField from '@rsl/core/src/components/form/MaskedField';
import ExtendedFormik from '@rsl/core/src/components/form/ExtendedFormik';
import LogoImageUpload from './LogoImageUpload';
const PartnerSchema = Yup.object().shape({
  firstName: Yup.string().matches(RegexConstants.Name, ValidationErrors.Name).max(50).required(),
  lastName: Yup.string().matches(RegexConstants.Name, ValidationErrors.Name).max(50).required(),
  mobilePhone: Yup.string().matches(RegexConstants.Phone, ValidationErrors.Phone).nullable(),
  email: Yup.string().email(ValidationErrors.Email).required(),
  licenseNumber: Yup.string().matches(RegexConstants.Nmls, ValidationErrors.Nmls).required(),
  urlWebsite: Yup.string().max(150, 'Too Long!').nullable(),
});

interface IPartnerForm {
  initialState?: IUserPartner;
  onSubmit: (values: IUserPartner) => void;
}

const PartnerForm: FC<IPartnerForm> = ({ initialState = {} as IUserPartner, onSubmit }) => {
  const { data: profileImageType } = useFetchPartnerProfileImageQuery();
  const [uploadProfileImage] = useUploadPartnerProfileImageMutation();
  const [uploadLogoImage] = useUploadPartnerLogoImageMutation();
  const { data: logoImageType } = useFetchPartnerLogoImageQuery();

  const sendProfileImage = async (image: string, fileName: string) => {
    var response: any = await uploadProfileImage({ fileName, profileImage: image });
    throwErrorProp(response);
    return response?.data?.profileImage ?? '';
  };

  const sendLogoImage = async (image: string, fileName: string) => {
    var response: any = await uploadLogoImage({ fileName, logoImage: image });
    throwErrorProp(response);
    return response?.data?.logoImage ?? '';
  };

  return (
    <ExtendedFormik validationSchema={PartnerSchema} initialValues={initialState} onSubmit={onSubmit} successMessage="The partner has been successfully saved!">
      {({ values }) => (
        <>
          <Row className="mb-3" spacing={3}>
            <Col xs={12} md={6} className="text-center">
              <h4 className="mb-0 font-italic">Profile Image</h4>
              <PartnerImage firstName={values.firstName} lastName={values.lastName} defaultImageBase64={profileImageType?.profileImage} onSubmit={sendProfileImage} />
            </Col>
            <Col xs={12} md={6} className="text-center">
              <h4 className="mb-0 font-italic">Logo Image</h4>
              <LogoImageUpload firstName={values.firstName} lastName={values.lastName} defaultImageBase64={logoImageType?.logoImage} onSubmit={sendLogoImage} />
            </Col>
          </Row>
          <Row className="mb-3" spacing={3}>
            <Col xs={12}>
              <h4 className="mb-0 font-italic">Contact Information</h4>
            </Col>
            <TextField xs={12} md={6} label="First Name" name="firstName" maxLength={50} required className="mb-3" />
            <TextField xs={12} md={6} label="Last Name" name="lastName" maxLength={50} required className="mb-3" />
            <MaskedField xs={12} md={6} label="Cell Phone" name="mobilePhone" mask="(999) 999-9999" mutation={/[^0-9]+/g} className="mb-3" />
            <TextField xs={12} md={6} label="Email" name="email" required disabled className="mb-3" />
            <TextField xs={12} md={6} label="License #" name="licenseNumber" maxLength={50} required className="mb-3" />
            <TextField xs={12} md={12} label="Website" name="urlWebsite" maxLength={150} className="mb-3" />
          </Row>
        </>
      )}
    </ExtendedFormik>
  );
};

export default PartnerForm;
