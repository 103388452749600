import { ApiResponse } from '@rsl/core/src/types';
import { baseApi } from '@rsl/core/src/api/baseApi';
import { DocumentSignTask, EConsent, CreditAuth } from 'app/types/baseDocumentTask.types';
import { invalidateOnSuccess } from '@rsl/core/src/utils/cacheUtils';

export const loanTasksApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchExternalLoanTasks: build.query<DocumentSignTask<any>[], string>({
      query: (loanId) => `/ExternalLoanTasks/${loanId}`,
      transformResponse: (response: ApiResponse<DocumentSignTask<any>[]>) => {
        let tasks = response.data.map((task) => ({ ...task, viewModelJson: JSON.parse(task.viewModelJson) }));
        tasks.sort((task) => (task.status === 'Completed' ? 1 : -1));
        return tasks;
      },
      providesTags: ['MyTasksList'],
    }),
    uploadTaskFiles: build.mutation<FormData, { body: FormData; loanId?: string; externalTaskId?: string; loanDisclosureDocumentId?: string }>({
      query: (params) => ({
        url: `/ExternalLoanTasks/${params.loanId}/${params.externalTaskId}/wetsign/${params.loanDisclosureDocumentId}`,
        method: 'POST',
        body: params.body,
      }),
      transformResponse: (response: ApiResponse<FormData>) => response.data,
      invalidatesTags: invalidateOnSuccess(['MyTasksList', 'LoanPosDashboardItems']),
    }),
    completeEConsentTask: build.mutation<any, EConsent>({
      query: (body) => ({
        url: `/ExternalLoanTasks/${body.loanId}/${body.externalTaskId}/econsent`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ApiResponse<any>) => response.data,
      invalidatesTags: invalidateOnSuccess(['MyTasksList', 'LoanPosDashboardItems']),
    }),
    completeCreditAuthTask: build.mutation<any, CreditAuth>({
      query: (body) => ({
        url: `/ExternalLoanTasks/${body.loanId}/${body.externalTaskId}/creditAuth`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ApiResponse<any>) => response.data,
      invalidatesTags: invalidateOnSuccess(['MyTasksList', 'LoanPosDashboardItems']),
    }),
  }),
  overrideExisting: false,
});

export const { useFetchExternalLoanTasksQuery, useUploadTaskFilesMutation, useCompleteEConsentTaskMutation, useCompleteCreditAuthTaskMutation } = loanTasksApi;
