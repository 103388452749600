import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import Asset from './Asset';

const A1d = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  const show = (event: any) => {
    if (event.target.checked) {
      setFieldValue('SharedA1d_Accounts', [{ amount: '', institutionName: '' }]);
    }
  };

  return (
    <div className="border-bottom py-2 border-gray-dark">
      <Row>
        <CheckboxField className="fw-bold lg-label" name="ShowSharedA1d_" label="Stock and bonds (brokerage account)" horizontal={true} onClick={show} />
      </Row>
      {!!values.ShowSharedA1d_ && <Asset namePrefix="SharedA1d_" multiple />}
    </div>
  );
};

export default A1d;
