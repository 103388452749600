import Application from './components/Application';
import PosLayout from './components/layouts/PosLayout';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { useAppParams } from '@rsl/core/src/hooks/routeHooks';
import { useFetchLoanPosApplicationQuery } from './api/loanPosApplicationApi';
import { useEffect } from 'react';
import LoanMapper from './utils/loanMapper';
import { Redirect, useLocation } from 'react-router-dom';
import NotFound from 'features/error-pages/NotFound';
import { useCurrentPos } from './hooks/posHooks';
import { useAppDispatch } from 'app/hooks/hooks';
import { cleanCurrentPos } from 'features/apply-now/pos/store/slices/posSlice';
import { isNil } from 'lodash';
import { cleanPendingPosApplication } from 'app/store/slices/authSlice';

const Pos = () => {
  const { loanPosApplicationId: id, posStep: posStepString } = useAppParams<{ loanPosApplicationId: string; posStep: string }>();
  const posStep = !isNil(posStepString) ? parseInt(posStepString, 10) : null;
  const isEditing = useLocation().pathname.includes('edit'); // Stills need to check if current session has permissions for editing
  const { data, isSuccess, error } = useFetchLoanPosApplicationQuery(id);
  const { savePos, currentPos } = useCurrentPos();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const setValues = async () => {
      if (isSuccess) {
        const values = await LoanMapper({} as ILoanQuestionsMapType, data);
        savePos(values);
      }
    };

    setValues();
  }, [isSuccess, data, savePos]);

  // clean Current POS data on left page
  useEffect(
    () => () => {
      dispatch(cleanCurrentPos());
      dispatch(cleanPendingPosApplication());
    },
    [dispatch]
  );

  if (error && (error as any).status === 404) {
    return <NotFound />;
  }

  if (!!currentPos && isNil(posStep)) {
    return <Redirect to={`/apply-now/${id}/${currentPos.uiCurrentStep ?? 0}`} />;
  }

  return (
    <>
      {!!currentPos && (
        <PosLayout>
          <Application isEditing={isEditing} currentStep={posStep as number} />
        </PosLayout>
      )}
    </>
  );
};

export default Pos;
