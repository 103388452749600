import { FC } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Col, Row } from 'react-bootstrap';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';

const P4a: FC<IQuestionType> = ({ showIf }) => {
  return (
    <Question label="" showIf={showIf}>
      <Row>
        <CurrencyField className="mb-2" label="Mortgage Balance" name="SharedP4a_1" required isInlineLabel placeholder="$0" xs={12} />
        <CurrencyField className="mb-2" label="Monthly Payment" name="SharedP4a_2" required isInlineLabel placeholder="$0" xs={12} />
        <CurrencyField className="mb-2" label="Taxes and insurance" name="SharedP4a_3" nullable isInlineLabel placeholder="$0" xs={12} />
        <CurrencyField className="mb-2" label="HOA" name="SharedP4a_4" nullable isInlineLabel placeholder="$0" xs={12} />
        <Col className="super-small fw-light" xs={12}>
          *Only include taxes and insurance if this is not already part of your monthly payment.
        </Col>
      </Row>
    </Question>
  );
};

export default P4a;
