import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';

const D3 = () => {
  return (
    <Question label="What funds do you plan to use for your down payment?">
      <Row>
        <RadioField
          className="mb-2"
          name="PurchaseD3_1"
          optionsClassName="pt-1"
          options={[
            { label: 'Checking / Savings', value: 'Checking / Savings' },
            { label: 'Gift', value: 'Gift' },
            { label: 'Proceeds from sale of home', value: 'Proceeds from sale of home' },
            { label: QuestionValues.Other, value: QuestionValues.Other },
          ]}
        />
      </Row>
    </Question>
  );
};

export default D3;
