import { FC } from 'react';
import { Row } from 'react-bootstrap';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { OwnerTypes } from 'app/types';
import { defaultMapper } from '@rsl/core/src/store/selectors/lookupSelectors';

const ownerTypesArray: OwnerTypes[] = Object.values(OwnerTypes);
const ownerTypeOptions = ownerTypesArray.map(defaultMapper);

const R4: FC<IQuestionType> = ({ showIfFn }) => {
  return (
    <Question label="Who owns this property?" showIfFn={showIfFn}>
      <Row>
        <RadioField className="mb-2" name="SharedR4_1" optionsClassName="pt-1" options={ownerTypeOptions} />
      </Row>
    </Question>
  );
};

export default R4;
