import React, { ReactNode } from 'react';

type Props = { children: ReactNode; onClick: React.MouseEventHandler<HTMLDivElement> };
type Ref = HTMLDivElement;
const CustomDropdownToggle = React.forwardRef<Ref, Props>(({ children, onClick }, ref) => (
  <div ref={ref} onClick={onClick} data-reference="parent">
    {children}
  </div>
));

export default CustomDropdownToggle;
