import { ILoanType } from '../../types';
import { emptyLoanBorrowerConstant } from './emptyLoanBorrower.constants';
import { QuestionValues } from './questionValues.constants';
import { PurchaseStepTitles } from './stepTitles.constants';

export const emptyLoanApplicationConstant: ILoanType = {
  borrower: emptyLoanBorrowerConstant,
  downPaymentType: '',
  gsePropertyType: '',
  loanPurposeType: QuestionValues.LoanPurposeTypePurchase,
  propertyUsageType: '',
  streetAddress: '',
  addressUnitIdentifier: '',
  city: '',
  state: '',
  postalCode: '',
  financedNumberOfUnits: 0,
  reoProperties: [],
  assetSameAsPrimaryBorrowerIndicator: false,
  purcahseInProcess: '',
  buyerAgentFirstName: '',
  buyerAgentLastName: '',
  buyerAgentEmail: '',
  buyerAgentMobilePhone: '',
  refinancePurposeType: '',
  otherRefinancePurpose: '',
  uiCurrentStep: 0,
  uiCurrentStepTitle: PurchaseStepTitles[0].name,
  uiReoPropertyIndex: null,
  uiBorrowerEmploymentIndex: null,
  uiBorrowerSelfEmploymentIndex: null,
  uiCoBorrowerEmploymentIndex: null,
  uiCoBorrowerSelfEmploymentIndex: null,
  agreementsCaption: {},
};
