import { useFetchExternalLoanQuery } from 'app/api/externalSessionApi';
import { useExternalAuth } from 'app/hooks/authHooks';
//import { useFetchActiveApplicationsQuery } from 'features/apply-now/pos/api/loanPosApplicationApi';
import DashboardItem from './DashboardItem/DashboardItem';
import { Button, NavDropdown } from 'react-bootstrap';
import AddButtonText from 'app/components/buttons/AddButtonText';
import { PurposeType, QuestionValues } from '@rsl/core/src/constants';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import { setPendingPosApplication } from 'app/store/slices/authSlice';
import { useNewApplicationMutation } from 'features/apply-now/pos/api/loanPosApplicationApi';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { useHistory } from 'react-router-dom';
import { ILoanPosApplicationType } from '@rsl/core';
import DashboardLayout from './DashboardLayout';
import { currentPosAsLoanSel } from 'app/store/selectors/loanSelector';
import { LoanRoles } from 'app/types';
import DashboardFilter from './DashboardFilter';
import { useState } from 'react';
import { useHasBeenTrue } from '@rsl/core/src/hooks/valueHooks';

const initialFilters = {
  word: '',
  actionTaken: 'Active',
};

const NewDashboard = () => {
  const [createApplication] = useNewApplicationMutation();
  const { fullName } = useExternalAuth();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState(initialFilters);
  //const { data: activePos = {} } = useFetchActiveApplicationsQuery();
  const { data, isLoading, isSuccess } = useFetchExternalLoanQuery(filters, { refetchOnMountOrArgChange: true });
  const currentPos = useAppSelector(currentPosAsLoanSel);

  const handleApplyNow = async (purpose: PurposeType) => {
    const response = (await createApplication(purpose)) as { data: ILoanPosApplicationType };
    throwErrorProp(response);
    dispatch(setPendingPosApplication(response.data));
    history.push(`/apply-now/${response.data.loanPosApplicationId}`);
  };

  const isBorrowerOrCoborrower = !!data?.rows.some((loan) => loan.role === LoanRoles.Borrower || loan.role === LoanRoles.CoBorrower);

  const isMoreThan5 = useHasBeenTrue((data?.totalRows ?? 0) > 5);
  return (
    <DashboardLayout
      titleHeader={`Welcome Back ${fullName}`}
      title="Your Dashboard"
      toolbar={
        !currentPos && isBorrowerOrCoborrower ? (
          <NavDropdown title={<AddButtonText>APPLY NEW LOAN</AddButtonText>}>
            <NavDropdown.Item as={Button} variant="link" onClick={() => handleApplyNow(QuestionValues.LoanPurposeTypePurchase)}>
              {QuestionValues.LoanPurposeTypePurchase}
            </NavDropdown.Item>
            <NavDropdown.Item as={Button} variant="link" onClick={() => handleApplyNow(QuestionValues.LoanPurposeTypeRefinance)}>
              {QuestionValues.LoanPurposeTypeRefinance}
            </NavDropdown.Item>
          </NavDropdown>
        ) : null
      }>
      {isMoreThan5 && <DashboardFilter initialFilters={filters} setFilters={setFilters} />}
      {currentPos && <DashboardItem loan={currentPos} />}
      {data?.rows?.map((loan) => (
        <DashboardItem key={`dashboard-loan-${loan.loanId}`} loan={loan} />
      ))}
      {!isLoading && isSuccess && !data?.rows.length && (
        <section className="d-flex justify-content-center align-items-center py-5">
          <h3>No loans found</h3>
        </section>
      )}
    </DashboardLayout>
  );
};

export default NewDashboard;
