import { deviceCookieName, tokenCookieName } from 'app/constants';
import { useAppDispatch } from 'app/hooks/hooks';
import { setDeviceId, setToken } from 'app/store/slices/authSlice';
import { ExternalUserRoles, ExternalUserSessionResponse } from 'app/types';
import { useRenewMutation } from 'features/pin/api/pinVerificationApi';
import { Suspense, useState } from 'react';
import { FC } from 'react';
import cookie from 'react-cookies';
import { v4 as uuid } from 'uuid';
import usePromise from 'react-promise-suspense';
import Splash from '../layout/splash/Splash';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetSessionData } from 'app/hooks/authHooks';
import { addMonths } from 'date-fns';

/**
 * routes that must be redirected to it's main Page
 */
const loggedInBlackListedRoutes = ['/', '/apply-now', 'apply-now/register-device', '/apply-now/sign-in', '/apply-now/register'];

/**
 * Main session Provider that verify if the current session is LoggedIn or not
 */
const SessionProvider: FC = ({ children }) => {
  return (
    <Suspense fallback={<Splash show />}>
      <FetchingSessionComponent>{children}</FetchingSessionComponent>
    </Suspense>
  );
};

const FetchingSessionComponent: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const [renew] = useRenewMutation();
  const { pathname } = useLocation();
  const [cachedPathName] = useState(pathname);
  const setSessionData = useSetSessionData();

  const fetchSession = useCallback(
    () =>
      new Promise<void>((resolve) => {
        // get the Token from the URL to take this as temporary token
        const [tokenMatch] = window.location.search.match(/(\?|&)token=[^?&]+/g) ?? [''];
        const [, urlToken] = tokenMatch.split('token=');
        const token = urlToken || cookie.load(tokenCookieName);
        // get the deviceId from the cookie , if not set then set the new deviceId as identifier
        const deviceId = cookie.load(deviceCookieName) || uuid();
        // always set the cookie on load
        cookie.save(deviceCookieName, deviceId, { path: '/', expires: addMonths(new Date(), 2), secure: true, sameSite: true });
        // set the deviceID to the current state, so the request can be made using deviceId
        dispatch(setDeviceId(deviceId));

        const from = loggedInBlackListedRoutes.includes(cachedPathName) ? '' : cachedPathName;

        if (urlToken) {
          return (async () => {
            setSessionData(
              {
                token: urlToken,
                isDeviceVerified: true,
                role: ExternalUserRoles.Applicant,
              },
              from.replace(`token=${urlToken}`, ''),
              false
            );
            resolve();
          })();
        }

        // when token available (means that the cookie has not expired yet) try to renew (in the future it going to help for revoke tokens)
        if (token) {
          (async () => {
            dispatch(setToken(token)); // first set the token to perform mutation
            const response = (await renew()) as { data: ExternalUserSessionResponse };
            const { error } = response as any;
            if (!error) {
              setSessionData(response.data, from);
            }
            resolve();
          })();
        } else {
          resolve();
        }
      }),
    [dispatch, renew, cachedPathName, setSessionData]
  );

  usePromise(fetchSession, []);
  return <>{children}</>;
};

export default SessionProvider;
