import { FC, useEffect, useMemo, useState } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import DropFilesZone from '@rsl/core/src/components/drop/DropFilesZone';
import { useUploadFilesMutation } from '../api/fileshareApi';
import { useFileList } from '@rsl/core/src/hooks/fileManagementHooks';
import { ILoanNeedsListBorrowerExt, ILoanNeedsListItem } from '@rsl/core/src/types';
import { useCurrentLoan } from 'app/hooks/loanHooks';

import { ReactComponent as TrashIcon } from 'app/assets/icons/trash.svg';
// import styles from './UploadShareModal.module.scss';
// import { ReactComponent as TaskIcon } from 'app/assets/icons/task.svg';
// import { Link } from 'react-router-dom';
import CheckBadge from '@rsl/core/src/components/badges/CheckBadge';
import { useAppDispatch } from 'app/hooks/hooks';
import { setloanAndNeedlistId } from '../store/slices/fileshareSlice';
import { useExternalAuth } from 'app/hooks/authHooks';
import TextField from '@rsl/core/src/components/form/TextField';

interface UploadShareModalProps {
  needList: ILoanNeedsListItem;
  modalShow: boolean;
  size?: 'sm' | 'lg' | 'xl' | undefined;
  title: string;
  onClose: () => void;
}

const UploadShareModal: FC<UploadShareModalProps> = ({ modalShow, size, onClose, title, needList }) => {
  const user = useExternalAuth();
  const [uploadFiles, { isLoading }] = useUploadFilesMutation();
  const { currentFiles, setCurrentFiles, addFiles, sendFormFiles, deleteFileButtonAction, limitReached } = useFileList(uploadFiles, { limit: 8 });
  const currentLoan = useCurrentLoan();
  const dispatch = useAppDispatch();

  const [descriptionValue, setDescriptionValue] = useState(needList.description);

  const initialState = useMemo(() => {
    if (needList.loanNeedsListBorrowers.length === 1) {
      const borrowers = needList.loanNeedsListBorrowers.map((borower) => borower.loanNeedsListBorrowerId.toString());
      return { borrowers };
    }

    const borrowers = needList.loanNeedsListBorrowers.filter((borower) => borower.email === user.email).map((borower) => borower.loanNeedsListBorrowerId.toString());
    return { borrowers };
  }, [needList.loanNeedsListBorrowers, user.email]);

  useEffect(() => {
    dispatch(setloanAndNeedlistId({ loanId: needList.loanId, loanNeedsListId: needList.loanNeedsListId, description: descriptionValue }));
  }, [dispatch, needList.loanId, needList.loanNeedsListId, descriptionValue]);

  // TODO: uncomment until we goet a license number for scanner
  // const setBorrowers = useCallback(
  //   (borrowers: string[]) => {
  //     dispatch(setBorrowersToShare(borrowers));
  //   },
  //   [dispatch]
  // );

  const handleSave = async (values: any) => {
    const borrowerIds = values.borrowers.map((id: string) => parseInt(id));
    const payloadGen = (files: File[]) => {
      const sharedToBorrowers = files.map(() => borrowerIds);

      return { sharedToBorrowers, loanId: needList.loanId, loanNeedsListId: needList.loanNeedsListId, description: descriptionValue };
    };

    await sendFormFiles({ loanId: currentLoan?.loanId }, payloadGen);
    setCurrentFiles([]);
    onClose();
  };

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" show={modalShow} onHide={onClose} size={size}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Upload Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <strong>{title}</strong>
        <Formik initialValues={initialState} onSubmit={handleSave}>
          {({ values }) => (
            <Form id="submit-files">
              {initialState?.borrowers?.length === 1 && needList.loanNeedsListBorrowers.length === 1 ? null : (
                <>
                  <h6 className="mt-3">
                    <CheckBadge className="align-middle me-2" checked={!!values.borrowers.length} />
                    <small>Select the coborrowers to share documents with</small>
                  </h6>
                  <CheckBorrowers borrowers={needList.loanNeedsListBorrowers} />
                  <hr />
                </>
              )}
              {needList.loanNeedsListId > 0 ? null : (
                <h6 className="mt-3">
                  <small>Description of your uploads</small>
                  <Field
                    className="form-control text-start"
                    type="text"
                    name="description"
                    value={needList.description}
                    onBlur={(e: { target: { value: string } }) => {
                      setDescriptionValue(e.target.value.trim());
                    }}
                    label="Description"
                    required
                  />
                </h6>
              )}

              <h6 className="mt-3">
                <CheckBadge className="align-middle me-2" checked={!!currentFiles.length} /> <small>Select the documents to share</small>
              </h6>
              {currentFiles.map(([name]) => (
                <Row className="py-1">
                  <Col sm={12}>
                    {name}{' '}
                    <i className="cursor-pointer ms-2" data-file-name={name} onClick={deleteFileButtonAction}>
                      <TrashIcon />
                    </i>
                  </Col>
                </Row>
              ))}
              {!!values.borrowers.length && (
                <div className="d-flex mt-4">
                  {/*TODO: uncomment until we goet a license number for scanner*/}
                  {/* <div className={`flex-md-grow-1 d-flex align-items-center justify-content-center vh-25 rounded border ${styles.container} p-0`}>
                    <div className="text-center">
                      <div className="mb-2">
                        <TaskIcon style={{ width: '48px', height: '48px' }} />
                      </div>
                      <Link
                        to={`/scanner?loanId=${needList.loanId}&loanNeedsListId=${needList.loanNeedsListId}`}
                        className="btn btn-primary-gradient rounded-pill pt-2"
                        onClick={() => setBorrowers(values.borrowers)}>
                        SCAN DOCUMENT
                      </Link>
                    </div>
                  </div> */}
                  <DropFilesZone
                    onDrop={addFiles}
                    className="flex-1 flex-grow-1"
                    disabled={isLoading || limitReached}
                    accept={{
                      'image/jpeg': ['.jpg', '.jpeg'],
                      'image/png': ['.png'],
                      'image/tiff': ['.tiff', '.tif'],
                      'application/pdf': ['.pdf'],
                    }}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button variant="outline-primary rounded-pill" onClick={onClose}>
          CANCEL
        </Button>
        <Button type="submit" form="submit-files" disabled={!currentFiles.length || isLoading} variant="primary-gradient rounded-pill">
          UPLOAD
          {isLoading && <i className="ps-2 fas fa-spinner fa-spin"></i>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

interface CheckBorrowersProps {
  borrowers: ILoanNeedsListBorrowerExt[];
}

const CheckBorrowers: FC<CheckBorrowersProps> = ({ borrowers }) => {
  return (
    <div role="group" className="ms-3">
      {borrowers.map((borrower, index) => {
        return (
          <label className={`checkbox-lite mt-2 ms-3`} key={`borrower-upload-${borrower.loanNeedsListId}-${borrower.loanNeedsListBorrowerId}-${index}`}>
            <Field type="checkbox" name="borrowers" className="form-check-input" value={borrower.loanNeedsListBorrowerId.toString()} />
            {borrower.name}
          </label>
        );
      })}
    </div>
  );
};

export default UploadShareModal;
