import { IStepTitleType, ILoanQuestionsMapType, StepSectionType } from '../../types';
import { QuestionValues } from './questionValues.constants';

export const PurchaseStepTitles: IStepTitleType[] = [
  { name: 'About Your Next Home', progress: 5 },
  { name: 'Down Payment', progress: 10 },
  { name: 'Borrower Information', progress: 15 },
  { name: 'Supplemental Consumer Information', progress: 17 },
  { name: 'Credit Information', progress: 20 },
  { name: 'Military Information', progress: 20 },
  { name: 'Income Information', progress: 25 },
  { name: 'Employment Information', progress: 25 },
  { name: 'Self-Employment Information', progress: 25 },
  { name: '', getName: (values: ILoanQuestionsMapType) => (values.isCoborrowerInvite ? 'Has Co-applicant?' : 'Has Co-borrower?'), progress: 30 },
  { name: 'Co-borrower Information', progress: 35 },
  { name: 'Co-borrower Supplemental Consumer Information', progress: 37 },
  { name: 'Co-borrower Credit Information', progress: 40 },
  { name: 'Co-borrower Military Information', progress: 40 },
  { name: 'Co-borrower Income Information', progress: 45 },
  { name: 'Co-borrower Employment Information', progress: 45 },
  { name: 'Co-borrower Self-Employment Information', progress: 45 },
  { name: 'Your Residential Property', progress: 50 },
  { name: 'Other Real Estate Owned', progress: 55 },
  { name: 'Asset Information', progress: 60 },
  { name: 'Co-borrower Asset Information', progress: 60 },
  { name: 'Liabilities Information', progress: 65 },
  { name: 'Co-borrower Liabilities Information', progress: 65 },
  { name: 'Borrower Demographic', progress: 70 },
  { name: 'Borrower Declaration', progress: 70 },
  { name: 'Co-Borrower Demographic', progress: 75 },
  { name: 'Co-Borrower Declaration', progress: 75 },
  { name: 'Contacts', progress: 80 },
  { name: 'Borrower Credit Authorization', progress: 90 },
  { name: 'Co-Borrower Credit Authorization', progress: 90 },
  { name: 'Borrower E-Consent Agreement', progress: 95 },
  { name: 'Co-Borrower E-Consent Agreement', progress: 95 },
  { name: 'Borrower Certification Agreement', progress: 97 },
  { name: 'Co-Borrower Certification Agreement', progress: 97 },
  { name: 'Loan Officer Assignment', progress: 99 },
  { name: 'Thank You', progress: 100, hide: true },
  { name: 'Borrower Previous Address', progress: 15 },
  { name: 'Co-Borrower Previous Address', progress: 15 },
];

export const RefinanceStepTitles: IStepTitleType[] = [
  { name: 'About Your Property', progress: 5 },
  { name: 'About Your Property', progress: 10 },
  { name: 'Borrower Information', progress: 15 },
  { name: 'Credit Information', progress: 20 },
  { name: 'Military Information', progress: 20 },
  { name: 'Income Information', progress: 25 },
  { name: 'Employment Information', progress: 25 },
  { name: 'Self-Employment Information', progress: 25 },
  { name: '', getName: (values: ILoanQuestionsMapType) => (values.isCoborrowerInvite ? 'Has Co-applicant?' : 'Has Co-borrower?'), progress: 30 },
  { name: 'Co-borrower Information', progress: 35 },
  { name: 'Co-borrower Credit Information', progress: 40 },
  { name: 'Co-borrower Military Information', progress: 40 },
  { name: 'Co-borrower Income Information', progress: 45 },
  { name: 'Co-borrower Employment Information', progress: 45 },
  { name: 'Co-borrower Self-Employment Information', progress: 45 },
  { name: 'Your Subject Property', progress: 50 },
  { name: 'Your Current Address', progress: 50 },
  { name: 'Other Real Estate Owned', progress: 60 },
  { name: 'Asset Information', progress: 65 },
  { name: 'Co-borrower Asset Information', progress: 65 },
  { name: 'Liabilities Information', progress: 70 },
  { name: 'Co-borrower Liabilities Information', progress: 70 },
  { name: 'Borrower Demographic', progress: 75 },
  { name: 'Borrower Declaration', progress: 75 },
  { name: 'Co-Borrower Demographic', progress: 80 },
  { name: 'Co-Borrower Declaration', progress: 80 },
  { name: 'Borrower Credit Authorization', progress: 90 },
  { name: 'Co-Borrower Credit Authorization', progress: 90 },
  { name: 'Borrower E-Consent Agreement', progress: 95 },
  { name: 'Co-Borrower E-Consent Agreement', progress: 95 },
  { name: 'Borrower Certification Agreement', progress: 97 },
  { name: 'Co-Borrower Certification Agreement', progress: 97 },
  { name: 'Loan Officer Assignment', progress: 99 },
  { name: 'Thank You', progress: 100, hide: true },
  { name: 'Borrower Previous Address', progress: 15 },
  { name: 'Co-Borrower Previous Address', progress: 15 },
];

export const titlesMapConstants = new Map<string, IStepTitleType[]>([
  [QuestionValues.LoanPurposeTypePurchase, PurchaseStepTitles],
  [QuestionValues.LoanPurposeTypeRefinance, RefinanceStepTitles],
]);

export const titlesSections: StepSectionType[] = [
  {
    name: 'Property Information',
    step: {
      [QuestionValues.LoanPurposeTypePurchase]: 0,
      [QuestionValues.LoanPurposeTypeRefinance]: 0,
    },
  },
  {
    name: 'Borrower Information',
    step: {
      [QuestionValues.LoanPurposeTypePurchase]: 2,
      [QuestionValues.LoanPurposeTypeRefinance]: 2,
    },
    subsections: [
      {
        name: ' Income & Employment',
        step: {
          [QuestionValues.LoanPurposeTypePurchase]: 7,
          [QuestionValues.LoanPurposeTypeRefinance]: 6,
        },
      },
    ],
  },
  {
    name: 'Co-borrower Information',
    step: {
      [QuestionValues.LoanPurposeTypePurchase]: 12,
      [QuestionValues.LoanPurposeTypeRefinance]: 11,
    },
    hideSection: (values?: ILoanQuestionsMapType | null) => values?.SharedB7_1 !== QuestionValues.ApplyingWithPerson || values?.SharedB8a_1 === QuestionValues.Yes,
    subsections: [
      {
        name: ' Income & Employment',
        step: {
          [QuestionValues.LoanPurposeTypePurchase]: 17,
          [QuestionValues.LoanPurposeTypeRefinance]: 15,
        },
      },
    ],
  },
  {
    name: 'Asset',
    step: {
      [QuestionValues.LoanPurposeTypePurchase]: 24,
      [QuestionValues.LoanPurposeTypeRefinance]: 23,
    },
  },
  {
    name: 'Liabilities',
    step: {
      [QuestionValues.LoanPurposeTypePurchase]: 26,
      [QuestionValues.LoanPurposeTypeRefinance]: 25,
    },
  },
  {
    name: 'Government',
    step: {
      [QuestionValues.LoanPurposeTypePurchase]: 28,
      [QuestionValues.LoanPurposeTypeRefinance]: 27,
    },
  },
  {
    name: 'Credit Authorization',
    step: {
      [QuestionValues.LoanPurposeTypePurchase]: 33,
      [QuestionValues.LoanPurposeTypeRefinance]: 31,
    },
  },
  {
    name: 'eConsent Agreement',
    step: {
      [QuestionValues.LoanPurposeTypePurchase]: 35,
      [QuestionValues.LoanPurposeTypeRefinance]: 33,
    },
  },
];
