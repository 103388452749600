import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import { useExhangeOtpMutation } from 'app/api/externalSessionApi';
import { useSetSessionData } from 'app/hooks/authHooks';
import { FC, useCallback, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const ExchangeOtp: FC = () => {
  const { externalUserId, otp } = useParams<{ externalUserId: string; otp: string }>();
  const [exchangeOtp] = useExhangeOtpMutation();
  const serSessionData = useSetSessionData();

  const getCallBack = useCallback(() => {
    const [callbackMatch] = window.location.search.match(/(\?|&)callback=[^?&]+/g) ?? [''];
    const [, callback] = callbackMatch.split('callback=');
    return callback;
  }, []);

  const callback = getCallBack();

  useEffect(() => {
    if (otp) {
      (async () => {
        var result: any = await exchangeOtp({ externalUserId, otp });
        throwErrorProp(result);
        serSessionData(result.data, callback);
        // TODO: fix issue that is not redirecting from app, this window.location should not be neccesary
        window.location.href = callback;
      })();
    }
  }, [callback, exchangeOtp, externalUserId, otp, serSessionData]);

  return (
    <div className="d-flex justify-content-center align-items-center w-100 vh-50">
      <Spinner variant="primary" animation="border" />
    </div>
  );
};

export default ExchangeOtp;
