import { FC, useMemo } from 'react';
import { Button, ListGroup } from 'react-bootstrap';

import { ILoanNeedsListItem, LoanNeedsListBorrowerStatus } from '@rsl/core';
import LoadingButton from '@rsl/core/src/components/buttons/LoadingButton';

import { ReactComponent as UploadIcon } from 'app/assets/icons/upload.svg';
import { ReactComponent as CheckedIcon } from 'app/assets/icons/checked-circle.svg';
import { ReactComponent as UnCheckedIcon } from 'app/assets/icons/unchecked-circle.svg';

import FileUploads from './FileUploads';

import styles from './FilesNeedList.module.scss';
import { useImportDocumentsMutation } from '../api/needlistApi';
import { useCurrentLoanId } from 'app/hooks/loanHooks';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import { toast } from 'react-toastify';

interface FilesNeedListProps {
  neededFiles?: ILoanNeedsListItem[];
  isSubmittedStatus: boolean;
  popupModal: (neddList: ILoanNeedsListItem) => void;
  title: string;
  files: ILoanNeedsListItem[];
  showTitle: boolean;
}

const FilesNeedList: FC<FilesNeedListProps> = ({ isSubmittedStatus, popupModal, title, files, showTitle }) => {
  const hasfileShareUploads = files.some((needList) => needList.fileShareUploads.length > 0);

  const loanId = useCurrentLoanId();
  const [importDocuments, { isLoading }] = useImportDocumentsMutation();

  const handleImport = async () => {
    const result: any = await importDocuments(loanId);
    throwErrorProp(result);
    toast.success(result.data?.message ?? 'Documents submited successfully!');
  };

  return (
    <>
      {showTitle && (
        <div className="d-flex justify-content-between align-items-center my-2">
          <h5 className="text-muted m-0 ps-1">{title}</h5>
          {!isSubmittedStatus && (
            <LoadingButton isLoading={isLoading} onClick={handleImport} disabled={!hasfileShareUploads}>
              Submit
            </LoadingButton>
          )}
        </div>
      )}
      <ListGroup>
        {files.map((needList) => {
          const borrowerNames = needList.loanNeedsListBorrowers.map((borrower: any) => borrower.name).join(', ');
          return (
            <ListGroup.Item className="px-2 px-md-3" key={needList.loanNeedsListId}>
              <div className="d-flex align-items-start justify-content-between">
                <div className="d-flex flex-1">
                  <div className="pe-2">{needList.fileShareUploads.length > 0 ? <CheckedIcon /> : <UnCheckedIcon />}</div>
                  <div className={`d-flex flex-column ${styles.title}`}>
                    <strong>{needList.documentation ?? 'No info'}</strong>
                    <i className="text-gray-600">
                      {needList.description} ({borrowerNames})
                    </i>
                  </div>
                </div>
                {!isSubmittedStatus && (
                  <Button variant="link p-0 px-md-2" onClick={() => popupModal(needList)}>
                    <UploadIcon />
                  </Button>
                )}
              </div>
              <FileUploads uploads={needList.fileShareUploads} borrowers={needList.loanNeedsListBorrowers} canDelete={!isSubmittedStatus} />
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </>
  );
};

export default FilesNeedList;
