import Question from '../../Question';
import { Collapse, Row } from 'react-bootstrap';
import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import TextField from '@rsl/core/src/components/form/TextField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { ValidationErrors } from '@rsl/core/src/constants/errors';

const G2 = () => {
  const { values, setFieldValue, errors } = useFormikContext<ILoanQuestionsMapType>();

  const notHispanicOrLatino = () => {
    setFieldValue('SharedG2_2', false);
    setFieldValue('SharedG2_3', false);
    setFieldValue('SharedG2_4', false);
    setFieldValue('SharedG2_5', false);
    setFieldValue('SharedG2_6', false);
    setFieldValue('SharedG2_7', '');
    setFieldValue('SharedG2_8', false);
  };

  const hispanicOrLatino = () => {
    setFieldValue('SharedG2_1', false);
    setFieldValue('SharedG2_8', false);

    if (values.SharedG2_2) {
      setFieldValue('SharedG2_3', false);
      setFieldValue('SharedG2_4', false);
      setFieldValue('SharedG2_5', false);
      setFieldValue('SharedG2_6', false);
      setFieldValue('SharedG2_7', '');
    }
  };

  const hispanicOrLatinoOption = () => {
    setFieldValue('SharedG2_1', false);
    setFieldValue('SharedG2_2', true);
    setFieldValue('SharedG2_8', false);
  };

  const doNotWishToProvideThisInformation = () => {
    setFieldValue('SharedG2_1', false);
    setFieldValue('SharedG2_2', false);
    setFieldValue('SharedG2_3', false);
    setFieldValue('SharedG2_4', false);
    setFieldValue('SharedG2_5', false);
    setFieldValue('SharedG2_6', false);
    setFieldValue('SharedG2_7', '');
  };

  return (
    <Question label="What is your ethnicity? (Check one or more)">
      {errors.atLeastOneEthnicityChecked && (
        <div className="text-danger">
          <small>{ValidationErrors.AtLeastOneAndSubChecked}</small>
        </div>
      )}
      <Row>
        <CheckboxField className="mb-0 checkbox-lite" name="SharedG2_1" label="Not Hispanic or Latino" horizontal={true} onClick={notHispanicOrLatino} />
      </Row>
      <Row>
        <CheckboxField className="mb-0 checkbox-lite" name="SharedG2_2" label="Hispanic or Latino" horizontal={true} onClick={hispanicOrLatino} />
      </Row>
      <Collapse in={values.SharedG2_2}>
        <div>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedG2_3" label="Mexican" horizontal={true} onClick={hispanicOrLatinoOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedG2_4" label="Puerto Rican" horizontal={true} onClick={hispanicOrLatinoOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedG2_5" label="Cuban" horizontal={true} onClick={hispanicOrLatinoOption} />
          </Row>
          <Row>
            <CheckboxField className="mb-0 mx-3 checkbox-lite" name="SharedG2_6" label="Other Hispanic or Latino" horizontal={true} onClick={hispanicOrLatinoOption} />
          </Row>
          <Row>
            <TextField className="mb-2 mx-3" xs={8} placeholder="Optional" name="SharedG2_7" onClick={hispanicOrLatinoOption} />
          </Row>
        </div>
      </Collapse>
      <Row>
        <CheckboxField
          className="mb-2 checkbox-lite"
          name="SharedG2_8"
          label="I do not wish to provide this information"
          horizontal={true}
          onClick={doNotWishToProvideThisInformation}
        />
      </Row>
    </Question>
  );
};

export default G2;
