import ExtendedFormik from '@rsl/core/src/components/form/ExtendedFormik';
import TextField from '@rsl/core/src/components/form/TextField';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import { useChangePassword } from 'app/hooks/authHooks';
import { FC } from 'react';
import * as Yup from 'yup';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import { RegexConstants } from '@rsl/core/src/constants';
import { ValidationErrors } from '@rsl/core/src/constants/errors';
import { useSetLayoutSettings } from 'app/hooks/layoutHooks';

interface ChangePasswordFormValues {
  password: string;
  passwordConfirm: string;
  pin: string;
}
interface PasswordChangeProps {
  isFirstTime: boolean;
}

const initialValues: ChangePasswordFormValues = {
  password: '',
  passwordConfirm: '',
  pin: '',
};

const validationSchema = Yup.object().shape({
  pin: Yup.string().required(),
  password: Yup.string()
    .required(ValidationErrors.EmptyPassword)
    .min(8, ValidationErrors.PasswordShort)
    .max(15, ValidationErrors.PasswordLong)
    .matches(RegexConstants.Password, ValidationErrors.PasswordFormat),
  passwordConfirm: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], ValidationErrors.PasswordMismatch),
});

const PasswordChange: FC<PasswordChangeProps> = ({ isFirstTime }) => {
  const changePassword = useChangePassword();

  const handleSubmit = async (values: ChangePasswordFormValues) => {
    const response = await changePassword(values.password, values.pin);
    throwErrorProp(response);
  };

  useSetLayoutSettings({
    subTitle: isFirstTime ? 'Complete Account' : 'Create New Password',
    isMainScreen: true,
  });

  return (
    <>
      <Alert variant="light  px-2 py-2 flexible-box">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center text-black mb-3 mt-2">
              <i className="fas fa-info-circle fs-1"></i>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="mb-0 text-primary text-center fs-3">
                <strong>Almost Done!</strong>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-center text-black">Before enter please complete the account by providing a password.</p>
            </Col>
          </Row>
        </Container>
      </Alert>
      <Card className="mx-2 text-center py-4 flexible-box">
        <Card.Body>
          <ExtendedFormik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            lockFormOnChanges={false}
            saveText="Create New Password"
            showCancelButton={false}
            buttonsAlign="center"
            variantSubmit="primary-gradient"
            buttonSize="lg">
            {() => (
              <>
                <h2 className="fw-bold">Create New Password</h2>
                <div>
                  A <strong>PIN</strong> has sent to your email in order to secure your account.
                </div>
                <Row className="mt-4">
                  <TextField className="mb-3" name="password" type="password" placeholder="Password" required />
                </Row>
                <Row>
                  <TextField type="password" className="mb-3" placeholder="Confirm Password" name="passwordConfirm" required />
                </Row>
                <Row>
                  <TextField type="text" name="pin" className="mb-3" placeholder="Pin" sm={3} required />
                </Row>
              </>
            )}
          </ExtendedFormik>
        </Card.Body>
      </Card>
    </>
  );
};

export default PasswordChange;
