import { Col, Row } from 'react-bootstrap';
import { FC } from 'react';
import NameBadge from '@rsl/core/src/components/badges/NameBadge';

import { ReactComponent as PhooneIcon } from 'app/assets/icons/phone.svg';
import { ReactComponent as EmailIcon } from 'app/assets/icons/email.svg';

import styles from './DashboardLoanContacts.module.scss';
import { useProfileImageByEmail } from 'features/profileImage/hooks/profileImageHooks';

interface LoanContactProps {
  name: string;
  position: string;
  phone?: string;
  email?: string;
}

const LoanContact: FC<LoanContactProps> = ({ name, position, phone, email }) => {
  const profileImage = useProfileImageByEmail(email);

  return (
    <article className="my-3">
      <Row>
        <Col xs={2}>
          <NameBadge name={name} className={styles.avatar} src={profileImage} />
        </Col>
        <Col xs={6}>
          <div>{name}</div>
          <small>{position}</small>
        </Col>
        <Col xs={4} className="d-flex align-items-center">
          {phone && (
            <a href={`tel:${phone}`} className="ms-4" rel="noreferrer external">
              <PhooneIcon /> <span className="d-none">{phone}</span>
            </a>
          )}
          {email && (
            <a href={`mailto:${email}`} className="ms-4" rel="noreferrer external">
              <EmailIcon />
              <span className="d-none">{email}</span>
            </a>
          )}
        </Col>
      </Row>
    </article>
  );
};

export default LoanContact;
