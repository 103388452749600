import { FC } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';

const B5a: FC<IQuestionType> = ({ showIf }) => {
  return (
    <Question label="What's your current military status?" showIf={showIf}>
      <Row>
        <RadioField
          className="mb-2"
          name="SharedB5a_1"
          optionsClassName="pt-1"
          options={[
            { label: 'Active duty', value: 'Active duty' },
            { label: 'Reserves', value: 'Reserves' },
            { label: 'National Guard', value: 'National Guard' },
            { label: 'Surviving spouse', value: 'Surviving spouse' },
            { label: 'Retired / Discharged', value: 'Retired / Discharged' },
          ]}
        />
      </Row>
    </Question>
  );
};

export default B5a;
