import { useState } from 'react';
import Question from '../../Question';
import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import TextField from '@rsl/core/src/components/form/TextField';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';

const B1j = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  const [sameAsCurrentAddress, setSameAsCurrentAddress] = useState<boolean>(!!values.SharedB1j_6);

  const handleClick = () => {
    if (!sameAsCurrentAddress) {
      setFieldValue('SharedB1j_1', '');
      setFieldValue('SharedB1j_2', '');
      setFieldValue('SharedB1j_3', '');
      setFieldValue('SharedB1j_4', '');
      setFieldValue('SharedB1j_5', '');
    }

    setSameAsCurrentAddress(!sameAsCurrentAddress);
  };

  return (
    <Question label="What is your mailing address?">
      <Row>
        <CheckboxField className="mb-2" name="SharedB1j_6" label="Same as current address" horizontal={true} onChange={handleClick} />
      </Row>
      {!sameAsCurrentAddress && (
        <>
          <Row>
            <TextField xs={9} className="mb-2" placeholder="Street Address" name="SharedB1j_1" />
            <TextField xs={3} className="mb-2" placeholder="Unit" name="SharedB1j_5" />
          </Row>
          <Row>
            <TextField className="mb-2" placeholder="City" name="SharedB1j_2" required />
            <LookupSelect className="mb-2" placeholder="State" name="SharedB1j_3" lookup="states" required enableDefault={false} />
            <TextField className="mb-2" placeholder="Zip" name="SharedB1j_4" required />
          </Row>
        </>
      )}
    </Question>
  );
};

export default B1j;
