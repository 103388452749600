import { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { setLockedRoute } from '../../store/slices/routeSlice';
import { useDispatch } from 'react-redux';

interface LockFormOnChangesProps {
  message?: string;
}

const LockFormOnChanges: FC<LockFormOnChangesProps> = ({ message }) => {
  const { dirty } = useFormikContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLockedRoute({ message, isLockedRoute: dirty }));
  }, [dirty, dispatch, message]);

  return <></>;
};

export default LockFormOnChanges;
