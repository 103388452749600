import NameBadge from '@rsl/core/src/components/badges/NameBadge';
import FormModal from '@rsl/core/src/components/modal/FormModal';
import { FC, useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CropImage from '@rsl/core/src/components/util/CropImage';
import { toBase64 } from '@rsl/core/src/utils/fileUtils';

import styles from './PartnerImage.module.scss';

interface IPartnerImage {
  firstName?: string;
  lastName?: string;
  defaultImageBase64?: string;
  onSubmit: (image: string, fileName: string) => Promise<string>;
}

const PartnerImage: FC<IPartnerImage> = ({ firstName, lastName, defaultImageBase64, onSubmit }) => {
  const [imageBase64, setImageBase64] = useState<string>(defaultImageBase64 ?? '');
  const fileInput = useRef<HTMLInputElement>(null);
  const [cropModalShow, setCropModalShow] = useState(false);
  const [newImage, setNewImage] = useState<string>('');
  const [newImageFileName, setNewImageFileName] = useState<string>('');

  useEffect(() => {
    setImageBase64(defaultImageBase64 ?? '');
  }, [defaultImageBase64]);

  const selectNewImage = () => {
    fileInput?.current?.click();
  };

  const changeNewImage = async (event: any) => {
    if (event.target.files[0]) {
      const fileExt = event.target.files[0].name.split('.').pop().toLowerCase();

      if (fileExt !== 'jpg' && fileExt !== 'jpeg' && fileExt !== 'png') {
        return;
      }

      const base64 = (await toBase64(event.target.files[0])) as string;

      setNewImage(base64.split(',')[1]);
      setNewImageFileName(event.target.files[0].name);
      setCropModalShow(true);
      event.target.value = '';
    }
  };

  let newCroppedImage: string = '';
  const changeNewProfileImage = (croppedImage: string) => {
    newCroppedImage = croppedImage;
  };

  const sendImage = async () => {
    if (!newImage || !newCroppedImage) return;

    setCropModalShow(false);

    const imageData = await onSubmit(newCroppedImage.split(',')[1], newImageFileName);
    setImageBase64(imageData);
    toast.success('The image has successfully updated!');
  };

  return (
    <>
      <NameBadge
        name={`${firstName ?? ''} ${lastName ?? ''}`}
        src={imageBase64 ? `data:image/png;base64,${imageBase64}` : undefined}
        className={`${styles.image} d-block mx-auto my-2`}
      />
      <Button variant="secondary" onClick={selectNewImage} className="mb-2">
        Upload New Image
      </Button>
      <FormModal modalTitle="Crop image" modalShow={cropModalShow} onCancel={() => setCropModalShow(false)} onAccept={sendImage} acceptLabel="Done">
        <CropImage src={`data:image/png;base64,${newImage}`} getCroppedImage={changeNewProfileImage} />
      </FormModal>
      <div className="d-none">
        <input ref={fileInput} type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => changeNewImage(event)} placeholder="file" />
      </div>
    </>
  );
};

export default PartnerImage;
