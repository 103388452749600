import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core';

const C11 = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();
  return (
    <Question label="Has the coborrower completed housing counseling within the last 12 months?" required={values.SharedC1e_1 === QuestionValues.Yes}>
      <Row>
        <RadioField
          className="mb-2"
          name="SharedC11_1"
          optionsClassName="pt-1 w-50 w-md-10"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default C11;
