import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import Question from '../../Question';
import { QuestionValues } from '@rsl/core/src/constants';

const G10 = () => {
  return (
    <Question
      label="Will this property be subject to a lien that could take priority over the first mortgage 
lien, such as a clean energy lien paid through your property taxes (e.g., the Property 
Assessed Clean Energy Program)?">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedG10_1"
          optionsClassName="pt-1 w-50 w-md-10"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default G10;
