import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FileShareState {
  loanId?: string | null;
  loanNeedsListId?: number | null;
  description?: string | null;
  borrowersToShareWith: string[];
}

const initialState: FileShareState = {
  borrowersToShareWith: [],
};

export const fileshareSlice = createSlice({
  name: 'fileshareSlice',
  initialState,
  reducers: {
    setBorrowersToShare: (state, { payload }: PayloadAction<string[]>) => {
      state.borrowersToShareWith = payload;
    },
    setloanAndNeedlistId: (state, { payload }: PayloadAction<{ loanId: string; loanNeedsListId: number; description: string }>) => {
      state.loanId = payload.loanId;
      state.loanNeedsListId = payload.loanNeedsListId;
      state.description = payload.description;
      state.borrowersToShareWith = [];
    },
    clearAll: (state) => {
      state.loanId = null;
      state.loanNeedsListId = null;
      state.description = null;
      state.borrowersToShareWith = [];
    },
  },
});

export const { setBorrowersToShare, setloanAndNeedlistId, clearAll } = fileshareSlice.actions;

export default fileshareSlice.reducer;
