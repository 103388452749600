import { Button, Navbar } from 'react-bootstrap';
import { ReactComponent as Minutemortgage } from 'app/assets/img/cobranding-page/minutemortgage.svg';

const Header = () => {
  return (
    <>
      <Navbar className="navbar-theme py-5 d-flex justify-content-around" bg="dark">
        <Minutemortgage height="90" className="px-2" />
        <Button href="/apply-now/sign-in" variant="outline-primary rounded-pill text-white" size="lg" className="px-5 py-3" style={{ minWidth: '11rem' }}>
          SIGN IN
        </Button>
      </Navbar>
    </>
  );
};

export default Header;
