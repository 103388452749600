import { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Scanner from './Scanner';

import './scanner.css';
import styles from './scanContainer.module.scss';
import AddButtonText from '../buttons/AddButtonText';
import { useHistory } from 'react-router-dom';

const ScanContainer = () => {
  const [scan, setScan] = useState(false);
  const history = useHistory();
  const resetScanStateDelayed = () => {
    const reset = () => {
      setScan(false);
    };
    setTimeout(reset, 1000);
  };

  const ref = useRef<any>(null);

  const exportImages = () => {
    ref.current.export();
  };

  const removeSelectedImages = () => {
    ref.current.delete();
  };

  return (
    <main className={`d-flex flex-column ${styles.container}`}>
      <header className="d-flex justify-content-between w-100">
        <div></div>
        <h1>Docs Scan</h1>
        <div>
          <Button onClick={removeSelectedImages}>Delete Selected Images</Button>
        </div>
      </header>
      <section className="flex-grow-1 d-flex overflow-auto">
        <Scanner ref={ref} scan={scan} license={process.env.REACT_APP_DYNAMICSOFT_LICENSE_KEY} />
      </section>
      <footer className="d-flex justify-content-between w-100">
        <Button variant="link" onClick={() => history.goBack()}>
          <i className="fas fa-solid fa-xmark"></i> Cancel
        </Button>
        <AddButtonText
          variant="link"
          onClick={() => {
            setScan(true);
            resetScanStateDelayed();
          }}>
          Add Document
        </AddButtonText>

        <Button variant="link" onClick={exportImages}>
          <i className="fas fa-solid fa-floppy-disk"></i> Save
        </Button>
      </footer>
    </main>
  );
};

export default ScanContainer;
