import { strEnum } from '@rsl/core/src/utils/enumUtils';

export const PinActionEnum = strEnum(['RegisterDevice', 'ResetPassword', 'SetFirstTimePassword', 'Login']);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PinActionEnum = keyof typeof PinActionEnum;

export interface PinActionType {
  pinId: string;
  action: PinActionEnum;
  expirationUtcDateTime: Date;
  hasValidated: boolean;
}

export interface PinActionRequest<T> {
  pinId: string;
  pin: string;
  payload: T;
}

export interface PinSliceState {
  currentPinAction: PinActionType | null;
}

export interface LoginType {
  email: string;
  password: string;
}
