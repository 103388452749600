import { FC, useState, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import { Button, Badge, Row, Col, FormSelect } from 'react-bootstrap';
import * as Yup from 'yup';

import { ICommunicationLog, ICommunicationLogRecipient, ILoanCommLogRecipientLookup } from '../../types/communication.types';
import TextField from '@rsl/core/src/components/form/TextField';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import { isAdditionalRecipientsValid, isExtensionNotValid, isFileSizeExceeded } from '@rsl/core/src/utils/validationUtils';
import { ValidationErrors, acceptedFiles } from '../../constants';

Yup.setLocale({
  mixed: {
    required: ValidationErrors.Required,
  },
});

const MessageFormSchema = Yup.object().shape({
  body: Yup.string().required().max(2500, 'Message must be at most 2500 characters'),
  notificationCategoryTypeId: Yup.string().required(),
  recipients: Yup.array().min(1),
  files: Yup.mixed()
    .test('fileSize', 'File Size is too large', (value) => {
      if (value) {
        let fileError = [];
        for (let i = 0; i < value.length; i++) {
          if (!isFileSizeExceeded(value[i])) {
            fileError.push(value[i].name);
          }
        }
        if (fileError.length > 0) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    })
    .test('fileType', 'Unsupported File Format', (value) => {
      if (value) {
        let fileError = [];
        for (let i = 0; i < value.length; i++) {
          if (!isExtensionNotValid(value[i])) {
            fileError.push(value[i].name);
          }
        }
        if (fileError.length > 0) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    }),
  additionalRecipients: Yup.string().test('Validate Email', 'Invalid emails', isAdditionalRecipientsValid),
});

const MessageChatFormSchema = Yup.object().shape({
  body: Yup.string().required().max(2500, 'Message must be at most 2500 characters'),
});

interface IMessageForm {
  title: string;
  messageData: ICommunicationLog;
  loanContactsLookup: ILoanCommLogRecipientLookup[];
  doSubmitForm: (values: ICommunicationLog) => void;
  isLoading?: boolean;
  isNew: boolean;
  disableCategory?: boolean;
  disableLoanContacts?: boolean;
  disableAdditionalRecipients?: boolean;
  disableAttachments?: boolean;
  disableSubmitButton?: boolean;
  hideSendButton?: boolean;
  chatForm?: boolean;
}

const MessageForm: FC<IMessageForm> = ({
  title,
  messageData,
  loanContactsLookup,
  isLoading,
  doSubmitForm,
  isNew = false,
  disableCategory,
  disableLoanContacts,
  disableAdditionalRecipients,
  disableAttachments,
  disableSubmitButton,
  hideSendButton = true,
  chatForm = false,
}) => {
  const [recipientsList, setRecipientsList] = useState([] as ICommunicationLogRecipient[]);
  const [contactsList, setContactsList] = useState([] as ILoanCommLogRecipientLookup[]);
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (messageData) {
      let data = messageData?.recipients ?? [];
      setRecipientsList(data);
    }
    if (loanContactsLookup) {
      let recipientsEmail = messageData?.recipients?.map((recipient: ICommunicationLogRecipient) => recipient.recipientEmail);
      let data = loanContactsLookup.filter((contact) => !recipientsEmail?.includes(contact.email));
      setContactsList(data);
    }
  }, [messageData, loanContactsLookup]);

  const handleLoanCotactsChange = (email: string) => {
    let loanContactData = contactsList?.find((contact) => contact.email === email);
    let recipients = [...recipientsList];

    recipients.push({
      communicationLogId: messageData.communicationLogId || 0,
      recipientEmail: loanContactData?.email || '',
      recipient: loanContactData?.name || '',
      recipientUserId: loanContactData?.userId,
    });
    let contacts = contactsList?.filter((contact) => contact.email !== email);
    setContactsList(contacts);
    setRecipientsList(recipients);
    return recipients;
  };

  const handleRecipientChange = (email: string) => {
    let loanContactData = loanContactsLookup?.filter((contact) => contact.email === email);
    let contacts = [...contactsList];
    loanContactData?.forEach((contact) => contacts.push(contact));

    let recipients = recipientsList?.filter((recipient: ICommunicationLogRecipient) => recipient.recipientEmail !== email);
    setContactsList(contacts);
    setRecipientsList(recipients);
    return recipients;
  };

  const handleAttachmentChange = (attachments: FileList) => {
    let fileList = attachments ? Array.from(attachments) : ([] as File[]);
    if (fileInput.current) {
      let filesLength = fileInput?.current?.files?.length;
      let dt = new DataTransfer();
      for (let i = 0; i < filesLength!; i++) {
        const file = fileInput.current.files![i];
        dt.items.add(file);
        fileList.push(file);
      }

      fileInput.current.files = dt.files;
    }
    return fileList;
  };

  const handleRemoveAttachment = (selectedFile: File, attachments: FileList) => {
    let fileList = attachments ? Array.from(attachments) : ([] as File[]);
    let result = fileList.filter((file) => file.name !== selectedFile.name);
    let dt = new DataTransfer();
    if (fileInput.current) {
      for (let i = 0; i < fileList.length; i++) {
        var file = attachments[i];
        if (file !== selectedFile) {
          dt.items.add(file);
        }
      }
      fileInput.current.files = dt.files;
    }
    return result;
  };

  return (
    <Formik
      validationSchema={!chatForm ? MessageFormSchema : MessageChatFormSchema}
      initialValues={messageData}
      onSubmit={(values, { resetForm }) => {
        doSubmitForm(values);
        resetForm();
      }}>
      {({ values, errors, isSubmitting, setFieldValue }) =>
        !chatForm ? (
          <Form id="messageFormReply">
            {!isNew && (
              <>
                <Row className="mb-2">
                  <Col md={3}>
                    <label>Original Message</label>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={9}>{values.originalBody}</Col>
                </Row>
                <hr />
              </>
            )}
            <Row className="mb-2">
              <Col xs={3}>
                <h2>{title}</h2>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={9}>
                {!!values.borrowerLastName && `${values.borrowerLastName}, `}
                {!!values.loanNumber && `Loan #${values.loanNumber}`}
              </Col>
            </Row>
            {isNew && !disableCategory && (
              <Row className="mb-2">
                <LookupSelect className="mb-3" label="Category" name="notificationCategoryTypeId" lookup="commLogCategories" required={isNew} />
              </Row>
            )}
            <Row className="mb-2">
              <Col xs={12}>
                <label>Loan Contacts</label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={12}>
                <FormSelect
                  id="loanContacts"
                  value={values.loanContact ?? ''}
                  onChange={(event) => setFieldValue('recipients', handleLoanCotactsChange(event.currentTarget.value))}
                  className="mb-2"
                  disabled={disableLoanContacts}
                  aria-label="Select Loan Contact"
                  name="loanContacts">
                  <option key="-1" value="">
                    &nbsp;
                  </option>
                  {contactsList &&
                    contactsList.map((contact) => (
                      <option key={contact.displayValue} value={contact.email}>
                        {contact.displayValue}
                      </option>
                    ))}
                </FormSelect>
                {recipientsList &&
                  recipientsList.map((recipient: ICommunicationLogRecipient) => (
                    <>
                      <Badge pill bg="primary" className="fs-5 me-2 mb-1">
                        {recipient.recipientEmail}{' '}
                        <Button
                          size={'sm'}
                          variant="close"
                          className={'btn-close-white align-middle rounded-circle ms-1 fs-5'}
                          onClick={() => setFieldValue('recipients', handleRecipientChange(recipient.recipientEmail))}></Button>
                      </Badge>{' '}
                    </>
                  ))}
                {errors.recipients ? <div style={{ color: '#dc3545', fontSize: '80%' }}>Required field</div> : null}
              </Col>
            </Row>
            {!disableAdditionalRecipients && (
              <Row className="mb-3">
                <TextField textarea rows={3} label="Additional recipients" name="additionalRecipients" />
              </Row>
            )}
            <Row>
              <TextField textarea className="mb-3" rows={3} label="Message" name="body" required />
            </Row>
            {!disableAttachments && (
              <>
                <Row className="mb-2">
                  <label>Attachment</label>
                </Row>

                <Row className="mb-3">
                  <input
                    name="files"
                    ref={fileInput}
                    accept={acceptedFiles.join(',')}
                    id="files"
                    type="file"
                    onChange={() => setFieldValue('files', handleAttachmentChange(values?.files!))}
                    multiple
                    placeholder="Files"
                  />
                  {errors.files ? <div style={{ color: '#dc3545', fontSize: '80%' }}>{errors.files}</div> : null}
                </Row>
              </>
            )}
            <Row>
              {values.files && (
                <Row>
                  <Col>
                    {Array.from(values.files).map((file: File) => (
                      <div>
                        <Button size={'sm'} variant="link" onClick={() => setFieldValue('files', handleRemoveAttachment(file, values?.files!))}>
                          <i className="align-middle fas fa-fw fa-trash" /> {file.name}
                        </Button>
                      </div>
                    ))}
                  </Col>
                </Row>
              )}
            </Row>
            {!hideSendButton && (
              <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary-gradient rounded-pill px-4" disabled={isSubmitting || isLoading || disableSubmitButton}>
                  SEND
                </button>
              </div>
            )}
          </Form>
        ) : (
          <Form>
            <div className="d-flex">
              <div className="flex-grow-1 ps-1 pe-3">
                <TextField name="body" placeholder="Type your message here" className="border-0 shadow-none rounded-pill" required />
              </div>
              <div className="flex-shrink-1 mt-1">
                <Button type="submit" variant="primary" size="sm" className="rounded-circle text-white p-2" disabled={isSubmitting || isLoading || disableSubmitButton}>
                  <i className="far fa-paper-plane fs-3" />
                </Button>
              </div>
            </div>
          </Form>
        )
      }
    </Formik>
  );
};

export default MessageForm;
