import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { baseApi } from '@rsl/core/src/api/baseApi';
import lookupReducer from '@rsl/core/src/store/slices/lookupSlice';
import routeReducer from '@rsl/core/src/store/slices/routeSlice';
import { AuthMiddleware } from '../api/middleware/authMiddleware';
import externalAuthReducer from 'app/store/slices/authSlice';
import pinReducer from 'features/pin/store/slices/pinSlice';
import routeManagerReducer from 'app/store/slices/routeManagerSlice';
import posReducer from 'features/apply-now/pos/store/slices/posSlice';
import layoutSettingsReducer from './slices/layoutSettingsSlice';
import fileshareReducer from 'features/fileshare/store/slices/fileshareSlice';

export const store = configureStore({
  reducer: {
    auth: externalAuthReducer,
    lookup: lookupReducer,
    route: routeReducer,
    pin: pinReducer,
    routeManager: routeManagerReducer,
    pos: posReducer,
    layoutSettings: layoutSettingsReducer,
    fileshare: fileshareReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware).concat(AuthMiddleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
