import { useFormikContext } from 'formik';
import { useDebouncedFunction } from '../../hooks/actionsHooks';
import { sum } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { getDefaultMap } from '../../utils/mapUtils';

type MinuteSyncType = 'percent' | 'percentValue' | 'subtract' | 'addition' | 'ruleOfThreeDivided' | 'multiply' | 'custom';

const getPercentageFromValue = (values: number[]) => {
  const [baseValue, ...rest] = values;
  const sumValues = sum(rest);
  const fullPercentage = (sumValues / baseValue) * 100;
  return fullPercentage.toFixed(2);
};

const getValueFromPercentage = (values: number[]) => {
  const [baseValue, ...rest] = values;
  const sumValues = sum(rest);
  const total = (sumValues / 100) * baseValue;
  return Math.round(total);
};

const getMultiplyValue = (values: number[]) => {
  const [baseValue, ...rest] = values;
  const sumValues = sum(rest);
  return sumValues * baseValue;
};

const getSubtractedValue = (values: number[]) => {
  const [baseValue, ...rest] = values;
  const sumValues = sum(rest);
  return baseValue - sumValues;
};

const getAdditionValue = (values: number[]) => {
  // Make sure we send numbers everytime
  const result = sum(values.map((value) => +value));
  return result;
};
const getRuleOfThreeDivided = ([a, b, c, division]: number[]) => {
  return Math.round(((a / b) * c) / division);
};

const syncMap = getDefaultMap<MinuteSyncType, Function>([
  ['percent', getPercentageFromValue],
  ['percentValue', getValueFromPercentage],
  ['subtract', getSubtractedValue],
  ['addition', getAdditionValue],
  ['ruleOfThreeDivided', getRuleOfThreeDivided],
  ['multiply', getMultiplyValue],
]);

interface SyncFieldProps {
  type: MinuteSyncType;
  fields: (string | number)[];
  lockOnEditing?: string[];
  name: string;
  defaultValuesToZero?: boolean;
  custom?: (values: (string | number)[]) => number;
}

const SyncField: FC<SyncFieldProps> = ({ name, type, fields, custom, lockOnEditing = [], defaultValuesToZero = false }) => {
  const [finalFields] = useState(fields);
  const [onEditingFields] = useState(lockOnEditing);
  const { setFieldValue, values } = useFormikContext<any>();

  const fieldValues = useMemo(
    () =>
      finalFields.map((field: string | number) => {
        if (typeof field === 'string') {
          return defaultValuesToZero ? values[field] || 0 : values[field];
        }
        return field;
      }),
    [defaultValuesToZero, finalFields, values]
  );
  const isFieldEditing = values.isFormikEditingField === name;
  const updateField = useDebouncedFunction((value) => {
    setFieldValue(name, value);
  }, 100);

  useEffect(() => {
    const canSetValue = !fieldValues.some((value) => Number.isNaN(parseFloat(value)));
    const isEditingLokedField = onEditingFields.includes(values.isFormikEditingField);
    if (!isEditingLokedField && !isFieldEditing && canSetValue) {
      if (type === 'custom' && typeof custom === 'function') {
        const newValue = custom(fieldValues);
        updateField(newValue);
        return;
      }

      const mapFunction = syncMap(type);
      const newValue = mapFunction(fieldValues);
      updateField(newValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, name, onEditingFields, setFieldValue, updateField, ...fieldValues]);
  return <></>;
};

export default SyncField;
