import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import MessageIndicator from '../../../shared/MessageIndicator';

const E1 = () => {
  return (
    <>
      <MessageIndicator>Any personal privacy data will not be shared, only application status.</MessageIndicator>
      <Question label="Would you like to share application updates with your agent?">
        <Row>
          <RadioField
            className="mb-2"
            name="PurchaseE1_1"
            optionsClassName="pt-1"
            options={[
              { label: QuestionValues.Yes, value: QuestionValues.Yes },
              { label: QuestionValues.No, value: QuestionValues.No },
            ]}
          />
        </Row>
      </Question>
    </>
  );
};

export default E1;
