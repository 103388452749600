import { useAppDispatch } from 'app/hooks/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { currentPosSel } from '../store/selectors/posSelectors';
import { setCurrentPos } from '../store/slices/posSlice';

/**
 * get current Pos helpers
 */
export const useCurrentPos = () => {
  const dispatch = useAppDispatch();
  const currentPos = useSelector(currentPosSel);

  // serializing de-serializing is a workaround for an issue that causes formik to be broken, maybe because both use context
  const savePos = useCallback(
    (values: any) => {
      const pos = !!values ? JSON.stringify(values) : null;
      const copy = !!pos ? JSON.parse(pos) : null;
      dispatch(setCurrentPos(copy));
    },
    [dispatch]
  );

  return { currentPos, savePos, posId: currentPos?.loanPosApplicationId };
};
