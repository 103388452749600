import { FC, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useFetchExternalMessagesQuery } from 'app/api/externalSessionApi';
import Message from './Message';
import NewMessage from './NewMessage';
import { useAppParams } from '@rsl/core/src/hooks/routeHooks';
import { formatFullDateFriendly } from '@rsl/core/src/utils/dateUtils';

import styles from './Inbox.module.scss';

const Inbox: FC = () => {
  const { loanId } = useAppParams<{ loanId: string }>();
  const { data: messages } = useFetchExternalMessagesQuery(loanId);
  const tempDate = useRef<string>('');

  return (
    <>
      <div className="d-flex justify-content-between py-2 py-md-0">
        <h2>
          <strong>Your Messages</strong>
        </h2>
      </div>
      <Card className="shadow-none border rounded-3">
        <Card.Body className={`p-0 shadow-none ${styles.messagesDiv}`}>
          <div className="px-3">
            {messages?.map((message) => {
              const newDate = formatFullDateFriendly(message.sentDateTimeUtc);
              const printDate = newDate !== tempDate.current;
              tempDate.current = newDate;

              return (
                <>
                  {printDate && (
                    <div className={`bg-light ${styles.hrText}`} data-content={tempDate.current}>
                      <hr />
                    </div>
                  )}
                  <Message key={message.communicationLogId} data={message} hideMarkAsRead />
                </>
              );
            })}
          </div>
        </Card.Body>
        <Card.Body className="border-top p-0 px-2 pt-3">
          <NewMessage chatForm />
        </Card.Body>
      </Card>
    </>
  );
};

export default Inbox;
