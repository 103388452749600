import { FC, ReactNode, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { ILoanQuestionsMapType, ILoanType } from '@rsl/core/src/types';
import { useSaveLoanPosApplicationMutation } from '../api/loanPosApplicationApi';
import Config from '../config/Config';
import Stepper from './Stepper';
import LoanMapper from '../utils/loanMapper';
import QuestionSkeleton from './questions/QuestionSkeleton';
import { useHistory } from 'react-router';
import { PinActionEnum } from 'features/pin/types';
import { pinActionRouteMap } from 'features/pin/constants/pinActionsConstants';
import { QuestionValues, PurchaseStepTitles, RefinanceStepTitles } from '@rsl/core/src/constants';
import { useCurrentPos } from '../hooks/posHooks';
import { machineStamp } from '../utils/agreeStamps';
import { getInsights } from 'app/appInsights';
import { toast } from 'react-toastify';

interface IStep {
  validationSchema: any;
  values: ILoanQuestionsMapType;
  setValues: any;
  currentStep: number;
  children: ReactNode;
}

const Step: FC<IStep> = ({ validationSchema, values, setValues, currentStep, children }) => {
  const [saveLoanPosApplication, { isLoading: isUpdating }] = useSaveLoanPosApplicationMutation();
  const { posId, savePos, currentPos } = useCurrentPos();
  const isLoading = isUpdating;
  const history = useHistory();
  const [isIpLoading, setIpLoading] = useState(true);
  const { appInsights } = getInsights();

  const doSubmitForm = async (newValues: ILoanQuestionsMapType) => {
    const data = await save(newValues);
    next(data);
  };

  useEffect(() => {
    (async () => {
      await machineStamp();
      setIpLoading(false);
    })();
  }, []);

  const save = async (newValues: ILoanQuestionsMapType) => {
    let newLoanApplication: ILoanType | null = null;

    try {
      newLoanApplication = await LoanMapper(newValues);
    } catch {
      appInsights.trackException({ error: new Error('[POS] Save Loan Pos Application fails on Mapper error'), properties: { currentStep, data: newValues } });
      newLoanApplication = await LoanMapper(currentPos as ILoanQuestionsMapType);
    }

    const nextStep = Config.nextStep(newValues, currentStep);
    const nextStepTitle = Config.title({ ...newValues, uiCurrentStep: nextStep }, nextStep).name;

    const response: any = await saveLoanPosApplication({
      ...(newLoanApplication ?? ({} as ILoanType)),
      uiCurrentStep: nextStep,
      uiCurrentStepTitle: nextStepTitle,
    });

    savePos(newLoanApplication);

    // TODO: make redirect from middleware and not from here, but more validations must be added
    if (!!response.error) {
      history.push(`${pinActionRouteMap(PinActionEnum.Login)}/1`);
      return;
    }

    return response.data ?? newLoanApplication;
  };

  const next = async (data: ILoanType) => {
    let newValues: ILoanQuestionsMapType | null = null;

    try {
      newValues = await LoanMapper({} as ILoanQuestionsMapType, data);
    } catch {
      appInsights.trackException({ error: new Error('[POS] Go Next in Loan Pos Application fails on Mapper error'), properties: { currentStep, data: newValues } });
      toast.error('An internal error has occurred, please contact us to help you.');
      newValues = currentPos;
    }

    setValues(newValues);
    window.scrollTo(0, 0);
    history.push(`/apply-now/${posId}/${newValues?.uiCurrentStep}`);
  };

  const previous = () => {
    const previousStep = Config.previousStep(values, currentStep);
    setValues({
      ...values,
      uiCurrentStep: previousStep,
      uiCurrentStepTitle: values.loanPurposeType === QuestionValues.LoanPurposeTypePurchase ? PurchaseStepTitles[previousStep] : RefinanceStepTitles[previousStep],
    });
    history.push(`/apply-now/${posId}/${previousStep}`);
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={values} onSubmit={doSubmitForm} validateOnMount={values.uiCurrentStep === 0}>
      {() => (
        <Form>
          {isLoading || isIpLoading ? <QuestionSkeleton /> : children}
          <Stepper
            values={values}
            previous={previous}
            isLoading={isLoading}
            disableBackButton={!!values.isCoborrowerInvite && values.uiCurrentStep === 2}
            currentStep={currentStep}
          />
        </Form>
      )}
    </Formik>
  );
};

export default Step;
