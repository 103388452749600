import Question from '../../Question';
import { Row } from 'react-bootstrap';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';
import SyncField from '@rsl/core/src/components/form/SyncField';
import { useEditingFormikField } from '@rsl/core/src/hooks/fieldHooks';

const D2 = () => {
  const editingProps = useEditingFormikField();
  return (
    <Question label="What is your estimated down payment amount?">
      <Row>
        <SyncField name="PurchaseD2_1" fields={['PurchaseD1_1', 'downPaymentPercent']} type="percentValue" lockOnEditing={['PurchaseD1_1']} />
        <CurrencyField xs={12} md={6} className="mb-2" placeholder="Estimated down payment" name="PurchaseD2_1" required {...editingProps} />

        <SyncField name="downPaymentPercent" fields={['PurchaseD1_1', 'PurchaseD2_1']} type="percent" />
        <CurrencyField xs={12} md={6} className="mb-2" placeholder="Estimated down payment %" name="downPaymentPercent" prefix="" suffix="%" {...editingProps} />
      </Row>
    </Question>
  );
};

export default D2;
