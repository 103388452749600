import { strEnum } from '@rsl/core/src/utils/enumUtils';

export const TaskTypes = strEnum(['eSign', 'eConsent', 'CreditAuthorization']);

export type TaskType = keyof typeof TaskTypes;

export interface DocumentSignTask<T> {
  externalTaskId: number;
  loanId: string;
  taskType: TaskType;
  name: string;
  description: string;
  isOptional: boolean;
  assignedTo: string[];
  status: string;
  dueDate: Date;
  viewModelJson: T;
  completedDateTimeUtc: Date;
  createdDateTimeUtc: Date;
}

export interface ESigners {
  LoanDisclosureId: number;
  SignSetId: string;
  SignSetToken: string;
  ESignLink: string;
  DownloadedDateTimeUtc?: Date;
  SignedDateTimeUtc?: Date;
  LoanDocumentId?: number;
  SignerName: string;
  SignerEmail: string;
}

interface SigningParties {
  Name: string;
  Email: string;
}

export interface WetSign {
  Name: string;
  LoanDisclosureDocumentId: string;
  LoanDisclosureId: string;
  FileName: string;
  DownloadServerFilename: Date;
  DownloadedDateTimeUtc?: Date;
  UploadedDateTimeUtc?: Date;
  UploadedBy: string;
  DueDate: Date;
  LoanDocumentId?: string;
  SigningParties: SigningParties[];
}

export interface EConsent {
  loanId: string;
  loanNumber: string;
  borrowerEConsentSignTimeStamp: string;
  borrowerEConsentMachineStamp: string;
  borrowerFirstName: string;
  borrowerLastName: string;
  borrowerEmail: string;
  isBorrower: boolean;
  loanDocumentId?: number;
  losAttachmentId?: string;
  errorMessage?: string;
  externalTaskId?: string;
}

export interface CreditAuth {
  loanId: string;
  loanNumber: string;
  borrowerCreditAuthSignTimeStamp: string;
  borrowerCreditAuthMachineStamp: string;
  borrowerFirstName: string;
  borrowerLastName: string;
  borrowerEmail: string;
  ssn?: string;
  birthDate?: string;
  isBorrower: boolean;
  borrowerIndex?: number;
  loanDocumentId?: number;
  losAttachmentId?: string;
  authorizedCreditReportDate?: string;
  errorMessage?: string;
  externalTaskId?: string;
}
