import { FC, useEffect, useRef } from 'react';
import * as Crop from 'croppie';
import 'croppie/croppie.css';
import styles from './CropImage.module.scss';

interface ICropImage {
  src: string;
  getCroppedImage: (croppedImage: string) => any;
}

const CropImage: FC<ICropImage> = ({ src, getCroppedImage }) => {
  const cropperRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (src) {
      new (Crop as any)(cropperRef?.current, {
        viewport: { width: 400, height: 400, type: 'circle' },
        update: async function () {
          const result = await this.result({ type: 'base64', size: 'viewport', format: 'jpeg', quality: 0.4, circle: true });
          getCroppedImage(result);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <div className={styles.container}>
      <img src={src} width="400" height={400} ref={cropperRef} alt="aaa" />
    </div>
  );
};

export default CropImage;
