import React, { FC, ElementType } from 'react';
import { useMemo } from 'react';
import { Badge, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import style from './SidebarItemNotification.module.scss';

interface ISidebarItemNotification {
  title: string;
  description?: string;
  notificationNumber?: number;
  path?: string;
  icon?: ElementType;
  linkClassName?: string;
  className?: string;
  hideTitleOnMobile?: boolean;
  iconSize?: 'sm' | 'md';
  iconClassName?: string;
  titleClassName?: string;
}

const SidebarItemNotification: FC<ISidebarItemNotification> = ({
  title,
  notificationNumber,
  path,
  description,
  linkClassName,
  className,
  children,
  icon: Icon = React.Fragment,
  hideTitleOnMobile = false,
  iconSize = 'sm',
  iconClassName,
  titleClassName,
}) => {
  const Component: FC<any> = useMemo(() => (!!path ? Link : Row), [path]);
  return (
    <div className={className}>
      <Component to={path} className={`d-flex justify-content-between align-items-center ${linkClassName}`}>
        <h6 className={classNames('d-inline-block p-0 text-center text-lg-start', style.title, titleClassName)}>
          <span className="position-relative d-inline-block me-lg-3">
            <Icon style={{ transform: `scale(${iconSize === 'sm' ? '1' : '1.5'})` }} className={iconClassName} />
            {notificationNumber !== undefined && (
              <Badge bg="primary" className={`${style.number} rounded-circle position-absolute top-0 start-100`}>
                <small className={`position-absolute top-50 start-50 translate-middle m-0`}>{notificationNumber}</small>
              </Badge>
            )}
          </span>
          <span className={hideTitleOnMobile ? 'd-none d-md-block d-lg-inline' : 'd-block d-lg-inline'}>{title}</span>
        </h6>

        <small className="fw-light text-black d-none d-lg-inline">{description}</small>
      </Component>
      {children}
    </div>
  );
};

export default SidebarItemNotification;
