import { ILoanType, ILoanQuestionsMapType, IReoPropertyType, ICoborrowerInviteType, IStepTitleType, IResidenceType, IEmploymentType, ILiabilityType } from '@rsl/core/src/types';
import {
  emptyLoanApplicationConstant,
  emptyLoanBorrowerConstant,
  QuestionValues,
  emptyReoPropertyConstant,
  emptyEmployerConstant,
  emptyBusinessConstant,
  emptyPreviousEmployerConstant,
  emptyMailingAddressConstant,
  AssetTypes,
  PurchaseStepTitles,
  RefinanceStepTitles,
  emptyResidenceConstant,
  LiabilityTypes,
} from '@rsl/core/src/constants';
import { OwnerTypes } from 'app/types/owner.types';
import { creditAuthSignTimeStamp, eConsentSignTimeStamp, certAuthSignTimeStamp, machineStamp } from './agreeStamps';
import { cloneDeep } from 'lodash';
import { getDate } from '@rsl/core/src/utils/dateUtils';
import {
  reverseYesNo,
  yesNo,
  getSubjectPropertyIndex,
  getResidentialPropertyIndex,
  getOtherPropertyIndex,
  getBorrowerEmploymentIndex,
  getBorrowerSelfEmploymentIndex,
  getCoBorrowerEmploymentIndex,
  getCoBorrowerSelfEmploymentIndex,
  uploadAsset,
  loadAsset,
  noNeedToFillCoborrower,
  isLessThan24Months,
} from './loanMapperUtils';

const mapCoborrowerInvite = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.loanId = loanApplication.loanId;
    values.isCoborrowerInvite = loanApplication.isCoborrowerInvite;

    if (loanApplication.isCoborrowerInvite) {
      if (!loanApplication.borrower.residences?.length) {
        loanApplication.borrower.residences = JSON.parse(JSON.stringify(emptyLoanBorrowerConstant.residences));
      }

      if (!loanApplication.borrower.incomes?.length) {
        loanApplication.borrower.incomes = JSON.parse(JSON.stringify(emptyLoanBorrowerConstant.incomes));
      }

      if (loanApplication.uiCurrentStepTitle === 'Borrower Information') {
        const stepIndex = (
          loanApplication.loanPurposeType === QuestionValues.LoanPurposeTypePurchase
            ? PurchaseStepTitles
            : loanApplication.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance
            ? RefinanceStepTitles
            : []
        ).findIndex((step: IStepTitleType) => step.name === loanApplication.uiCurrentStepTitle);
        loanApplication.uiCurrentStep = stepIndex;
      }
    }
  } else {
    loanApplication.loanId = values.loanId;
    loanApplication.isCoborrowerInvite = values.isCoborrowerInvite;
  }
};

const mapLoanOfficerEmail = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.loanOfficerEmail = loanApplication.loanOfficerEmail;
  } else {
    loanApplication.loanOfficerEmail = values.loanOfficerEmail;
  }
};

const mapReferralSource = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.referralSource = loanApplication.referralSource;
  } else {
    loanApplication.referralSource = values.referralSource;
  }
};

const mapCurrentStep = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.uiCurrentStep = loanApplication.uiCurrentStep;
    values.uiCurrentStepTitle = loanApplication.uiCurrentStepTitle;
  } else {
    loanApplication.uiCurrentStep = values.uiCurrentStep;
    loanApplication.uiCurrentStepTitle = values.uiCurrentStepTitle;
  }
};

const mapLoanPosApplicationId = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.loanPosApplicationId = loanApplication.loanPosApplicationId;
  } else {
    loanApplication.loanPosApplicationId = values.loanPosApplicationId;
  }
};

const mapSharedW0 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedW0_1 = loanApplication.borrower.firstName;
    values.SharedW0_2 = loanApplication.borrower.mobilePhone;
    values.SharedW0_3 = loanApplication.borrower.emailAddressText;
    values.SharedW0_4 = loanApplication.borrower.lastName;
    values.SharedW0_5 = loanApplication.borrower.password;
    values.SharedW0_6 = loanApplication.borrower.confirmPassword;
    values.loanPurposeType = loanApplication.loanPurposeType;
  } else {
    loanApplication.borrower.firstName = values.SharedW0_1;
    loanApplication.borrower.mobilePhone = values.SharedW0_2;
    loanApplication.borrower.emailAddressText = values.SharedW0_3;
    loanApplication.borrower.lastName = values.SharedW0_4;
    loanApplication.borrower.password = values.SharedW0_5;
    loanApplication.borrower.confirmPassword = values.SharedW0_6;
    loanApplication.loanPurposeType = values.loanPurposeType;
  }
};

const mapPurchaseW1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.PurchaseW1_1 = loanApplication.purcahseInProcess;
  } else {
    loanApplication.purcahseInProcess = values.PurchaseW1_1;
  }
};

const mapSharedW2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, subjectPropertyIndex: number) => {
  if (reverse) {
    values.SharedW2_1 = loanApplication.streetAddress;
    values.SharedW2_2 = loanApplication.city;
    values.SharedW2_3 = loanApplication.state;
    values.SharedW2_4 = loanApplication.postalCode;
    values.SharedW2_5 = loanApplication.addressUnitIdentifier;
  } else {
    loanApplication.streetAddress = values.SharedW2_1;
    loanApplication.city = values.SharedW2_2;
    loanApplication.state = values.SharedW2_3;
    loanApplication.postalCode = values.SharedW2_4;
    loanApplication.addressUnitIdentifier = values.SharedW2_5;

    if (loanApplication.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance) {
      loanApplication.reoProperties[subjectPropertyIndex].streetAddress = values.SharedW2_1;
      loanApplication.reoProperties[subjectPropertyIndex].city = values.SharedW2_2;
      loanApplication.reoProperties[subjectPropertyIndex].state = values.SharedW2_3;
      loanApplication.reoProperties[subjectPropertyIndex].postalCode = values.SharedW2_4;
    }
  }
};

const mapSharedW2a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedW2a_1 = loanApplication.city;
    values.SharedW2a_2 = loanApplication.state;
  } else {
    loanApplication.city = values.SharedW2_2 || values.SharedW2a_1;
    loanApplication.state = values.SharedW2_3 || values.SharedW2a_2;
  }
};

const mapSharedW3 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedW3_1 = loanApplication.propertyUsageType;
  } else {
    loanApplication.propertyUsageType = values.SharedW3_1;
  }
};

const mapSharedW4 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedW4_1 = loanApplication.gsePropertyType;
  } else {
    loanApplication.gsePropertyType = values.SharedW4_1;
  }
};

const mapSharedW4a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedW4a_1 = loanApplication.financedNumberOfUnits.toString();
  } else {
    loanApplication.financedNumberOfUnits = values.SharedW4a_1;
  }
};

const mapPurchaseD1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.PurchaseD1_1 = loanApplication.purchasePriceAmount;
  } else {
    loanApplication.purchasePriceAmount = values.PurchaseD1_1;
  }
};

const mapPurchaseD2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.PurchaseD2_1 = loanApplication.downPaymentAmount;
  } else {
    loanApplication.downPaymentAmount = values.PurchaseD2_1;
  }
};

const mapPurchaseD3 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.PurchaseD3_1 = loanApplication.downPaymentType;
  } else {
    loanApplication.downPaymentType = values.PurchaseD3_1;
  }
};

const mapPurchaseD3a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.PurchaseD3a_1 = loanApplication.downPaymentTypeOtherDescription;
  } else {
    loanApplication.downPaymentTypeOtherDescription = values.PurchaseD3a_1;
  }
};

const mapSharedB1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB1_1 = loanApplication.borrower.firstName;
    values.SharedB1_2 = loanApplication.borrower.middleName;
    values.SharedB1_3 = loanApplication.borrower.lastName;
    values.SharedB1_4 = loanApplication.borrower.mobilePhone;
    values.SharedB1_5 = loanApplication.borrower.emailAddressText;
    values.SharedB1_6 = loanApplication.borrower.homePhoneNumber;
  } else {
    loanApplication.borrower.firstName = values.SharedB1_1 || values.SharedW0_1;
    loanApplication.borrower.middleName = values.SharedB1_2;
    loanApplication.borrower.lastName = values.SharedB1_3 || values.SharedW0_4;
    loanApplication.borrower.mobilePhone = values.SharedB1_4 || values.SharedW0_2;
    loanApplication.borrower.emailAddressText = values.SharedB1_5 || values.SharedW0_3;
    loanApplication.borrower.homePhoneNumber = values.SharedB1_6;
  }
};

const mapSharedB1b = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, residentialPropertyIndex: number) => {
  if (reverse) {
    values.SharedB1b_1 = loanApplication.borrower.residences[0].addressStreetLine1;
    values.SharedB1b_2 = loanApplication.borrower.residences[0].addressCity;
    values.SharedB1b_3 = loanApplication.borrower.residences[0].addressState;
    values.SharedB1b_4 = loanApplication.borrower.residences[0].addressPostalCode;
    values.SharedB1b_5 = loanApplication.borrower.residences[0].unitNumber;
  } else {
    loanApplication.borrower.residences[0].addressStreetLine1 = values.SharedB1b_1;
    loanApplication.borrower.residences[0].addressCity = values.SharedB1b_2;
    loanApplication.borrower.residences[0].addressState = values.SharedB1b_3;
    loanApplication.borrower.residences[0].addressPostalCode = values.SharedB1b_4;
    loanApplication.borrower.residences[0].unitNumber = values.SharedB1b_5;

    if (residentialPropertyIndex !== -1) {
      loanApplication.reoProperties[residentialPropertyIndex].streetAddress = values.SharedB1b_1;
      loanApplication.reoProperties[residentialPropertyIndex].city = values.SharedB1b_2;
      loanApplication.reoProperties[residentialPropertyIndex].state = values.SharedB1b_3;
      loanApplication.reoProperties[residentialPropertyIndex].postalCode = values.SharedB1b_4;
    }
  }
};

const mapSharedB1c = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB1c_1 = loanApplication.borrower.residences[0].residencyBasisType;
  } else {
    loanApplication.borrower.residences[0].residencyBasisType = values.SharedB1c_1;

    const hasResidentialPropertyRefinance =
      values.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance &&
      values.SharedW3_1 !== QuestionValues.HomeIntendPrimary &&
      values.SharedB1c_1 === QuestionValues.CurrentlyOwn;
    const hasResidentialPropertyPurchase = values.loanPurposeType === QuestionValues.LoanPurposeTypePurchase && values.SharedB1c_1 === QuestionValues.CurrentlyOwn;

    if ((hasResidentialPropertyRefinance || hasResidentialPropertyPurchase) && getResidentialPropertyIndex(loanApplication) === -1) {
      loanApplication.reoProperties = [...loanApplication.reoProperties, { ...emptyReoPropertyConstant, type: 'ResidentialProperty' }];
    } else if (!hasResidentialPropertyRefinance && !hasResidentialPropertyPurchase) {
      loanApplication.reoProperties = loanApplication.reoProperties.filter((reoProperty: IReoPropertyType) => reoProperty.type !== 'ResidentialProperty');
    }
  }
};

const mapSharedB1d = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB1d_1 = loanApplication.borrower.residences[0].durationTermYears;
    values.SharedB1d_2 = loanApplication.borrower.residences[0].durationTermMonths;
  } else {
    loanApplication.borrower.residences[0].durationTermYears = values.SharedB1d_1;
    loanApplication.borrower.residences[0].durationTermMonths = values.SharedB1d_2;
  }
};

const mapSharedB1e = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB1e_1 = reverseYesNo.get(loanApplication.borrower.firstTimeHomeBuyer) ?? null;
  } else {
    loanApplication.borrower.firstTimeHomeBuyer = yesNo.get(values.SharedB1e_1) ?? null;
  }
};

const mapSharedB1f = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const previousAddressKeys = ['SharedB1fa', 'SharedB1fb', 'SharedB1fc'];
  const previousAddress = loanApplication.borrower.residences.filter((residence: IResidenceType) => residence.residencyType === 'Prior');

  if (reverse) {
    previousAddress.forEach((residence: IResidenceType, index: number) => {
      (values as any)[`${previousAddressKeys[index]}_1`] = residence.addressStreetLine1;
      (values as any)[`${previousAddressKeys[index]}_2`] = residence.addressCity;
      (values as any)[`${previousAddressKeys[index]}_3`] = residence.addressState;
      (values as any)[`${previousAddressKeys[index]}_4`] = residence.addressPostalCode;
      (values as any)[`${previousAddressKeys[index]}_5`] = residence.unitNumber;
      (values as any)[`${previousAddressKeys[index]}_6`] = residence.residencyBasisType;
      (values as any)[`${previousAddressKeys[index]}_7`] = residence.durationTermYears;
      (values as any)[`${previousAddressKeys[index]}_8`] = residence.durationTermMonths;
    });
  } else {
    if (previousAddress.length) {
      loanApplication.borrower.residences = loanApplication.borrower.residences.filter((residence: IResidenceType) => residence.residencyType !== 'Prior');
    }

    let newPreviousEmployments: IResidenceType[] = [];
    previousAddressKeys.forEach((key: string) => {
      let previousAddress = cloneDeep(emptyResidenceConstant);
      previousAddress.addressStreetLine1 = (values as any)[`${key}_1`];
      previousAddress.addressCity = (values as any)[`${key}_2`];
      previousAddress.addressState = (values as any)[`${key}_3`];
      previousAddress.addressPostalCode = (values as any)[`${key}_4`];
      previousAddress.unitNumber = (values as any)[`${key}_5`];
      previousAddress.residencyBasisType = (values as any)[`${key}_6`];
      previousAddress.durationTermYears = (values as any)[`${key}_7`];
      previousAddress.durationTermMonths = (values as any)[`${key}_8`];
      previousAddress.residencyType = 'Prior';

      if (!!(values as any)[`${key}_1`] && isLessThan24Months(values.SharedB1d_1, values.SharedB1d_2)) {
        newPreviousEmployments.push(previousAddress);
      }
    });

    loanApplication.borrower.residences = [...loanApplication.borrower.residences, ...newPreviousEmployments];
  }
};

const mapSharedB1i = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB1i_1 = loanApplication.borrower.residences[0].rent;
  } else {
    loanApplication.borrower.residences[0].rent = values.SharedB1i_1;
  }
};

const mapSharedB1j = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB1j_1 = !loanApplication.borrower.mailingAddressSameAsPresentIndicator ? loanApplication.borrower.mailingAddress?.addressStreetLine1 : '';
    values.SharedB1j_2 = !loanApplication.borrower.mailingAddressSameAsPresentIndicator ? loanApplication.borrower.mailingAddress?.addressCity : '';
    values.SharedB1j_3 = !loanApplication.borrower.mailingAddressSameAsPresentIndicator ? loanApplication.borrower.mailingAddress?.addressState : '';
    values.SharedB1j_4 = !loanApplication.borrower.mailingAddressSameAsPresentIndicator ? loanApplication.borrower.mailingAddress?.addressPostalCode : '';
    values.SharedB1j_5 = !loanApplication.borrower.mailingAddressSameAsPresentIndicator ? loanApplication.borrower.mailingAddress?.addressUnitIdentifier : '';
    values.SharedB1j_6 = loanApplication.borrower.mailingAddressSameAsPresentIndicator;
  } else {
    loanApplication.borrower.mailingAddressSameAsPresentIndicator = values.SharedB1j_6;

    if (loanApplication.borrower.mailingAddressSameAsPresentIndicator) {
      loanApplication.borrower.mailingAddress = null;
    } else {
      if (!loanApplication.borrower.mailingAddress) {
        loanApplication.borrower.mailingAddress = cloneDeep(emptyMailingAddressConstant);
      }

      loanApplication.borrower.mailingAddress.addressStreetLine1 = values.SharedB1j_1;
      loanApplication.borrower.mailingAddress.addressCity = values.SharedB1j_2;
      loanApplication.borrower.mailingAddress.addressState = values.SharedB1j_3;
      loanApplication.borrower.mailingAddress.addressPostalCode = values.SharedB1j_4;
      loanApplication.borrower.mailingAddress.addressUnitIdentifier = values.SharedB1j_5;
    }
  }
};

const mapSharedB2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB2_1 = loanApplication.borrower.middleCreditScore ? loanApplication.borrower.middleCreditScore.toString() : undefined;
  } else {
    loanApplication.borrower.middleCreditScore = values.SharedB2_1 ?? null;
  }
};

const mapSharedB3 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB3_1 = loanApplication.borrower.maritalStatusType;
  } else {
    loanApplication.borrower.maritalStatusType = values.SharedB3_1;
  }
};

const mapSharedB4 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB4_1 = loanApplication.borrower.dependentCount;
  } else {
    loanApplication.borrower.dependentCount = values.SharedB4_1;
  }
};

const mapSharedB4a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB4a_1 = loanApplication.borrower.dependentsAgesDescription;
  } else {
    loanApplication.borrower.dependentsAgesDescription = values.SharedB4a_1;
  }
};

const mapSharedB5 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB5_1 = reverseYesNo.get(loanApplication.borrower.selfDeclaredMilitaryServiceIndicator) ?? null;
  } else {
    loanApplication.borrower.selfDeclaredMilitaryServiceIndicator = yesNo.get(values.SharedB5_1) ?? null;
  }
};

const mapSharedB5a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB5a_1 = loanApplication.borrower.militaryServiceStatusType;
  } else {
    loanApplication.borrower.militaryServiceStatusType = values.SharedB5a_1;
  }
};

const mapSharedB5c = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB5c_1 = reverseYesNo.get(loanApplication.borrower.hadVaLoanPreviously) ?? null;
  } else {
    loanApplication.borrower.hadVaLoanPreviously = yesNo.get(values.SharedB5c_1) ?? null;
  }
};

const mapSharedB6a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedB6a_ = loanApplication.borrower.uiShowSalaryIncome;
    values.SharedB6a_1 = loanApplication.uiBorrowerEmploymentIndex;
    values.employment = loanApplication.borrower.employment;
  } else {
    loanApplication.borrower.uiShowSalaryIncome = values.ShowSharedB6a_;
    loanApplication.uiBorrowerEmploymentIndex = values.SharedB6a_1;
    loanApplication.borrower.incomes[0].amount = values.employment.reduce(
      (sum: number, employment: IEmploymentType) =>
        !!employment.currentEmploymentIndicator && !employment.selfEmployedIndicator
          ? sum + (employment.basePayAmount ?? 0) + (employment.commissionsAmount ?? 0) + (employment.bonusAmount ?? 0) + (employment.overtimeAmount ?? 0)
          : sum,
      0
    );

    if (
      typeof loanApplication.uiBorrowerEmploymentIndex === 'number' &&
      loanApplication.uiBorrowerEmploymentIndex !== -1 &&
      values.employment[loanApplication.uiBorrowerEmploymentIndex] === undefined
    ) {
      loanApplication.borrower.employment = [...values.employment, cloneDeep(emptyEmployerConstant)];
    } else {
      loanApplication.borrower.employment = values.employment;
    }
  }
};

const mapSharedB6b = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedB6b_ = loanApplication.borrower.uiShowSelfEmploymentIncome;
    values.SharedB6b_1 = loanApplication.uiBorrowerSelfEmploymentIndex;
    values.employment = loanApplication.borrower.employment;
  } else {
    loanApplication.borrower.uiShowSelfEmploymentIncome = values.ShowSharedB6b_;
    loanApplication.uiBorrowerSelfEmploymentIndex = values.SharedB6b_1;
    loanApplication.borrower.incomes[1].amount = values.employment.reduce(
      (sum: number, employment: IEmploymentType) => (!employment.currentEmploymentIndicator && !!employment.selfEmployedIndicator ? sum + (employment.basePayAmount ?? 0) : sum),
      0
    );

    if (
      typeof loanApplication.uiBorrowerSelfEmploymentIndex === 'number' &&
      loanApplication.uiBorrowerSelfEmploymentIndex !== -1 &&
      values.employment[loanApplication.uiBorrowerSelfEmploymentIndex] === undefined
    ) {
      loanApplication.borrower.employment = [...values.employment, cloneDeep(emptyBusinessConstant)];
    } else if (!!loanApplication.borrower.employment.length && !!loanApplication.borrower.employment[loanApplication.borrower.employment.length - 1].employerName) {
      // Above if does check if is adding a new current employment to avoid resetting
      loanApplication.borrower.employment = values.employment;
    }
  }
};

const mapSharedB6c = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedB6c_ = loanApplication.borrower.uiShowSocialSecurityIncome;
    values.SharedB6c_1 = loanApplication.borrower.incomes[2].amount;
    values.SharedB6c_2 = loanApplication.borrower.incomes[3].amount;
    values.SharedB6c_3 = loanApplication.borrower.incomes[4].amount;
  } else {
    loanApplication.borrower.uiShowSocialSecurityIncome = values.ShowSharedB6c_;
    loanApplication.borrower.incomes[2].amount = values.SharedB6c_1;
    loanApplication.borrower.incomes[3].amount = values.SharedB6c_2;
    loanApplication.borrower.incomes[4].amount = values.SharedB6c_3;
  }
};

const mapSharedB6d = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedB6d_ = loanApplication.borrower.uiShowOtherIncome;
    values.SharedB6d_1 = loanApplication.borrower.incomes[5].amount;
    values.SharedB6d_2 = loanApplication.borrower.incomes[6].amount;
    values.SharedB6d_3 = loanApplication.borrower.incomes[7].amount;
  } else {
    loanApplication.borrower.uiShowOtherIncome = values.ShowSharedB6d_;
    loanApplication.borrower.incomes[5].amount = values.SharedB6d_1;
    loanApplication.borrower.incomes[6].amount = values.SharedB6d_2;
    loanApplication.borrower.incomes[7].amount = values.SharedB6d_3;
  }
};

const mapSharedB6f = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedB6f_ = loanApplication.borrower.uiShowNoIncome;
  } else {
    loanApplication.borrower.uiShowNoIncome = values.ShowSharedB6f_;
  }
};

const mapSharedE1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, borrowerEmploymentIndex: number) => {
  if (reverse) {
    values.SharedE1_1 = loanApplication.borrower.employment[borrowerEmploymentIndex].employerName;
    values.SharedE1_2 = loanApplication.borrower.employment[borrowerEmploymentIndex].addressStreetLine1;
    values.SharedE1_3 = loanApplication.borrower.employment[borrowerEmploymentIndex].unitNumber;
    values.SharedE1_4 = loanApplication.borrower.employment[borrowerEmploymentIndex].addressCity;
    values.SharedE1_5 = loanApplication.borrower.employment[borrowerEmploymentIndex].addressState;
    values.SharedE1_6 = loanApplication.borrower.employment[borrowerEmploymentIndex].addressPostalCode;
    values.SharedE1_7 = loanApplication.borrower.employment[borrowerEmploymentIndex].phoneNumber;
    values.SharedE1_8 = loanApplication.borrower.employment[borrowerEmploymentIndex].positionDescription;
    values.SharedE1_9 = getDate(loanApplication.borrower.employment[borrowerEmploymentIndex].employmentStartDate);
    values.SharedE1_10 = loanApplication.borrower.employment[borrowerEmploymentIndex].timeInLineOfWorkYears;
    values.SharedE1_11 = loanApplication.borrower.employment[borrowerEmploymentIndex].basePayAmount;
    values.SharedE1_12 = loanApplication.borrower.employment[borrowerEmploymentIndex].overtimeAmount;
    values.SharedE1_13 = loanApplication.borrower.employment[borrowerEmploymentIndex].commissionsAmount;
    values.SharedE1_14 = loanApplication.borrower.employment[borrowerEmploymentIndex].bonusAmount;
    values.SharedE1_15 = loanApplication.borrower.employment[borrowerEmploymentIndex].timeInLineOfWorkMonths;
  } else {
    loanApplication.borrower.employment[borrowerEmploymentIndex].employerName = values.SharedE1_1;
    loanApplication.borrower.employment[borrowerEmploymentIndex].addressStreetLine1 = values.SharedE1_2;
    loanApplication.borrower.employment[borrowerEmploymentIndex].unitNumber = values.SharedE1_3;
    loanApplication.borrower.employment[borrowerEmploymentIndex].addressCity = values.SharedE1_4;
    loanApplication.borrower.employment[borrowerEmploymentIndex].addressState = values.SharedE1_5;
    loanApplication.borrower.employment[borrowerEmploymentIndex].addressPostalCode = values.SharedE1_6;
    loanApplication.borrower.employment[borrowerEmploymentIndex].phoneNumber = values.SharedE1_7;
    loanApplication.borrower.employment[borrowerEmploymentIndex].positionDescription = values.SharedE1_8;
    loanApplication.borrower.employment[borrowerEmploymentIndex].employmentStartDate = values.SharedE1_9;
    loanApplication.borrower.employment[borrowerEmploymentIndex].timeInLineOfWorkYears = values.SharedE1_10;
    loanApplication.borrower.employment[borrowerEmploymentIndex].basePayAmount = values.SharedE1_11;
    loanApplication.borrower.employment[borrowerEmploymentIndex].overtimeAmount = values.SharedE1_12;
    loanApplication.borrower.employment[borrowerEmploymentIndex].commissionsAmount = values.SharedE1_13;
    loanApplication.borrower.employment[borrowerEmploymentIndex].bonusAmount = values.SharedE1_14;
    loanApplication.borrower.employment[borrowerEmploymentIndex].timeInLineOfWorkMonths = values.SharedE1_15;
  }
};

const mapSharedE3 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const previousEmploymentKeys = ['SharedE3a', 'SharedE3b', 'SharedE3c'];
  const previousEmployment = loanApplication.borrower.employment.filter(
    (employment: IEmploymentType) => !employment.currentEmploymentIndicator && !employment.selfEmployedIndicator
  );

  if (reverse) {
    previousEmployment.forEach((employment: IEmploymentType, index: number) => {
      (values as any)[`${previousEmploymentKeys[index]}_1`] = employment.employerName;
      (values as any)[`${previousEmploymentKeys[index]}_2`] = employment.addressStreetLine1;
      (values as any)[`${previousEmploymentKeys[index]}_3`] = employment.unitNumber;
      (values as any)[`${previousEmploymentKeys[index]}_4`] = employment.addressCity;
      (values as any)[`${previousEmploymentKeys[index]}_5`] = employment.addressState;
      (values as any)[`${previousEmploymentKeys[index]}_6`] = employment.addressPostalCode;
      (values as any)[`${previousEmploymentKeys[index]}_7`] = employment.phoneNumber;
      (values as any)[`${previousEmploymentKeys[index]}_8`] = employment.positionDescription;
      (values as any)[`${previousEmploymentKeys[index]}_9`] = getDate(employment.employmentStartDate);
      (values as any)[`${previousEmploymentKeys[index]}_10`] = employment.timeInLineOfWorkYears;
      (values as any)[`${previousEmploymentKeys[index]}_11`] = getDate(employment.employmentEndDate);
      (values as any)[`${previousEmploymentKeys[index]}_12`] = reverseYesNo.get(employment.uiHasPreviousEmployment) ?? null;
      (values as any)[`${previousEmploymentKeys[index]}_13`] = employment.timeInLineOfWorkMonths;
    });
  } else {
    if (previousEmployment.length) {
      loanApplication.borrower.employment = loanApplication.borrower.employment.filter(
        (employment: IEmploymentType) => employment.currentEmploymentIndicator || employment.selfEmployedIndicator
      );
    }

    let newPreviousEmployments: IEmploymentType[] = [];
    previousEmploymentKeys.forEach((key: string) => {
      let previousEmployment = cloneDeep(emptyPreviousEmployerConstant);
      previousEmployment.employerName = (values as any)[`${key}_1`];
      previousEmployment.addressStreetLine1 = (values as any)[`${key}_2`];
      previousEmployment.unitNumber = (values as any)[`${key}_3`];
      previousEmployment.addressCity = (values as any)[`${key}_4`];
      previousEmployment.addressState = (values as any)[`${key}_5`];
      previousEmployment.addressPostalCode = (values as any)[`${key}_6`];
      previousEmployment.phoneNumber = (values as any)[`${key}_7`];
      previousEmployment.positionDescription = (values as any)[`${key}_8`];
      previousEmployment.employmentStartDate = (values as any)[`${key}_9`];
      previousEmployment.timeInLineOfWorkYears = (values as any)[`${key}_10`];
      previousEmployment.employmentEndDate = (values as any)[`${key}_11`];
      previousEmployment.uiHasPreviousEmployment = yesNo.get((values as any)[`${key}_12`]) ?? null;
      previousEmployment.timeInLineOfWorkMonths = (values as any)[`${key}_13`];

      if (!!(values as any)[`${key}_12`]) {
        newPreviousEmployments.push(previousEmployment);
      }
    });

    loanApplication.borrower.employment = [...loanApplication.borrower.employment, ...newPreviousEmployments];
  }
};

const mapSharedE2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, borrowerSelfEmploymentIndex: number) => {
  if (reverse) {
    values.SharedE2_1 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].employerName;
    values.SharedE2_2 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].addressStreetLine1;
    values.SharedE2_3 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].unitNumber;
    values.SharedE2_4 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].addressCity;
    values.SharedE2_5 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].addressState;
    values.SharedE2_6 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].addressPostalCode;
    values.SharedE2_7 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].phoneNumber;
    values.SharedE2_8 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].positionDescription;
    values.SharedE2_9 = getDate(loanApplication.borrower.employment[borrowerSelfEmploymentIndex].employmentStartDate);
    values.SharedE2_10 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].timeInLineOfWorkYears;
    values.SharedE2_11 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].percentOwn;
    values.SharedE2_12 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].basePayAmount;
    values.SharedE2_13 = loanApplication.borrower.employment[borrowerSelfEmploymentIndex].timeInLineOfWorkMonths;
  } else {
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].employerName = values.SharedE2_1;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].addressStreetLine1 = values.SharedE2_2;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].unitNumber = values.SharedE2_3;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].addressCity = values.SharedE2_4;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].addressState = values.SharedE2_5;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].addressPostalCode = values.SharedE2_6;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].phoneNumber = values.SharedE2_7;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].positionDescription = values.SharedE2_8;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].employmentStartDate = values.SharedE2_9;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].timeInLineOfWorkYears = values.SharedE2_10;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].percentOwn = values.SharedE2_11;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].basePayAmount = values.SharedE2_12;
    loanApplication.borrower.employment[borrowerSelfEmploymentIndex].timeInLineOfWorkMonths = values.SharedE2_13;
  }
};

const mapSharedB7 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB7_1 =
      loanApplication.hasCoborrower == null
        ? null
        : loanApplication.hasCoborrower || loanApplication.hasCoborrowerInvite
        ? QuestionValues.ApplyingWithPerson
        : QuestionValues.ApplyingMyOwn;
  } else {
    loanApplication.hasCoborrower = values.SharedB7_1 == null ? null : !noNeedToFillCoborrower(values);
    loanApplication.coborrower = loanApplication.hasCoborrower ? { ...JSON.parse(JSON.stringify(emptyLoanBorrowerConstant)), isBorrower: false } : undefined;
  }
};

const mapSharedB8 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB8_1 = reverseYesNo.get(loanApplication.jointAssetLiabilityReportingIndicator) ?? null;
  } else {
    loanApplication.jointAssetLiabilityReportingIndicator = yesNo.get(values.SharedB8_1) ?? null;
  }
};

const mapSharedB8a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB8a_1 = reverseYesNo.get(loanApplication.hasCoborrowerInvite) ?? null;
  } else {
    loanApplication.hasCoborrowerInvite = yesNo.get(values.SharedB8a_1) ?? null;
    loanApplication.coborrowerInvite = values.SharedB8a_1 === QuestionValues.Yes && !loanApplication.coborrowerInvite ? ({} as ICoborrowerInviteType) : undefined;
  }
};

const mapSharedB8b = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB8b_1 = loanApplication.coborrowerInvite?.firstName;
    values.SharedB8b_2 = loanApplication.coborrowerInvite?.middleName;
    values.SharedB8b_3 = loanApplication.coborrowerInvite?.lastName;
    values.SharedB8b_4 = loanApplication.coborrowerInvite?.mobilePhone;
    values.SharedB8b_5 = loanApplication.coborrowerInvite?.emailAddressText;
    values.SharedB8b_6 = loanApplication.coborrowerInvite?.homePhoneNumber;
  } else if (loanApplication.coborrowerInvite) {
    loanApplication.coborrowerInvite.firstName = values.SharedB8b_1;
    loanApplication.coborrowerInvite.middleName = values.SharedB8b_2;
    loanApplication.coborrowerInvite.lastName = values.SharedB8b_3;
    loanApplication.coborrowerInvite.mobilePhone = values.SharedB8b_4;
    loanApplication.coborrowerInvite.emailAddressText = values.SharedB8b_5;
    loanApplication.coborrowerInvite.homePhoneNumber = values.SharedB8b_6;
  }
};

const mapSharedL1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    (loanApplication.borrower.otherLiabilities ?? []).forEach((liability: ILiabilityType) => {
      if (liability.liabilityOrExpenseType === LiabilityTypes.Alimony) {
        values.ShowSharedL1a_ = true;
        values.SharedL1a_1 = liability.amount;
      }

      if (liability.liabilityOrExpenseType === LiabilityTypes.ChildSupport) {
        values.ShowSharedL1b_ = true;
        values.SharedL1b_1 = liability.amount;
      }

      if (liability.liabilityOrExpenseType === LiabilityTypes.SeparateMaintenanceExpense) {
        values.ShowSharedL1c_ = true;
        values.SharedL1c_1 = liability.amount;
      }

      if (liability.liabilityOrExpenseType === LiabilityTypes.Other) {
        values.ShowSharedL1d_ = true;
        values.SharedL1d_1 = liability.otherDescription;
        values.SharedL1d_2 = liability.amount;
        values.SharedL1d_3 = liability.owner;
      }
    });
  } else {
    const liabilities: ILiabilityType[] = [];

    if (values.ShowSharedL1a_) {
      liabilities.push({
        owner: OwnerTypes.Borrower,
        amount: values.SharedL1a_1,
        liabilityOrExpenseType: LiabilityTypes.Alimony,
      });
    }

    if (values.ShowSharedL1b_) {
      liabilities.push({
        owner: OwnerTypes.Borrower,
        amount: values.SharedL1b_1,
        liabilityOrExpenseType: LiabilityTypes.ChildSupport,
      });
    }

    if (values.ShowSharedL1c_) {
      liabilities.push({
        owner: OwnerTypes.Borrower,
        amount: values.SharedL1c_1,
        liabilityOrExpenseType: LiabilityTypes.SeparateMaintenanceExpense,
      });
    }

    if (values.ShowSharedL1d_) {
      liabilities.push({
        owner: noNeedToFillCoborrower(values) ? OwnerTypes.Borrower : values.SharedL1d_3,
        amount: values.SharedL1d_2,
        liabilityOrExpenseType: LiabilityTypes.Other,
        otherDescription: values.SharedL1d_1,
      });
    }

    loanApplication.borrower.otherLiabilities = cloneDeep(liabilities);
  }
};

const mapSharedB10 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB10_1 = reverseYesNo.get(loanApplication.borrower.ownershipEducationConfirmationIndicator) ?? null;
  } else {
    loanApplication.borrower.ownershipEducationConfirmationIndicator = yesNo.get(values.SharedB10_1) ?? null;
  }
};

const mapSharedB11 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB11_1 = reverseYesNo.get(loanApplication.borrower.ownershipConfirmationIndicator) ?? null;
  } else {
    loanApplication.borrower.ownershipConfirmationIndicator = yesNo.get(values.SharedB11_1) ?? null;
  }
};

const mapSharedB12 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedB12_1 = loanApplication.borrower.languagePreference;
    values.SharedB12_2 = loanApplication.borrower.languageCodeOtherDescription;
  } else {
    loanApplication.borrower.languagePreference = values.SharedB12_1;
    loanApplication.borrower.languageCodeOtherDescription = values.SharedB12_2;
  }
};

const mapSharedC1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC1_1 = loanApplication.coborrower ? loanApplication.coborrower.firstName : undefined;
    values.SharedC1_2 = loanApplication.coborrower ? loanApplication.coborrower.middleName : undefined;
    values.SharedC1_3 = loanApplication.coborrower ? loanApplication.coborrower.lastName : undefined;
    values.SharedC1_4 = loanApplication.coborrower ? loanApplication.coborrower.mobilePhone : undefined;
    values.SharedC1_5 = loanApplication.coborrower ? loanApplication.coborrower.emailAddressText : undefined;
    values.SharedC1_6 = loanApplication.coborrower ? loanApplication.coborrower.homePhoneNumber : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.firstName = values.SharedC1_1;
    loanApplication.coborrower.middleName = values.SharedC1_2;
    loanApplication.coborrower.lastName = values.SharedC1_3;
    loanApplication.coborrower.mobilePhone = values.SharedC1_4;
    loanApplication.coborrower.emailAddressText = values.SharedC1_5;
    loanApplication.coborrower.homePhoneNumber = values.SharedC1_6;
  }
};

const mapSharedC1a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC1a_1 = loanApplication.coborrower ? loanApplication.coborrower.uiCopyAddressFromBorrower : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiCopyAddressFromBorrower = values.SharedC1a_1;
  }
};

const mapSharedC1b = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC1b_1 = loanApplication.coborrower ? loanApplication.coborrower.residences[0].addressStreetLine1 : undefined;
    values.SharedC1b_2 = loanApplication.coborrower ? loanApplication.coborrower.residences[0].addressCity : undefined;
    values.SharedC1b_3 = loanApplication.coborrower ? loanApplication.coborrower.residences[0].addressState : undefined;
    values.SharedC1b_4 = loanApplication.coborrower ? loanApplication.coborrower.residences[0].addressPostalCode : undefined;
    values.SharedC1b_5 = loanApplication.coborrower ? loanApplication.coborrower.residences[0].unitNumber : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.residences[0].addressStreetLine1 = values.SharedC1b_1;
    loanApplication.coborrower.residences[0].addressCity = values.SharedC1b_2;
    loanApplication.coborrower.residences[0].addressState = values.SharedC1b_3;
    loanApplication.coborrower.residences[0].addressPostalCode = values.SharedC1b_4;
    loanApplication.coborrower.residences[0].unitNumber = values.SharedC1b_5;
  }
};

const mapSharedC1c = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC1c_1 = loanApplication.coborrower ? loanApplication.coborrower.residences[0].residencyBasisType : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.residences[0].residencyBasisType = values.SharedC1c_1;
  }
};

const mapSharedC1d = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC1d_1 = loanApplication.coborrower ? loanApplication.coborrower.residences[0].durationTermYears : undefined;
    values.SharedC1d_2 = loanApplication.coborrower ? loanApplication.coborrower.residences[0].durationTermMonths : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.residences[0].durationTermYears = values.SharedC1d_1;
    loanApplication.coborrower.residences[0].durationTermMonths = values.SharedC1d_2;
  }
};

const mapSharedC1e = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC1e_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.firstTimeHomeBuyer) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.firstTimeHomeBuyer = yesNo.get(values.SharedC1e_1) ?? null;
  }
};

const mapSharedC1f = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const previousAddressKeys = ['SharedC1fa', 'SharedC1fb', 'SharedC1fc'];
  const previousAddress = (loanApplication.coborrower?.residences ?? []).filter((residence: IResidenceType) => residence.residencyType === 'Prior');

  if (reverse) {
    previousAddress.forEach((residence: IResidenceType, index: number) => {
      (values as any)[`${previousAddressKeys[index]}_1`] = loanApplication.coborrower ? residence.addressStreetLine1 : undefined;
      (values as any)[`${previousAddressKeys[index]}_2`] = loanApplication.coborrower ? residence.addressCity : undefined;
      (values as any)[`${previousAddressKeys[index]}_3`] = loanApplication.coborrower ? residence.addressState : undefined;
      (values as any)[`${previousAddressKeys[index]}_4`] = loanApplication.coborrower ? residence.addressPostalCode : undefined;
      (values as any)[`${previousAddressKeys[index]}_5`] = loanApplication.coborrower ? residence.unitNumber : undefined;
      (values as any)[`${previousAddressKeys[index]}_6`] = loanApplication.coborrower ? residence.residencyBasisType : undefined;
      (values as any)[`${previousAddressKeys[index]}_7`] = loanApplication.coborrower ? residence.durationTermYears : undefined;
      (values as any)[`${previousAddressKeys[index]}_8`] = loanApplication.coborrower ? residence.durationTermMonths : undefined;
    });
  } else if (loanApplication.coborrower) {
    if (previousAddress.length) {
      loanApplication.coborrower.residences = loanApplication.coborrower.residences.filter((residence: IResidenceType) => residence.residencyType !== 'Prior');
    }

    let newPreviousEmployments: IResidenceType[] = [];
    previousAddressKeys.forEach((key: string) => {
      let previousAddress = cloneDeep(emptyResidenceConstant);
      previousAddress.addressStreetLine1 = (values as any)[`${key}_1`];
      previousAddress.addressCity = (values as any)[`${key}_2`];
      previousAddress.addressState = (values as any)[`${key}_3`];
      previousAddress.addressPostalCode = (values as any)[`${key}_4`];
      previousAddress.unitNumber = (values as any)[`${key}_5`];
      previousAddress.residencyBasisType = (values as any)[`${key}_6`];
      previousAddress.durationTermYears = (values as any)[`${key}_7`];
      previousAddress.durationTermMonths = (values as any)[`${key}_8`];
      previousAddress.residencyType = 'Prior';

      if (!!(values as any)[`${key}_1`] && isLessThan24Months(values.SharedC1d_1, values.SharedC1d_2)) {
        newPreviousEmployments.push(previousAddress);
      }
    });

    loanApplication.coborrower.residences = [...loanApplication.coborrower.residences, ...newPreviousEmployments];
  }
};

const mapSharedC1i = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC1i_1 = loanApplication.coborrower ? loanApplication.coborrower.residences[0].rent : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.residences[0].rent = values.SharedC1i_1;
  }
};

const mapSharedC1j = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC1j_1 = loanApplication.coborrower
      ? !loanApplication.coborrower.mailingAddressSameAsPresentIndicator
        ? loanApplication.coborrower.mailingAddress?.addressStreetLine1
        : ''
      : undefined;
    values.SharedC1j_2 = loanApplication.coborrower
      ? !loanApplication.coborrower.mailingAddressSameAsPresentIndicator
        ? loanApplication.coborrower.mailingAddress?.addressCity
        : ''
      : undefined;
    values.SharedC1j_3 = loanApplication.coborrower
      ? !loanApplication.coborrower.mailingAddressSameAsPresentIndicator
        ? loanApplication.coborrower.mailingAddress?.addressState
        : ''
      : undefined;
    values.SharedC1j_4 = loanApplication.coborrower
      ? !loanApplication.coborrower.mailingAddressSameAsPresentIndicator
        ? loanApplication.coborrower.mailingAddress?.addressPostalCode
        : ''
      : undefined;
    values.SharedC1j_5 = loanApplication.coborrower
      ? !loanApplication.coborrower.mailingAddressSameAsPresentIndicator
        ? loanApplication.coborrower.mailingAddress?.addressUnitIdentifier
        : ''
      : undefined;
    values.SharedC1j_6 = loanApplication.coborrower ? loanApplication.coborrower.mailingAddressSameAsPresentIndicator : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.mailingAddressSameAsPresentIndicator = values.SharedC1j_6;

    if (loanApplication.coborrower.mailingAddressSameAsPresentIndicator) {
      loanApplication.coborrower.mailingAddress = null;
    } else {
      if (!loanApplication.coborrower.mailingAddress) {
        loanApplication.coborrower.mailingAddress = cloneDeep(emptyMailingAddressConstant);
      }

      loanApplication.coborrower.mailingAddress.addressStreetLine1 = values.SharedC1j_1;
      loanApplication.coborrower.mailingAddress.addressCity = values.SharedC1j_2;
      loanApplication.coborrower.mailingAddress.addressState = values.SharedC1j_3;
      loanApplication.coborrower.mailingAddress.addressPostalCode = values.SharedC1j_4;
      loanApplication.coborrower.mailingAddress.addressUnitIdentifier = values.SharedC1j_5;
    }
  }
};

const mapSharedC2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC2_1 = loanApplication.coborrower
      ? loanApplication.coborrower.middleCreditScore
        ? loanApplication.coborrower.middleCreditScore.toString()
        : undefined
      : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.middleCreditScore = values.SharedC2_1 ?? null;
  }
};

const mapSharedC3 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC3_1 = loanApplication.coborrower ? loanApplication.coborrower.maritalStatusType : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.maritalStatusType = values.SharedC3_1;
  }
};

const mapSharedC4 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC4_1 = loanApplication.coborrower ? loanApplication.coborrower.dependentCount : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.dependentCount = values.SharedC4_1;
  }
};

const mapSharedC4a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC4a_1 = loanApplication.coborrower ? loanApplication.coborrower.dependentsAgesDescription : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.dependentsAgesDescription = values.SharedC4a_1;
  }
};

const mapSharedC5 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC5_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.selfDeclaredMilitaryServiceIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.selfDeclaredMilitaryServiceIndicator = yesNo.get(values.SharedC5_1) ?? null;
  }
};

const mapSharedC5a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC5a_1 = loanApplication.coborrower ? loanApplication.coborrower.militaryServiceStatusType : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.militaryServiceStatusType = values.SharedC5a_1;
  }
};

const mapSharedC5c = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC5c_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.hadVaLoanPreviously) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.hadVaLoanPreviously = yesNo.get(values.SharedC5c_1) ?? null;
  }
};

const mapSharedC6a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedC6a_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowSalaryIncome : undefined;
    values.SharedC6a_1 = loanApplication.coborrower ? loanApplication.uiCoBorrowerEmploymentIndex : undefined;
    values.coborrowerEmployment = loanApplication.coborrower ? loanApplication.coborrower.employment : [];
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowSalaryIncome = values.ShowSharedC6a_;
    loanApplication.uiCoBorrowerEmploymentIndex = values.SharedC6a_1;
    loanApplication.coborrower.incomes[0].amount = values.coborrowerEmployment.reduce(
      (sum: number, employment: IEmploymentType) =>
        !!employment.currentEmploymentIndicator && !employment.selfEmployedIndicator
          ? sum + (employment.basePayAmount ?? 0) + (employment.commissionsAmount ?? 0) + (employment.bonusAmount ?? 0) + (employment.overtimeAmount ?? 0)
          : sum,
      0
    );

    if (
      typeof loanApplication.uiCoBorrowerEmploymentIndex === 'number' &&
      loanApplication.uiCoBorrowerEmploymentIndex !== -1 &&
      values.coborrowerEmployment[loanApplication.uiCoBorrowerEmploymentIndex] === undefined
    ) {
      loanApplication.coborrower.employment = [...values.coborrowerEmployment, cloneDeep(emptyEmployerConstant)];
    } else {
      loanApplication.coborrower.employment = values.coborrowerEmployment;
    }
  }
};

const mapSharedC6b = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedC6b_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowSelfEmploymentIncome : undefined;
    values.SharedC6b_1 = loanApplication.coborrower ? loanApplication.uiCoBorrowerSelfEmploymentIndex : undefined;
    values.coborrowerEmployment = loanApplication.coborrower ? loanApplication.coborrower.employment : [];
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowSelfEmploymentIncome = values.ShowSharedC6b_;
    loanApplication.uiCoBorrowerSelfEmploymentIndex = values.SharedC6b_1;
    loanApplication.coborrower.incomes[1].amount = values.coborrowerEmployment.reduce(
      (sum: number, employment: IEmploymentType) => (!employment.currentEmploymentIndicator && !!employment.selfEmployedIndicator ? sum + (employment.basePayAmount ?? 0) : sum),
      0
    );

    if (
      typeof loanApplication.uiCoBorrowerSelfEmploymentIndex === 'number' &&
      loanApplication.uiCoBorrowerSelfEmploymentIndex !== -1 &&
      values.coborrowerEmployment[loanApplication.uiCoBorrowerSelfEmploymentIndex] === undefined
    ) {
      loanApplication.coborrower.employment = [...values.coborrowerEmployment, cloneDeep(emptyBusinessConstant)];
    } else if (!!loanApplication.coborrower.employment.length && !!loanApplication.coborrower.employment[loanApplication.coborrower.employment.length - 1].employerName) {
      // Above if does check if is adding a new current employment to avoid resetting
      loanApplication.coborrower.employment = values.coborrowerEmployment;
    }
  }
};

const mapSharedC6c = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedC6c_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowSocialSecurityIncome : undefined;
    values.SharedC6c_1 = loanApplication.coborrower ? loanApplication.coborrower.incomes[2].amount : undefined;
    values.SharedC6c_2 = loanApplication.coborrower ? loanApplication.coborrower.incomes[3].amount : undefined;
    values.SharedC6c_3 = loanApplication.coborrower ? loanApplication.coborrower.incomes[4].amount : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowSocialSecurityIncome = values.ShowSharedC6c_;
    loanApplication.coborrower.incomes[2].amount = values.SharedC6c_1;
    loanApplication.coborrower.incomes[3].amount = values.SharedC6c_2;
    loanApplication.coborrower.incomes[4].amount = values.SharedC6c_3;
  }
};

const mapSharedC6d = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedC6d_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowOtherIncome : undefined;
    values.SharedC6d_1 = loanApplication.coborrower ? loanApplication.coborrower.incomes[5].amount : undefined;
    values.SharedC6d_2 = loanApplication.coborrower ? loanApplication.coborrower.incomes[6].amount : undefined;
    values.SharedC6d_3 = loanApplication.coborrower ? loanApplication.coborrower.incomes[7].amount : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowOtherIncome = values.ShowSharedC6d_;
    loanApplication.coborrower.incomes[5].amount = values.SharedC6d_1;
    loanApplication.coborrower.incomes[6].amount = values.SharedC6d_2;
    loanApplication.coborrower.incomes[7].amount = values.SharedC6d_3;
  }
};

const mapSharedC6f = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedC6f_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowNoIncome : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowNoIncome = values.ShowSharedC6f_;
  }
};

const mapSharedL2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    (loanApplication.coborrower?.otherLiabilities ?? []).forEach((liability: ILiabilityType) => {
      if (liability.liabilityOrExpenseType === LiabilityTypes.Alimony) {
        values.ShowSharedL2a_ = true;
        values.SharedL2a_1 = liability.amount;
      }

      if (liability.liabilityOrExpenseType === LiabilityTypes.ChildSupport) {
        values.ShowSharedL2b_ = true;
        values.SharedL2b_1 = liability.amount;
      }

      if (liability.liabilityOrExpenseType === LiabilityTypes.SeparateMaintenanceExpense) {
        values.ShowSharedL2c_ = true;
        values.SharedL2c_1 = liability.amount;
      }

      if (liability.liabilityOrExpenseType === LiabilityTypes.Other) {
        values.ShowSharedL2d_ = true;
        values.SharedL2d_1 = liability.otherDescription;
        values.SharedL2d_2 = liability.amount;
        values.SharedL2d_3 = liability.owner;
      }
    });
  } else if (loanApplication.coborrower) {
    const liabilities: ILiabilityType[] = [];

    if (values.ShowSharedL2a_) {
      liabilities.push({
        owner: OwnerTypes.CoBorrower,
        amount: values.SharedL2a_1,
        liabilityOrExpenseType: LiabilityTypes.Alimony,
      });
    }

    if (values.ShowSharedL2b_) {
      liabilities.push({
        owner: OwnerTypes.CoBorrower,
        amount: values.SharedL2b_1,
        liabilityOrExpenseType: LiabilityTypes.ChildSupport,
      });
    }

    if (values.ShowSharedL2c_) {
      liabilities.push({
        owner: OwnerTypes.CoBorrower,
        amount: values.SharedL2c_1,
        liabilityOrExpenseType: LiabilityTypes.SeparateMaintenanceExpense,
      });
    }

    if (values.ShowSharedL2d_) {
      liabilities.push({
        owner: values.SharedL2d_3,
        amount: values.SharedL2d_2,
        liabilityOrExpenseType: LiabilityTypes.Other,
        otherDescription: values.SharedL2d_1,
      });
    }

    loanApplication.coborrower.otherLiabilities = cloneDeep(liabilities);
  }
};

const mapSharedC10 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC10_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.ownershipEducationConfirmationIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.ownershipEducationConfirmationIndicator = yesNo.get(values.SharedC10_1) ?? null;
  }
};

const mapSharedC11 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC11_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.ownershipConfirmationIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.ownershipConfirmationIndicator = yesNo.get(values.SharedC11_1) ?? null;
  }
};

const mapSharedC12 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedC12_1 = loanApplication.coborrower ? loanApplication.coborrower.languagePreference : undefined;
    values.SharedC12_2 = loanApplication.coborrower ? loanApplication.coborrower.languageCodeOtherDescription : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.languagePreference = values.SharedC12_1;
    loanApplication.coborrower.languageCodeOtherDescription = values.SharedC12_2;
  }
};

const mapSharedEC1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, coborrowerEmploymentIndex: number) => {
  if (reverse) {
    values.SharedEC1_1 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].employerName : undefined;
    values.SharedEC1_2 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].addressStreetLine1 : undefined;
    values.SharedEC1_3 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].unitNumber : undefined;
    values.SharedEC1_4 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].addressCity : undefined;
    values.SharedEC1_5 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].addressState : undefined;
    values.SharedEC1_6 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].addressPostalCode : undefined;
    values.SharedEC1_7 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].phoneNumber : undefined;
    values.SharedEC1_8 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].positionDescription : undefined;
    values.SharedEC1_9 = loanApplication.coborrower ? getDate(loanApplication.coborrower.employment[coborrowerEmploymentIndex].employmentStartDate) : undefined;
    values.SharedEC1_10 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].timeInLineOfWorkYears : undefined;
    values.SharedEC1_11 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].basePayAmount : undefined;
    values.SharedEC1_12 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].overtimeAmount : undefined;
    values.SharedEC1_13 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].commissionsAmount : undefined;
    values.SharedEC1_14 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].bonusAmount : undefined;
    values.SharedEC1_15 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerEmploymentIndex].timeInLineOfWorkMonths : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].employerName = values.SharedEC1_1;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].addressStreetLine1 = values.SharedEC1_2;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].unitNumber = values.SharedEC1_3;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].addressCity = values.SharedEC1_4;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].addressState = values.SharedEC1_5;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].addressPostalCode = values.SharedEC1_6;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].phoneNumber = values.SharedEC1_7;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].positionDescription = values.SharedEC1_8;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].employmentStartDate = values.SharedEC1_9;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].timeInLineOfWorkYears = values.SharedEC1_10;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].basePayAmount = values.SharedEC1_11;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].overtimeAmount = values.SharedEC1_12;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].commissionsAmount = values.SharedEC1_13;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].bonusAmount = values.SharedEC1_14;
    loanApplication.coborrower.employment[coborrowerEmploymentIndex].timeInLineOfWorkMonths = values.SharedEC1_15;
  }
};

const mapSharedEC3 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const previousEmploymentKeys = ['SharedEC3a', 'SharedEC3b', 'SharedEC3c'];
  const previousEmployment = (loanApplication.coborrower?.employment ?? []).filter(
    (employment: IEmploymentType) => !employment.currentEmploymentIndicator && !employment.selfEmployedIndicator
  );

  if (reverse) {
    previousEmployment.forEach((employment: IEmploymentType, index: number) => {
      (values as any)[`${previousEmploymentKeys[index]}_1`] = loanApplication.coborrower ? employment.employerName : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_2`] = loanApplication.coborrower ? employment.addressStreetLine1 : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_3`] = loanApplication.coborrower ? employment.unitNumber : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_4`] = loanApplication.coborrower ? employment.addressCity : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_5`] = loanApplication.coborrower ? employment.addressState : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_6`] = loanApplication.coborrower ? employment.addressPostalCode : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_7`] = loanApplication.coborrower ? employment.phoneNumber : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_8`] = loanApplication.coborrower ? employment.positionDescription : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_9`] = loanApplication.coborrower ? getDate(employment.employmentStartDate) : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_10`] = loanApplication.coborrower ? employment.timeInLineOfWorkYears : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_11`] = loanApplication.coborrower ? getDate(employment.employmentEndDate) : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_12`] = loanApplication.coborrower ? reverseYesNo.get(employment.uiHasPreviousEmployment) ?? null : undefined;
      (values as any)[`${previousEmploymentKeys[index]}_13`] = loanApplication.coborrower ? employment.timeInLineOfWorkMonths : undefined;
    });
  } else if (loanApplication.coborrower) {
    if (previousEmployment.length) {
      loanApplication.coborrower.employment = loanApplication.coborrower.employment.filter(
        (employment: IEmploymentType) => employment.currentEmploymentIndicator || employment.selfEmployedIndicator
      );
    }

    let newPreviousEmployments: IEmploymentType[] = [];
    previousEmploymentKeys.forEach((key: string) => {
      let previousEmployment = cloneDeep(emptyPreviousEmployerConstant);
      previousEmployment.employerName = (values as any)[`${key}_1`];
      previousEmployment.addressStreetLine1 = (values as any)[`${key}_2`];
      previousEmployment.unitNumber = (values as any)[`${key}_3`];
      previousEmployment.addressCity = (values as any)[`${key}_4`];
      previousEmployment.addressState = (values as any)[`${key}_5`];
      previousEmployment.addressPostalCode = (values as any)[`${key}_6`];
      previousEmployment.phoneNumber = (values as any)[`${key}_7`];
      previousEmployment.positionDescription = (values as any)[`${key}_8`];
      previousEmployment.employmentStartDate = (values as any)[`${key}_9`];
      previousEmployment.timeInLineOfWorkYears = (values as any)[`${key}_10`];
      previousEmployment.employmentEndDate = (values as any)[`${key}_11`];
      previousEmployment.uiHasPreviousEmployment = yesNo.get((values as any)[`${key}_12`]) ?? null;
      previousEmployment.timeInLineOfWorkMonths = (values as any)[`${key}_13`];

      if (!!(values as any)[`${key}_12`]) {
        newPreviousEmployments.push(previousEmployment);
      }
    });

    loanApplication.coborrower.employment = [...loanApplication.coborrower.employment, ...newPreviousEmployments];
  }
};

const mapSharedEC2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, coborrowerSelfEmploymentIndex: number) => {
  if (reverse) {
    values.SharedEC2_1 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].employerName : undefined;
    values.SharedEC2_2 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].addressStreetLine1 : undefined;
    values.SharedEC2_3 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].unitNumber : undefined;
    values.SharedEC2_4 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].addressCity : undefined;
    values.SharedEC2_5 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].addressState : undefined;
    values.SharedEC2_6 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].addressPostalCode : undefined;
    values.SharedEC2_7 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].phoneNumber : undefined;
    values.SharedEC2_8 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].positionDescription : undefined;
    values.SharedEC2_9 = loanApplication.coborrower ? getDate(loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].employmentStartDate) : undefined;
    values.SharedEC2_10 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].timeInLineOfWorkYears : undefined;
    values.SharedEC2_11 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].percentOwn : undefined;
    values.SharedEC2_12 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].basePayAmount : undefined;
    values.SharedEC2_13 = loanApplication.coborrower ? loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].timeInLineOfWorkMonths : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].employerName = values.SharedEC2_1;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].addressStreetLine1 = values.SharedEC2_2;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].unitNumber = values.SharedEC2_3;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].addressCity = values.SharedEC2_4;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].addressState = values.SharedEC2_5;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].addressPostalCode = values.SharedEC2_6;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].phoneNumber = values.SharedEC2_7;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].positionDescription = values.SharedEC2_8;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].employmentStartDate = values.SharedEC2_9;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].timeInLineOfWorkYears = values.SharedEC2_10;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].percentOwn = values.SharedEC2_11;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].basePayAmount = values.SharedEC2_12;
    loanApplication.coborrower.employment[coborrowerSelfEmploymentIndex].timeInLineOfWorkMonths = values.SharedEC2_13;
  }
};

const mapPurchaseP1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, subjectPropertyIndex: number, residentialPropertyIndex: number) => {
  if (reverse) {
    if (values.loanPurposeType === QuestionValues.LoanPurposeTypePurchase && residentialPropertyIndex !== -1) {
      values.PurchaseP1_1 = loanApplication.reoProperties[residentialPropertyIndex].dispositionStatusType;
    }

    if (values.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance) {
      values.PurchaseP1_1 = loanApplication.reoProperties[subjectPropertyIndex].dispositionStatusType;
    }
  } else {
    if (values.loanPurposeType === QuestionValues.LoanPurposeTypePurchase && residentialPropertyIndex !== -1) {
      loanApplication.reoProperties[residentialPropertyIndex].dispositionStatusType = values.PurchaseP1_1;
    }

    if (values.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance) {
      loanApplication.reoProperties[subjectPropertyIndex].dispositionStatusType = values.PurchaseP1_1;
    }
  }
};

const mapSharedP2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, subjectPropertyIndex: number) => {
  if (reverse) {
    values.SharedP2_1 = loanApplication.reoProperties[subjectPropertyIndex].rentalIncomeGrossAmount;
  } else {
    loanApplication.reoProperties[subjectPropertyIndex].rentalIncomeGrossAmount = values.SharedP2_1;
  }
};

const mapSharedP3 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, subjectPropertyIndex: number) => {
  if (reverse) {
    values.SharedP3_1 = reverseYesNo.get(loanApplication.reoProperties[subjectPropertyIndex].liabilityDoesNotApply) ?? null;
  } else {
    loanApplication.reoProperties[subjectPropertyIndex].liabilityDoesNotApply = yesNo.get(values.SharedP3_1) ?? null;
  }
};

const mapSharedP3a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, subjectPropertyIndex: number) => {
  if (reverse) {
    values.SharedP3a_1 = loanApplication.reoProperties[subjectPropertyIndex].lienUpbAmount;
    values.SharedP3a_2 = loanApplication.reoProperties[subjectPropertyIndex].lienInstallmentAmount;
    values.SharedP3a_3 = loanApplication.reoProperties[subjectPropertyIndex].maintenanceExpenseAmount;
    values.SharedP3a_4 = loanApplication.reoProperties[subjectPropertyIndex].hoaAmount;
  } else {
    loanApplication.reoProperties[subjectPropertyIndex].lienUpbAmount = values.SharedP3a_1;
    loanApplication.reoProperties[subjectPropertyIndex].lienInstallmentAmount = values.SharedP3a_2;
    loanApplication.reoProperties[subjectPropertyIndex].maintenanceExpenseAmount = values.SharedP3a_3;
    loanApplication.reoProperties[subjectPropertyIndex].hoaAmount = values.SharedP3a_4;
  }
};

const mapSharedP4 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, residentialPropertyIndex: number) => {
  if (reverse) {
    values.SharedP4_1 = reverseYesNo.get(loanApplication.reoProperties[residentialPropertyIndex].liabilityDoesNotApply) ?? null;
  } else {
    loanApplication.reoProperties[residentialPropertyIndex].liabilityDoesNotApply = yesNo.get(values.SharedP4_1) ?? null;
  }
};

const mapSharedP4a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, residentialPropertyIndex: number) => {
  if (reverse) {
    values.SharedP4a_1 = loanApplication.reoProperties[residentialPropertyIndex].lienUpbAmount;
    values.SharedP4a_2 = loanApplication.reoProperties[residentialPropertyIndex].lienInstallmentAmount;
    values.SharedP4a_3 = loanApplication.reoProperties[residentialPropertyIndex].maintenanceExpenseAmount;
    values.SharedP4a_4 = loanApplication.reoProperties[residentialPropertyIndex].hoaAmount;
  } else {
    loanApplication.reoProperties[residentialPropertyIndex].lienUpbAmount = values.SharedP4a_1;
    loanApplication.reoProperties[residentialPropertyIndex].lienInstallmentAmount = values.SharedP4a_2;
    loanApplication.reoProperties[residentialPropertyIndex].maintenanceExpenseAmount = values.SharedP4a_3;
    loanApplication.reoProperties[residentialPropertyIndex].hoaAmount = values.SharedP4a_4;
  }
};

const mapPurchaseP11 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, subjectPropertyIndex: number) => {
  if (reverse) {
    values.PurchaseP11_1 = reverseYesNo.get(loanApplication.reoProperties[subjectPropertyIndex].planToSell) ?? null;
  } else {
    loanApplication.reoProperties[subjectPropertyIndex].planToSell = yesNo.get(values.PurchaseP11_1) ?? null;
  }
};

const mapSharedR0 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedR0_1 = loanApplication.uiReoPropertyIndex;
    values.reoProperties = loanApplication.reoProperties;
  } else {
    loanApplication.uiReoPropertyIndex = values.SharedR0_1;

    if (
      typeof loanApplication.uiReoPropertyIndex === 'number' &&
      loanApplication.uiReoPropertyIndex !== -1 &&
      values.reoProperties[loanApplication.uiReoPropertyIndex] === undefined
    ) {
      loanApplication.reoProperties = [...values.reoProperties, { ...emptyReoPropertyConstant, type: 'Other' }];
    } else {
      loanApplication.reoProperties = values.reoProperties;
    }
  }
};

const mapSharedR1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, otherPropertyIndex: number) => {
  if (reverse) {
    values.SharedR1_1 = loanApplication.reoProperties[otherPropertyIndex].streetAddress;
    values.SharedR1_2 = loanApplication.reoProperties[otherPropertyIndex].city;
    values.SharedR1_3 = loanApplication.reoProperties[otherPropertyIndex].state;
    values.SharedR1_4 = loanApplication.reoProperties[otherPropertyIndex].postalCode;
    values.SharedR1_5 = loanApplication.reoProperties[otherPropertyIndex].unitNumber;
  } else {
    loanApplication.reoProperties[otherPropertyIndex].streetAddress = values.SharedR1_1;
    loanApplication.reoProperties[otherPropertyIndex].city = values.SharedR1_2;
    loanApplication.reoProperties[otherPropertyIndex].state = values.SharedR1_3;
    loanApplication.reoProperties[otherPropertyIndex].postalCode = values.SharedR1_4;
    loanApplication.reoProperties[otherPropertyIndex].unitNumber = values.SharedR1_5;
  }
};

const mapSharedR2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, otherPropertyIndex: number) => {
  if (reverse) {
    values.SharedR2_1 = loanApplication.reoProperties[otherPropertyIndex].futurePropertyUsageType;
  } else {
    loanApplication.reoProperties[otherPropertyIndex].futurePropertyUsageType = values.SharedR2_1;
  }
};

const mapSharedR2a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, otherPropertyIndex: number) => {
  if (reverse) {
    values.SharedR2a_1 = loanApplication.reoProperties[otherPropertyIndex].rentalIncomeGrossAmount;
  } else {
    loanApplication.reoProperties[otherPropertyIndex].rentalIncomeGrossAmount = values.SharedR2a_1;
  }
};

const mapSharedR3 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, otherPropertyIndex: number) => {
  if (reverse) {
    values.SharedR3_1 = reverseYesNo.get(loanApplication.reoProperties[otherPropertyIndex].liabilityDoesNotApply) ?? null;
  } else {
    loanApplication.reoProperties[otherPropertyIndex].liabilityDoesNotApply = yesNo.get(values.SharedR3_1) ?? null;
  }
};

const mapSharedR3a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, otherPropertyIndex: number) => {
  if (reverse) {
    values.SharedR3a_1 = loanApplication.reoProperties[otherPropertyIndex].lienUpbAmount;
    values.SharedR3a_2 = loanApplication.reoProperties[otherPropertyIndex].lienInstallmentAmount;
    values.SharedR3a_3 = loanApplication.reoProperties[otherPropertyIndex].maintenanceExpenseAmount;
    values.SharedR3a_4 = loanApplication.reoProperties[otherPropertyIndex].hoaAmount;
  } else {
    loanApplication.reoProperties[otherPropertyIndex].lienUpbAmount = values.SharedR3a_1;
    loanApplication.reoProperties[otherPropertyIndex].lienInstallmentAmount = values.SharedR3a_2;
    loanApplication.reoProperties[otherPropertyIndex].maintenanceExpenseAmount = values.SharedR3a_3;
    loanApplication.reoProperties[otherPropertyIndex].hoaAmount = values.SharedR3a_4;
  }
};

const mapSharedR4 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean, otherPropertyIndex: number) => {
  if (reverse) {
    values.SharedR4_1 = loanApplication.reoProperties[otherPropertyIndex].owner;
  } else {
    loanApplication.reoProperties[otherPropertyIndex].owner = noNeedToFillCoborrower(values) ? OwnerTypes.Borrower : values.SharedR4_1;
  }
};

const mapSharedA1a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA1a_';
  if (reverse) {
    values.ShowSharedA1a_ = loanApplication.borrower.uiShowCheckingAsset;
    loadAsset(AssetTypes.CheckingAccount, namePrefix, values, loanApplication);
  } else {
    loanApplication.borrower.uiShowCheckingAsset = values.ShowSharedA1a_;
    uploadAsset(AssetTypes.CheckingAccount, namePrefix, values, loanApplication);
  }
};

const mapSharedA1b = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA1b_';
  if (reverse) {
    values.ShowSharedA1b_ = loanApplication.borrower.uiShowSavingsAsset;
    loadAsset(AssetTypes.SavingsAccount, namePrefix, values, loanApplication);
  } else {
    loanApplication.borrower.uiShowSavingsAsset = values.ShowSharedA1b_;
    uploadAsset(AssetTypes.SavingsAccount, namePrefix, values, loanApplication);
  }
};

const mapSharedA1c = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA1c_';
  if (reverse) {
    values.ShowSharedA1c_ = loanApplication.borrower.uiShowRetirementAsset;
    loadAsset(AssetTypes.RetirementFund, namePrefix, values, loanApplication);
  } else {
    loanApplication.borrower.uiShowRetirementAsset = values.ShowSharedA1c_;
    uploadAsset(AssetTypes.RetirementFund, namePrefix, values, loanApplication);
  }
};

const mapSharedA1d = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA1d_';
  if (reverse) {
    values.ShowSharedA1d_ = loanApplication.borrower.uiShowStocksAsset;
    loadAsset(AssetTypes.Stock, namePrefix, values, loanApplication);
  } else {
    loanApplication.borrower.uiShowStocksAsset = values.ShowSharedA1d_;
    uploadAsset(AssetTypes.Stock, namePrefix, values, loanApplication);
  }
};

const mapSharedA1e = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA1e_';
  if (reverse) {
    values.ShowSharedA1e_ = loanApplication.borrower.uiShowGiftsAsset;
    loadAsset(AssetTypes.GiftOfCash, namePrefix, values, loanApplication);
  } else {
    loanApplication.borrower.uiShowGiftsAsset = values.ShowSharedA1e_;
    uploadAsset(AssetTypes.GiftOfCash, namePrefix, values, loanApplication);
  }
};

const mapSharedA1f = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA1f_';
  if (reverse) {
    values.ShowSharedA1f_ = loanApplication.borrower.uiShowSalesAsset;
    loadAsset(AssetTypes.PendingNetSaleProceedsFromRealEstateAssets, namePrefix, values, loanApplication);
  } else {
    loanApplication.borrower.uiShowSalesAsset = values.ShowSharedA1f_;
    uploadAsset(AssetTypes.PendingNetSaleProceedsFromRealEstateAssets, namePrefix, values, loanApplication);
  }
};

const mapSharedA1g = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA1g_';
  if (reverse) {
    values.ShowSharedA1g_ = loanApplication.borrower.uiShowOtherAsset;
    loadAsset(AssetTypes.Other, namePrefix, values, loanApplication);
  } else {
    loanApplication.borrower.uiShowOtherAsset = values.ShowSharedA1g_;
    uploadAsset(AssetTypes.Other, namePrefix, values, loanApplication);
  }
};

const mapSharedA1i = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedA1i_ = loanApplication.borrower.uiShowNoAsset;
  } else {
    loanApplication.borrower.uiShowNoAsset = values.ShowSharedA1i_;
  }
};

const mapSharedA2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedA2_ = loanApplication.assetSameAsPrimaryBorrowerIndicator;
  } else if (loanApplication.coborrower) {
    loanApplication.assetSameAsPrimaryBorrowerIndicator = values.ShowSharedA2_;
  }
};

const mapSharedA2a = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA2a_';
  if (reverse) {
    values.ShowSharedA2a_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowCheckingAsset : undefined;
    loadAsset(AssetTypes.CheckingAccount, namePrefix, values, loanApplication);
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowCheckingAsset = values.ShowSharedA2a_;
    uploadAsset(AssetTypes.CheckingAccount, namePrefix, values, loanApplication);
  }
};

const mapSharedA2b = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA2b_';
  if (reverse) {
    values.ShowSharedA2b_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowSavingsAsset : undefined;
    loadAsset(AssetTypes.SavingsAccount, namePrefix, values, loanApplication);
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowSavingsAsset = values.ShowSharedA2b_;
    uploadAsset(AssetTypes.SavingsAccount, namePrefix, values, loanApplication);
  }
};

const mapSharedA2c = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA2c_';
  if (reverse) {
    values.ShowSharedA2c_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowRetirementAsset : undefined;
    loadAsset(AssetTypes.RetirementFund, namePrefix, values, loanApplication);
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowRetirementAsset = values.ShowSharedA2c_;
    uploadAsset(AssetTypes.RetirementFund, namePrefix, values, loanApplication);
  }
};

const mapSharedA2d = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA2d_';
  if (reverse) {
    values.ShowSharedA2d_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowStocksAsset : undefined;
    loadAsset(AssetTypes.Stock, namePrefix, values, loanApplication);
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowStocksAsset = values.ShowSharedA2d_;
    uploadAsset(AssetTypes.Stock, namePrefix, values, loanApplication);
  }
};

const mapSharedA2e = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA2e_';
  if (reverse) {
    values.ShowSharedA2e_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowGiftsAsset : undefined;
    loadAsset(AssetTypes.GiftOfCash, namePrefix, values, loanApplication);
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowGiftsAsset = values.ShowSharedA2e_;
    uploadAsset(AssetTypes.GiftOfCash, namePrefix, values, loanApplication);
  }
};

const mapSharedA2f = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA2f_';
  if (reverse) {
    values.ShowSharedA2f_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowSalesAsset : undefined;
    loadAsset(AssetTypes.PendingNetSaleProceedsFromRealEstateAssets, namePrefix, values, loanApplication);
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowSalesAsset = values.ShowSharedA2f_;
    uploadAsset(AssetTypes.PendingNetSaleProceedsFromRealEstateAssets, namePrefix, values, loanApplication);
  }
};

const mapSharedA2g = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  const namePrefix = 'SharedA2g_';
  if (reverse) {
    values.ShowSharedA2g_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowOtherAsset : undefined;
    loadAsset(AssetTypes.Other, namePrefix, values, loanApplication);
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowOtherAsset = values.ShowSharedA2g_;
    uploadAsset(AssetTypes.Other, namePrefix, values, loanApplication);
  }
};

const mapSharedA2i = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.ShowSharedA2i_ = loanApplication.coborrower ? loanApplication.coborrower.uiShowNoAsset : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.uiShowNoAsset = values.ShowSharedA2i_;
  }
};

const mapSharedG1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG1_1 = loanApplication.borrower.hmdaGendertypeMaleIndicator
      ? QuestionValues.GenderMaleIndicator
      : loanApplication.borrower.hmdaGendertypeFemaleIndicator
      ? QuestionValues.GenderFemaleIndicator
      : loanApplication.borrower.hmdaGendertypeNotApplicableIndicator
      ? QuestionValues.GenderNotApplicableIndicator
      : '';
  } else {
    loanApplication.borrower.hmdaGendertypeMaleIndicator = values.SharedG1_1 === QuestionValues.GenderMaleIndicator;
    loanApplication.borrower.hmdaGendertypeFemaleIndicator = values.SharedG1_1 === QuestionValues.GenderFemaleIndicator;
    loanApplication.borrower.hmdaGendertypeNotApplicableIndicator = values.SharedG1_1 === QuestionValues.GenderNotApplicableIndicator;
  }
};

const mapSharedG2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG2_1 = loanApplication.borrower.hmdaEthnicityNotHispanicLatinoIndicator;
    values.SharedG2_2 = loanApplication.borrower.hmdaEthnicityHispanicLatinoIndicator;
    values.SharedG2_3 = loanApplication.borrower.hmdaMexicanIndicator;
    values.SharedG2_4 = loanApplication.borrower.hmdaPuertoRicanIndicator;
    values.SharedG2_5 = loanApplication.borrower.hmdaCubanIndicator;
    values.SharedG2_6 = loanApplication.borrower.hmdaHispanicLatinoOtherOriginIndicator;
    values.SharedG2_7 = loanApplication.borrower.hmdaOtherHispanicLatinoOrigin;
    values.SharedG2_8 = loanApplication.borrower.hmdaEthnicityDoNotWishIndicator;
  } else {
    loanApplication.borrower.hmdaEthnicityNotHispanicLatinoIndicator = values.SharedG2_1;
    loanApplication.borrower.hmdaEthnicityHispanicLatinoIndicator = values.SharedG2_2;
    loanApplication.borrower.hmdaMexicanIndicator = values.SharedG2_3;
    loanApplication.borrower.hmdaPuertoRicanIndicator = values.SharedG2_4;
    loanApplication.borrower.hmdaCubanIndicator = values.SharedG2_5;
    loanApplication.borrower.hmdaHispanicLatinoOtherOriginIndicator = values.SharedG2_6;
    loanApplication.borrower.hmdaOtherHispanicLatinoOrigin = values.SharedG2_7;
    loanApplication.borrower.hmdaEthnicityDoNotWishIndicator = values.SharedG2_8;
  }
};

const mapSharedG3 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG3_1 = loanApplication.borrower.hmdaAmericanIndianIndicator;
    values.SharedG3_2 = loanApplication.borrower.hmdaAmericanIndianTribe;
    values.SharedG3_3 = loanApplication.borrower.hmdaAsianIndicator;
    values.SharedG3_4 = loanApplication.borrower.hmdaAsianIndianIndicator;
    values.SharedG3_5 = loanApplication.borrower.hmdaChineseIndicator;
    values.SharedG3_6 = loanApplication.borrower.hmdaFilipinoIndicator;
    values.SharedG3_7 = loanApplication.borrower.hmdaJapaneseIndicator;
    values.SharedG3_8 = loanApplication.borrower.hmdaKoreanIndicator;
    values.SharedG3_9 = loanApplication.borrower.hmdaVietnameseIndicator;
    values.SharedG3_10 = loanApplication.borrower.hmdaAsianOtherRaceIndicator;
    values.SharedG3_11 = loanApplication.borrower.hmdaOtherAsianRace;
    values.SharedG3_12 = loanApplication.borrower.hmdaPacificIslanderIndicator;
    values.SharedG3_13 = loanApplication.borrower.hmdaNativeHawaiianIndicator;
    values.SharedG3_14 = loanApplication.borrower.hmdaGuamanianOrChamorroIndicator;
    values.SharedG3_15 = loanApplication.borrower.hmdaSamoanIndicator;
    values.SharedG3_16 = loanApplication.borrower.hmdaPacificIslanderOtherIndicator;
    values.SharedG3_17 = loanApplication.borrower.hmdaOtherPacificIslanderRace;
    values.SharedG3_18 = loanApplication.borrower.hmdaAfricanAmericanIndicator;
    values.SharedG3_19 = loanApplication.borrower.hmdaWhiteIndicator;
    values.SharedG3_20 = loanApplication.borrower.hmdaRaceDoNotWishProvideIndicator;
  } else {
    loanApplication.borrower.hmdaAmericanIndianIndicator = values.SharedG3_1;
    loanApplication.borrower.hmdaAmericanIndianTribe = values.SharedG3_2;
    loanApplication.borrower.hmdaAsianIndicator = values.SharedG3_3;
    loanApplication.borrower.hmdaAsianIndianIndicator = values.SharedG3_4;
    loanApplication.borrower.hmdaChineseIndicator = values.SharedG3_5;
    loanApplication.borrower.hmdaFilipinoIndicator = values.SharedG3_6;
    loanApplication.borrower.hmdaJapaneseIndicator = values.SharedG3_7;
    loanApplication.borrower.hmdaKoreanIndicator = values.SharedG3_8;
    loanApplication.borrower.hmdaVietnameseIndicator = values.SharedG3_9;
    loanApplication.borrower.hmdaAsianOtherRaceIndicator = values.SharedG3_10;
    loanApplication.borrower.hmdaOtherAsianRace = values.SharedG3_11;
    loanApplication.borrower.hmdaPacificIslanderIndicator = values.SharedG3_12;
    loanApplication.borrower.hmdaNativeHawaiianIndicator = values.SharedG3_13;
    loanApplication.borrower.hmdaGuamanianOrChamorroIndicator = values.SharedG3_14;
    loanApplication.borrower.hmdaSamoanIndicator = values.SharedG3_15;
    loanApplication.borrower.hmdaPacificIslanderOtherIndicator = values.SharedG3_16;
    loanApplication.borrower.hmdaOtherPacificIslanderRace = values.SharedG3_17;
    loanApplication.borrower.hmdaAfricanAmericanIndicator = values.SharedG3_18;
    loanApplication.borrower.hmdaWhiteIndicator = values.SharedG3_19;
    loanApplication.borrower.hmdaRaceDoNotWishProvideIndicator = values.SharedG3_20;
  }
};

const mapSharedG4 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG4_1 = reverseYesNo.get(loanApplication.borrower.intentToOccupyIndicator) ?? null;
  } else {
    loanApplication.borrower.intentToOccupyIndicator = yesNo.get(values.SharedG4_1) ?? null;
  }
};

const mapSharedG5 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG5_1 = reverseYesNo.get(loanApplication.borrower.homeownerPastThreeYearsIndicator) ?? null;
  } else {
    loanApplication.borrower.homeownerPastThreeYearsIndicator = yesNo.get(values.SharedG5_1) ?? null;
  }
};

const mapSharedG6 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG6_1 = reverseYesNo.get(loanApplication.borrower.specialBorrowerSellerRelationshipIndicator) ?? null;
  } else {
    loanApplication.borrower.specialBorrowerSellerRelationshipIndicator = yesNo.get(values.SharedG6_1) ?? null;
  }
};

const mapSharedG7 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG7_1 = reverseYesNo.get(loanApplication.borrower.undisclosedBorrowedFundsIndicator) ?? null;
  } else {
    loanApplication.borrower.undisclosedBorrowedFundsIndicator = yesNo.get(values.SharedG7_1) ?? null;
  }
};

const mapSharedG8 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG8_1 = reverseYesNo.get(loanApplication.borrower.undisclosedMortgageApplicationIndicator) ?? null;
  } else {
    loanApplication.borrower.undisclosedMortgageApplicationIndicator = yesNo.get(values.SharedG8_1) ?? null;
  }
};

const mapSharedG9 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG9_1 = reverseYesNo.get(loanApplication.borrower.undisclosedCreditApplicationIndicator) ?? null;
  } else {
    loanApplication.borrower.undisclosedCreditApplicationIndicator = yesNo.get(values.SharedG9_1) ?? null;
  }
};

const mapSharedG10 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG10_1 = reverseYesNo.get(loanApplication.borrower.propertyProposedCleanEnergyLienIndicator) ?? null;
  } else {
    loanApplication.borrower.propertyProposedCleanEnergyLienIndicator = yesNo.get(values.SharedG10_1) ?? null;
  }
};

const mapSharedG11 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG11_1 = reverseYesNo.get(loanApplication.borrower.undisclosedComakerOfNoteIndicator) ?? null;
  } else {
    loanApplication.borrower.undisclosedComakerOfNoteIndicator = yesNo.get(values.SharedG11_1) ?? null;
  }
};

const mapSharedG12 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG12_1 = reverseYesNo.get(loanApplication.borrower.outstandingJudgementsIndicator) ?? null;
  } else {
    loanApplication.borrower.outstandingJudgementsIndicator = yesNo.get(values.SharedG12_1) ?? null;
  }
};

const mapSharedG13 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG13_1 = reverseYesNo.get(loanApplication.borrower.presentlyDelinquentIndicatorURLA) ?? null;
  } else {
    loanApplication.borrower.presentlyDelinquentIndicatorURLA = yesNo.get(values.SharedG13_1) ?? null;
  }
};

const mapSharedG14 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG14_1 = reverseYesNo.get(loanApplication.borrower.partyToLawsuitIndicatorURLA) ?? null;
  } else {
    loanApplication.borrower.partyToLawsuitIndicatorURLA = yesNo.get(values.SharedG14_1) ?? null;
  }
};

const mapSharedG15 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG15_1 = reverseYesNo.get(loanApplication.borrower.priorPropertyDeedInLieuConveyedIndicator) ?? null;
  } else {
    loanApplication.borrower.priorPropertyDeedInLieuConveyedIndicator = yesNo.get(values.SharedG15_1) ?? null;
  }
};

const mapSharedG16 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG16_1 = reverseYesNo.get(loanApplication.borrower.priorPropertyShortSaleCompletedIndicator) ?? null;
  } else {
    loanApplication.borrower.priorPropertyShortSaleCompletedIndicator = yesNo.get(values.SharedG16_1) ?? null;
  }
};

const mapSharedG17 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG17_1 = reverseYesNo.get(loanApplication.borrower.priorPropertyForeclosureCompletedIndicator) ?? null;
  } else {
    loanApplication.borrower.priorPropertyForeclosureCompletedIndicator = yesNo.get(values.SharedG17_1) ?? null;
  }
};

const mapSharedG18 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG18_1 = reverseYesNo.get(loanApplication.borrower.bankruptcyIndicator) ?? null;
  } else {
    loanApplication.borrower.bankruptcyIndicator = yesNo.get(values.SharedG18_1) ?? null;
  }
};

const mapSharedG19 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG19_1 = loanApplication.borrower.urla2020CitizenshipResidencyType;
  } else {
    loanApplication.borrower.urla2020CitizenshipResidencyType = values.SharedG19_1;
  }
};

const mapSharedG20 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedG20_1 = loanApplication.borrower.priorPropertyUsageType;
    values.SharedG20_2 = loanApplication.borrower.priorPropertyTitleType;
  } else {
    loanApplication.borrower.priorPropertyUsageType = values.SharedG20_1;
    loanApplication.borrower.priorPropertyTitleType = values.SharedG20_2;
  }
};

const mapSharedGC1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC1_1 = loanApplication.coborrower
      ? loanApplication.coborrower.hmdaGendertypeMaleIndicator
        ? QuestionValues.GenderMaleIndicator
        : loanApplication.coborrower.hmdaGendertypeFemaleIndicator
        ? QuestionValues.GenderFemaleIndicator
        : loanApplication.coborrower.hmdaGendertypeNotApplicableIndicator
        ? QuestionValues.GenderNotApplicableIndicator
        : ''
      : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.hmdaGendertypeMaleIndicator = values.SharedGC1_1 === QuestionValues.GenderMaleIndicator;
    loanApplication.coborrower.hmdaGendertypeFemaleIndicator = values.SharedGC1_1 === QuestionValues.GenderFemaleIndicator;
    loanApplication.coborrower.hmdaGendertypeNotApplicableIndicator = values.SharedGC1_1 === QuestionValues.GenderNotApplicableIndicator;
  }
};

const mapSharedGC2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC2_1 = loanApplication.coborrower ? loanApplication.coborrower.hmdaEthnicityNotHispanicLatinoIndicator : undefined;
    values.SharedGC2_2 = loanApplication.coborrower ? loanApplication.coborrower.hmdaEthnicityHispanicLatinoIndicator : undefined;
    values.SharedGC2_3 = loanApplication.coborrower ? loanApplication.coborrower.hmdaMexicanIndicator : undefined;
    values.SharedGC2_4 = loanApplication.coborrower ? loanApplication.coborrower.hmdaPuertoRicanIndicator : undefined;
    values.SharedGC2_5 = loanApplication.coborrower ? loanApplication.coborrower.hmdaCubanIndicator : undefined;
    values.SharedGC2_6 = loanApplication.coborrower ? loanApplication.coborrower.hmdaHispanicLatinoOtherOriginIndicator : undefined;
    values.SharedGC2_7 = loanApplication.coborrower ? loanApplication.coborrower.hmdaOtherHispanicLatinoOrigin : undefined;
    values.SharedGC2_8 = loanApplication.coborrower ? loanApplication.coborrower.hmdaEthnicityDoNotWishIndicator : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.hmdaEthnicityNotHispanicLatinoIndicator = values.SharedGC2_1;
    loanApplication.coborrower.hmdaEthnicityHispanicLatinoIndicator = values.SharedGC2_2;
    loanApplication.coborrower.hmdaMexicanIndicator = values.SharedGC2_3;
    loanApplication.coborrower.hmdaPuertoRicanIndicator = values.SharedGC2_4;
    loanApplication.coborrower.hmdaCubanIndicator = values.SharedGC2_5;
    loanApplication.coborrower.hmdaHispanicLatinoOtherOriginIndicator = values.SharedGC2_6;
    loanApplication.coborrower.hmdaOtherHispanicLatinoOrigin = values.SharedGC2_7;
    loanApplication.coborrower.hmdaEthnicityDoNotWishIndicator = values.SharedGC2_8;
  }
};

const mapSharedGC3 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC3_1 = loanApplication.coborrower ? loanApplication.coborrower.hmdaAmericanIndianIndicator : undefined;
    values.SharedGC3_2 = loanApplication.coborrower ? loanApplication.coborrower.hmdaAmericanIndianTribe : undefined;
    values.SharedGC3_3 = loanApplication.coborrower ? loanApplication.coborrower.hmdaAsianIndicator : undefined;
    values.SharedGC3_4 = loanApplication.coborrower ? loanApplication.coborrower.hmdaAsianIndianIndicator : undefined;
    values.SharedGC3_5 = loanApplication.coborrower ? loanApplication.coborrower.hmdaChineseIndicator : undefined;
    values.SharedGC3_6 = loanApplication.coborrower ? loanApplication.coborrower.hmdaFilipinoIndicator : undefined;
    values.SharedGC3_7 = loanApplication.coborrower ? loanApplication.coborrower.hmdaJapaneseIndicator : undefined;
    values.SharedGC3_8 = loanApplication.coborrower ? loanApplication.coborrower.hmdaKoreanIndicator : undefined;
    values.SharedGC3_9 = loanApplication.coborrower ? loanApplication.coborrower.hmdaVietnameseIndicator : undefined;
    values.SharedGC3_10 = loanApplication.coborrower ? loanApplication.coborrower.hmdaAsianOtherRaceIndicator : undefined;
    values.SharedGC3_11 = loanApplication.coborrower ? loanApplication.coborrower.hmdaOtherAsianRace : undefined;
    values.SharedGC3_12 = loanApplication.coborrower ? loanApplication.coborrower.hmdaPacificIslanderIndicator : undefined;
    values.SharedGC3_13 = loanApplication.coborrower ? loanApplication.coborrower.hmdaNativeHawaiianIndicator : undefined;
    values.SharedGC3_14 = loanApplication.coborrower ? loanApplication.coborrower.hmdaGuamanianOrChamorroIndicator : undefined;
    values.SharedGC3_15 = loanApplication.coborrower ? loanApplication.coborrower.hmdaSamoanIndicator : undefined;
    values.SharedGC3_16 = loanApplication.coborrower ? loanApplication.coborrower.hmdaPacificIslanderOtherIndicator : undefined;
    values.SharedGC3_17 = loanApplication.coborrower ? loanApplication.coborrower.hmdaOtherPacificIslanderRace : undefined;
    values.SharedGC3_18 = loanApplication.coborrower ? loanApplication.coborrower.hmdaAfricanAmericanIndicator : undefined;
    values.SharedGC3_19 = loanApplication.coborrower ? loanApplication.coborrower.hmdaWhiteIndicator : undefined;
    values.SharedGC3_20 = loanApplication.coborrower ? loanApplication.coborrower.hmdaRaceDoNotWishProvideIndicator : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.hmdaAmericanIndianIndicator = values.SharedGC3_1;
    loanApplication.coborrower.hmdaAmericanIndianTribe = values.SharedGC3_2;
    loanApplication.coborrower.hmdaAsianIndicator = values.SharedGC3_3;
    loanApplication.coborrower.hmdaAsianIndianIndicator = values.SharedGC3_4;
    loanApplication.coborrower.hmdaChineseIndicator = values.SharedGC3_5;
    loanApplication.coborrower.hmdaFilipinoIndicator = values.SharedGC3_6;
    loanApplication.coborrower.hmdaJapaneseIndicator = values.SharedGC3_7;
    loanApplication.coborrower.hmdaKoreanIndicator = values.SharedGC3_8;
    loanApplication.coborrower.hmdaVietnameseIndicator = values.SharedGC3_9;
    loanApplication.coborrower.hmdaAsianOtherRaceIndicator = values.SharedGC3_10;
    loanApplication.coborrower.hmdaOtherAsianRace = values.SharedGC3_11;
    loanApplication.coborrower.hmdaPacificIslanderIndicator = values.SharedGC3_12;
    loanApplication.coborrower.hmdaNativeHawaiianIndicator = values.SharedGC3_13;
    loanApplication.coborrower.hmdaGuamanianOrChamorroIndicator = values.SharedGC3_14;
    loanApplication.coborrower.hmdaSamoanIndicator = values.SharedGC3_15;
    loanApplication.coborrower.hmdaPacificIslanderOtherIndicator = values.SharedGC3_16;
    loanApplication.coborrower.hmdaOtherPacificIslanderRace = values.SharedGC3_17;
    loanApplication.coborrower.hmdaAfricanAmericanIndicator = values.SharedGC3_18;
    loanApplication.coborrower.hmdaWhiteIndicator = values.SharedGC3_19;
    loanApplication.coborrower.hmdaRaceDoNotWishProvideIndicator = values.SharedGC3_20;
  }
};

const mapSharedGC4 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC4_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.intentToOccupyIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.intentToOccupyIndicator = yesNo.get(values.SharedGC4_1) ?? null;
  }
};

const mapSharedGC5 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC5_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.homeownerPastThreeYearsIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.homeownerPastThreeYearsIndicator = yesNo.get(values.SharedGC5_1) ?? null;
  }
};

const mapSharedGC6 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC6_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.specialBorrowerSellerRelationshipIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.specialBorrowerSellerRelationshipIndicator = yesNo.get(values.SharedGC6_1) ?? null;
  }
};

const mapSharedGC7 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC7_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.undisclosedBorrowedFundsIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.undisclosedBorrowedFundsIndicator = yesNo.get(values.SharedGC7_1) ?? null;
  }
};

const mapSharedGC8 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC8_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.undisclosedMortgageApplicationIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.undisclosedMortgageApplicationIndicator = yesNo.get(values.SharedGC8_1) ?? null;
  }
};

const mapSharedGC9 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC9_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.undisclosedCreditApplicationIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.undisclosedCreditApplicationIndicator = yesNo.get(values.SharedGC9_1) ?? null;
  }
};

const mapSharedGC10 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC10_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.propertyProposedCleanEnergyLienIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.propertyProposedCleanEnergyLienIndicator = yesNo.get(values.SharedGC10_1) ?? null;
  }
};

const mapSharedGC11 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC11_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.undisclosedComakerOfNoteIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.undisclosedComakerOfNoteIndicator = yesNo.get(values.SharedGC11_1) ?? null;
  }
};

const mapSharedGC12 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC12_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.outstandingJudgementsIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.outstandingJudgementsIndicator = yesNo.get(values.SharedGC12_1) ?? null;
  }
};

const mapSharedGC13 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC13_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.presentlyDelinquentIndicatorURLA) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.presentlyDelinquentIndicatorURLA = yesNo.get(values.SharedGC13_1) ?? null;
  }
};

const mapSharedGC14 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC14_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.partyToLawsuitIndicatorURLA) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.partyToLawsuitIndicatorURLA = yesNo.get(values.SharedGC14_1) ?? null;
  }
};

const mapSharedGC15 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC15_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.priorPropertyDeedInLieuConveyedIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.priorPropertyDeedInLieuConveyedIndicator = yesNo.get(values.SharedGC15_1) ?? null;
  }
};

const mapSharedGC16 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC16_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.priorPropertyShortSaleCompletedIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.priorPropertyShortSaleCompletedIndicator = yesNo.get(values.SharedGC16_1) ?? null;
  }
};

const mapSharedGC17 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC17_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.priorPropertyForeclosureCompletedIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.priorPropertyForeclosureCompletedIndicator = yesNo.get(values.SharedGC17_1) ?? null;
  }
};

const mapSharedGC18 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC18_1 = loanApplication.coborrower ? reverseYesNo.get(loanApplication.coborrower.bankruptcyIndicator) ?? null : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.bankruptcyIndicator = yesNo.get(values.SharedGC18_1) ?? null;
  }
};

const mapSharedGC19 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC19_1 = loanApplication.coborrower ? loanApplication.coborrower.urla2020CitizenshipResidencyType : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.urla2020CitizenshipResidencyType = values.SharedGC19_1;
  }
};

const mapSharedGC20 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedGC20_1 = loanApplication.coborrower ? loanApplication.coborrower.priorPropertyUsageType : undefined;
    values.SharedGC20_2 = loanApplication.coborrower ? loanApplication.coborrower.priorPropertyTitleType : undefined;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.priorPropertyUsageType = values.SharedGC20_1;
    loanApplication.coborrower.priorPropertyTitleType = values.SharedGC20_2;
  }
};

const mapPurchaseE1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.PurchaseE1_1 = reverseYesNo.get(loanApplication.shareApplicationUpdateWithAgentIndicator) ?? null;
  } else {
    loanApplication.shareApplicationUpdateWithAgentIndicator = yesNo.get(values.PurchaseE1_1) ?? null;
  }
};

const mapPurchaseE2 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.PurchaseE2_1 = loanApplication.buyerAgentFirstName;
    values.PurchaseE2_2 = loanApplication.buyerAgentLastName;
    values.PurchaseE2_3 = loanApplication.buyerAgentEmail;
    values.PurchaseE2_4 = loanApplication.buyerAgentMobilePhone;
  } else {
    loanApplication.buyerAgentFirstName = values.PurchaseE2_1;
    loanApplication.buyerAgentLastName = values.PurchaseE2_2;
    loanApplication.buyerAgentEmail = values.PurchaseE2_3;
    loanApplication.buyerAgentMobilePhone = values.PurchaseE2_4;
  }
};

const mapRefinanceW1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.RefinanceW1_1 = loanApplication.refinancePurposeType;
  } else {
    loanApplication.refinancePurposeType = values.RefinanceW1_1;
  }
};

const mapRefinanceW5 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.RefinanceW5_1 = loanApplication.propertyEstimatedValueAmount;
  } else {
    loanApplication.propertyEstimatedValueAmount = values.RefinanceW5_1;
  }
};

const mapRefinanceW6 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.RefinanceW6_1 = loanApplication.homeownersAssociationDuesAndCondoFeesAmount;
  } else {
    loanApplication.homeownersAssociationDuesAndCondoFeesAmount = values.RefinanceW6_1;
  }
};

const mapRefinanceL1 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.RefinanceL1_1 = loanApplication.cashoutAmount;
  } else {
    loanApplication.cashoutAmount = values.RefinanceL1_1;
  }
};

const mapSharedZ1 = async (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedZ1_1 =
      loanApplication.borrower.creditAuthorizationAgreed == null ? null : loanApplication.borrower.creditAuthorizationAgreed ? QuestionValues.Agree : QuestionValues.Disagree;
    values.SharedZ1_2 = loanApplication.borrower.ssn;
    values.SharedZ1_3 = getDate(loanApplication.borrower.birthDate);
    values.agreementsCaption = loanApplication.agreementsCaption;
  } else {
    loanApplication.borrower.creditAuthorizationAgreed = values.SharedZ1_1 == null ? null : values.SharedZ1_1 === QuestionValues.Agree;
    loanApplication.borrower.ssn = values.SharedZ1_2;
    loanApplication.borrower.birthDate = values.SharedZ1_3;

    if (loanApplication.borrower.creditAuthorizationAgreed) {
      loanApplication.agreementsCaption.borrowerCreditAuthSignTimeStamp = creditAuthSignTimeStamp(`${values.SharedB1_1} ${values.SharedB1_3}`, values.SharedB1_5);
      loanApplication.agreementsCaption.borrowerCreditAuthMachineStamp = await machineStamp();
    }
  }
};

const mapSharedZ1a = async (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedZ1a_1 = loanApplication.coborrower
      ? loanApplication.coborrower.creditAuthorizationAgreed == null
        ? null
        : loanApplication.coborrower.creditAuthorizationAgreed
        ? QuestionValues.Agree
        : QuestionValues.Disagree
      : undefined;
    values.SharedZ1a_2 = loanApplication.coborrower ? loanApplication.coborrower.ssn : undefined;
    values.SharedZ1a_3 = loanApplication.coborrower ? getDate(loanApplication.coborrower.birthDate) : undefined;
    values.agreementsCaption = loanApplication.agreementsCaption;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.creditAuthorizationAgreed = values.SharedZ1a_1 == null ? null : values.SharedZ1a_1 === QuestionValues.Agree;
    loanApplication.coborrower.ssn = values.SharedZ1a_2;
    loanApplication.coborrower.birthDate = values.SharedZ1a_3;

    if (loanApplication.coborrower.creditAuthorizationAgreed) {
      loanApplication.agreementsCaption.coborrowerCreditAuthSignTimeStamp = creditAuthSignTimeStamp(`${values.SharedC1_1} ${values.SharedC1_3}`, values.SharedC1_5);
      loanApplication.agreementsCaption.coborrowerCreditAuthMachineStamp = await machineStamp();
    }
  }
};

const mapSharedZ2 = async (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedZ2_1 = loanApplication.borrower.eConsentAgreed == null ? null : loanApplication.borrower.eConsentAgreed ? QuestionValues.Agree : QuestionValues.Disagree;
    values.agreementsCaption = loanApplication.agreementsCaption;
  } else {
    loanApplication.borrower.eConsentAgreed = values.SharedZ2_1 == null ? null : values.SharedZ2_1 === QuestionValues.Agree;

    if (loanApplication.borrower.eConsentAgreed) {
      loanApplication.agreementsCaption.borrowerEConsentSignTimeStamp = eConsentSignTimeStamp(`${values.SharedB1_1} ${values.SharedB1_3}`, values.SharedB1_5);
      loanApplication.agreementsCaption.borrowerEConsentMachineStamp = await machineStamp();
    }
  }
};

const mapSharedZ2a = async (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedZ2a_1 = loanApplication.coborrower
      ? loanApplication.coborrower.eConsentAgreed == null
        ? null
        : loanApplication.coborrower.eConsentAgreed
        ? QuestionValues.Agree
        : QuestionValues.Disagree
      : undefined;
    values.agreementsCaption = loanApplication.agreementsCaption;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.eConsentAgreed = values.SharedZ2a_1 == null ? null : values.SharedZ2a_1 === QuestionValues.Agree;

    if (loanApplication.coborrower.eConsentAgreed) {
      loanApplication.agreementsCaption.coborrowerEConsentSignTimeStamp = eConsentSignTimeStamp(`${values.SharedC1_1} ${values.SharedC1_3}`, values.SharedC1_5);
      loanApplication.agreementsCaption.coborrowerEConsentMachineStamp = await machineStamp();
    }
  }
};

const mapSharedZ3 = async (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedZ3_1 =
      loanApplication.borrower.certAuthorizationAgreed == null ? null : loanApplication.borrower.certAuthorizationAgreed ? QuestionValues.Agree : QuestionValues.Disagree;
    values.agreementsCaption = loanApplication.agreementsCaption;
  } else {
    loanApplication.borrower.certAuthorizationAgreed = values.SharedZ3_1 == null ? null : values.SharedZ3_1 === QuestionValues.Agree;

    if (loanApplication.borrower.certAuthorizationAgreed) {
      loanApplication.agreementsCaption.borrowerCertAuthSignTimeStamp = certAuthSignTimeStamp(`${values.SharedB1_1} ${values.SharedB1_3}`, values.SharedB1_5);
      loanApplication.agreementsCaption.borrowerCertAuthMachineStamp = await machineStamp();
    }
  }
};

const mapSharedZ3a = async (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedZ3a_1 = loanApplication.coborrower
      ? loanApplication.coborrower.certAuthorizationAgreed == null
        ? null
        : loanApplication.coborrower.certAuthorizationAgreed
        ? QuestionValues.Agree
        : QuestionValues.Disagree
      : undefined;
    values.agreementsCaption = loanApplication.agreementsCaption;
  } else if (loanApplication.coborrower) {
    loanApplication.coborrower.certAuthorizationAgreed = values.SharedZ3a_1 == null ? null : values.SharedZ3a_1 === QuestionValues.Agree;

    if (loanApplication.coborrower.certAuthorizationAgreed) {
      loanApplication.agreementsCaption.coborrowerCertAuthSignTimeStamp = certAuthSignTimeStamp(`${values.SharedC1_1} ${values.SharedC1_3}`, values.SharedC1_5);
      loanApplication.agreementsCaption.coborrowerCertAuthMachineStamp = await machineStamp();
    }
  }
};

const mapLoanOfficerContact = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.loanOfficerContact = loanApplication.loanOfficerContact;
  } else {
    loanApplication.loanOfficerContact = values.loanOfficerContact;
  }
};

const mapSharedT0 = (values: ILoanQuestionsMapType, loanApplication: ILoanType, reverse: boolean) => {
  if (reverse) {
    values.SharedT0_1 = reverseYesNo.get(loanApplication.uiAlreadyWorkingWithMM) ?? null;
  } else {
    loanApplication.uiAlreadyWorkingWithMM = yesNo.get(values.SharedT0_1) ?? null;
  }
};

const LoanMapper = async (values: ILoanQuestionsMapType, reverseLoanApplication?: ILoanType) => {
  let newValues = JSON.parse(JSON.stringify(values));
  let newLoanApplication = reverseLoanApplication ? cloneDeep(reverseLoanApplication) : cloneDeep(emptyLoanApplicationConstant);
  const reverse = !!reverseLoanApplication;

  mapCoborrowerInvite(newValues, newLoanApplication, reverse);
  mapCurrentStep(newValues, newLoanApplication, reverse);
  mapLoanOfficerEmail(newValues, newLoanApplication, reverse);
  mapReferralSource(newValues, newLoanApplication, reverse);
  mapLoanPosApplicationId(newValues, newLoanApplication, reverse);
  mapSharedW0(newValues, newLoanApplication, reverse);
  mapPurchaseW1(newValues, newLoanApplication, reverse);
  mapSharedR0(newValues, newLoanApplication, reverse);
  const subjectPropertyIndex = getSubjectPropertyIndex(newLoanApplication);
  mapSharedW2(newValues, newLoanApplication, reverse, subjectPropertyIndex);
  mapSharedW2a(newValues, newLoanApplication, reverse);
  mapSharedW3(newValues, newLoanApplication, reverse);
  mapSharedW4(newValues, newLoanApplication, reverse);
  mapSharedW4a(newValues, newLoanApplication, reverse);
  mapPurchaseD1(newValues, newLoanApplication, reverse);
  mapPurchaseD2(newValues, newLoanApplication, reverse);
  mapPurchaseD3(newValues, newLoanApplication, reverse);
  mapPurchaseD3a(newValues, newLoanApplication, reverse);
  mapSharedB1(newValues, newLoanApplication, reverse);
  mapSharedB1c(newValues, newLoanApplication, reverse);
  mapSharedB1d(newValues, newLoanApplication, reverse);
  mapSharedB1e(newValues, newLoanApplication, reverse);
  const residentialPropertyIndex = getResidentialPropertyIndex(newLoanApplication);
  mapSharedB1b(newValues, newLoanApplication, reverse, residentialPropertyIndex);
  mapSharedB1f(newValues, newLoanApplication, reverse);
  mapSharedB1i(newValues, newLoanApplication, reverse);
  mapSharedB1j(newValues, newLoanApplication, reverse);
  mapSharedB2(newValues, newLoanApplication, reverse);
  mapSharedB3(newValues, newLoanApplication, reverse);
  mapSharedB4(newValues, newLoanApplication, reverse);
  mapSharedB4a(newValues, newLoanApplication, reverse);
  mapSharedB5(newValues, newLoanApplication, reverse);
  mapSharedB5a(newValues, newLoanApplication, reverse);
  mapSharedB5c(newValues, newLoanApplication, reverse);
  mapSharedB6a(newValues, newLoanApplication, reverse);
  mapSharedB6b(newValues, newLoanApplication, reverse);
  mapSharedB6c(newValues, newLoanApplication, reverse);
  mapSharedB6d(newValues, newLoanApplication, reverse);
  mapSharedB6f(newValues, newLoanApplication, reverse);
  const borrowerEmploymentIndex = getBorrowerEmploymentIndex(newLoanApplication);
  if (borrowerEmploymentIndex !== -1) {
    mapSharedE1(newValues, newLoanApplication, reverse, borrowerEmploymentIndex);
  }
  mapSharedE3(newValues, newLoanApplication, reverse);
  const borrowerSelfEmploymentIndex = getBorrowerSelfEmploymentIndex(newLoanApplication);
  if (borrowerSelfEmploymentIndex !== -1) {
    mapSharedE2(newValues, newLoanApplication, reverse, borrowerSelfEmploymentIndex);
  }
  mapSharedL1(newValues, newLoanApplication, reverse);
  mapSharedB10(newValues, newLoanApplication, reverse);
  mapSharedB11(newValues, newLoanApplication, reverse);
  mapSharedB12(newValues, newLoanApplication, reverse);
  mapSharedB7(newValues, newLoanApplication, reverse);
  mapSharedB8(newValues, newLoanApplication, reverse);
  mapSharedB8a(newValues, newLoanApplication, reverse);
  mapSharedB8b(newValues, newLoanApplication, reverse);
  mapSharedC1(newValues, newLoanApplication, reverse);
  mapSharedC1a(newValues, newLoanApplication, reverse);
  mapSharedC1b(newValues, newLoanApplication, reverse);
  mapSharedC1c(newValues, newLoanApplication, reverse);
  mapSharedC1d(newValues, newLoanApplication, reverse);
  mapSharedC1e(newValues, newLoanApplication, reverse);
  mapSharedC1f(newValues, newLoanApplication, reverse);
  mapSharedC1i(newValues, newLoanApplication, reverse);
  mapSharedC1j(newValues, newLoanApplication, reverse);
  mapSharedC2(newValues, newLoanApplication, reverse);
  mapSharedC3(newValues, newLoanApplication, reverse);
  mapSharedC4(newValues, newLoanApplication, reverse);
  mapSharedC4a(newValues, newLoanApplication, reverse);
  mapSharedC5(newValues, newLoanApplication, reverse);
  mapSharedC5a(newValues, newLoanApplication, reverse);
  mapSharedC5c(newValues, newLoanApplication, reverse);
  mapSharedC6a(newValues, newLoanApplication, reverse);
  mapSharedC6b(newValues, newLoanApplication, reverse);
  mapSharedC6c(newValues, newLoanApplication, reverse);
  mapSharedC6d(newValues, newLoanApplication, reverse);
  mapSharedC6f(newValues, newLoanApplication, reverse);
  const coborrowerEmploymentIndex = getCoBorrowerEmploymentIndex(newLoanApplication);
  if (coborrowerEmploymentIndex !== -1) {
    mapSharedEC1(newValues, newLoanApplication, reverse, coborrowerEmploymentIndex);
  }
  mapSharedEC3(newValues, newLoanApplication, reverse);
  const coborrowerSelfEmploymentIndex = getCoBorrowerSelfEmploymentIndex(newLoanApplication);
  if (coborrowerSelfEmploymentIndex !== -1) {
    mapSharedEC2(newValues, newLoanApplication, reverse, coborrowerSelfEmploymentIndex);
  }
  mapSharedL2(newValues, newLoanApplication, reverse);
  mapSharedC10(newValues, newLoanApplication, reverse);
  mapSharedC11(newValues, newLoanApplication, reverse);
  mapSharedC12(newValues, newLoanApplication, reverse);
  mapPurchaseP1(newValues, newLoanApplication, reverse, subjectPropertyIndex, residentialPropertyIndex);
  if (newLoanApplication.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance && subjectPropertyIndex !== -1) {
    mapSharedP2(newValues, newLoanApplication, reverse, subjectPropertyIndex);
    mapSharedP3(newValues, newLoanApplication, reverse, subjectPropertyIndex);
    mapSharedP3a(newValues, newLoanApplication, reverse, subjectPropertyIndex);
    mapPurchaseP11(newValues, newLoanApplication, reverse, subjectPropertyIndex);
  }
  if (residentialPropertyIndex !== -1) {
    mapSharedP2(newValues, newLoanApplication, reverse, residentialPropertyIndex);
    mapSharedP4(newValues, newLoanApplication, reverse, residentialPropertyIndex);
    mapSharedP4a(newValues, newLoanApplication, reverse, residentialPropertyIndex);
  }
  const otherPropertyIndex = getOtherPropertyIndex(newLoanApplication);
  if (otherPropertyIndex !== -1) {
    mapSharedR1(newValues, newLoanApplication, reverse, otherPropertyIndex);
    mapSharedR2(newValues, newLoanApplication, reverse, otherPropertyIndex);
    mapSharedR2a(newValues, newLoanApplication, reverse, otherPropertyIndex);
    mapSharedR3(newValues, newLoanApplication, reverse, otherPropertyIndex);
    mapSharedR3a(newValues, newLoanApplication, reverse, otherPropertyIndex);
    mapSharedR4(newValues, newLoanApplication, reverse, otherPropertyIndex);
  }
  mapSharedA1a(newValues, newLoanApplication, reverse);
  mapSharedA1b(newValues, newLoanApplication, reverse);
  mapSharedA1c(newValues, newLoanApplication, reverse);
  mapSharedA1d(newValues, newLoanApplication, reverse);
  mapSharedA1e(newValues, newLoanApplication, reverse);
  mapSharedA1f(newValues, newLoanApplication, reverse);
  mapSharedA1g(newValues, newLoanApplication, reverse);
  mapSharedA1i(newValues, newLoanApplication, reverse);
  mapSharedA2(newValues, newLoanApplication, reverse);
  mapSharedA2a(newValues, newLoanApplication, reverse);
  mapSharedA2b(newValues, newLoanApplication, reverse);
  mapSharedA2c(newValues, newLoanApplication, reverse);
  mapSharedA2d(newValues, newLoanApplication, reverse);
  mapSharedA2e(newValues, newLoanApplication, reverse);
  mapSharedA2f(newValues, newLoanApplication, reverse);
  mapSharedA2g(newValues, newLoanApplication, reverse);
  mapSharedA2i(newValues, newLoanApplication, reverse);
  mapSharedG1(newValues, newLoanApplication, reverse);
  mapSharedG2(newValues, newLoanApplication, reverse);
  mapSharedG3(newValues, newLoanApplication, reverse);
  mapSharedG4(newValues, newLoanApplication, reverse);
  mapSharedG5(newValues, newLoanApplication, reverse);
  mapSharedG6(newValues, newLoanApplication, reverse);
  mapSharedG7(newValues, newLoanApplication, reverse);
  mapSharedG8(newValues, newLoanApplication, reverse);
  mapSharedG9(newValues, newLoanApplication, reverse);
  mapSharedG10(newValues, newLoanApplication, reverse);
  mapSharedG11(newValues, newLoanApplication, reverse);
  mapSharedG12(newValues, newLoanApplication, reverse);
  mapSharedG13(newValues, newLoanApplication, reverse);
  mapSharedG14(newValues, newLoanApplication, reverse);
  mapSharedG15(newValues, newLoanApplication, reverse);
  mapSharedG16(newValues, newLoanApplication, reverse);
  mapSharedG17(newValues, newLoanApplication, reverse);
  mapSharedG18(newValues, newLoanApplication, reverse);
  mapSharedG19(newValues, newLoanApplication, reverse);
  mapSharedG20(newValues, newLoanApplication, reverse);
  mapSharedGC1(newValues, newLoanApplication, reverse);
  mapSharedGC2(newValues, newLoanApplication, reverse);
  mapSharedGC3(newValues, newLoanApplication, reverse);
  mapSharedGC4(newValues, newLoanApplication, reverse);
  mapSharedGC5(newValues, newLoanApplication, reverse);
  mapSharedGC6(newValues, newLoanApplication, reverse);
  mapSharedGC7(newValues, newLoanApplication, reverse);
  mapSharedGC8(newValues, newLoanApplication, reverse);
  mapSharedGC9(newValues, newLoanApplication, reverse);
  mapSharedGC10(newValues, newLoanApplication, reverse);
  mapSharedGC11(newValues, newLoanApplication, reverse);
  mapSharedGC12(newValues, newLoanApplication, reverse);
  mapSharedGC13(newValues, newLoanApplication, reverse);
  mapSharedGC14(newValues, newLoanApplication, reverse);
  mapSharedGC15(newValues, newLoanApplication, reverse);
  mapSharedGC16(newValues, newLoanApplication, reverse);
  mapSharedGC17(newValues, newLoanApplication, reverse);
  mapSharedGC18(newValues, newLoanApplication, reverse);
  mapSharedGC19(newValues, newLoanApplication, reverse);
  mapSharedGC20(newValues, newLoanApplication, reverse);
  mapPurchaseE1(newValues, newLoanApplication, reverse);
  mapPurchaseE2(newValues, newLoanApplication, reverse);
  mapRefinanceW1(newValues, newLoanApplication, reverse);
  mapRefinanceW5(newValues, newLoanApplication, reverse);
  mapRefinanceW6(newValues, newLoanApplication, reverse);
  mapRefinanceL1(newValues, newLoanApplication, reverse);
  await mapSharedZ1(newValues, newLoanApplication, reverse);
  await mapSharedZ1a(newValues, newLoanApplication, reverse);
  await mapSharedZ2(newValues, newLoanApplication, reverse);
  await mapSharedZ2a(newValues, newLoanApplication, reverse);
  await mapSharedZ3(newValues, newLoanApplication, reverse);
  await mapSharedZ3a(newValues, newLoanApplication, reverse);
  mapSharedT0(newValues, newLoanApplication, reverse);
  mapLoanOfficerContact(newValues, newLoanApplication, reverse);

  return JSON.parse(JSON.stringify(reverse ? newValues : newLoanApplication));
};

export default LoanMapper;
