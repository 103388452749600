import Question from '../../Question';
import { Row } from 'react-bootstrap';
import TextField from '@rsl/core/src/components/form/TextField';

const B1d = () => {
  return (
    <Question label="How long have you lived at your current address?">
      <Row>
        <TextField className="mb-2" type="number" name="SharedB1d_1" placeholder="Years" min={0} max={99} required />
        <TextField className="mb-2" type="number" name="SharedB1d_2" placeholder="Months" min={0} max={11} required />
      </Row>
    </Question>
  );
};

export default B1d;
