import { useFormikContext, useField } from 'formik';
import { FC, useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { IPlaceAutocompleteResponse } from './fields.types';

export enum PlaceType {
  ADDRESS = 'address',
  BUSINESS = 'establishment',
  POINT_OF_INTEREST = 'point_of_interest',
  STREET_ADDRESS = 'street_address',
}

interface PlacesAutoCompleteProps {
  onChange: (res: IPlaceAutocompleteResponse) => void;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  types: PlaceType[];
  availableStates?: string[];
}

const PlacesAutoComplete: FC<PlacesAutoCompleteProps> = ({ onChange, name, placeholder, disabled, className, types, availableStates }) => {
  const apiKey = process.env.REACT_APP_WEB_POS_GMAPS_APIKEY;

  const { values } = useFormikContext<any>();
  const [address, setAddress] = useState<string | null>(values[name]);
  const [prevAddress, setPrevAddress] = useState<string | null>(null);
  const [autocompleteError, setAutocompleteError] = useState<string>('');
  const [, { error, touched }] = useField({ name });

  useEffect(() => {
    setAddress(values[name]);
  }, [values, name]);

  const getFullData = (placeValue: any) => {
    if (placeValue && placeValue.value && placeValue.value.place_id) {
      geocodeByPlaceId(placeValue.value.place_id)
        .then((res: google.maps.GeocoderResult[]) => {
          const [first] = res;
          const r = first.address_components;
          const streetNumber = r.find((i) => i.types.includes('street_number'))?.short_name;
          const streetName = r.find((i) => i.types.includes('route'))?.short_name;
          const city = r.find((i) => i.types.includes('locality'))?.short_name;
          const state = r.find((i) => i.types.includes('administrative_area_level_1'))?.short_name;
          const zipCode = r.find((i) => i.types.includes('postal_code'))?.short_name;

          if (availableStates && !availableStates.includes(String(state))) {
            onChange({
              streetName: '',
              streetNumber: '',
              establishment: '',
              address: '',
              city: '',
              state: '',
              zipCode: '',
            } as IPlaceAutocompleteResponse);

            setAutocompleteError(`This loan purpose is only available for ${availableStates.join(', ')}.`);
            return;
          }

          const fullAddress = `${streetNumber ?? ''} ${streetName ?? ''}`.trim();

          let establishment;
          if (first.types.includes(PlaceType.ADDRESS) || first.types.includes(PlaceType.STREET_ADDRESS)) {
            setAddress(fullAddress);
          } else if (first.types.includes(PlaceType.BUSINESS) || first.types.includes(PlaceType.POINT_OF_INTEREST)) {
            establishment = placeValue.value.structured_formatting.main_text;
            setAddress(establishment);
          }

          const compAddress: IPlaceAutocompleteResponse = {
            streetName,
            streetNumber,
            establishment,
            address: fullAddress,
            city,
            state,
            zipCode,
          };
          onChange(compAddress);
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <>
      <GooglePlacesAutocomplete
        apiKey={apiKey}
        apiOptions={{ language: 'en' }}
        autocompletionRequest={{
          componentRestrictions: { country: 'us' },
          types,
        }}
        selectProps={{
          placeholder: <div className="form-label mt-2 placeholder-color">{placeholder}</div>,
          className: `form-control p-0 border-1 rounded-5 ${className} ${error && touched ? 'border-danger' : !error && touched ? 'border-success' : ''}`,
          isDisabled: disabled,
          value: address ? { label: address, value: {} } : null,
          inputId: `${name}-input`,
          classNamePrefix: 'minute-select',
          id: name,
          onChange: getFullData,
          onMenuOpen: () => {
            setPrevAddress(address);
            setAddress(null);
            setAutocompleteError('');
          },
          onMenuClose: () => {
            if (!address) {
              setAddress(prevAddress);
            }

            setPrevAddress(null);
          },
          filterOption: (option: any, inputValue: string) => {
            if (availableStates) {
              return option.data.value.terms.map((term: any) => term.value).some((value: string) => availableStates.includes(value));
            }

            return true;
          },
          onInputChange: (inputValue: string, action: any) => {
            if (action.action !== 'input-blur' && action.action !== 'menu-close') {
              setAddress(inputValue);
            }
          },
          onBlur: (e: any) => {
            onChange({
              address: e.target.value || address,
            } as IPlaceAutocompleteResponse);
          },
          styles: {
            control: (base) => ({
              ...base,
              height: 46,
              minHeight: 46,
              border: 0,
            }),
          },
          noOptionsMessage: ({ inputValue }) => (availableStates ? 'Try typing more to find results' : 'No results found'),
        }}
      />
      {(autocompleteError || (error && touched)) && (
        <div className="text-danger mb-2">
          <small>
            {autocompleteError} {error}
          </small>
        </div>
      )}
    </>
  );
};

export default PlacesAutoComplete;
