import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useExternalAuth } from 'app/hooks/authHooks';

const MainPageRedirection = () => {
  const { mainPage, isDeviceVerified, isLoggedIn } = useExternalAuth();
  const history = useHistory();
  const isRedirect = useMemo(() => !(!isDeviceVerified && isLoggedIn), [isDeviceVerified, isLoggedIn]);

  useEffect(() => {
    //workaround for redirect, to move at last step of the eventLoop when the session is verified
    isRedirect && setTimeout(() => history.replace(mainPage), 0);
  }, [mainPage, history, isRedirect]);

  return <></>;
};

export default MainPageRedirection;
