import { Alert } from 'react-bootstrap';
import { ReactComponent as InfoIcon } from 'app/assets/icons/info.svg';
import { FC } from 'react';

const MessageIndicator: FC = ({ children }) => {
  return (
    <Alert variant="light">
      <div className="px-2 py-2 d-flex text-black super-small">
        <span>
          <InfoIcon />
        </span>
        <p className="mx-2 mb-0">{children}</p>
      </div>
    </Alert>
  );
};

export default MessageIndicator;
