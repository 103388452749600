import { FC } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';

const C1c: FC<IQuestionType> = ({ showIf }) => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  return (
    <Question label="Do you currently rent or own?" showIf={showIf}>
      <Row>
        <RadioField
          className="mb-2"
          name="SharedC1c_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.CurrentlyOwn, value: QuestionValues.CurrentlyOwn },
            { label: QuestionValues.CurrentlyRent, value: QuestionValues.CurrentlyRent },
            { label: QuestionValues.CurrentlyRentFree, value: QuestionValues.CurrentlyRentFree },
          ]}
          disabled={values.SharedC1a_1}
        />
      </Row>
    </Question>
  );
};

export default C1c;
