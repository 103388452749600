import { FC, useEffect } from 'react';
import Question from '../../Question';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { Row } from 'react-bootstrap';
import { QuestionValues } from '@rsl/core/src/constants';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';

const W1: FC = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.PurchaseW1_1 === QuestionValues.InContract) {
      setFieldValue('SharedW2a_1', '');
      setFieldTouched('SharedW2a_1', false);
      setFieldValue('SharedW2a_2', '');
      setFieldTouched('SharedW2a_2', false);
    } else {
      setFieldValue('SharedW2_1', '');
      setFieldTouched('SharedW2_1', false);
      setFieldValue('SharedW2_5', '');
      setFieldTouched('SharedW2_5', false);
      setFieldValue('SharedW2_2', '');
      setFieldTouched('SharedW2_2', false);
      setFieldValue('SharedW2_3', '');
      setFieldTouched('SharedW2_3', false);
      setFieldValue('SharedW2_4', '');
      setFieldTouched('SharedW2_4', false);
    }
  }, [values.PurchaseW1_1, setFieldValue, setFieldTouched]);

  return (
    <Question label="Where in the process are you?">
      <Row>
        <RadioField
          className="mb-2"
          name="PurchaseW1_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.HouseHunting, value: QuestionValues.HouseHunting, labelEmphasis: `I'd like to know how much I can afford` },
            { label: QuestionValues.MakingOffers, value: QuestionValues.MakingOffers, labelEmphasis: '“I need to get pre-qualified' },
            { label: QuestionValues.InContract, value: QuestionValues.InContract, labelEmphasis: 'I need a loan approval' },
          ]}
        />
      </Row>
    </Question>
  );
};

export default W1;
