import { IPublicRoute } from '@rsl/core/src/types';
import LandingPage from './landingPage';

const routes: IPublicRoute = {
  title: 'Cobranding Page',
  slug: ':agentEmail',
  displayOnMenu: false,
  exact: true,
  render: () => <LandingPage />,
};

export default routes;
