import { useEffect, useState } from 'react';
import Question from '../../Question';
import TextField from '@rsl/core/src/components/form/TextField';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';
import PlacesAutoComplete, { PlaceType } from '../../../fields/PlacesAutocomplete';
import { IPlaceAutocompleteResponse } from '../../../fields/fields.types';

const B1b = () => {
  const { values, setFieldValue, setValues } = useFormikContext<ILoanQuestionsMapType>();
  const [changedPrimaryResidenceAddress, setChangedPrimaryResidenceAddress] = useState<boolean>(false);

  const isPrimaryResidenceAndRefinance: boolean = values.SharedW3_1 === QuestionValues.HomeIntendPrimary && values.loanPurposeType === QuestionValues.LoanPurposeTypeRefinance;

  useEffect(() => {
    if (isPrimaryResidenceAndRefinance && !changedPrimaryResidenceAddress) {
      setFieldValue('SharedB1b_1', values.SharedW2_1 || values.SharedB1b_1);
      setFieldValue('SharedB1b_2', values.SharedW2_2 || values.SharedB1b_2);
      setFieldValue('SharedB1b_3', values.SharedW2_3 || values.SharedB1b_3);
      setFieldValue('SharedB1b_4', values.SharedW2_4 || values.SharedB1b_4);
      setFieldValue('SharedB1c_1', QuestionValues.CurrentlyOwn);
      setChangedPrimaryResidenceAddress(true);
    }
  }, [
    setFieldValue,
    isPrimaryResidenceAndRefinance,
    values.SharedW2_1,
    values.SharedW2_2,
    values.SharedW2_3,
    values.SharedW2_4,
    values.SharedB1b_1,
    values.SharedB1b_2,
    values.SharedB1b_3,
    values.SharedB1b_4,
    changedPrimaryResidenceAddress,
    setChangedPrimaryResidenceAddress,
    values.loanPurposeType,
  ]);

  return (
    <Question label="What is your current address?">
      <Row>
        <Col xs={9}>
          <PlacesAutoComplete
            placeholder="Street Address"
            name="SharedB1b_1"
            types={[PlaceType.ADDRESS]}
            className="mb-2 places-autocomplete"
            disabled={isPrimaryResidenceAndRefinance}
            onChange={(res: IPlaceAutocompleteResponse) =>
              setValues({
                ...values,
                SharedB1b_1: res.address ?? values.SharedB1b_1,
                SharedB1b_2: res.city ?? values.SharedB1b_2,
                SharedB1b_3: res.state ?? values.SharedB1b_3,
                SharedB1b_4: res.zipCode ?? values.SharedB1b_4,
              })
            }
          />
        </Col>
        <TextField xs={3} className="mb-2" placeholder="Unit" name="SharedB1b_5" disabled={isPrimaryResidenceAndRefinance} />
      </Row>
      <Row>
        <TextField className="mb-2" placeholder="City" name="SharedB1b_2" required disabled={isPrimaryResidenceAndRefinance} />
        <LookupSelect className="mb-2" placeholder="State" name="SharedB1b_3" lookup="states" required disabled={isPrimaryResidenceAndRefinance} enableDefault={false} />
        <TextField className="mb-2" placeholder="Zip" name="SharedB1b_4" required disabled={isPrimaryResidenceAndRefinance} />
      </Row>
      {isPrimaryResidenceAndRefinance && (
        <Row>
          <Col>
            <small className="text-secondary">* This is your primary residence.</small>
          </Col>
        </Row>
      )}
    </Question>
  );
};

export default B1b;
