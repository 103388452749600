import { FC, useEffect } from 'react';
import { useFieldIds } from '@rsl/core/src/hooks/fieldHooks';
import classNames from 'classnames';
import { Col, Form, FormGroup } from 'react-bootstrap';
import { IMinuteField } from '../../types/form.types';
import { useField, useFormikContext } from 'formik';

const Field: FC<IMinuteField<any> & { fieldNode?: any }> = ({
  id: fieldId,
  value,
  name,
  label,
  disabled,
  required,
  nullable,
  className,
  fieldNode,
  children,
  hiddenLabel = false,
  renderLabel,
  isCheckbox = false,
  isLoading = false,
  labelEmphasis,
  isInlineLabel = false,
  groupClassName,
  ...props
}) => {
  const { name: fieldName, id } = useFieldIds({ name, label, id: fieldId });
  const [meta, { error, touched, initialValue }] = useField({ name: fieldName });
  const { submitCount } = useFormikContext();

  const isDirty = initialValue !== meta.value;
  const mustBeChecked = !nullable || isDirty;

  const isInvalid = mustBeChecked && (touched || submitCount > 0);
  const validationClass = isInvalid ? 'was-validated' : '';
  const isInvalidClass = isInvalid ? 'is-invalid' : '';

  useEffect(() => {
    if (fieldNode) {
      fieldNode.setCustomValidity(((touched || !!submitCount) && error) || '');
    }
  }, [error, fieldNode, touched, submitCount]);

  return (
    <Col className={className} {...props}>
      <FormGroup className={classNames(isInlineLabel ? 'inline-label' : '', validationClass, isCheckbox ? 'd-flex align-items-baseline' : '', groupClassName ?? '')} controlId={id}>
        <span className={classNames('d-none', isInvalidClass)} />
        {label && (
          <Form.Label className={classNames(required && 'field-required', isCheckbox ? 'order-1 mx-2 form-check-label' : '')} visuallyHidden={hiddenLabel}>
            {!renderLabel ? label : renderLabel(label)}
            {!!labelEmphasis && <em>{labelEmphasis}</em>}
          </Form.Label>
        )}
        {children}
        <Form.Control.Feedback type="invalid" className="order-2">
          {error}
        </Form.Control.Feedback>
      </FormGroup>
    </Col>
  );
};

export default Field;
