import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { lookupsApi } from '../../api/lookupsApi';
import { LookupKey, LookupState, LookupType } from '@rsl/core/src/types';

const initialState: LookupState = {
  lookups: {} as Record<LookupKey, LookupType>,
  pendingFetchLookups: [],
  isLoading: false,
};

export const lookupSlice = createSlice({
  name: 'lookup',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addPendingLookupIf: (state, { payload }: PayloadAction<LookupKey>) => {
      if (!state.lookups[payload] && !state.pendingFetchLookups.includes(payload)) {
        state.pendingFetchLookups.push(payload);
      }
    },
    flushPendingLookups: (state) => {
      state.pendingFetchLookups = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(lookupsApi.endpoints.fetchLookups.matchFulfilled, (state, { payload }) => {
      state.lookups = { ...state.lookups, ...payload };
      state.isLoading = false;
    });

    builder.addMatcher(lookupsApi.endpoints.fetchLookups.matchPending, (state) => {
      state.isLoading = true;
    });
  },
});

export const { addPendingLookupIf, flushPendingLookups } = lookupSlice.actions;

export default lookupSlice.reducer;
