import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers: Headers, api): Headers => {
      const { token, deviceId } = (api.getState() as any)?.auth ?? {};
      if (!!token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      if (!!deviceId) {
        headers.set('X-DeviceId', deviceId);
      }

      return headers;
    },
  }),
  tagTypes: [
    'Activity',
    'ActivityTemplate',
    'NotificationTemplate',
    'SecurityRoleList',
    'TeamList',
    'Team',
    'Communication',
    'UserProfileList',
    'UserProfile',
    'UserProfileImage',
    'UserProfileSubscriptionList',
    'Market',
    'MarketList',
    'Loan',
    'LoanNeedsList',
    'LoanDocuments',
    'ReportDefinition',
    'ReportDefinitionViews',
    'Lookups',
    'Fileshare',
    'NeedsListItemList',
    'MyShares',
    'FileshareDownload',
    'FileshareNeededList',
    'DailyRates',
    'DailyRateAssumptions',
    'LoanImportantDates',
    'LoanHistory',
    'LoanContact',
    'LoanContacts',
    'LoanPoSApplicationList',
    'LoanPoSApplication',
    'LoanPreQualDocument',
    'LoanPreQualForm',
    'LoanLsuDocument',
    'ExternalUserLoans',
    'LoanDisclosure',
    'MyTasksList',
    'Partner',
    'UserPartner',
    'PartnerProfileImage',
    'PartnerLogoImage',
    'LoanOfficerProfileImage',
    'LoanDisclosureDetail',
    'LoanPosDashboardItems',
  ],
  endpoints: () => ({}),
});
