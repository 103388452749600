import { FC, ReactNode, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType, IQuestionShowIfType } from '@rsl/core/src/types';
import QuestionLayout from '../layouts/QuestionLayout';
import classNames from 'classnames';

interface IQuestion {
  label: string | ReactNode;
  small?: string;
  center?: boolean;
  showIf?: IQuestionShowIfType[];
  showIfFn?: (values: ILoanQuestionsMapType) => boolean;
  labelSize?: 'md' | 'lg';
  showBackground?: boolean;
  titleClassName?: string;
  required?: boolean;
}

const useShowIfOperator: any = {
  '===': (index: string, value: string) => index === value,
  '!==': (index: string, value: string) => index !== value,
  '!!': (index: string, value: string) => !!index,
  '>=': (index: string, value: string) => Number(index) >= Number(value),
  '<=': (index: string, value: string) => Number(index) <= Number(value),
  '>': (index: string, value: string) => Number(index) > Number(value),
  '<': (index: string, value: string) => Number(index) < Number(value),
};

//TODO: move this to a shared hook
export const useShowQuestion = (showIf: IQuestionShowIfType[] | undefined, values: ILoanQuestionsMapType, showIfFn?: (values: ILoanQuestionsMapType) => boolean) => {
  const [ifOperators] = useState(showIf);
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(
      (showIfFn?.(values) ?? true) &&
        (ifOperators?.every(({ operator, value, index }) => {
          return useShowIfOperator[operator](values[index as keyof ILoanQuestionsMapType], value);
        }) ??
          true)
    );
  }, [ifOperators, values, showIfFn]);

  return show;
};

const Question: FC<IQuestion> = ({ label, small, center, showIf, showIfFn, children, labelSize = 'md', showBackground = true, titleClassName, required = false }) => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  const showQuestion = useShowQuestion(showIf, values, showIfFn);

  return showQuestion ? (
    <QuestionLayout showBackground={showBackground}>
      <QuestionLayout.Body showBackground={showBackground}>
        <div className={`${center && 'text-center'}`}>
          <h2 className={classNames(`mb-2 fw-bold ${labelSize === 'lg' ? 'fs-3' : 'fs-5'}`, titleClassName)}>
            {label}{' '}
            {required && (
              <sup>
                <strong className="text-danger">*</strong>
              </sup>
            )}
          </h2>
          {small && <small className="super-small">{small}</small>}
        </div>
        {children}
      </QuestionLayout.Body>
    </QuestionLayout>
  ) : null;
};

export default Question;
