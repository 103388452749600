import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from 'features/error-pages/NotFound';
import MainExternalLayout from './components/layout/MainExternalLayout';
import { flatRoutesArray } from './routes';
import ExternalReplyMessage from 'features/standalone/ExternalReplyMessage';
import { useRouteManager } from './hooks/useRouteManager';
import ProtectedRoute from './components/router/ProtectedRoute';
import MainPageRedirection from './components/router/MainPageRedirection';
import { flatProtectedRoutesArray } from './protectedRoutes';
import FileShareOutlet from 'features/fileshare/FileShareOutlet';
import { outsideRoutesArray } from './outsideRoutes';
import NewDashboard from 'features/dashboard/NewDashboard';
import ScanContainer from './components/scanner/ScanContainer';
import GeneralErrorComponents from './components/error/GeneralErrorComponents';
import { ErrorBoundary } from 'react-error-boundary';

const RslExternalApp: FC = () => {
  useRouteManager();

  const flatProtectedPathsArray = flatProtectedRoutesArray.map((route) => String(route.path));
  const flatPathsArray = flatRoutesArray.map((route) => String(route.path));

  return (
    <ErrorBoundary fallbackRender={GeneralErrorComponents}>
      <Switch>
        <Route path={'/scanner'}>
          <ScanContainer />
        </Route>
        <Route path={['/communication', '/fileshare', '/dashboard', ...flatProtectedPathsArray, ...flatPathsArray]}>
          <MainExternalLayout>
            <Switch>
              <Route path="/communication/reply/:commLogRecipientId">
                <ExternalReplyMessage />
              </Route>

              <Route path="/fileshare/:recipientId">
                <FileShareOutlet />
              </Route>

              <ProtectedRoute path={'/dashboard'}>
                <NewDashboard />
              </ProtectedRoute>

              {flatProtectedRoutesArray.map((route, index) => (
                <ProtectedRoute key={`route-protected-${route.slug}-${index}`} {...route} />
              ))}

              {flatRoutesArray.map((route, index) => (
                <Route key={`route-public-${route.slug}-${index}`} {...route} />
              ))}
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </MainExternalLayout>
        </Route>
        <Route path={['/']}>
          <Switch>
            {outsideRoutesArray.map((route, index) => (
              <Route key={`outside-route-public-${route.slug}-${index}`} {...route} />
            ))}
            <Route exact path="/">
              <MainPageRedirection />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};

export default RslExternalApp;
