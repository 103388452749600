import { FC, useEffect } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import { IQuestionType, ILoanQuestionsMapType } from '@rsl/core/src/types';
import { useFormikContext } from 'formik';

const B1e: FC<IQuestionType> = ({ showIf }) => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (!values.SharedB1c_1 || values.SharedB1c_1 === QuestionValues.CurrentlyOwn) {
      setFieldValue('SharedB1e_1', null);
    }
  }, [setFieldValue, values.SharedB1c_1]);

  return values.SharedB1c_1 ? (
    <Question label="Are you a first time homebuyer?" showIf={showIf}>
      <Row>
        <RadioField
          className="mb-2"
          name="SharedB1e_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  ) : null;
};

export default B1e;
