import { FC } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import TextField from '@rsl/core/src/components/form/TextField';

const B4a: FC<IQuestionType> = ({ showIf }) => {
  return (
    <Question label="What are the ages of your dependents?" small="Please enter the age of each dependent and separated by comma (8, 10, 13)" showIf={showIf}>
      <Row>
        <TextField className="mb-2" placeholder="#" name="SharedB4a_1" required />
      </Row>
    </Question>
  );
};

export default B4a;
