import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';

const C2 = () => {
  return (
    <Question label="Do you know your credit score?" small="We use this info to match you with the best rate loan options">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedC2_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.CreditScoreExcellentLabel, value: QuestionValues.CreditScoreExcellentValue },
            { label: QuestionValues.CreditScoreGoodLabel, value: QuestionValues.CreditScoreGoodValue },
            { label: QuestionValues.CreditScoreAverageLabel, value: QuestionValues.CreditScoreAverageValue },
            { label: QuestionValues.CreditScoreFairLabel, value: QuestionValues.CreditScoreFairValue },
            { label: QuestionValues.CreditScoreLowLabel, value: QuestionValues.CreditScoreLowValue },
          ]}
        />
      </Row>
    </Question>
  );
};

export default C2;
