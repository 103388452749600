import classNames from 'classnames';
import { FC } from 'react';
import { ReactComponent as Logo } from 'app/assets/img/brands/MM_Logo_RGB_Horizontal_Black.svg';

interface ISplash {
  show: boolean;
}

const Splash: FC<ISplash> = ({ show }) => {
  return (
    <div className={classNames('splash', show && 'active', 'd-flex justify-content-center align-items-center flex-column')}>
      <Logo style={{ maxWidth: '500px' }} />
      <div>
        <div className="splash-icon" />
      </div>
    </div>
  );
};

export default Splash;
