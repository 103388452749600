import { ApiResponse } from '@rsl/core/src/types';
import { baseApi } from '@rsl/core/src/api/baseApi';
import { ILoanOfficerPartnerType } from '../types/loanOfficerPartner.types';

export const cobrandingPageApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchLoanOfficerPartner: build.query<ILoanOfficerPartnerType, string>({
      query: (agentEmail) => `/externalUser/applyLink/${agentEmail}`,
      transformResponse: (response: ApiResponse<ILoanOfficerPartnerType>) => response.data,
    }),
  }),
  overrideExisting: false,
});

export const { useFetchLoanOfficerPartnerQuery } = cobrandingPageApi;
