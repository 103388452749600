import { ApiResponse, ILoanNeedsListItem } from '@rsl/core/src/types';
import { baseApi } from '@rsl/core/src/api/baseApi';
import { FileShareInfo, IFileDownload } from '../types';
import { invalidateOnSuccess } from '@rsl/core/src/utils/cacheUtils';

export const fileshareApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchFileShareInfo: build.query<FileShareInfo, string>({
      query: (recipientId) => `/FileShareExternal/${recipientId}`,
      transformResponse: (response: ApiResponse<FileShareInfo>) => response.data,
    }),
    fetchDownloadFiles: build.query<IFileDownload[], string>({
      query: (loanId) => `/FileShareExternal/${loanId}/fileshare`,
      transformResponse: (response: ApiResponse<IFileDownload[]>) => response.data,
      providesTags: ['FileshareDownload'],
    }),
    fetchNeededList: build.query<ILoanNeedsListItem[], string>({
      query: (loanId) => `/FileShareExternal/${loanId}/needlist`,
      transformResponse: (response: ApiResponse<ILoanNeedsListItem[]>) => response.data,
      providesTags: ['FileshareNeededList'],
    }),
    uploadFiles: build.mutation<FormData, { body: FormData; loanId?: string }>({
      query: (params) => ({
        url: `/FileShareExternal/${params.loanId}/files/upload`,
        method: 'POST',
        body: params.body,
      }),
      transformResponse: (response: ApiResponse<FormData>) => response.data,
      invalidatesTags: invalidateOnSuccess(['FileshareNeededList']),
    }),
    archiveFile: build.mutation<FormData, number>({
      query: (fileShareUploadId) => ({
        url: `/FileShareExternal/files/${fileShareUploadId}`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOnSuccess(['FileshareNeededList']),
    }),
  }),
  overrideExisting: false,
});

export const { useFetchFileShareInfoQuery, useFetchDownloadFilesQuery, useFetchNeededListQuery, useUploadFilesMutation, useArchiveFileMutation } = fileshareApi;
