import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { formatDate } from '@rsl/core/src/utils/dateUtils';
import { useMessageAcknowledgeMutation } from 'app/api/externalSessionApi';
import { ICommunicationLog } from '@rsl/core/src/types/communication.types';
import { useFetchLoanOfficerProfileImageQuery } from 'features/apply-now/pos/api/loanPosApplicationApi';
import NameBadge from '@rsl/core/src/components/badges/NameBadge';

import styles from './Message.module.scss';

interface MessageProps {
  data: ICommunicationLog;
  hideMarkAsRead?: boolean;
}

const Message: FC<MessageProps> = ({ data, hideMarkAsRead }) => {
  const [acknowledge, { isLoading }] = useMessageAcknowledgeMutation();
  const { data: profileImage } = useFetchLoanOfficerProfileImageQuery(`${data.senderUserId ?? ''}`, { skip: !data.senderUserId });

  const markAsRead = async () => {
    await acknowledge(data.communicationLogId);
  };

  const isExternalReply = (data: ICommunicationLog) => {
    return data.isExternalReply;
  };

  return (
    <div className="d-flex my-4">
      <div className="flex-shrink-1 d-flex flex-column align-items-center">
        <NameBadge name={data.senderName ?? ''} src={profileImage ? `data:image/jpeg;base64,${profileImage}` : ''} className={styles.fs3rem} />
        {!hideMarkAsRead && data.isUnreadMessage && !isExternalReply(data) && (
          <Button variant="outline-primary" size="sm" className="rounded-circle text-primary p-2 mt-2" onClick={markAsRead} disabled={isLoading}>
            <i className="far fa-envelope-open fs-3" />
          </Button>
        )}
      </div>
      <div className="flex-grow-1 px-2">
        <div>
          <strong className={isExternalReply(data) ? 'text-primary' : 'text-dark'}>{data.senderName}</strong>
          <small className="mx-3 text-tertiary">{formatDate(data.sentDateTimeUtc)}</small>
        </div>
        <div>
          <p className="mb-0 text-break">{data.body}</p>
        </div>
      </div>
      <div className="flex-shrink-1 d-flex align-items-center justify-content-end w-10">
        {!hideMarkAsRead && data.isUnreadMessage && !isExternalReply(data) && (
          <Button variant="outline-primary" size="sm" className="rounded-circle text-primary p-2" onClick={markAsRead} disabled={isLoading}>
            <i className="far fa-envelope-open fs-3" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Message;
