import { lookupsApi } from '../api/lookupsApi';
import { getLookupOptionSel, lookupsModuleSel, lookupsSel, pendingFetchLookupsSel } from '../store/selectors/lookupSelectors';
import { addPendingLookupIf, flushPendingLookups } from '../store/slices/lookupSlice';
import { LookupKey } from '@rsl/core/src/types/lookup.types';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

const debouncedLookups = debounce((lookupNames: LookupKey[], dispatch: Dispatch) => {
  if (lookupNames.length) {
    dispatch<any>(lookupsApi.endpoints.fetchLookups.initiate(lookupNames));
    // dont wait for refetch
    dispatch(flushPendingLookups());
  }
}, 200);

// this will handle request or not the specified lookup for now it does nothing
export const useLookup = <T>(lookupName: LookupKey) => {
  const pendingFetchLookups = useSelector(pendingFetchLookupsSel);
  const options = useSelector((state) => getLookupOptionSel(state, lookupName));
  const data: T[] | undefined = useSelector((state) => lookupsSel(state)[lookupName]);
  const { isLoading } = useSelector(lookupsModuleSel);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addPendingLookupIf(lookupName));
  }, [lookupName, dispatch]);

  useEffect(() => {
    debouncedLookups(pendingFetchLookups, dispatch);
  }, [pendingFetchLookups, dispatch]);

  return {
    data,
    isLoading,
    options,
  };
};
