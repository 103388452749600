import classNames from 'classnames';
import { FC } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import styles from './DropFilesZone.module.scss';

import { ReactComponent as DropIcon } from 'app/assets/icons/drop.svg';
import { Button } from 'react-bootstrap';

interface DropFilesZoneProps {
  /**
   * memorized function that wors as callback after dropping files
   */
  onDrop: (acceptedFiles: File[]) => void;
  disabled?: boolean;
  className?: string;
  maxFiles?: number;
  accept?: Accept;
}

// 20 MB
const maxLength = 20971520;

function sizeLengthValidator(file: File) {
  if (file.size > maxLength) {
    return {
      code: 'file-too-large',
      message: `File size is larger than ${maxLength}`,
    };
  }

  return null;
}

const unacceptedFiles = (accept?: Accept) => (file: File) => {
  const acceptedExtensions = Object.values(accept || {}).flat();
  const currentextension = file.name.split('.').pop();
  const isExtensionAccepted = acceptedExtensions.includes(`.${currentextension ?? ''}`);

  if (!isExtensionAccepted) {
    return {
      code: 'file-wrong-type',
      message: `Extension ".${currentextension}" not accepted, only: ${acceptedExtensions.join(', ')}`,
    };
  }
  return null;
};

const DropFilesZone: FC<DropFilesZoneProps> = ({ onDrop, disabled, className, maxFiles, accept }) => {
  const validator = (file: File) => {
    const sizeError = sizeLengthValidator(file);
    const extensionError = unacceptedFiles(accept)(file);
    return extensionError || sizeError;
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    disabled,
    maxFiles,
    validator,
    accept,
    excludeAcceptAllOption: true,
  } as any);

  const fileRejectionItems = fileRejections.map(({ file, errors }, index) => (
    <li key={`file-error-${file.name}-${index}`} className="d-block">
      <strong className="text-danger mt-2">{file.name}</strong>
      <ul className="d-block">
        {errors
          // remove the invalid type error just to show small error messaje
          .filter(({ code }) => code !== 'file-invalid-type')
          .map((e, i) => (
            <li key={`error-${e.code}-${file.name}-${index}-${i}`} className="text-danger d-block">
              <small>{e.message}</small>
            </li>
          ))}
      </ul>
    </li>
  ));

  return (
    <div
      {...(getRootProps() as any)}
      className={classNames('d-flex align-items-center justify-content-center vh-25 rounded border', styles.root, className, disabled ? 'border-gray' : 'border-dark')}>
      <input {...(getInputProps() as any)} />
      {isDragActive ? (
        <p className="text-center">Drop the files here ...</p>
      ) : (
        <div className="text-center">
          <div className={classNames(disabled ? styles.disabled : '', 'mb-2')}>
            <DropIcon />
          </div>
          <Button variant="primary-gradient rounded-pill" className={`pt-2 ${disabled ? styles.disabled : ''}`}>
            SELECT FILE
          </Button>
          <ul className="d-block text-start px-2 pt-3">{fileRejectionItems}</ul>
        </div>
      )}
    </div>
  );
};

export default DropFilesZone;
