import { CreditAuth, DocumentSignTask } from 'app/types';
import { FC, useState } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import Task from './Task';
import CreditAuthorizationAgreement from 'features/apply-now/pos/components/questions/shared/agreement/CreditAuthorizationAgreement';
import { creditAuthSignTimeStamp, machineStamp } from 'features/apply-now/pos/utils/agreeStamps';
import { useCompleteCreditAuthTaskMutation } from 'features/loan/api/tasksApi';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import { toast } from 'react-toastify';
import { ExternalTaskStatus } from 'app/types/externalTask.status';
import { useExternalAuth } from 'app/hooks/authHooks';
import MaskedField from '@rsl/core/src/components/form/MaskedField';
import TextField from '@rsl/core/src/components/form/TextField';
import ExtendedFormik from '@rsl/core/src/components/form/ExtendedFormik';
import { shortTimestamp } from '@rsl/core/src/utils/dateUtils';
import * as Yup from 'yup';

interface MyTaskProps {
  task: DocumentSignTask<CreditAuth[]>;
}

const validationSchema = Yup.object().shape({
  ssn: Yup.string().required(),
  birthDate: Yup.string().nullable(),
});

const initialValues = {
  ssn: '',
  birthDate: '',
};

const CreditAuthorizationTask: FC<MyTaskProps> = ({ task }) => {
  const [disableAgree, SetDisableAgree] = useState<boolean>(true);
  const [completeCreditAuthorizationTask] = useCompleteCreditAuthTaskMutation();
  const { email } = useExternalAuth();

  const agree = async (values: any) => {
    const creditAuthorizationTask: CreditAuth = {
      loanId: `${(task.viewModelJson as any).LoanId}`,
      loanNumber: `${(task.viewModelJson as any).LoanNumber}`,
      borrowerCreditAuthSignTimeStamp: creditAuthSignTimeStamp(
        `${(task.viewModelJson as any).BorrowerFirstName} ${(task.viewModelJson as any).BorrowerLastName}`,
        `${(task.viewModelJson as any).BorrowerEmail}`
      ),
      borrowerCreditAuthMachineStamp: await machineStamp(),
      borrowerFirstName: `${(task.viewModelJson as any).BorrowerFirstName}`,
      borrowerLastName: `${(task.viewModelJson as any).BorrowerLastName}`,
      borrowerEmail: `${(task.viewModelJson as any).BorrowerEmail}`,
      isBorrower: true,
      externalTaskId: `${task.externalTaskId}`,
      ssn: values.ssn,
      birthDate: values.birthDate,
    };

    const response = await completeCreditAuthorizationTask(creditAuthorizationTask);
    throwErrorProp(response);
    toast.success('Credit Authorization Agreed!');
    SetDisableAgree(true);
  };

  const isAssignedTask = task.assignedTo.includes(email);

  return (
    <Task name={task.name} status={task.status} type={task.taskType}>
      <Row className="mt-2">
        <Col xs={12} md={true}>
          <Card.Text className="fw-bold text-tertiary mb-0 d-md-none">
            <small>Document</small>
          </Card.Text>
          <Card.Text className="fw-bold mb-0">EDisclosure Consent</Card.Text>
        </Col>
        <Col xs={12} md={true}>
          <Card.Text className="fw-bold text-tertiary mt-2 mb-0 d-md-none">
            <small>User</small>
          </Card.Text>
          <Card.Text className="mb-0">{(task.viewModelJson as any).BorrowerFirstName}</Card.Text>
        </Col>
        <Col xs={12} md={true} className="mt-3 mt-md-0">
          <div key={`radio-econsent-agreement`} className="d-flex justify-content-start mb-3">
            {task.status === ExternalTaskStatus.Completed ? (
              <Form.Check id={`radio-econsent-agree`} type="radio" label={`I authorize this Credit Check`} checked disabled />
            ) : (
              <Form.Check id={`radio-econsent-agree`} type="radio" label={`I authorize this Credit Check`} onClick={() => SetDisableAgree(false)} disabled={!isAssignedTask} />
            )}
          </div>
          {!disableAgree && (
            <div>
              <ExtendedFormik
                formId="creditAuthForm"
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={agree}
                showSaveButton={false}
                showCancelButton={false}>
                {() => (
                  <>
                    <Row>
                      <MaskedField className="mb-2" label="Social security number" name="ssn" mask="999-99-9999" mutation={/[^0-9]+/g} required />
                    </Row>
                    <Row>
                      <TextField className="mb-2" type="date" name="birthDate" placeholder="MM/DD/YYYY" max={shortTimestamp()} required label="Date of birth" />
                    </Row>
                  </>
                )}
              </ExtendedFormik>
            </div>
          )}
          <div className="d-flex d-md-block justify-content-center mb-2 mb-md-0">
            <Button type="submit" form="creditAuthForm" variant="primary-gradient" disabled={disableAgree} className="rounded-pill px-4 my-2">
              AGREE
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <CreditAuthorizationAgreement />
        </Col>
      </Row>
    </Task>
  );
};

export default CreditAuthorizationTask;
