import { FC } from 'react';
import { ListGroup } from 'react-bootstrap';
import { IFileDownload } from '../types';
import DownloadLink from '@rsl/core/src/components/link/DownloadLink';
import { formatDateFriendly } from '@rsl/core/src/utils/dateUtils';
import { useExternalAuth } from 'app/hooks/authHooks';
import { useCurrentLoanId } from 'app/hooks/loanHooks';
import { useFetchDownloadFilesQuery } from '../api/fileshareApi';

const FileShared: FC = () => {
  const { token, deviceId } = useExternalAuth();
  const loanId = useCurrentLoanId();
  const { data: filesShared } = useFetchDownloadFilesQuery(loanId, { skip: !loanId });

  const getUrl = (file: IFileDownload) => {
    return `/FileShareExternal/${file.loanId}/files/${file.communicationLogAttachmentId}/download`;
  };

  return (
    <ListGroup>
      {filesShared?.map((file, index) => {
        const fileUrl = getUrl(file);
        return (
          <ListGroup.Item key={`download-file-${index}`} className="py-md-3 d-flex flex-column flex-md-row justify-content-between text-break">
            <div>
              <DownloadLink href={fileUrl} filename={file.filename} className="text-start p-0" token={token} deviceId={deviceId} />
            </div>
            <div>
              {file.sender}, {formatDateFriendly(file.sentDateTimeUtc)}
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default FileShared;
