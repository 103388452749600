import { ExternalTaskStatus } from 'app/types/externalTask.status';
import { useState, useEffect } from 'react';
import { FC } from 'react';
import { Card, Collapse, Row, Col, Button } from 'react-bootstrap';
import { TaskTypes } from 'app/types';

interface TaskProps {
  name: string;
  status: string;
  type?: string;
  children: React.ReactNode;
}

const Task: FC<TaskProps> = (taskProps) => {
  const [collapsed, setCollapsed] = useState(taskProps.status === ExternalTaskStatus.Completed);
  const toggleCollapse = () => setCollapsed(!collapsed);

  useEffect(() => {
    if (taskProps.status === ExternalTaskStatus.Completed) {
      setCollapsed(true);
    }
  }, [taskProps.status]);

  return (
    <>
      {taskProps.status !== ExternalTaskStatus.Canceled && (
        <Card className="rounded-3 shadow-0 border-1">
          <Card.Body>
            <Card.Title className="text-secondary">
              <Row className="border-bottom border-2 pb-3 pt-2">
                <Col xs={6} className="d-flex align-items-center">
                  <p className="mb-0">
                    <strong>{taskProps.name}</strong>
                  </p>
                </Col>
                <Col xs={6}>
                  <p className="text-end mb-0 text-primary">
                    <small>{taskProps.status}</small>
                    <Button onClick={toggleCollapse} variant="outline-primary" className="ms-1 text-primary" size="sm">
                      {collapsed ? <i className="fas fa-plus"></i> : <i className="fas fa-minus"></i>}
                    </Button>
                  </p>
                </Col>
              </Row>
            </Card.Title>
            <Collapse in={!collapsed}>
              <div>
                <Row className="pb-3 pt-2 border-bottom d-none d-md-flex">
                  <Col className="text-tertiary">
                    <small>
                      <strong>Document</strong>
                    </small>
                  </Col>
                  <Col className="text-tertiary">
                    <small>
                      <strong>{taskProps.type === TaskTypes.CreditAuthorization ? 'User' : 'Signer'}</strong>
                    </small>
                  </Col>
                  <Col className="text-tertiary">
                    <small>
                      <strong>Action</strong>
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col>{taskProps.children}</Col>
                </Row>
              </div>
            </Collapse>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default Task;
