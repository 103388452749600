import { useCurrentLoanId } from 'app/hooks/loanHooks';
import { FC } from 'react';
import { useFetchExternalLoanTasksQuery } from '../api/tasksApi';
import TaskOutlet from './components/TaskOutlet';
import { Container } from 'react-bootstrap';

const MyTasks: FC = () => {
  const currentLoanId = useCurrentLoanId();
  const { data } = useFetchExternalLoanTasksQuery(currentLoanId ?? '', { skip: !currentLoanId });

  return (
    <Container fluid className="mt-4 px-0 mx-0 w-100">
      {data?.map((task, index) => (
        <TaskOutlet task={task} key={`task-${index}`} />
      ))}
    </Container>
  );
};

export default MyTasks;
