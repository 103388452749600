import { useLoanRoleSecureHasAccess } from 'app/hooks/authHooks';
import { LoanRoles } from 'app/types';
import NotFound from 'features/error-pages/NotFound';
import { FC } from 'react';

interface LoanroleSecureProps {
  roles: LoanRoles[];
  showNotFound?: boolean;
  currentRole?: LoanRoles;
}

const LoanRoleSecure: FC<LoanroleSecureProps> = ({ roles, showNotFound = false, currentRole, children }) => {
  const valid = useLoanRoleSecureHasAccess(roles, currentRole);

  return (
    <>
      {valid && children}
      {!valid && showNotFound && NotFound}
    </>
  );
};

export default LoanRoleSecure;
