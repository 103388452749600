import * as Question from '../components/questions';
import * as Yup from 'yup';
import { IConfigType, ILoanQuestionsMapType, IEmploymentType } from '@rsl/core/src/types';
import { QuestionValues, RegexConstants, RefinanceStepTitles } from '@rsl/core/src/constants';
import { ValidationErrors } from '@rsl/core/src/constants/errors';
import QuestionLayout from '../components/layouts/QuestionLayout';
import { noNeedToFillCoborrower, inputDateIsLessThanTwoYears, isLessThan24Months, isJointAssetLiabilityReportingIndicatorTrue } from '../utils/loanMapperUtils';
import { getAssetValidation } from '../utils/loanValidationUtils';
import { getDate } from '@rsl/core/src/utils/dateUtils';

Yup.setLocale({
  mixed: {
    required: ValidationErrors.Required,
  },
  number: {
    integer: ValidationErrors.Integer,
    positive: ValidationErrors.Positive,
    min: ValidationErrors.Min,
    max: ValidationErrors.Max,
  },
  string: {
    min: ValidationErrors.Min,
    max: ValidationErrors.Max,
    email: ValidationErrors.Email,
  },
});

const RefinanceConfig: IConfigType = {
  steps: [
    {
      // step: 0
      questions: (
        <>
          <Question.RefinanceW1 />
          <Question.RefinanceL1 showIf={[{ index: 'RefinanceW1_1', operator: '===', value: QuestionValues.TakeCashOutToPayOtherExpenses }]} />
          <Question.SharedW2 />
        </>
      ),
      validations: {
        RefinanceW1_1: Yup.string().required(),
        RefinanceL1_1: Yup.mixed().when('RefinanceW1_1', {
          is: QuestionValues.TakeCashOutToPayOtherExpenses,
          then: Yup.number().positive().max(10000000).required(),
        }),
        SharedW2_1: Yup.string().max(100).required(),
        SharedW2_2: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        SharedW2_3: Yup.string().required(),
        SharedW2_4: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        SharedW2_5: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
      },
      title: RefinanceStepTitles[0],
    },
    {
      // step: 1
      questions: (
        <>
          <Question.SharedW3 />
          <Question.SharedW4 />
          <Question.SharedW4a showIf={[{ index: 'SharedW4_1', operator: '===', value: QuestionValues.CooperativePropertyType }]} />
          <Question.RefinanceW5 />
        </>
      ),
      validations: {
        SharedW3_1: Yup.string().required(),
        SharedW4_1: Yup.string().required(),
        SharedW4a_1: Yup.mixed().when('SharedW4_1', {
          is: QuestionValues.CooperativePropertyType,
          then: Yup.string().required(),
        }),
        RefinanceW5_1: Yup.string().required(),
      },
      title: RefinanceStepTitles[1],
    },
    {
      // step: 2
      questions: (
        <>
          <Question.SharedB1 />
          <Question.SharedB1b />
          <Question.SharedB1d />
          <Question.SharedB1c />
          <Question.SharedB1i showIf={[{ index: 'SharedB1c_1', operator: '===', value: QuestionValues.CurrentlyRent }]} />
          <Question.SharedB1j />
        </>
      ),
      validations: {
        SharedB1_1: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        SharedB1_2: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50),
        SharedB1_3: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        SharedB1_4: Yup.string().matches(RegexConstants.Phone, ValidationErrors.Phone).max(50),
        SharedB1_5: Yup.string().email().required(),
        SharedB1_6: Yup.string().matches(RegexConstants.Phone, ValidationErrors.Phone).max(50),
        SharedB1b_1: Yup.string().max(100).required(),
        SharedB1b_2: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        SharedB1b_3: Yup.string().required(),
        SharedB1b_4: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        SharedB1b_5: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        SharedB1d_1: Yup.number().min(0).max(99).required(),
        SharedB1d_2: Yup.number().min(0).max(11).required(),
        SharedB1c_1: Yup.string().required(),
        SharedB1i_1: Yup.mixed().when(['SharedB1c_1'], {
          is: (SharedB1c_1: string) => SharedB1c_1 === QuestionValues.CurrentlyRent,
          then: Yup.number().min(1).max(10000000).required(),
        }),
        SharedB1j_1: Yup.mixed().when('SharedB1j_6', {
          is: false,
          then: Yup.string().max(100).required(),
        }),
        SharedB1j_2: Yup.mixed().when('SharedB1j_6', {
          is: false,
          then: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        }),
        SharedB1j_3: Yup.mixed().when('SharedB1j_6', {
          is: false,
          then: Yup.string().required(),
        }),
        SharedB1j_4: Yup.mixed().when('SharedB1j_6', {
          is: false,
          then: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        }),
        SharedB1j_5: Yup.mixed().when('SharedB1j_6', {
          is: false,
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedB1j_6: Yup.boolean().required(),
      },
      title: RefinanceStepTitles[2],
    },
    {
      // step: 3
      questions: (
        <>
          <Question.SharedB1f />
        </>
      ),
      validations: {
        SharedB1fa_1: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2'], {
          is: (SharedB1d_1: number, SharedB1d_2: number) => isLessThan24Months(SharedB1d_1, SharedB1d_2),
          then: Yup.string().max(100).required(),
        }),
        SharedB1fa_2: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2'], {
          is: (SharedB1d_1: number, SharedB1d_2: number) => isLessThan24Months(SharedB1d_1, SharedB1d_2),
          then: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        }),
        SharedB1fa_3: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2'], {
          is: (SharedB1d_1: number, SharedB1d_2: number) => isLessThan24Months(SharedB1d_1, SharedB1d_2),
          then: Yup.string().required(),
        }),
        SharedB1fa_4: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2'], {
          is: (SharedB1d_1: number, SharedB1d_2: number) => isLessThan24Months(SharedB1d_1, SharedB1d_2),
          then: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        }),
        SharedB1fa_5: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2'], {
          is: (SharedB1d_1: number, SharedB1d_2: number) => isLessThan24Months(SharedB1d_1, SharedB1d_2),
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedB1fa_6: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2'], {
          is: (SharedB1d_1: number, SharedB1d_2: number) => isLessThan24Months(SharedB1d_1, SharedB1d_2),
          then: Yup.string().required(),
        }),
        SharedB1fa_7: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2'], {
          is: (SharedB1d_1: number, SharedB1d_2: number) => isLessThan24Months(SharedB1d_1, SharedB1d_2),
          then: Yup.number().min(0).max(99).required(),
        }),
        SharedB1fa_8: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2'], {
          is: (SharedB1d_1: number, SharedB1d_2: number) => isLessThan24Months(SharedB1d_1, SharedB1d_2),
          then: Yup.number().min(0).max(11).required(),
        }),
        SharedB1fb_1: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7], [SharedB1d_2, SharedB1fa_8]),
          then: Yup.string().max(100).required(),
        }),
        SharedB1fb_2: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7], [SharedB1d_2, SharedB1fa_8]),
          then: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        }),
        SharedB1fb_3: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7], [SharedB1d_2, SharedB1fa_8]),
          then: Yup.string().required(),
        }),
        SharedB1fb_4: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7], [SharedB1d_2, SharedB1fa_8]),
          then: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        }),
        SharedB1fb_5: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7], [SharedB1d_2, SharedB1fa_8]),
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedB1fb_6: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7], [SharedB1d_2, SharedB1fa_8]),
          then: Yup.string().required(),
        }),
        SharedB1fb_7: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7], [SharedB1d_2, SharedB1fa_8]),
          then: Yup.number().min(0).max(99).required(),
        }),
        SharedB1fb_8: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7], [SharedB1d_2, SharedB1fa_8]),
          then: Yup.number().min(0).max(11).required(),
        }),
        SharedB1fc_1: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8', 'SharedB1fb_7', 'SharedB1fb_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number, SharedB1fb_7: number, SharedB1fb_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7, SharedB1fb_7], [SharedB1d_2, SharedB1fa_8, SharedB1fb_8]),
          then: Yup.string().max(100).required(),
        }),
        SharedB1fc_2: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8', 'SharedB1fb_7', 'SharedB1fb_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number, SharedB1fb_7: number, SharedB1fb_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7, SharedB1fb_7], [SharedB1d_2, SharedB1fa_8, SharedB1fb_8]),
          then: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        }),
        SharedB1fc_3: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8', 'SharedB1fb_7', 'SharedB1fb_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number, SharedB1fb_7: number, SharedB1fb_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7, SharedB1fb_7], [SharedB1d_2, SharedB1fa_8, SharedB1fb_8]),
          then: Yup.string().required(),
        }),
        SharedB1fc_4: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8', 'SharedB1fb_7', 'SharedB1fb_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number, SharedB1fb_7: number, SharedB1fb_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7, SharedB1fb_7], [SharedB1d_2, SharedB1fa_8, SharedB1fb_8]),
          then: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        }),
        SharedB1fc_5: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8', 'SharedB1fb_7', 'SharedB1fb_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number, SharedB1fb_7: number, SharedB1fb_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7, SharedB1fb_7], [SharedB1d_2, SharedB1fa_8, SharedB1fb_8]),
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedB1fc_6: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8', 'SharedB1fb_7', 'SharedB1fb_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number, SharedB1fb_7: number, SharedB1fb_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7, SharedB1fb_7], [SharedB1d_2, SharedB1fa_8, SharedB1fb_8]),
          then: Yup.string().required(),
        }),
        SharedB1fc_7: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8', 'SharedB1fb_7', 'SharedB1fb_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number, SharedB1fb_7: number, SharedB1fb_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7, SharedB1fb_7], [SharedB1d_2, SharedB1fa_8, SharedB1fb_8]),
          then: Yup.number().min(0).max(99).required(),
        }),
        SharedB1fc_8: Yup.mixed().when(['SharedB1d_1', 'SharedB1d_2', 'SharedB1fa_7', 'SharedB1fa_8', 'SharedB1fb_7', 'SharedB1fb_8'], {
          is: (SharedB1d_1: number, SharedB1d_2: number, SharedB1fa_7: number, SharedB1fa_8: number, SharedB1fb_7: number, SharedB1fb_8: number) =>
            isLessThan24Months([SharedB1d_1, SharedB1fa_7, SharedB1fb_7], [SharedB1d_2, SharedB1fa_8, SharedB1fb_8]),
          then: Yup.number().min(0).max(11).required(),
        }),
      },
      title: RefinanceStepTitles[34],
      skip: (values: ILoanQuestionsMapType) => {
        return !isLessThan24Months(values.SharedB1d_1, values.SharedB1d_2);
      },
    },
    {
      // step: 4
      questions: (
        <>
          <Question.SharedB2 />
          <Question.SharedB3 />
          <Question.SharedB4 />
          <Question.SharedB4a
            showIf={[
              { index: 'SharedB4_1', operator: '!!', value: '' },
              { index: 'SharedB4_1', operator: '>', value: '0' },
            ]}
          />
        </>
      ),
      validations: {
        SharedB2_1: Yup.string().required(),
        SharedB3_1: Yup.string().required().nullable(),
        SharedB4_1: Yup.number().min(0).max(25).required(),
        SharedB4a_1: Yup.string().test('SharedB4a_1', 'Invalid ages', function (value) {
          let SharedB4_1 = this.resolve(Yup.ref('SharedB4_1'));

          if (!Number(SharedB4_1)) {
            return true;
          }

          let SharedB4a_1 = String(this.resolve(Yup.ref('SharedB4a_1'))).replace(/\s/g, '');

          if (!RegexConstants.Ages.test(SharedB4a_1)) {
            return false;
          }

          if (SharedB4a_1.split(',').length !== Number(SharedB4_1)) {
            return false;
          }

          if (SharedB4a_1.split(',').find((x) => Number(x) > 99)) {
            return false;
          }

          return true;
        }),
      },
      title: RefinanceStepTitles[3],
    },
    {
      // step: 5
      questions: (
        <>
          <Question.SharedB5 />
          <Question.SharedB5a showIf={[{ index: 'SharedB5_1', operator: '===', value: QuestionValues.Yes }]} />
          <Question.SharedB5c showIf={[{ index: 'SharedB5_1', operator: '===', value: QuestionValues.Yes }]} />
        </>
      ),
      validations: {
        SharedB5_1: Yup.string().required().nullable(),
        SharedB5a_1: Yup.mixed().when('SharedB5_1', {
          is: QuestionValues.Yes,
          then: Yup.string().required().nullable(),
        }),
        SharedB5c_1: Yup.mixed().when('SharedB5_1', {
          is: QuestionValues.Yes,
          then: Yup.string().required().nullable(),
        }),
      },
      title: RefinanceStepTitles[4],
    },
    {
      // step: 6
      questions: (
        <>
          <Question.SharedB6 />
          <QuestionLayout>
            <QuestionLayout.Body>
              <Question.SharedB6a />
              <Question.SharedB6b />
              <Question.SharedB6c />
              <Question.SharedB6d />
              <Question.SharedB6f />
              <Question.B6eCompute />
            </QuestionLayout.Body>
          </QuestionLayout>
        </>
      ),
      validations: {
        ShowSharedB6a_: Yup.boolean(),
        SharedB6a_1: Yup.number().nullable(),
        atLeastOneBorrowerEmployment: Yup.boolean().test('atLeastOneBorrowerEmployment', 'atLeastOneBorrowerEmployment', function (value) {
          let showSharedB6b_ = this.resolve(Yup.ref('ShowSharedB6b_')) as boolean;
          let sharedB6b_1 = this.resolve(Yup.ref('SharedB6b_1')) as number;
          let isAddingNewSelfEmployment = showSharedB6b_ && sharedB6b_1 === -1;

          if (isAddingNewSelfEmployment) {
            return true;
          }

          let showSharedB6a_ = this.resolve(Yup.ref('ShowSharedB6a_')) as boolean;
          let sharedB6a_1 = this.resolve(Yup.ref('SharedB6a_1')) as number;
          let currentEmployment = (this.resolve(Yup.ref('employment')) as IEmploymentType[]).filter(
            (employment: IEmploymentType) => !!employment.currentEmploymentIndicator && !employment.selfEmployedIndicator
          );
          return showSharedB6a_ ? (sharedB6a_1 === -1 ? true : !!currentEmployment.length) : true;
        }),
        ShowSharedB6b_: Yup.boolean(),
        SharedB6b_1: Yup.number().nullable(),
        atLeastOneBorrowerSelfEmployment: Yup.boolean().test('atLeastOneBorrowerSelfEmployment', 'atLeastOneBorrowerSelfEmployment', function (value) {
          let showSharedB6a_ = this.resolve(Yup.ref('ShowSharedB6a_')) as boolean;
          let sharedB6a_1 = this.resolve(Yup.ref('SharedB6a_1')) as number;
          let isAddingNewCurrentEmployment = showSharedB6a_ && sharedB6a_1 === -1;

          if (isAddingNewCurrentEmployment) {
            return true;
          }

          let showSharedB6b_ = this.resolve(Yup.ref('ShowSharedB6b_')) as boolean;
          let sharedB6b_1 = this.resolve(Yup.ref('SharedB6b_1')) as number;
          let selfEmployment = (this.resolve(Yup.ref('employment')) as IEmploymentType[]).filter(
            (employment: IEmploymentType) => !employment.currentEmploymentIndicator && !!employment.selfEmployedIndicator
          );
          return showSharedB6b_ ? (sharedB6b_1 === -1 ? true : !!selfEmployment.length) : true;
        }),
        ShowSharedB6c_: Yup.boolean(),
        SharedB6c_1: Yup.mixed().when('ShowSharedB6c_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedB6c_2: Yup.mixed().when('ShowSharedB6c_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedB6c_3: Yup.mixed().when('ShowSharedB6c_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        ShowSharedB6d_: Yup.boolean(),
        SharedB6d_1: Yup.mixed().when('ShowSharedB6d_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedB6d_2: Yup.mixed().when('ShowSharedB6d_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedB6d_3: Yup.mixed().when('ShowSharedB6d_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        ShowSharedB6f_: Yup.boolean(),
        atLeastOneChecked: Yup.boolean().test('atLeastOneChecked', 'atLeastOneChecked', function (value) {
          let ShowSharedB6a_ = this.resolve(Yup.ref('ShowSharedB6a_'));
          let ShowSharedB6b_ = this.resolve(Yup.ref('ShowSharedB6b_'));
          let ShowSharedB6c_ = this.resolve(Yup.ref('ShowSharedB6c_'));
          let ShowSharedB6d_ = this.resolve(Yup.ref('ShowSharedB6d_'));
          let ShowSharedB6f_ = this.resolve(Yup.ref('ShowSharedB6f_'));

          return !!ShowSharedB6a_ || !!ShowSharedB6b_ || !!ShowSharedB6c_ || !!ShowSharedB6d_ || !!ShowSharedB6f_;
        }),
        atLeastOneB6cIncome: Yup.boolean().test('atLeastOneB6cIncome', 'atLeastOneB6cIncome', function (value) {
          let ShowSharedB6c_ = this.resolve(Yup.ref('ShowSharedB6c_'));
          let SharedB6c_1 = this.resolve(Yup.ref('SharedB6c_1'));
          let SharedB6c_2 = this.resolve(Yup.ref('SharedB6c_2'));
          let SharedB6c_3 = this.resolve(Yup.ref('SharedB6c_3'));

          return !!ShowSharedB6c_ ? !!SharedB6c_1 || !!SharedB6c_2 || !!SharedB6c_3 : true;
        }),
        atLeastOneB6dIncome: Yup.boolean().test('atLeastOneB6dIncome', 'atLeastOneB6dIncome', function (value) {
          let ShowSharedB6d_ = this.resolve(Yup.ref('ShowSharedB6d_'));
          let SharedB6d_1 = this.resolve(Yup.ref('SharedB6d_1'));
          let SharedB6d_2 = this.resolve(Yup.ref('SharedB6d_2'));
          let SharedB6d_3 = this.resolve(Yup.ref('SharedB6d_3'));

          return !!ShowSharedB6d_ ? !!SharedB6d_1 || !!SharedB6d_2 || !!SharedB6d_3 : true;
        }),
      },
      title: RefinanceStepTitles[5],
    },
    {
      // step: 7
      questions: (
        <>
          <Question.SharedE1 />
        </>
      ),
      validations: {
        SharedE1_1: Yup.string().required(),
        SharedE1_2: Yup.string().required(),
        SharedE1_3: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        SharedE1_4: Yup.string().required(),
        SharedE1_5: Yup.string().required(),
        SharedE1_6: Yup.string().required(),
        SharedE1_7: Yup.string().required(),
        SharedE1_8: Yup.string().required(),
        SharedE1_9: Yup.string().required(),
        SharedE1_10: Yup.number().integer().min(0).max(100).required(),
        SharedE1_11: Yup.number().positive().max(10000000).required(),
        SharedE1_12: Yup.number().positive().max(10000000).nullable(),
        SharedE1_13: Yup.number().positive().max(10000000).nullable(),
        SharedE1_14: Yup.number().positive().max(10000000).nullable(),
        SharedE1_15: Yup.number().integer().min(0).max(11).required(),
      },
      title: RefinanceStepTitles[6],
      skip: (values: ILoanQuestionsMapType) => {
        return values.SharedB6a_1 == null;
      },
      jumpToStep: (values: ILoanQuestionsMapType) => {
        const step = 6;
        return step;
      },
    },
    {
      // step: 8
      questions: (
        <>
          <Question.SharedE2 />
        </>
      ),
      validations: {
        SharedE2_1: Yup.string().required(),
        SharedE2_2: Yup.string().required(),
        SharedE2_3: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        SharedE2_4: Yup.string().required(),
        SharedE2_5: Yup.string().required(),
        SharedE2_6: Yup.string().required(),
        SharedE2_7: Yup.string().required(),
        SharedE2_8: Yup.string().required(),
        SharedE2_9: Yup.string().required(),
        SharedE2_10: Yup.number().integer().min(0).max(100).required(),
        SharedE2_11: Yup.string().required(),
        SharedE2_12: Yup.number().positive().max(10000000).required(),
        SharedE2_13: Yup.number().integer().min(0).max(11).required(),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return values.SharedB6b_1 == null;
      },
      jumpToStep: (values: ILoanQuestionsMapType) => {
        const step = 6;
        return step;
      },
      title: RefinanceStepTitles[7],
    },
    {
      // step: 9
      questions: (
        <>
          <Question.SharedE3 />
        </>
      ),
      validations: {
        SharedE3a_1: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3a_2: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3a_3: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedE3a_4: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3a_5: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3a_6: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3a_7: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3a_8: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3a_9: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3a_10: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(100).required(),
        }),
        SharedE3a_11: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3a_13: Yup.mixed().when('SharedE3a_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(11).required(),
        }),
        SharedE3a_12: Yup.string().required().nullable(),
        SharedE3b_1: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3b_2: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3b_3: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedE3b_4: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3b_5: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3b_6: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3b_7: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3b_8: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3b_9: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3b_10: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(100).required(),
        }),
        SharedE3b_11: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3b_13: Yup.mixed().when('SharedE3b_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(11).required(),
        }),
        SharedE3b_12: Yup.mixed().when('SharedE3a_9', {
          is: (SharedE3a_9: string) => inputDateIsLessThanTwoYears(SharedE3a_9),
          then: Yup.string().required().nullable(),
        }),
        SharedE3c_1: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3c_2: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3c_3: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedE3c_4: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3c_5: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3c_6: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3c_7: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3c_8: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3c_9: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3c_10: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(100).required(),
        }),
        SharedE3c_11: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedE3c_13: Yup.mixed().when('SharedE3c_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(11).required(),
        }),
        SharedE3c_12: Yup.mixed().when('SharedE3b_9', {
          is: (SharedE3b_9: string) => inputDateIsLessThanTwoYears(SharedE3b_9),
          then: Yup.string().required().nullable(),
        }),
      },
      title: RefinanceStepTitles[6],
      skip: (values: ILoanQuestionsMapType) => {
        const hasPreviousEmployment = values.employment.some((employment: IEmploymentType) => !employment.currentEmploymentIndicator && !employment.selfEmployedIndicator);

        if (hasPreviousEmployment) {
          return false;
        }

        return values.employment.some((employment: IEmploymentType) => !inputDateIsLessThanTwoYears(getDate(employment.employmentStartDate)));
      },
    },
    {
      // step: 10
      questions: (
        <>
          <Question.SharedB7 />
          <Question.SharedB8
            showIf={[
              { index: 'SharedB7_1', operator: '===', value: QuestionValues.ApplyingWithPerson },
              { index: 'SharedB3_1', operator: '===', value: QuestionValues.Married },
              { index: 'isCoborrowerInvite', operator: '!==', value: true },
            ]}
          />
          <Question.SharedB8a
            showIf={[
              { index: 'SharedB7_1', operator: '===', value: QuestionValues.ApplyingWithPerson },
              { index: 'isCoborrowerInvite', operator: '!==', value: true },
              { index: 'SharedB8_1', operator: '!==', value: QuestionValues.Yes },
              { index: 'SharedB8_1', operator: '===', value: QuestionValues.Other },
            ]}
          />
          <Question.SharedB8b
            showIf={[
              { index: 'SharedB7_1', operator: '===', value: QuestionValues.ApplyingWithPerson },
              { index: 'SharedB8a_1', operator: '===', value: QuestionValues.Yes },
              { index: 'isCoborrowerInvite', operator: '!==', value: true },
            ]}
          />
        </>
      ),
      validations: {
        SharedB7_1: Yup.string().required().nullable(),
        SharedB8_1: Yup.mixed().when(['SharedB7_1', 'SharedB3_1', 'isCoborrowerInvite'], {
          is: (SharedB7_1: string, SharedB3_1: string, isCoborrowerInvite: boolean) =>
            SharedB7_1 === QuestionValues.ApplyingWithPerson && SharedB3_1 === QuestionValues.Married && isCoborrowerInvite !== true,
          then: Yup.string().required().nullable(),
        }),
        SharedB8a_1: Yup.mixed().when(['SharedB7_1', 'isCoborrowerInvite'], {
          is: (SharedB7_1: string, isCoborrowerInvite: boolean) => SharedB7_1 === QuestionValues.ApplyingWithPerson && isCoborrowerInvite !== true,
          then: Yup.string().required().nullable(),
        }),
        SharedB8b_1: Yup.mixed().when(['SharedB7_1', 'SharedB8a_1', 'isCoborrowerInvite'], {
          is: (SharedB7_1: string, SharedB8a_1: string, isCoborrowerInvite: boolean) =>
            SharedB7_1 === QuestionValues.ApplyingWithPerson && SharedB8a_1 === QuestionValues.Yes && isCoborrowerInvite !== true,
          then: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        }),
        SharedB8b_2: Yup.mixed().when(['SharedB7_1', 'SharedB8a_1', 'isCoborrowerInvite'], {
          is: (SharedB7_1: string, SharedB8a_1: string, isCoborrowerInvite: boolean) =>
            SharedB7_1 === QuestionValues.ApplyingWithPerson && SharedB8a_1 === QuestionValues.Yes && isCoborrowerInvite !== true,
          then: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50),
        }),
        SharedB8b_3: Yup.mixed().when(['SharedB7_1', 'SharedB8a_1', 'isCoborrowerInvite'], {
          is: (SharedB7_1: string, SharedB8a_1: string, isCoborrowerInvite: boolean) =>
            SharedB7_1 === QuestionValues.ApplyingWithPerson && SharedB8a_1 === QuestionValues.Yes && isCoborrowerInvite !== true,
          then: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        }),
        SharedB8b_4: Yup.mixed().when(['SharedB7_1', 'SharedB8a_1', 'isCoborrowerInvite'], {
          is: (SharedB7_1: string, SharedB8a_1: string, isCoborrowerInvite: boolean) =>
            SharedB7_1 === QuestionValues.ApplyingWithPerson && SharedB8a_1 === QuestionValues.Yes && isCoborrowerInvite !== true,
          then: Yup.string().matches(RegexConstants.Phone, ValidationErrors.Phone).max(50),
        }),
        SharedB8b_5: Yup.mixed().when(['SharedB7_1', 'SharedB8a_1', 'isCoborrowerInvite'], {
          is: (SharedB7_1: string, SharedB8a_1: string, isCoborrowerInvite: boolean) =>
            SharedB7_1 === QuestionValues.ApplyingWithPerson && SharedB8a_1 === QuestionValues.Yes && isCoborrowerInvite !== true,
          then: Yup.string().email().required(),
        }),
        SharedB8b_6: Yup.mixed().when(['SharedB7_1', 'SharedB8a_1', 'isCoborrowerInvite'], {
          is: (SharedB7_1: string, SharedB8a_1: string, isCoborrowerInvite: boolean) =>
            SharedB7_1 === QuestionValues.ApplyingWithPerson && SharedB8a_1 === QuestionValues.Yes && isCoborrowerInvite !== true,
          then: Yup.string().matches(RegexConstants.Phone, ValidationErrors.Phone).max(50),
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return !!values.isCoborrowerInvite && values.SharedB3_1 !== QuestionValues.Married;
      },
      title: RefinanceStepTitles[8],
    },
    {
      // step: 11
      questions: (
        <>
          <Question.SharedC1 />
          <Question.SharedC1a />
          <Question.SharedC1b />
          <Question.SharedC1d />
          <Question.SharedC1c />
          <Question.SharedC1i showIf={[{ index: 'SharedC1c_1', operator: '===', value: QuestionValues.CurrentlyRent }]} />
          <Question.SharedC1j />
        </>
      ),
      validations: {
        SharedC1_1: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        SharedC1_2: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50),
        SharedC1_3: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        SharedC1_4: Yup.string().matches(RegexConstants.Phone, ValidationErrors.Phone).max(50),
        SharedC1_5: Yup.string().email().required(),
        SharedC1_6: Yup.string().matches(RegexConstants.Phone, ValidationErrors.Phone).max(50),
        SharedC1a_1: Yup.boolean(),
        SharedC1b_1: Yup.string().max(100).required(),
        SharedC1b_2: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        SharedC1b_3: Yup.string().required(),
        SharedC1b_4: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        SharedC1b_5: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        SharedC1d_1: Yup.number().min(0).max(99).required(),
        SharedC1d_2: Yup.number().min(0).max(11).required(),
        SharedC1c_1: Yup.string().required(),
        SharedC1i_1: Yup.mixed().when(['SharedC1c_1'], {
          is: (SharedC1c_1: string) => SharedC1c_1 === QuestionValues.CurrentlyRent,
          then: Yup.number().min(1).max(10000000).required(),
        }),
        SharedC1j_1: Yup.mixed().when('SharedC1j_6', {
          is: false,
          then: Yup.string().max(100).required(),
        }),
        SharedC1j_2: Yup.mixed().when('SharedC1j_6', {
          is: false,
          then: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        }),
        SharedC1j_3: Yup.mixed().when('SharedC1j_6', {
          is: false,
          then: Yup.string().required(),
        }),
        SharedC1j_4: Yup.mixed().when('SharedC1j_6', {
          is: false,
          then: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        }),
        SharedC1j_5: Yup.mixed().when('SharedC1j_6', {
          is: false,
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedC1j_6: Yup.boolean().required(),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values);
      },
      title: RefinanceStepTitles[9],
    },
    {
      // step: 12
      questions: (
        <>
          <Question.SharedC1f />
        </>
      ),
      validations: {
        SharedC1fa_1: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2'], {
          is: (SharedC1d_1: number, SharedC1d_2: number) => isLessThan24Months(SharedC1d_1, SharedC1d_2),
          then: Yup.string().max(100).required(),
        }),
        SharedC1fa_2: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2'], {
          is: (SharedC1d_1: number, SharedC1d_2: number) => isLessThan24Months(SharedC1d_1, SharedC1d_2),
          then: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        }),
        SharedC1fa_3: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2'], {
          is: (SharedC1d_1: number, SharedC1d_2: number) => isLessThan24Months(SharedC1d_1, SharedC1d_2),
          then: Yup.string().required(),
        }),
        SharedC1fa_4: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2'], {
          is: (SharedC1d_1: number, SharedC1d_2: number) => isLessThan24Months(SharedC1d_1, SharedC1d_2),
          then: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        }),
        SharedC1fa_5: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2'], {
          is: (SharedC1d_1: number, SharedC1d_2: number) => isLessThan24Months(SharedC1d_1, SharedC1d_2),
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedC1fa_6: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2'], {
          is: (SharedC1d_1: number, SharedC1d_2: number) => isLessThan24Months(SharedC1d_1, SharedC1d_2),
          then: Yup.string().required(),
        }),
        SharedC1fa_7: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2'], {
          is: (SharedC1d_1: number, SharedC1d_2: number) => isLessThan24Months(SharedC1d_1, SharedC1d_2),
          then: Yup.number().min(0).max(99).required(),
        }),
        SharedC1fa_8: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2'], {
          is: (SharedC1d_1: number, SharedC1d_2: number) => isLessThan24Months(SharedC1d_1, SharedC1d_2),
          then: Yup.number().min(0).max(11).required(),
        }),
        SharedC1fb_1: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7], [SharedC1d_2, SharedC1fa_8]),
          then: Yup.string().max(100).required(),
        }),
        SharedC1fb_2: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7], [SharedC1d_2, SharedC1fa_8]),
          then: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        }),
        SharedC1fb_3: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7], [SharedC1d_2, SharedC1fa_8]),
          then: Yup.string().required(),
        }),
        SharedC1fb_4: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7], [SharedC1d_2, SharedC1fa_8]),
          then: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        }),
        SharedC1fb_5: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7], [SharedC1d_2, SharedC1fa_8]),
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedC1fb_6: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7], [SharedC1d_2, SharedC1fa_8]),
          then: Yup.string().required(),
        }),
        SharedC1fb_7: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7], [SharedC1d_2, SharedC1fa_8]),
          then: Yup.number().min(0).max(99).required(),
        }),
        SharedC1fb_8: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7], [SharedC1d_2, SharedC1fa_8]),
          then: Yup.number().min(0).max(11).required(),
        }),
        SharedC1fc_1: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8', 'SharedC1fb_7', 'SharedC1fb_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number, SharedC1fb_7: number, SharedC1fb_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7, SharedC1fb_7], [SharedC1d_2, SharedC1fa_8, SharedC1fb_8]),
          then: Yup.string().max(100).required(),
        }),
        SharedC1fc_2: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8', 'SharedC1fb_7', 'SharedC1fb_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number, SharedC1fb_7: number, SharedC1fb_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7, SharedC1fb_7], [SharedC1d_2, SharedC1fa_8, SharedC1fb_8]),
          then: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        }),
        SharedC1fc_3: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8', 'SharedC1fb_7', 'SharedC1fb_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number, SharedC1fb_7: number, SharedC1fb_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7, SharedC1fb_7], [SharedC1d_2, SharedC1fa_8, SharedC1fb_8]),
          then: Yup.string().required(),
        }),
        SharedC1fc_4: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8', 'SharedC1fb_7', 'SharedC1fb_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number, SharedC1fb_7: number, SharedC1fb_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7, SharedC1fb_7], [SharedC1d_2, SharedC1fa_8, SharedC1fb_8]),
          then: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        }),
        SharedC1fc_5: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8', 'SharedC1fb_7', 'SharedC1fb_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number, SharedC1fb_7: number, SharedC1fb_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7, SharedC1fb_7], [SharedC1d_2, SharedC1fa_8, SharedC1fb_8]),
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedC1fc_6: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8', 'SharedC1fb_7', 'SharedC1fb_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number, SharedC1fb_7: number, SharedC1fb_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7, SharedC1fb_7], [SharedC1d_2, SharedC1fa_8, SharedC1fb_8]),
          then: Yup.string().required(),
        }),
        SharedC1fc_7: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8', 'SharedC1fb_7', 'SharedC1fb_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number, SharedC1fb_7: number, SharedC1fb_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7, SharedC1fb_7], [SharedC1d_2, SharedC1fa_8, SharedC1fb_8]),
          then: Yup.number().min(0).max(99).required(),
        }),
        SharedC1fc_8: Yup.mixed().when(['SharedC1d_1', 'SharedC1d_2', 'SharedC1fa_7', 'SharedC1fa_8', 'SharedC1fb_7', 'SharedC1fb_8'], {
          is: (SharedC1d_1: number, SharedC1d_2: number, SharedC1fa_7: number, SharedC1fa_8: number, SharedC1fb_7: number, SharedC1fb_8: number) =>
            isLessThan24Months([SharedC1d_1, SharedC1fa_7, SharedC1fb_7], [SharedC1d_2, SharedC1fa_8, SharedC1fb_8]),
          then: Yup.number().min(0).max(11).required(),
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values) || !isLessThan24Months(values.SharedC1d_1, values.SharedC1d_2) || !!values.SharedC1a_1;
      },
      title: RefinanceStepTitles[35],
    },
    {
      // step: 13
      questions: (
        <>
          <Question.SharedC2 />
          <Question.SharedC3 />
          <Question.SharedC4 showIf={[{ index: 'SharedB8_1', operator: '!==', value: QuestionValues.Yes }]} />
          <Question.SharedC4a
            showIf={[
              { index: 'SharedC4_1', operator: '!!', value: '' },
              { index: 'SharedC4_1', operator: '>', value: '0' },
              { index: 'SharedB8_1', operator: '!==', value: QuestionValues.Yes },
            ]}
          />
        </>
      ),
      validations: {
        SharedC2_1: Yup.string().required(),
        SharedC3_1: Yup.string().required().nullable(),
        SharedC4_1: Yup.mixed().when('SharedB8_1', {
          is: QuestionValues.No,
          then: Yup.number().min(0).max(25).required(),
        }),
        SharedC4a_1: Yup.string().test('SharedC4a_1', 'Invalid ages', function (value) {
          let SharedC4_1 = this.resolve(Yup.ref('SharedC4_1'));

          if (!Number(SharedC4_1)) {
            return true;
          }

          let SharedC4a_1 = String(this.resolve(Yup.ref('SharedC4a_1'))).replace(/\s/g, '');

          if (!RegexConstants.Ages.test(SharedC4a_1)) {
            return false;
          }

          if (SharedC4a_1.split(',').length !== Number(SharedC4_1)) {
            return false;
          }

          if (SharedC4a_1.split(',').find((x) => Number(x) > 99)) {
            return false;
          }

          return true;
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values);
      },
      title: RefinanceStepTitles[10],
    },
    {
      // step: 14
      questions: (
        <>
          <Question.SharedC5 />
          <Question.SharedC5a showIf={[{ index: 'SharedC5_1', operator: '===', value: QuestionValues.Yes }]} />
          <Question.SharedC5c showIf={[{ index: 'SharedC5_1', operator: '===', value: QuestionValues.Yes }]} />
        </>
      ),
      validations: {
        SharedC5_1: Yup.string().required().nullable(),
        SharedC5a_1: Yup.mixed().when('SharedC5_1', {
          is: QuestionValues.Yes,
          then: Yup.string().required().nullable(),
        }),
        SharedC5c_1: Yup.mixed().when('SharedC5_1', {
          is: QuestionValues.Yes,
          then: Yup.string().required().nullable(),
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values);
      },
      title: RefinanceStepTitles[11],
    },
    {
      // step: 15
      questions: (
        <>
          <Question.SharedC6 />
          <QuestionLayout>
            <QuestionLayout.Body>
              <Question.SharedC6a />
              <Question.SharedC6b />
              <Question.SharedC6c />
              <Question.SharedC6d />
              <Question.SharedC6f />
              <Question.C6eCompute />
            </QuestionLayout.Body>
          </QuestionLayout>
        </>
      ),
      validations: {
        ShowSharedC6a_: Yup.boolean(),
        SharedC6a_1: Yup.number().nullable(),
        atLeastOneCoBorrowerEmployment: Yup.boolean().test('atLeastOneCoBorrowerEmployment', 'atLeastOneCoBorrowerEmployment', function (value) {
          let showSharedC6b_ = this.resolve(Yup.ref('ShowSharedC6b_')) as boolean;
          let sharedC6b_1 = this.resolve(Yup.ref('SharedC6b_1')) as number;
          let isAddingNewSelfEmployment = showSharedC6b_ && sharedC6b_1 === -1;

          if (isAddingNewSelfEmployment) {
            return true;
          }

          let showSharedC6a_ = this.resolve(Yup.ref('ShowSharedC6a_')) as boolean;
          let sharedC6a_1 = this.resolve(Yup.ref('SharedC6a_1')) as number;
          let currentEmployment = (this.resolve(Yup.ref('coborrowerEmployment')) as IEmploymentType[]).filter(
            (employment: IEmploymentType) => !!employment.currentEmploymentIndicator && !employment.selfEmployedIndicator
          );
          return showSharedC6a_ ? (sharedC6a_1 === -1 ? true : !!currentEmployment.length) : true;
        }),
        ShowSharedC6b_: Yup.boolean(),
        SharedC6b_1: Yup.number().nullable(),
        atLeastOneCoBorrowerSelfEmployment: Yup.boolean().test('atLeastOneCoBorrowerSelfEmployment', 'atLeastOneCoBorrowerSelfEmployment', function (value) {
          let showSharedC6a_ = this.resolve(Yup.ref('ShowSharedC6a_')) as boolean;
          let sharedC6a_1 = this.resolve(Yup.ref('SharedC6a_1')) as number;
          let isAddingNewCurrentEmployment = showSharedC6a_ && sharedC6a_1 === -1;

          if (isAddingNewCurrentEmployment) {
            return true;
          }

          let showSharedC6b_ = this.resolve(Yup.ref('ShowSharedC6b_')) as boolean;
          let sharedC6b_1 = this.resolve(Yup.ref('SharedC6b_1')) as number;
          let selfEmployment = (this.resolve(Yup.ref('coborrowerEmployment')) as IEmploymentType[]).filter(
            (employment: IEmploymentType) => !employment.currentEmploymentIndicator && !!employment.selfEmployedIndicator
          );
          return showSharedC6b_ ? (sharedC6b_1 === -1 ? true : !!selfEmployment.length) : true;
        }),
        ShowSharedC6c_: Yup.boolean(),
        SharedC6c_1: Yup.mixed().when('ShowSharedC6c_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedC6c_2: Yup.mixed().when('ShowSharedC6c_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedC6c_3: Yup.mixed().when('ShowSharedC6c_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        ShowSharedC6d_: Yup.boolean(),
        SharedC6d_1: Yup.mixed().when('ShowSharedC6d_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedC6d_2: Yup.mixed().when('ShowSharedC6d_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedC6d_3: Yup.mixed().when('ShowSharedC6d_', {
          is: true,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        ShowSharedC6f_: Yup.boolean(),
        atLeastOneChecked: Yup.boolean().test('atLeastOneChecked', 'atLeastOneChecked', function (value) {
          let ShowSharedC6a_ = this.resolve(Yup.ref('ShowSharedC6a_'));
          let ShowSharedC6b_ = this.resolve(Yup.ref('ShowSharedC6b_'));
          let ShowSharedC6c_ = this.resolve(Yup.ref('ShowSharedC6c_'));
          let ShowSharedC6d_ = this.resolve(Yup.ref('ShowSharedC6d_'));
          let ShowSharedC6f_ = this.resolve(Yup.ref('ShowSharedC6f_'));

          return !!ShowSharedC6a_ || !!ShowSharedC6b_ || !!ShowSharedC6c_ || !!ShowSharedC6d_ || !!ShowSharedC6f_;
        }),
        atLeastOneC6cIncome: Yup.boolean().test('atLeastOneC6cIncome', 'atLeastOneC6cIncome', function (value) {
          let ShowSharedC6c_ = this.resolve(Yup.ref('ShowSharedC6c_'));
          let SharedC6c_1 = this.resolve(Yup.ref('SharedC6c_1'));
          let SharedC6c_2 = this.resolve(Yup.ref('SharedC6c_2'));
          let SharedC6c_3 = this.resolve(Yup.ref('SharedC6c_3'));

          return !!ShowSharedC6c_ ? !!SharedC6c_1 || !!SharedC6c_2 || !!SharedC6c_3 : true;
        }),
        atLeastOneC6dIncome: Yup.boolean().test('atLeastOneC6dIncome', 'atLeastOneC6dIncome', function (value) {
          let ShowSharedC6d_ = this.resolve(Yup.ref('ShowSharedC6d_'));
          let SharedC6d_1 = this.resolve(Yup.ref('SharedC6d_1'));
          let SharedC6d_2 = this.resolve(Yup.ref('SharedC6d_2'));
          let SharedC6d_3 = this.resolve(Yup.ref('SharedC6d_3'));

          return !!ShowSharedC6d_ ? !!SharedC6d_1 || !!SharedC6d_2 || !!SharedC6d_3 : true;
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values);
      },
      title: RefinanceStepTitles[12],
    },
    {
      // step: 16
      questions: (
        <>
          <Question.SharedEC1 />
        </>
      ),
      validations: {
        SharedEC1_1: Yup.string().required(),
        SharedEC1_2: Yup.string().required(),
        SharedEC1_3: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        SharedEC1_4: Yup.string().required(),
        SharedEC1_5: Yup.string().required(),
        SharedEC1_6: Yup.string().required(),
        SharedEC1_7: Yup.string().required(),
        SharedEC1_8: Yup.string().required(),
        SharedEC1_9: Yup.string().required(),
        SharedEC1_10: Yup.number().integer().min(0).max(100).required(),
        SharedEC1_11: Yup.number().positive().max(10000000).required(),
        SharedEC1_12: Yup.number().positive().max(10000000).nullable(),
        SharedEC1_13: Yup.number().positive().max(10000000).nullable(),
        SharedEC1_14: Yup.number().positive().max(10000000).nullable(),
        SharedEC1_15: Yup.number().integer().min(0).max(11).required(),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return values.SharedC6a_1 == null;
      },
      jumpToStep: (values: ILoanQuestionsMapType) => {
        const step = 15;
        return step;
      },
      title: RefinanceStepTitles[13],
    },
    {
      // step: 17
      questions: (
        <>
          <Question.SharedEC2 />
        </>
      ),
      validations: {
        SharedEC2_1: Yup.string().required(),
        SharedEC2_2: Yup.string().required(),
        SharedEC2_3: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        SharedEC2_4: Yup.string().required(),
        SharedEC2_5: Yup.string().required(),
        SharedEC2_6: Yup.string().required(),
        SharedEC2_7: Yup.string().required(),
        SharedEC2_8: Yup.string().required(),
        SharedEC2_9: Yup.string().required(),
        SharedEC2_10: Yup.number().integer().min(0).max(100).required(),
        SharedEC2_11: Yup.string().required(),
        SharedEC2_12: Yup.number().positive().max(10000000).required(),
        SharedEC2_13: Yup.number().integer().min(0).max(11).required(),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return values.SharedC6b_1 == null;
      },
      jumpToStep: (values: ILoanQuestionsMapType) => {
        const step = 15;
        return step;
      },
      title: RefinanceStepTitles[14],
    },
    {
      // step: 18
      questions: (
        <>
          <Question.SharedEC3 />
        </>
      ),
      validations: {
        SharedEC3a_1: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3a_2: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3a_3: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedEC3a_4: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3a_5: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3a_6: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3a_7: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3a_8: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3a_9: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3a_10: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(100).required(),
        }),
        SharedEC3a_11: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3a_13: Yup.mixed().when('SharedEC3a_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(11).required(),
        }),
        SharedEC3a_12: Yup.string().required().nullable(),
        SharedEC3b_1: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3b_2: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3b_3: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedEC3b_4: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3b_5: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3b_6: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3b_7: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3b_8: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3b_9: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3b_10: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(100).required(),
        }),
        SharedEC3b_11: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3b_13: Yup.mixed().when('SharedEC3b_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(11).required(),
        }),
        SharedEC3b_12: Yup.mixed().when('SharedEC3a_9', {
          is: (SharedEC3a_9: string) => inputDateIsLessThanTwoYears(SharedEC3a_9),
          then: Yup.string().required().nullable(),
        }),
        SharedEC3c_1: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3c_2: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3c_3: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        }),
        SharedEC3c_4: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3c_5: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3c_6: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3c_7: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3c_8: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3c_9: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3c_10: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(100).required(),
        }),
        SharedEC3c_11: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
        SharedEC3c_13: Yup.mixed().when('SharedEC3c_12', {
          is: QuestionValues.Yes,
          then: Yup.number().integer().min(0).max(11).required(),
        }),
        SharedEC3c_12: Yup.mixed().when('SharedEC3b_9', {
          is: (SharedEC3b_9: string) => inputDateIsLessThanTwoYears(SharedEC3b_9),
          then: Yup.string().required().nullable(),
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        if (noNeedToFillCoborrower(values)) {
          return true;
        }

        const hasPreviousEmployment = values.coborrowerEmployment.some(
          (employment: IEmploymentType) => !employment.currentEmploymentIndicator && !employment.selfEmployedIndicator
        );

        if (hasPreviousEmployment) {
          return false;
        }

        return values.coborrowerEmployment.some((employment: IEmploymentType) => !inputDateIsLessThanTwoYears(getDate(employment.employmentStartDate)));
      },
      title: RefinanceStepTitles[13],
    },
    {
      // step: 19
      questions: (
        <>
          <Question.SharedP3 />
          <Question.SharedP3a showIf={[{ index: 'SharedP3_1', operator: '===', value: QuestionValues.Yes }]} />
          <Question.SharedP2 showIf={[{ index: 'SharedW3_1', operator: '===', value: QuestionValues.HomeIntendInvestment }]} />
        </>
      ),
      validations: {
        SharedP2_1: Yup.mixed().when('SharedW3_1', {
          is: QuestionValues.HomeIntendInvestment,
          then: Yup.number().positive().max(10000000).required(),
        }),
        SharedP3_1: Yup.string().required().nullable(),
        SharedP3a_1: Yup.mixed().when('SharedP3_1', {
          is: QuestionValues.Yes,
          then: Yup.number().positive().max(10000000).required(),
        }),
        SharedP3a_2: Yup.mixed().when('SharedP3_1', {
          is: QuestionValues.Yes,
          then: Yup.number().positive().max(10000000).required(),
        }),
        SharedP3a_3: Yup.mixed().when('SharedP3_1', {
          is: QuestionValues.Yes,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedP3a_4: Yup.mixed().when('SharedP3_1', {
          is: QuestionValues.Yes,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
      },
      title: RefinanceStepTitles[15],
    },
    {
      // step: 20
      questions: (
        <>
          <Question.SharedP4 />
          <Question.SharedP4a showIf={[{ index: 'SharedP4_1', operator: '===', value: QuestionValues.Yes }]} />
        </>
      ),
      validations: {
        SharedP4_1: Yup.string().required().nullable(),
        SharedP4a_1: Yup.mixed().when('SharedP4_1', {
          is: QuestionValues.Yes,
          then: Yup.number().positive().max(10000000).required(),
        }),
        SharedP4a_2: Yup.mixed().when('SharedP4_1', {
          is: QuestionValues.Yes,
          then: Yup.number().positive().max(10000000).required(),
        }),
        SharedP4a_3: Yup.mixed().when('SharedP4_1', {
          is: QuestionValues.Yes,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedP4a_4: Yup.mixed().when('SharedP4_1', {
          is: QuestionValues.Yes,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        const hasResidentialProperty = values.SharedW3_1 !== QuestionValues.HomeIntendPrimary && values.SharedB1c_1 === QuestionValues.CurrentlyOwn;
        return !hasResidentialProperty;
      },
      title: RefinanceStepTitles[16],
    },
    {
      // step: 21
      questions: (
        <>
          <Question.SharedR0 />
        </>
      ),
      validations: {
        SharedR0_1: Yup.number().nullable(),
      },
      title: RefinanceStepTitles[17],
    },
    {
      // step: 22
      questions: (
        <>
          <Question.SharedR1 />
          <Question.SharedR2 />
          <Question.SharedR2a showIf={[{ index: 'SharedR2_1', operator: '===', value: QuestionValues.HomeIntendInvestment }]} />
          <Question.SharedR4 showIfFn={(values: ILoanQuestionsMapType) => !noNeedToFillCoborrower(values)} />
          <Question.SharedR3 />
          <Question.SharedR3a showIf={[{ index: 'SharedR3_1', operator: '===', value: QuestionValues.Yes }]} />
        </>
      ),
      validations: {
        SharedR1_1: Yup.string().required(),
        SharedR1_2: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).required(),
        SharedR1_3: Yup.string().required(),
        SharedR1_4: Yup.string().matches(RegexConstants.Zip, ValidationErrors.Zip).max(5).required(),
        SharedR1_5: Yup.string().matches(RegexConstants.LettersNumbersAndSpaces, ValidationErrors.LettersNumbersAndSpaces).max(10).nullable(),
        SharedR2_1: Yup.string().required(),
        SharedR2a_1: Yup.mixed().when('SharedR2_1', {
          is: QuestionValues.HomeIntendInvestment,
          then: Yup.number().positive().max(10000000).required(),
        }),
        SharedR3_1: Yup.string().required(),
        SharedR3a_1: Yup.mixed().when('SharedR3_1', {
          is: QuestionValues.Yes,
          then: Yup.number().positive().max(10000000).required(),
        }),
        SharedR3a_2: Yup.mixed().when('SharedR3_1', {
          is: QuestionValues.Yes,
          then: Yup.number().positive().max(10000000).required(),
        }),
        SharedR3a_3: Yup.mixed().when('SharedR3_1', {
          is: QuestionValues.Yes,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedR3a_4: Yup.mixed().when('SharedR3_1', {
          is: QuestionValues.Yes,
          then: Yup.number().min(0).max(10000000).nullable(),
        }),
        SharedR4_1: Yup.mixed().when(['SharedB7_1', 'SharedB8a_1', 'SharedB8_1'], {
          is: (SharedB7_1: string, SharedB8a_1: string, SharedB8_1: string) => !noNeedToFillCoborrower({ SharedB7_1, SharedB8a_1, SharedB8_1 } as any),
          then: Yup.string().required(),
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return values.SharedR0_1 == null;
      },
      jumpToStep: (values: ILoanQuestionsMapType) => {
        const step = 21;
        return step;
      },
      title: RefinanceStepTitles[17],
    },
    {
      // step: 23
      questions: (
        <>
          <Question.SharedA1 />
          <QuestionLayout>
            <QuestionLayout.Body>
              <Question.SharedA1a />
              <Question.SharedA1b />
              <Question.SharedA1c />
              <Question.SharedA1d />
              <Question.SharedA1e />
              <Question.SharedA1f />
              <Question.SharedA1g />
              <Question.SharedA1i />
              <Question.A1hCompute />
            </QuestionLayout.Body>
          </QuestionLayout>
        </>
      ),
      validations: {
        ShowSharedA1a_: Yup.boolean(),
        SharedA1a_Accounts: Yup.mixed().when('ShowSharedA1a_', getAssetValidation(true)),
        ShowSharedA1b_: Yup.boolean(),
        SharedA1b_Accounts: Yup.mixed().when('ShowSharedA1b_', getAssetValidation(true)),
        ShowSharedA1c_: Yup.boolean(),
        SharedA1c_Accounts: Yup.mixed().when('ShowSharedA1c_', getAssetValidation(true)),
        ShowSharedA1d_: Yup.boolean(),
        SharedA1d_Accounts: Yup.mixed().when('ShowSharedA1d_', getAssetValidation(true)),
        ShowSharedA1e_: Yup.boolean(),
        SharedA1e_Accounts: Yup.mixed().when('ShowSharedA1e_', getAssetValidation(false)),
        ShowSharedA1f_: Yup.boolean(),
        SharedA1f_Accounts: Yup.mixed().when('ShowSharedA1f_', getAssetValidation(false)),
        ShowSharedA1g_: Yup.boolean(),
        SharedA1g_Accounts: Yup.mixed().when('ShowSharedA1g_', getAssetValidation(true)),
        ShowSharedA1i_: Yup.boolean(),
        atLeastOneChecked: Yup.boolean().test('atLeastOneChecked', 'atLeastOneChecked', function (value) {
          let ShowSharedA1a_ = this.resolve(Yup.ref('ShowSharedA1a_'));
          let ShowSharedA1b_ = this.resolve(Yup.ref('ShowSharedA1b_'));
          let ShowSharedA1c_ = this.resolve(Yup.ref('ShowSharedA1c_'));
          let ShowSharedA1d_ = this.resolve(Yup.ref('ShowSharedA1d_'));
          let ShowSharedA1e_ = this.resolve(Yup.ref('ShowSharedA1e_'));
          let ShowSharedA1f_ = this.resolve(Yup.ref('ShowSharedA1f_'));
          let ShowSharedA1g_ = this.resolve(Yup.ref('ShowSharedA1g_'));
          let ShowSharedA1i_ = this.resolve(Yup.ref('ShowSharedA1i_'));

          return !!ShowSharedA1a_ || !!ShowSharedA1b_ || !!ShowSharedA1c_ || !!ShowSharedA1d_ || !!ShowSharedA1e_ || !!ShowSharedA1f_ || !!ShowSharedA1g_ || !!ShowSharedA1i_;
        }),
      },
      title: RefinanceStepTitles[18],
    },
    {
      // step: 24
      questions: (
        <>
          <QuestionLayout>
            <QuestionLayout.Body>
              <Question.SharedA2a />
              <Question.SharedA2b />
              <Question.SharedA2c />
              <Question.SharedA2d />
              <Question.SharedA2e />
              <Question.SharedA2f />
              <Question.SharedA2g />
              <Question.SharedA2i />
              <Question.SharedA2hCompute />
            </QuestionLayout.Body>
          </QuestionLayout>
        </>
      ),
      validations: {
        ShowSharedA2a_: Yup.boolean(),
        SharedA2a_Accounts: Yup.mixed().when('ShowSharedA2a_', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().min(1).max(10000000).required(),
              institutionName: Yup.string().required(),
            })
          ),
        }),
        ShowSharedA2b_: Yup.boolean(),
        SharedA2b_Accounts: Yup.mixed().when('ShowSharedA2b_', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().min(1).max(10000000).required(),
              institutionName: Yup.string().required(),
            })
          ),
        }),
        ShowSharedA2c_: Yup.boolean(),
        SharedA2c_Accounts: Yup.mixed().when('ShowSharedA2c_', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().min(1).max(10000000).required(),
              institutionName: Yup.string().required(),
            })
          ),
        }),
        ShowSharedA2d_: Yup.boolean(),
        SharedA2d_Accounts: Yup.mixed().when('ShowSharedA2d_', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().min(1).max(10000000).required(),
              institutionName: Yup.string().required(),
            })
          ),
        }),
        ShowSharedA2e_: Yup.boolean(),
        SharedA2e_Accounts: Yup.mixed().when('ShowSharedA2e_', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().min(1).max(10000000).required(),
            })
          ),
        }),
        ShowSharedA2f_: Yup.boolean(),
        SharedA2f_Accounts: Yup.mixed().when('ShowSharedA2f_', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().min(1).max(10000000).required(),
            })
          ),
        }),
        ShowSharedA2g_: Yup.boolean(),
        SharedA2g_Accounts: Yup.mixed().when('ShowSharedA2g_', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().min(1).max(10000000).required(),
              institutionName: Yup.string().required(),
            })
          ),
        }),
        ShowSharedA2i_: Yup.boolean(),
        atLeastOneChecked: Yup.boolean().test('atLeastOneChecked', 'atLeastOneChecked', function (value) {
          let ShowSharedA2_ = this.resolve(Yup.ref('ShowSharedA2_'));
          let ShowSharedA2a_ = this.resolve(Yup.ref('ShowSharedA2a_'));
          let ShowSharedA2b_ = this.resolve(Yup.ref('ShowSharedA2b_'));
          let ShowSharedA2c_ = this.resolve(Yup.ref('ShowSharedA2c_'));
          let ShowSharedA2d_ = this.resolve(Yup.ref('ShowSharedA2d_'));
          let ShowSharedA2e_ = this.resolve(Yup.ref('ShowSharedA2e_'));
          let ShowSharedA2f_ = this.resolve(Yup.ref('ShowSharedA2f_'));
          let ShowSharedA2g_ = this.resolve(Yup.ref('ShowSharedA2g_'));
          let ShowSharedA2i_ = this.resolve(Yup.ref('ShowSharedA2i_'));

          return (
            !!ShowSharedA2_ ||
            !!ShowSharedA2a_ ||
            !!ShowSharedA2b_ ||
            !!ShowSharedA2c_ ||
            !!ShowSharedA2d_ ||
            !!ShowSharedA2e_ ||
            !!ShowSharedA2f_ ||
            !!ShowSharedA2g_ ||
            !!ShowSharedA2i_
          );
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values) || isJointAssetLiabilityReportingIndicatorTrue(values);
      },
      title: RefinanceStepTitles[19],
    },
    {
      // step: 25
      questions: (
        <>
          <Question.SharedL1 />
          <QuestionLayout>
            <QuestionLayout.Body>
              <Question.SharedL1a />
              <Question.SharedL1b />
              <Question.SharedL1c />
              <Question.SharedL1d />
              <Question.SharedL1Total />
            </QuestionLayout.Body>
          </QuestionLayout>
        </>
      ),
      validations: {
        SharedL1a_1: Yup.mixed().when('ShowSharedL1a_', {
          is: true,
          then: Yup.number().min(1).max(10000000).required(),
        }),
        SharedL1b_1: Yup.mixed().when('ShowSharedL1b_', {
          is: true,
          then: Yup.number().min(1).max(10000000).required(),
        }),
        SharedL1c_1: Yup.mixed().when('ShowSharedL1c_', {
          is: true,
          then: Yup.number().min(1).max(10000000).required(),
        }),
        SharedL1d_1: Yup.mixed().when('ShowSharedL1d_', {
          is: true,
          then: Yup.string().required(),
        }),
        SharedL1d_2: Yup.mixed().when('ShowSharedL1d_', {
          is: true,
          then: Yup.number().min(1).max(10000000).required(),
        }),
        SharedL1d_3: Yup.mixed().when(['ShowSharedL1d_', 'SharedB7_1', 'SharedB8a_1', 'SharedB8_1'], {
          is: (ShowSharedL1d_: boolean, SharedB7_1: string, SharedB8a_1: string, SharedB8_1: string) =>
            ShowSharedL1d_ && !noNeedToFillCoborrower({ SharedB7_1, SharedB8a_1, SharedB8_1 } as any),
          then: Yup.string().required(),
        }),
      },
      title: RefinanceStepTitles[20],
    },
    {
      // step: 26
      questions: (
        <>
          <Question.SharedL2 />
          <QuestionLayout>
            <QuestionLayout.Body>
              <Question.SharedL2a />
              <Question.SharedL2b />
              <Question.SharedL2c />
              <Question.SharedL2d />
              <Question.SharedL2Total />
            </QuestionLayout.Body>
          </QuestionLayout>
        </>
      ),
      validations: {
        SharedL2a_1: Yup.mixed().when('ShowSharedL2a_', {
          is: true,
          then: Yup.number().min(1).max(10000000).required(),
        }),
        SharedL2b_1: Yup.mixed().when('ShowSharedL2b_', {
          is: true,
          then: Yup.number().min(1).max(10000000).required(),
        }),
        SharedL2c_1: Yup.mixed().when('ShowSharedL2c_', {
          is: true,
          then: Yup.number().min(1).max(10000000).required(),
        }),
        SharedL2d_1: Yup.mixed().when('ShowSharedL2d_', {
          is: true,
          then: Yup.string().required(),
        }),
        SharedL2d_2: Yup.mixed().when('ShowSharedL2d_', {
          is: true,
          then: Yup.number().min(1).max(10000000).required(),
        }),
        SharedL2d_3: Yup.mixed().when('ShowSharedL2d_', {
          is: true,
          then: Yup.string().required(),
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values);
      },
      title: RefinanceStepTitles[21],
    },
    {
      // step: 27
      questions: (
        <>
          <Question.SharedG1 />
          <Question.SharedG2 />
          <Question.SharedG3 />
        </>
      ),
      validations: {
        SharedG1_1: Yup.string().required(),
        SharedG2_7: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).nullable(),
        SharedG3_2: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).nullable(),
        SharedG3_11: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).nullable(),
        SharedG3_17: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).nullable(),
        atLeastOneEthnicityChecked: Yup.boolean().test('atLeastOneEthnicityChecked', 'atLeastOneEthnicityChecked', function (value) {
          let SharedG2_1 = this.resolve(Yup.ref('SharedG2_1'));
          let SharedG2_3 = this.resolve(Yup.ref('SharedG2_3'));
          let SharedG2_4 = this.resolve(Yup.ref('SharedG2_4'));
          let SharedG2_5 = this.resolve(Yup.ref('SharedG2_5'));
          let SharedG2_6 = this.resolve(Yup.ref('SharedG2_6'));
          let SharedG2_7 = this.resolve(Yup.ref('SharedG2_7'));
          let SharedG2_8 = this.resolve(Yup.ref('SharedG2_8'));

          return !!SharedG2_1 || !!SharedG2_3 || !!SharedG2_4 || !!SharedG2_5 || !!SharedG2_6 || !!SharedG2_7 || !!SharedG2_8;
        }),
        atLeastOneRaceChecked: Yup.boolean().test('atLeastOneRaceChecked', 'atLeastOneRaceChecked', function (value) {
          let SharedG3_1 = this.resolve(Yup.ref('SharedG3_1'));
          let SharedG3_4 = this.resolve(Yup.ref('SharedG3_4'));
          let SharedG3_5 = this.resolve(Yup.ref('SharedG3_5'));
          let SharedG3_6 = this.resolve(Yup.ref('SharedG3_6'));
          let SharedG3_7 = this.resolve(Yup.ref('SharedG3_7'));
          let SharedG3_8 = this.resolve(Yup.ref('SharedG3_8'));
          let SharedG3_9 = this.resolve(Yup.ref('SharedG3_9'));
          let SharedG3_10 = this.resolve(Yup.ref('SharedG3_10'));
          let SharedG3_13 = this.resolve(Yup.ref('SharedG3_13'));
          let SharedG3_14 = this.resolve(Yup.ref('SharedG3_14'));
          let SharedG3_15 = this.resolve(Yup.ref('SharedG3_15'));
          let SharedG3_16 = this.resolve(Yup.ref('SharedG3_16'));
          let SharedG3_18 = this.resolve(Yup.ref('SharedG3_18'));
          let SharedG3_19 = this.resolve(Yup.ref('SharedG3_19'));
          let SharedG3_20 = this.resolve(Yup.ref('SharedG3_20'));

          return (
            !!SharedG3_1 ||
            !!SharedG3_4 ||
            !!SharedG3_5 ||
            !!SharedG3_6 ||
            !!SharedG3_7 ||
            !!SharedG3_8 ||
            !!SharedG3_9 ||
            !!SharedG3_10 ||
            !!SharedG3_13 ||
            !!SharedG3_14 ||
            !!SharedG3_15 ||
            !!SharedG3_16 ||
            !!SharedG3_18 ||
            !!SharedG3_19 ||
            !!SharedG3_20
          );
        }),
      },
      title: RefinanceStepTitles[22],
    },
    {
      // step: 28
      questions: (
        <>
          <Question.SharedG4 />
          <Question.SharedG5 />
          <Question.SharedG20 showIf={[{ index: 'SharedG5_1', operator: '===', value: QuestionValues.Yes }]} />
          <Question.SharedG7 />
          <Question.SharedG8 />
          <Question.SharedG9 />
          <Question.SharedG10 />
          <Question.SharedG11 />
          <Question.SharedG12 />
          <Question.SharedG13 />
          <Question.SharedG14 />
          <Question.SharedG15 />
          <Question.SharedG16 />
          <Question.SharedG17 />
          <Question.SharedG18 />
          <Question.SharedG19 />
        </>
      ),
      validations: {
        SharedG4_1: Yup.string().required().nullable(),
        SharedG5_1: Yup.string().required().nullable(),
        SharedG7_1: Yup.string().required().nullable(),
        SharedG8_1: Yup.string().required().nullable(),
        SharedG9_1: Yup.string().required().nullable(),
        SharedG10_1: Yup.string().required().nullable(),
        SharedG11_1: Yup.string().required().nullable(),
        SharedG12_1: Yup.string().required().nullable(),
        SharedG13_1: Yup.string().required().nullable(),
        SharedG14_1: Yup.string().required().nullable(),
        SharedG15_1: Yup.string().required().nullable(),
        SharedG16_1: Yup.string().required().nullable(),
        SharedG17_1: Yup.string().required().nullable(),
        SharedG18_1: Yup.string().required().nullable(),
        SharedG19_1: Yup.string().required().nullable(),
        SharedG20_1: Yup.mixed().when('SharedG5_1', {
          is: QuestionValues.Yes,
          then: Yup.string().required().nullable(),
        }),
        SharedG20_2: Yup.mixed().when('SharedG5_1', {
          is: QuestionValues.Yes,
          then: Yup.string().required().nullable(),
        }),
      },
      title: RefinanceStepTitles[23],
    },
    {
      // step: 29
      questions: (
        <>
          <Question.SharedGC1 />
          <Question.SharedGC2 />
          <Question.SharedGC3 />
        </>
      ),
      validations: {
        SharedGC1_1: Yup.string().required(),
        SharedGC2_7: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).nullable(),
        SharedGC3_2: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).nullable(),
        SharedGC3_11: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).nullable(),
        SharedGC3_17: Yup.string().matches(RegexConstants.Entity, ValidationErrors.Entity).max(50).nullable(),
        atLeastOneEthnicityChecked: Yup.boolean().test('atLeastOneEthnicityChecked', 'atLeastOneEthnicityChecked', function (value) {
          let SharedGC2_1 = this.resolve(Yup.ref('SharedGC2_1'));
          let SharedGC2_3 = this.resolve(Yup.ref('SharedGC2_3'));
          let SharedGC2_4 = this.resolve(Yup.ref('SharedGC2_4'));
          let SharedGC2_5 = this.resolve(Yup.ref('SharedGC2_5'));
          let SharedGC2_6 = this.resolve(Yup.ref('SharedGC2_6'));
          let SharedGC2_7 = this.resolve(Yup.ref('SharedGC2_7'));
          let SharedGC2_8 = this.resolve(Yup.ref('SharedGC2_8'));

          return !!SharedGC2_1 || !!SharedGC2_3 || !!SharedGC2_4 || !!SharedGC2_5 || !!SharedGC2_6 || !!SharedGC2_7 || !!SharedGC2_8;
        }),
        atLeastOneRaceChecked: Yup.boolean().test('atLeastOneRaceChecked', 'atLeastOneRaceChecked', function (value) {
          let SharedGC3_1 = this.resolve(Yup.ref('SharedGC3_1'));
          let SharedGC3_4 = this.resolve(Yup.ref('SharedGC3_4'));
          let SharedGC3_5 = this.resolve(Yup.ref('SharedGC3_5'));
          let SharedGC3_6 = this.resolve(Yup.ref('SharedGC3_6'));
          let SharedGC3_7 = this.resolve(Yup.ref('SharedGC3_7'));
          let SharedGC3_8 = this.resolve(Yup.ref('SharedGC3_8'));
          let SharedGC3_9 = this.resolve(Yup.ref('SharedGC3_9'));
          let SharedGC3_10 = this.resolve(Yup.ref('SharedGC3_10'));
          let SharedGC3_13 = this.resolve(Yup.ref('SharedGC3_13'));
          let SharedGC3_14 = this.resolve(Yup.ref('SharedGC3_14'));
          let SharedGC3_15 = this.resolve(Yup.ref('SharedGC3_15'));
          let SharedGC3_16 = this.resolve(Yup.ref('SharedGC3_16'));
          let SharedGC3_18 = this.resolve(Yup.ref('SharedGC3_18'));
          let SharedGC3_19 = this.resolve(Yup.ref('SharedGC3_19'));
          let SharedGC3_20 = this.resolve(Yup.ref('SharedGC3_20'));

          return (
            !!SharedGC3_1 ||
            !!SharedGC3_4 ||
            !!SharedGC3_5 ||
            !!SharedGC3_6 ||
            !!SharedGC3_7 ||
            !!SharedGC3_8 ||
            !!SharedGC3_9 ||
            !!SharedGC3_10 ||
            !!SharedGC3_13 ||
            !!SharedGC3_14 ||
            !!SharedGC3_15 ||
            !!SharedGC3_16 ||
            !!SharedGC3_18 ||
            !!SharedGC3_19 ||
            !!SharedGC3_20
          );
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values);
      },
      title: RefinanceStepTitles[24],
    },
    {
      // step: 30
      questions: (
        <>
          <Question.SharedGC4 />
          <Question.SharedGC5 />
          <Question.SharedGC20 showIf={[{ index: 'SharedGC5_1', operator: '===', value: QuestionValues.Yes }]} />
          <Question.SharedGC7 />
          <Question.SharedGC8 />
          <Question.SharedGC9 />
          <Question.SharedGC10 />
          <Question.SharedGC11 />
          <Question.SharedGC12 />
          <Question.SharedGC13 />
          <Question.SharedGC14 />
          <Question.SharedGC15 />
          <Question.SharedGC16 />
          <Question.SharedGC17 />
          <Question.SharedGC18 />
          <Question.SharedGC19 />
        </>
      ),
      validations: {
        SharedGC4_1: Yup.string().required().nullable(),
        SharedGC5_1: Yup.string().required().nullable(),
        SharedGC7_1: Yup.string().required().nullable(),
        SharedGC8_1: Yup.string().required().nullable(),
        SharedGC9_1: Yup.string().required().nullable(),
        SharedGC10_1: Yup.string().required().nullable(),
        SharedGC11_1: Yup.string().required().nullable(),
        SharedGC12_1: Yup.string().required().nullable(),
        SharedGC13_1: Yup.string().required().nullable(),
        SharedGC14_1: Yup.string().required().nullable(),
        SharedGC15_1: Yup.string().required().nullable(),
        SharedGC16_1: Yup.string().required().nullable(),
        SharedGC17_1: Yup.string().required().nullable(),
        SharedGC18_1: Yup.string().required().nullable(),
        SharedGC19_1: Yup.string().required().nullable(),
        SharedGC20_1: Yup.mixed().when('SharedGC5_1', {
          is: QuestionValues.Yes,
          then: Yup.string().required().nullable(),
        }),
        SharedGC20_2: Yup.mixed().when('SharedGC5_1', {
          is: QuestionValues.Yes,
          then: Yup.string().required().nullable(),
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values);
      },
      title: RefinanceStepTitles[25],
    },
    {
      // step: 31
      questions: (
        <>
          <Question.SharedZ1 />
        </>
      ),
      validations: {
        SharedZ1_1: Yup.string().required().nullable(),
        SharedZ1_2: Yup.mixed().when('SharedZ1_1', {
          is: QuestionValues.Agree,
          then: Yup.string().length(9, ValidationErrors.SSN).nullable(),
        }),
        SharedZ1_3: Yup.mixed().when('SharedZ1_1', {
          is: QuestionValues.Agree,
          then: Yup.string().nullable(),
        }),
      },
      title: RefinanceStepTitles[26],
    },
    {
      // step: 32
      questions: (
        <>
          <Question.SharedZ1a />
        </>
      ),
      validations: {
        SharedZ1a_1: Yup.string().required().nullable(),
        SharedZ1a_2: Yup.mixed().when('SharedZ1a_1', {
          is: QuestionValues.Agree,
          then: Yup.mixed().when('SharedZ1a_2', {
            is: (val: string) => val && val.length > 0,
            then: Yup.string()
              .length(9, ValidationErrors.SSN)
              .nullable()
              .notOneOf([Yup.ref('SharedZ1_2')], 'SSN must be different from borrower'),
            otherwise: Yup.string().length(9, ValidationErrors.SSN).nullable(),
          }),
        }),
        SharedZ1a_3: Yup.mixed().when('SharedZ1a_1', {
          is: QuestionValues.Agree,
          then: Yup.string().nullable(),
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values);
      },
      title: RefinanceStepTitles[27],
    },
    {
      // step: 33
      questions: (
        <>
          <Question.SharedZ2 />
        </>
      ),
      validations: {
        SharedZ2_1: Yup.string().required().nullable(),
      },
      title: RefinanceStepTitles[28],
    },
    {
      // step: 34
      questions: (
        <>
          <Question.SharedZ2a />
        </>
      ),
      validations: {
        SharedZ2a_1: Yup.string().required().nullable(),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values);
      },
      title: RefinanceStepTitles[29],
    },
    {
      // step: 35
      questions: (
        <>
          <Question.SharedZ3 />
        </>
      ),
      validations: {
        SharedZ3_1: Yup.string().required().nullable(),
      },
      title: RefinanceStepTitles[30],
    },
    {
      // step: 36
      questions: (
        <>
          <Question.SharedZ3a />
        </>
      ),
      validations: {
        SharedZ3a_1: Yup.string().required().nullable(),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return noNeedToFillCoborrower(values);
      },
      title: RefinanceStepTitles[31],
    },
    {
      // step: 37
      questions: (
        <>
          <Question.SharedT0 />
        </>
      ),
      validations: {
        SharedT0_1: Yup.string().required().nullable(),
        loanOfficerEmail: Yup.mixed().when('SharedT0_1', {
          is: QuestionValues.Yes,
          then: Yup.string().required(),
        }),
      },
      skip: (values: ILoanQuestionsMapType) => {
        return !!values.loanOfficerContact;
      },
      title: RefinanceStepTitles[32],
    },
    {
      // step: 38
      questions: (
        <>
          <Question.SharedT1 />
        </>
      ),
      validations: {},
      title: RefinanceStepTitles[33],
    },
  ],
};

export default RefinanceConfig;
