import { FC } from 'react';
import { IQuestionType } from '@rsl/core/src/types';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';

const W4a: FC<IQuestionType> = ({ showIf }) => {
  return (
    <Question label="How many units will the property have?" showIf={showIf}>
      <Row>
        <RadioField
          className="mb-2"
          name="SharedW4a_1"
          optionsClassName="pt-1"
          options={[
            { label: '2 units', value: '2' },
            { label: '3 units', value: '3' },
            { label: '4 units', value: '4' },
          ]}
        />
      </Row>
    </Question>
  );
};

export default W4a;
