import { debounce } from 'lodash';
import { useCallback } from 'react';

/**
 * get debounced (and inmmutable Function) that is debounced
 * @param callback the function that will be debounced
 * @param delay the delayTime
 * @example const debounced = useDebouncedCallback(callback, delay)// returns debounced function
 */
export const useDebouncedFunction = (callback: (...args: any) => any, delay: number) => {
  // disable exhaustive-deps check because the intention is to don;t update the callback so is automatically memorized
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useCallback(debounce(callback, delay), []);
  return debouncedCallback;
};
