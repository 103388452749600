import { ILoanType, ILoanQuestionsMapType, IReoPropertyType, IAssetType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';
import { dateIsLessThanYears } from '@rsl/core/src/utils/dateUtils';

export const reverseYesNo: Map<boolean | null | undefined, string> = new Map([
  [true, QuestionValues.Yes],
  [false, QuestionValues.No],
]);

export const yesNo: Map<string, boolean> = new Map([
  [QuestionValues.Yes, true],
  [QuestionValues.No, false],
]);

export const getSubjectPropertyIndex = (loanApplication: ILoanType) => {
  return loanApplication.reoProperties.findIndex((reoProperty: IReoPropertyType) => reoProperty.type === 'SubjectProperty');
};

export const getResidentialPropertyIndex = (loanApplication: ILoanType) => {
  return loanApplication.reoProperties.findIndex((reoProperty: IReoPropertyType) => reoProperty.type === 'ResidentialProperty');
};

export const getOtherPropertyIndex = (loanApplication: ILoanType) => {
  return typeof loanApplication.uiReoPropertyIndex !== 'number' ? -1 : loanApplication.uiReoPropertyIndex;
};

export const getBorrowerEmploymentIndex = (loanApplication: ILoanType) => {
  return typeof loanApplication.uiBorrowerEmploymentIndex !== 'number' ? -1 : loanApplication.uiBorrowerEmploymentIndex;
};

export const getCoBorrowerEmploymentIndex = (loanApplication: ILoanType) => {
  return typeof loanApplication.uiCoBorrowerEmploymentIndex !== 'number' ? -1 : loanApplication.uiCoBorrowerEmploymentIndex;
};

export const getBorrowerSelfEmploymentIndex = (loanApplication: ILoanType) => {
  return typeof loanApplication.uiBorrowerSelfEmploymentIndex !== 'number' ? -1 : loanApplication.uiBorrowerSelfEmploymentIndex;
};

export const getCoBorrowerSelfEmploymentIndex = (loanApplication: ILoanType) => {
  return typeof loanApplication.uiCoBorrowerSelfEmploymentIndex !== 'number' ? -1 : loanApplication.uiCoBorrowerSelfEmploymentIndex;
};

export const noNeedToFillCoborrower = (values: ILoanQuestionsMapType) => {
  const applyingAlone = values.SharedB7_1 === QuestionValues.ApplyingMyOwn;
  const sendEmailToCoborrower = values.SharedB8a_1 === QuestionValues.Yes;

  return applyingAlone || sendEmailToCoborrower;
};

export const isJointAssetLiabilityReportingIndicatorTrue = (values: ILoanQuestionsMapType) => {
  const jointAssetLiabilityReportingIndicator = values.SharedB8_1 === QuestionValues.Yes;
  return jointAssetLiabilityReportingIndicator;
};

export const uploadAsset = (assetType: string, namePrefix: string, values: ILoanQuestionsMapType, loanApplication: ILoanType) => {
  const isCoborrower = namePrefix.includes('2');
  if (isCoborrower && loanApplication.coborrower?.assets) {
    loanApplication.coborrower.assets = loanApplication.coborrower.assets.filter((asset: IAssetType) => asset.assetType !== assetType);
  } else {
    loanApplication.borrower.assets = loanApplication.borrower.assets.filter((asset: IAssetType) => asset.assetType !== assetType);
  }

  if ((values as any)[`Show${namePrefix}`]) {
    (values as any)[`${namePrefix}Accounts`].forEach((account: IAssetType) => {
      (isCoborrower ? loanApplication.coborrower : loanApplication.borrower)?.assets.push({
        ...account,
        otherDescription: '',
        assetType,
      });
    });
  }
};

export const loadAsset = (assetType: string, namePrefix: string, values: ILoanQuestionsMapType, loanApplication: ILoanType) => {
  const isCoborrower = namePrefix.includes('2');
  (values as any)[`${namePrefix}Accounts`] = (isCoborrower ? loanApplication.coborrower : loanApplication.borrower)?.assets.filter(
    (asset: IAssetType) => asset.assetType === assetType
  );
};

export const inputDateIsLessThanTwoYears = (inputDate: string): boolean => {
  return dateIsLessThanYears(new Date(inputDate), 2);
};

export const isLessThan24Months = (years: string | number | (string | number)[], months: string | number | (string | number)[]): boolean => {
  return (
    (Array.isArray(years) ? years.reduce((sum: number, year: string | number) => sum + +(year || 0), 0) : +(years || 0)) * 12 +
      (Array.isArray(months) ? months.reduce((sum: number, month: string | number) => sum + +(month || 0), 0) : +(months || 0)) <
    24
  );
};
