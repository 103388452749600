import SidebarItemNotification from 'app/components/sidebar/SidebarItemNotification';
import { Card } from 'react-bootstrap';
import { ReactComponent as TaskIcon } from 'app/assets/icons/task.svg';
import { ReactComponent as InboxIcon } from 'app/assets/icons/messages-single.svg';
import { ReactComponent as FilesNeededIcon } from 'app/assets/icons/filesNeededIcon.svg';
import { ReactComponent as FilesSharedIcon } from 'app/assets/icons/filesSharedIcon.svg';
import { LoanRoles, LoanSummaryExternal } from 'app/types';
import { FC } from 'react';
import LoanRoleSecure from 'app/components/auth/LoanRoleSecure';

interface DashboardNotificationsProps {
  loan: LoanSummaryExternal;
}

const DashboardNotifications: FC<DashboardNotificationsProps> = ({ loan }) => {
  return (
    <article>
      <h4 className="fw-bold mb-3 d-none d-md-block">See What You Missed</h4>

      <Card className="hide-card-mobile">
        <Card.Body>
          <ul className="d-flex flex-row flex-lg-column justify-content-around flex-grow-1">
            <li>
              <SidebarItemNotification
                title="Tasks"
                notificationNumber={loan.tasksCount}
                path={`/loan/${loan.loanId}/tasks`}
                icon={TaskIcon}
                linkClassName="fw-bold text-reset"
                className="py-0 py-md-3"
                description={!!loan.tasksCount ? 'We have a task for you' : 'No new task yet'}
                hideTitleOnMobile
              />
            </li>
            <li>
              <SidebarItemNotification
                title="Messages"
                notificationNumber={loan.messageCount}
                path={`/loan/${loan.loanId}/messages`}
                icon={InboxIcon}
                linkClassName="fw-bold text-reset"
                className="py-0 py-md-3"
                description={!!loan.messageCount ? 'Look! You got a message' : "You're all caught up"}
                hideTitleOnMobile
              />
            </li>
            <LoanRoleSecure roles={[LoanRoles.Borrower, LoanRoles.CoBorrower]} currentRole={loan.role}>
              <li>
                <SidebarItemNotification
                  title="Documents Needed"
                  notificationNumber={loan.needslistCount}
                  path={`/loan/${loan.loanId}/filesNeeded`}
                  icon={FilesNeededIcon}
                  linkClassName="fw-bold text-reset"
                  className="py-0 py-md-3"
                  description={!!loan.needslistCount ? 'Just a few more files' : 'Nothing new here!'}
                  hideTitleOnMobile
                />
              </li>
            </LoanRoleSecure>
            <li>
              <SidebarItemNotification
                title="Shared Documents"
                notificationNumber={loan.fileshareCount}
                path={`/loan/${loan.loanId}/sharedResources`}
                icon={FilesSharedIcon}
                linkClassName="fw-bold text-reset"
                className="py-0 py-md-3"
                description={!!loan.fileshareCount ? 'We have files for you' : 'Nothing new here!'}
                hideTitleOnMobile
              />
            </li>
          </ul>
        </Card.Body>
      </Card>
    </article>
  );
};

export default DashboardNotifications;
