import * as Yup from 'yup';
import { Col, Row, Container, Alert, Card } from 'react-bootstrap';
import TextField from '@rsl/core/src/components/form/TextField';
import ExtendedFormik from '@rsl/core/src/components/form/ExtendedFormik';
import { useChangePasswordRequestMutation } from 'features/pin/api/pinVerificationApi';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import { useState } from 'react';
import { useSetLayoutSettings } from 'app/hooks/layoutHooks';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

interface RequestPasswordFormValues {
  email: string;
}

const values: RequestPasswordFormValues = {
  email: '',
};

const ResetPasswordRequest = () => {
  const [currentEmail, setCurrentEmail] = useState('');
  const [request] = useChangePasswordRequestMutation();

  const doSubmitForm = async (values: RequestPasswordFormValues) => {
    var result = await request(values.email);
    throwErrorProp(result);
    setCurrentEmail(values.email);
  };

  useSetLayoutSettings({
    subTitle: 'Reset Password',
    isMainScreen: true,
  });

  return (
    <>
      {!!currentEmail ? (
        <Alert variant="light  px-2 py-2 flexible-box">
          <Container>
            <Row>
              <Col className="d-flex justify-content-center text-black mb-3 mt-2">
                <i className="fas fa-info-circle fs-1"></i>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="mb-0 text-primary text-center fs-3">
                  <strong>Requested</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-center text-black">
                  A Password Reset link will be sent to <strong>{currentEmail}</strong>. If you don't receive this reset link, please make sure is the registered email.
                </p>
              </Col>
            </Row>
          </Container>
        </Alert>
      ) : (
        <Card className="mx-2 text-center py-4 flexible-box">
          <Card.Body>
            <ExtendedFormik
              validationSchema={validationSchema}
              initialValues={values}
              onSubmit={doSubmitForm}
              buttonsAlign="center"
              showCancelButton={false}
              variantSubmit="primary-gradient"
              buttonSize="lg"
              saveText="RESET PASSWORD"
              lockFormOnChanges={false}>
              {() => (
                <>
                  <div>write your email to request a password reset!</div>
                  <Row className="mt-4">
                    <TextField className="mb-3" placeholder="Email" name="email" required />
                  </Row>
                </>
              )}
            </ExtendedFormik>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ResetPasswordRequest;
