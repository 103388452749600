import { useAppSelector } from 'app/hooks/hooks';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { currentPinActionSel } from '../store/selectors/pinSelectors';

export const useCurrentPinAction = () => {
  const currentPinAction = useAppSelector(currentPinActionSel);
  const history = useHistory();

  useEffect(() => {
    if (!currentPinAction) {
      history.push('/');
    }
  }, [currentPinAction, history]);

  return currentPinAction;
};
