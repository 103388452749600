import { IOption } from '@rsl/core';
import SelectField from '@rsl/core/src/components/form/SelectField';
import { getCurrencyFormatted } from '@rsl/core/src/utils/numberUtils';
import { getDate } from '@rsl/core/src/utils/dateUtils';
import { useFetchExternalLoanQuery } from 'app/api/externalSessionApi';
import { Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { Card, Row, Col, Container, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const options: IOption<string>[] = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Closed',
    value: 'Closed',
  },
  {
    label: 'Adverse',
    value: 'Adverse',
  },
];

const MyLoans = () => {
  const [actionTaken, setActionTaken] = useState('Active');
  const { data: { rows: loans = [] } = {}, isLoading } = useFetchExternalLoanQuery({ actionTaken });

  const handleSubmit = (values: any) => {};

  const handleChange = useCallback((value: any) => {
    setActionTaken(value);
  }, []);

  // fake cards to fill space on the flex components and align-like left
  const additionalCards = useMemo(() => new Array((loans?.length ?? 0) % 3).fill(null), [loans?.length]);

  return (
    <Container>
      <Row>
        <h2 className="text-center pt-5 pb-4 fw-bold mb-0"> My Loans</h2>
      </Row>
      <Formik initialValues={{ actionTaken }} onSubmit={handleSubmit}>
        <Row>
          <SelectField xs={4} className="mb-3" label="Filter:" options={options} name="actionTaken" onChange={handleChange} />
        </Row>
      </Formik>
      <hr />
      {isLoading && <Spinner animation="border" variant="primary" />}

      {!isLoading && !loans.length && (
        <Row className="py-2">
          <Col xs={12} className="py-5 text-center fs-3">
            No Results
          </Col>
        </Row>
      )}
      <section className="d-flex flex-wrap w-100 flex-row justify-content-between">
        {loans?.map((loan) => (
          <Card className={`mx-1 d-flex flex-column w-100 w-md-48 w-lg-30`} key={`loan-card-${loan.loanId}`}>
            <Card.Body className="d-flex flex-column flex-1 justify-content-between">
              <Card.Title className="text-center">
                <Link to={`/loan/${loan.loanId}`} className="text-primary fs-4 fw-bold">
                  {loan.loanNumber} | {loan.currentMilestone}
                </Link>
              </Card.Title>
              <Card.Text className="fw-bold flex-1">
                <hr />
                <p>
                  {loan.borrowerFirstName} {loan.borrowerLastName}
                </p>
                <p>
                  {!!loan.propertyStreetAddress && `${loan.propertyStreetAddress},`} {!!loan.propertyCity && `${loan.propertyCity},`}{' '}
                  {loan.propertyState && `${loan.propertyState}`}
                </p>
                <p>{loan.loanType}</p>
                <p>
                  {!!loan.propertyLoanPurposeType && `${loan.propertyLoanPurposeType},`} {loan?.propertyUsageType}
                </p>
                <p>
                  <span className="me-4">{loan?.rateLockStatus}</span> {`${loan.requestedInterestRatePercent}%`}
                </p>
              </Card.Text>
              <Card.Text className="fw-bold">
                <hr />
                <p className="d-flex align-items-center">
                  <label className="text-primary me-4"> Loan Amount: &nbsp; </label>
                  {getCurrencyFormatted(loan.borrowerRequestedLoanAmount ?? 0)}
                </p>
                <p className="d-flex align-items-center">
                  <label className="text-primary me-4"> Est COE:</label>
                  {!!loan.loanScheduledClosingDate ? getDate(loan.loanScheduledClosingDate) : ''}
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
        {additionalCards.map((_, index) => (
          <div className={`mx-1 d-flex flex-column w-100 w-md-30`} key={`empty-loan-card-${index}`}></div>
        ))}
      </section>
    </Container>
  );
};

export default MyLoans;
