import { ApiResponse, ILoanPosApplicationType, RegisterUserType } from '@rsl/core/src/types';
import { baseApi } from '@rsl/core/src/api/baseApi';
import { ILoanType, ILoanOfficerContactType } from '@rsl/core/src/types';
import { invalidateOnSuccess } from '@rsl/core/src/utils/cacheUtils';
import { PurposeType } from '@rsl/core/src/constants';

export const loanPosApplicationApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchActiveApplications: build.query<ILoanType, void>({
      query: () => `/loanposapplication/active`,
      transformResponse: (response: ApiResponse<ILoanType>) => response.data,
      providesTags: ['LoanPoSApplication'],
    }),
    fetchLoanPosApplication: build.query<ILoanType, string>({
      query: (loanPosApplicationId) => `/loanposapplication/${loanPosApplicationId}`,
      transformResponse: (response: ApiResponse<ILoanType>) => response.data,
      providesTags: ['LoanPoSApplication'],
    }),
    createLoanPosApplication: build.mutation<ILoanType, RegisterUserType>({
      query: (body) => ({
        url: '/loanposapplication/register',
        method: 'POST',
        body,
      }),
      transformResponse: (response: ApiResponse<ILoanType>) => response.data,
    }),
    saveLoanPosApplication: build.mutation<ILoanType, Omit<ILoanType, 'loanPosApplicationId'>>({
      query: (body) => ({
        url: '/loanposapplication',
        method: 'POST',
        body,
      }),
      transformResponse: (response: ApiResponse<ILoanType>) => response.data,
      invalidatesTags: invalidateOnSuccess(['LoanPoSApplication', 'LoanPoSApplicationList']),
    }),
    newApplication: build.mutation<ILoanPosApplicationType, PurposeType>({
      query: (type) => ({
        url: `/loanposapplication/new/${type}`,
        method: 'POST',
      }),
      transformResponse: (response: ApiResponse<ILoanPosApplicationType>) => response.data,
    }),
    fetchLoanOfficers: build.query<ILoanOfficerContactType[], string>({
      query: (loanPosApplicationId) => `/loanposapplication/${loanPosApplicationId}/loanOfficers`,
      transformResponse: (response: ApiResponse<ILoanOfficerContactType[]>) => response.data,
    }),
    importLoan: build.mutation<ILoanPosApplicationType, string>({
      query: (loanAppId) => ({
        url: `/loanposapplication/${loanAppId}/import`,
        method: 'POST',
      }),
      transformResponse: (response: ApiResponse<ILoanPosApplicationType>) => response.data,
      invalidatesTags: invalidateOnSuccess(['ExternalUserLoans']),
    }),
    fetchLoanOfficerProfileImage: build.query<string, string>({
      query: (userId) => `/loanposapplication/loanOfficer/${userId}/profileImage`,
      transformResponse: (response: ApiResponse<string>) => response.data,
      providesTags: ['LoanOfficerProfileImage'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchLoanPosApplicationQuery,
  useSaveLoanPosApplicationMutation,
  useCreateLoanPosApplicationMutation,
  useNewApplicationMutation,
  useFetchLoanOfficersQuery,
  useImportLoanMutation,
  useFetchLoanOfficerProfileImageQuery,
  useFetchActiveApplicationsQuery,
} = loanPosApplicationApi;
