import { useState } from 'react';
import * as Yup from 'yup';
import { Col, Row, Button, Card } from 'react-bootstrap';
import TextField from '@rsl/core/src/components/form/TextField';
import { useExternalAuth } from 'app/hooks/authHooks';
import { useFetchPinActionQuery, useRegisterDeviceMutation, useRegisterDeviceRequestMutation } from 'features/pin/api/pinVerificationApi';
import { useEffect } from 'react';
import { PinActionType } from 'features/pin/types';
import { useCallbackRoute } from 'app/hooks/routeHooks';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks/hooks';
import { setIsDeviceVerified, setIsLoggedIn } from 'app/store/slices/authSlice';
import ExtendedFormik from '@rsl/core/src/components/form/ExtendedFormik';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { ValidationErrors } from '@rsl/core/src/constants/errors';
import { useSetLayoutSettings } from 'app/hooks/layoutHooks';

const validationSchema = Yup.object().shape({
  pin: Yup.string().required(ValidationErrors.PinEmpty),
  verify: Yup.boolean().required().oneOf([true], ValidationErrors.VerifyDevice),
});

interface PinValues {
  pin: string;
}

const values: PinValues = {
  pin: '',
};

const DeviceVerification = () => {
  const [pinAction, setPinAction] = useState<PinActionType | null>(null);
  const [resend, setResend] = useState(false);
  const callbackUrl = useCallbackRoute();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { refetch } = useFetchPinActionQuery(pinAction?.pinId ?? '', { skip: !resend });

  const { deviceId, token } = useExternalAuth();
  const [registerDeviceRequest] = useRegisterDeviceRequestMutation();
  const [registerDevice] = useRegisterDeviceMutation();

  useEffect(() => {
    if (!!token) {
      (async () => {
        const result: any = await registerDeviceRequest();
        if (!result.error) {
          setPinAction(result.data);
        }
      })();
    }
    dispatch(setIsLoggedIn(false));
  }, [dispatch, registerDeviceRequest, token]);

  const doSubmitForm = async (values: PinValues) => {
    const result: any = await registerDevice({
      pinId: pinAction?.pinId ?? '',
      pin: values.pin,
      payload: deviceId,
    });

    if (!result.error) {
      dispatch(setIsLoggedIn(true));
      dispatch(setIsDeviceVerified(true));
      history.push(callbackUrl);
    }

    throwErrorProp(result);
  };

  const handleResend = () => {
    setResend(true);
    refetch();
  };

  useSetLayoutSettings({
    subTitle: 'Sign In',
    isMainScreen: true,
  });

  return (
    <Card className="mx-2 text-center py-4">
      <Card.Body>
        <ExtendedFormik
          validationSchema={validationSchema}
          initialValues={values}
          onSubmit={doSubmitForm}
          showCancelButton={false}
          saveText="VALIDATE DEVICE"
          buttonSize="lg"
          variantSubmit="primary-gradient"
          buttonsAlign="center"
          lockFormOnChanges={false}>
          {() => (
            <>
              <h2 className="fw-bold">You need to validate your device!</h2>
              <div>A pin has sent to your email, put it on the textbox below!</div>
              <Row className="mt-4">
                <TextField key="pin" className="mb-3" placeholder="Pin" name="pin" required />
              </Row>
              <Row className="mt-4 ps-2">
                <CheckboxField label="Verify device" name="verify" className="minute-checkbox" horizontal />
              </Row>
              <Row>
                <Col>
                  <div className="text-center">
                    <Button variant="link" className="mx-1 text-info" onClick={handleResend}>
                      <strong>Resend Pin</strong>
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ExtendedFormik>
      </Card.Body>
    </Card>
  );
};

export default DeviceVerification;
