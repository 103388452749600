import { RenderTitleType, DisplayOnMenuType } from '@rsl/core/src/types';

/**
 * display Text based on RenderTitleType based if string provided, remains the same if not executes the Function
 * @param rootState the root state of the app
 * @param text the text function or text
 */
export const getTextFromStateOrstring = (rootState: any, text?: RenderTitleType) => {
  return typeof text === 'string' ? text : text?.(rootState);
};

/**
 * Gets the value based on DisplayOnMenuType, whether is a boolean or a function to evaluate a result.
 * @param rootState the root state of the app
 * @param value the boolean or function
 * @returns boolean
 */
export const getBooleanFromStateOrValue = (rootState: any, value?: DisplayOnMenuType) => {
  return typeof value === 'boolean' ? value : value?.(rootState);
};

/**
 * gets thepreferred title to display on the BreadCrumb
 * @param rootState the root state of the app
 * @param title the text function for text for function
 * @param breadcrumb the text function for breadcrumb
 */
export const getBreadCrumbTitle = (rootState: any, title?: RenderTitleType, breadcrumb?: RenderTitleType) => {
  const titleFn = breadcrumb ?? title;
  return getTextFromStateOrstring(rootState, titleFn);
};
