import { Row, Col, Image, Spinner, Button, Container, ProgressBar, Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
// import Milestones from 'features/dashboard/milestones/Milestones';
// import { LoanSummaryExternal } from 'app/types';
import { useHistory } from 'react-router-dom';
import { useImportLoanMutation, useFetchLoanOfficerProfileImageQuery } from '../../../../api/loanPosApplicationApi';
import { useCurrentPos } from 'features/apply-now/pos/hooks/posHooks';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/hooks';
import { currentPosStatusSel } from 'features/apply-now/pos/store/selectors/posSelectors';
import { LoanPosApplicationStatus } from '@rsl/core/src/constants';
import { formatPhoneNumber } from '@rsl/core/src/utils/stringUtils';
import backgroundSrc from 'app/assets/img/pos/thankyou-card-bg.jpg';
import milestonesSrc from 'app/assets/img/pos/milestones.png';
import milestonesSrcXs from 'app/assets/img/pos/milestones-xs.png';
import styles from './T1.module.scss';
import classNames from 'classnames';

const T1 = () => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();

  // const milestones: LoanSummaryExternal = { milestoneStartedDate: new Date() } as any;
  const { posId } = useCurrentPos();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const status = useAppSelector(currentPosStatusSel);
  const [createImportLoan, { data: loanApp, isLoading: isImportLoading, isSuccess: hasImported }] = useImportLoanMutation();
  const { data: profileImage } = useFetchLoanOfficerProfileImageQuery(`${values.loanOfficerContact?.userId ?? ''}`, { skip: !values.loanOfficerContact?.userId });

  useEffect(() => {
    if (LoanPosApplicationStatus.Submitted === status && !hasImported) {
      setTimeout(async () => {
        posId && (await createImportLoan(posId));
      }, 500);
    }
  }, [status, posId, createImportLoan, hasImported, dispatch]);

  const goToNeedList = () => {
    history.push(`/loan/${loanApp?.loanId}/filesNeeded`);
  };

  return (
    <Container fluid className="mt-md-4 text-dark">
      <Row>
        <Col className="text-center mb-2">
          <span className="text-lime d-md-none">
            <strong>100%</strong>
          </span>
        </Col>
      </Row>
      <Row className="mb-4 mb-md-3">
        <Col xs={12} className="d-flex align-items-center justify-content-around px-0 mx-0">
          <Container fluid className="px-2 mx-0">
            <ProgressBar now={100} variant="pos-progress" style={{ height: '4px' }} />
          </Container>
          <span className="text-lime d-none d-md-block">
            <strong>100%</strong>
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="fs-2 text-primary d-none d-md-block mb-0">
            <strong>Yay! We Got Your Loan Application.</strong>
          </p>
          <p className="fs-2 text-primary text-center d-md-none mb-0">
            <strong>Yay! We Got Your Loan Application.</strong>
          </p>
        </Col>
      </Row>
      <hr className="mt-3 mt-md-2 mb-4 mb-md-5" />
      <Card className="overflow-hidden mb-5">
        <Card.Body className="px-4 py-5">
          <Row>
            <Col xs={12} md={6} className="mb-6 pb-5 mb-md-0 pb-md-0">
              <p className="fs-5 mb-4">
                <strong>Your Knowledgeable Loan Officer can Help Along the Way.</strong>
              </p>
              <p>
                Our loan team is now looking over your application and we will be in touch soon! Don't forget if you ever have any questions feel free to contact your loan officer.
              </p>
              <p className="mb-4">
                Begin by visiting your <span className="text-tertiary">Dashboard</span> to see all necessary information or documention that is needed regarding your loan.
              </p>
              {isImportLoading && <Spinner animation="border" variant="primary" />}
              {!isImportLoading && hasImported && (
                <div className="d-flex d-md-block justify-content-center">
                  <Button variant="text" size="lg" className="px-0 text-primary d-flex align-items-center" onClick={goToNeedList}>
                    <i className="fas fa-chevron-left fs-2"></i>
                    <strong>
                      <span className="mx-3">GO TO YOUR DASHBOARD</span>
                    </strong>
                  </Button>
                </div>
              )}
            </Col>
            <Col xs={12} md={6} className="d-lg-flex justify-content-end align-items-center position-relative">
              <figure className={classNames('position-absolute rounded-circle overflow-hidden m-0', styles.figure)}>
                <Image src={backgroundSrc} alt="background" className="w-100 m-0" />
              </figure>
              <div className="mx-md-5 mx-lg-0 mb-3 mb-lg-0 mt-lg-0 d-flex d-lg-block justify-content-center justify-content-md-end position-relative">
                {profileImage ? (
                  <Image
                    thumbnail
                    roundedCircle
                    src={`data:image/jpeg;base64,${profileImage}`}
                    className={classNames('border-light border-3', styles.thumbnail)}
                    alt="Loan Officer"
                  />
                ) : (
                  <i className="fas fa-user fs-1 mt-3"></i>
                )}
              </div>
              <div className="mx-4 position-relative d-flex d-lg-block flex-column align-items-center align-items-md-end">
                <h3 className="fw-bold mb-0 text-light text-nowrap">{`${values.loanOfficerContact?.firstName} ${values.loanOfficerContact?.lastName}`}</h3>
                <p className="fw-bold mb-0 text-light">
                  <small>{values.loanOfficerContact?.title}</small>
                </p>
                <p className="mb-3 text-light">
                  <small>
                    NMLS#{values.loanOfficerContact?.nmlsNumber} | {values.loanOfficerContact?.licenseState} {values.loanOfficerContact?.licenseNumber}
                  </small>
                </p>
                {values.loanOfficerContact?.workPhone && (
                  <p className="mb-0 text-light">
                    <span className="fw-bold">o:</span> {formatPhoneNumber(values.loanOfficerContact.workPhone)}
                  </p>
                )}
                {values.loanOfficerContact?.mobilePhone && (
                  <p className="mb-0 text-light">
                    <span className="fw-bold">c:</span> {formatPhoneNumber(values.loanOfficerContact.mobilePhone)}
                  </p>
                )}
                <p className="mb-0 fw-bold text-light small">{values.loanOfficerContact?.email}</p>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/*Replace this milestones with Dashboard milestone*/}
      <Card className="mb-5">
        <Card.Body>
          <p className="fs-2 text-center mb-4 mb-lg-4 my-lg-3">
            <strong>Milestones</strong>
          </p>
          <Container fluid className="d-flex d-lg-block justify-content-center align-items-start">
            <Image fluid src={milestonesSrc} className="d-none d-lg-block mb-4" />
            <Image src={milestonesSrcXs} className="d-lg-none mb-5" height="500" width="150" />
            <div className={classNames('d-flex flex-column flex-lg-row justify-content-around my-2', styles.milestoneTextDiv)}>
              <div className="d-lg-none px-2">
                <p className="fs-5 fw-bold mb-0 small d-sm-none mb-1">Application Submitted</p>
                <p className="fs-3 fw-bold mb-0 d-none d-sm-block">Application Submitted</p>
                <p className="small d-sm-none">The first step to your dream home</p>
                <p className="d-none d-sm-block">The first step to your dream home</p>
              </div>
              <div className="d-lg-none px-2">
                <p className="fs-5 fw-bold mb-0 small d-sm-none mb-1">Rate Lock</p>
                <p className="fs-3 fw-bold mb-0 d-none d-sm-block">Rate Lock</p>
                <p className="small d-sm-none">This will help protect you against market fluctuations</p>
                <p className="d-none d-sm-block">This will help protect you against market fluctuations</p>
              </div>
              <div className="d-lg-none px-2">
                <p className="fs-5 fw-bold mb-0 small d-sm-none mb-1">Approval</p>
                <p className="fs-3 fw-bold mb-0 d-none d-sm-block">Approval</p>
                <p className="small d-sm-none">This is when we’ll verify your full financial profile</p>
                <p className="d-none d-sm-block">This is when we’ll verify your full financial profile</p>
              </div>
              <div className="d-lg-none px-2">
                <p className="fs-5 fw-bold mb-0 small d-sm-none mb-1">Signing Scheduled</p>
                <p className="fs-3 fw-bold mb-0 d-none d-sm-block">Signing Scheduled</p>
                <p className="small d-sm-none">This is when you sign documents for close of escrow</p>
                <p className="d-none d-sm-block">This is when you sign documents for close of escrow</p>
              </div>
              <div className="d-lg-none px-2">
                <p className="fs-5 fw-bold mb-0 small d-sm-none mb-1">Close of Escrow</p>
                <p className="fs-3 fw-bold mb-0 d-none d-sm-block">Close of Escrow</p>
                <p className="small d-sm-none">This is when your funds are transferred</p>
                <p className="d-none d-sm-block">This is when your funds are transferred</p>
              </div>

              <div className="text-center d-none d-lg-block px-2">
                <p className="fs-3 fw-bold mb-1">Application Submitted</p>
                <p>The first step to your dream home</p>
              </div>
              <div className="text-center d-none d-lg-block px-2">
                <p className="fs-3 fw-bold mb-1">Rate Lock</p>
                <p>This will help protect you against market fluctuations</p>
              </div>
              <div className="text-center d-none d-lg-block px-2">
                <p className="fs-3 fw-bold mb-1">Approval</p>
                <p>This is when we’ll verify your full financial profile</p>
              </div>
              <div className="text-center d-none d-lg-block px-2">
                <p className="fs-3 fw-bold mb-1">Signing Scheduled</p>
                <p>This is when you sign documents for close of escrow</p>
              </div>
              <div className="text-center d-none d-lg-block px-2">
                <p className="fs-3 fw-bold mb-1">Close of Escrow</p>
                <p>This is when your funds are transferred</p>
              </div>
            </div>
          </Container>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default T1;
