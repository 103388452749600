import { FC } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';
import TextField from '@rsl/core/src/components/form/TextField';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType, IAssetType } from '@rsl/core/src/types';
import { QuestionValues, maxAssetAccounts } from '@rsl/core/src/constants';
import { FieldArray } from 'formik';
import OwnerTypeSelection from './OwnerTypeSelection';

interface IAccount {
  institutionLabel?: string;
  totalLabel?: string;
  institutionName: string;
  totalName: string;
  hideInstitutionField?: boolean;
  disableInstitutionField?: boolean;
  disableTotalField?: boolean;
}

const Account: FC<IAccount> = ({
  institutionLabel = 'Institution name',
  totalLabel = 'Total balances',
  institutionName,
  totalName,
  hideInstitutionField,
  disableInstitutionField,
  disableTotalField,
}) => {
  return (
    <Row className={`pt-1}`}>
      {hideInstitutionField ? (
        <Col xs={0} md={5} />
      ) : (
        <TextField className="mb-2" placeholder={institutionLabel} name={institutionName} xs={12} md={5} required disabled={disableInstitutionField} />
      )}
      <CurrencyField className="mb-2" label={totalLabel} name={totalName} xs={12} md={7} required isInlineLabel disabled={disableTotalField} />
    </Row>
  );
};

interface IAsset {
  namePrefix: string;
  institutionLabel?: string;
  totalLabel?: string;
  multiple?: boolean;
  hideInstitutionField?: boolean;
}

const Asset: FC<IAsset> = ({ namePrefix, multiple, hideInstitutionField, institutionLabel, totalLabel }) => {
  const { values } = useFormikContext<ILoanQuestionsMapType>();
  const maxAccounts = maxAssetAccounts;
  const isCoborrower = namePrefix.includes('2');
  const disableForCoborrowerIfCopyFromBorrowerChecked = isCoborrower && values.ShowSharedA2_;
  const jointAssetLiabilityReportingIndicator = values.SharedB8_1 === QuestionValues.Yes;

  return (
    <FieldArray
      name={`${namePrefix}Accounts`}
      render={(arrayHelpers) => {
        const accounts = (values as any)[`${namePrefix}Accounts`];

        return (
          <>
            {accounts.map((account: IAssetType, index: number) => (
              <>
                {jointAssetLiabilityReportingIndicator && !isCoborrower && <OwnerTypeSelection prefix={`${namePrefix}Accounts.${index}`} />}
                <Row>
                  <Col xs={10} md={11} lg={11}>
                    <Account
                      key={`${namePrefix}-${index}`}
                      institutionLabel={institutionLabel}
                      totalLabel={totalLabel}
                      totalName={`${namePrefix}Accounts.${index}.amount`}
                      institutionName={`${namePrefix}Accounts.${index}.institutionName`}
                      hideInstitutionField={hideInstitutionField}
                      disableInstitutionField={disableForCoborrowerIfCopyFromBorrowerChecked}
                      disableTotalField={disableForCoborrowerIfCopyFromBorrowerChecked}
                    />
                  </Col>
                  <Col xs={2} md={1} lg={1} className="px-0">
                    {index > 0 && (
                      <Button type="button" variant="primary" onClick={() => arrayHelpers.remove(index)} className="mt-2" disabled={disableForCoborrowerIfCopyFromBorrowerChecked}>
                        <i className="fas fa-trash"></i>
                      </Button>
                    )}
                  </Col>
                </Row>
              </>
            ))}
            {multiple && (
              <Button
                type="button"
                variant="primary"
                size="sm"
                onClick={() =>
                  arrayHelpers.push({
                    amount: '',
                    institutionName: '',
                  })
                }
                disabled={accounts.length >= maxAccounts || disableForCoborrowerIfCopyFromBorrowerChecked}>
                <i className="fas fa-plus"></i>
              </Button>
            )}
          </>
        );
      }}
    />
  );
};

export default Asset;
