import { useEffect, FC } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import Question from '../../Question';
import { ILoanQuestionsMapType, IReoPropertyType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';
import AddButtonText from 'app/components/buttons/AddButtonText';
import { getCurrencyFormatted } from '@rsl/core/src/utils/numberUtils';

const R0 = () => {
  const { values, setFieldValue, submitForm } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    setFieldValue('SharedR0_1', null);
    setFieldValue('SharedR1_1', '');
    setFieldValue('SharedR1_2', '');
    setFieldValue('SharedR1_3', '');
    setFieldValue('SharedR1_4', '');
    setFieldValue('SharedR1_5', '');
    setFieldValue('SharedR2_1', '');
    setFieldValue('SharedR2a_1', '');
    setFieldValue('SharedR3_1', '');
    setFieldValue('SharedR3a_1', '');
    setFieldValue('SharedR3a_2', '');
    setFieldValue('SharedR3a_3', '');
    setFieldValue('SharedR3a_4', '');
  }, [setFieldValue]);

  const addProperty = () => {
    setFieldValue('SharedR0_1', -1);
    submitForm();
  };

  const editProperty = (propertyIndex: number) => {
    setFieldValue('SharedR0_1', propertyIndex);

    const property = values.reoProperties[propertyIndex] as IReoPropertyType;
    setFieldValue('SharedR1_1', property.streetAddress);
    setFieldValue('SharedR1_2', property.city);
    setFieldValue('SharedR1_3', property.state);
    setFieldValue('SharedR1_4', property.postalCode);
    setFieldValue('SharedR1_5', property.unitNumber);
    setFieldValue('SharedR2_1', property.futurePropertyUsageType);
    setFieldValue('SharedR2a_1', property.rentalIncomeGrossAmount);
    setFieldValue('SharedR3_1', property.liabilityDoesNotApply ? QuestionValues.Yes : QuestionValues.No);
    setFieldValue('SharedR3a_1', property.lienUpbAmount);
    setFieldValue('SharedR3a_2', property.lienInstallmentAmount);
    setFieldValue('SharedR3a_3', property.maintenanceExpenseAmount);
    setFieldValue('SharedR3a_4', property.hoaAmount);

    submitForm();
  };

  const removeProperty = (propertyIndex: number) => {
    setFieldValue(
      'reoProperties',
      values.reoProperties.filter((el, index) => index !== propertyIndex)
    );
  };

  return (
    <>
      <Question label="Tell us about the real estate you own">
        {values.reoProperties.map((property: IReoPropertyType, index: number) => {
          switch (property.type) {
            case 'SubjectProperty':
              return (
                <HouseWidget className="mt-4" address={values.SharedW2_1} usage="Subject Property" balance={values.SharedP3a_1} income={property.rentalIncomeGrossAmount ?? 0} />
              );
            case 'ResidentialProperty':
              return (
                <HouseWidget
                  className="mt-4"
                  address={values.SharedB1b_1}
                  usage={`Current Address ${values.PurchaseP1_1 === QuestionValues.PlanForPendingSale ? '(To be sold)' : ''}`}
                  balance={values.SharedP4a_1}
                  income={property.rentalIncomeGrossAmount ?? 0}
                />
              );
            case 'Other':
              return (
                <>
                  <Row>
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                  <HouseWidget
                    address={property.streetAddress}
                    usage={property.futurePropertyUsageType}
                    balance={Number(property.lienUpbAmount)}
                    income={Number(property.rentalIncomeGrossAmount)}>
                    <>
                      <Button variant="link" onClick={() => editProperty(index)} className="ps-0 mid-small text-decoration-underline">
                        Edit
                      </Button>
                      <Button variant="link" onClick={() => removeProperty(index)} className="mx-2 ps-0 mid-small text-decoration-underline">
                        Remove
                      </Button>
                    </>
                  </HouseWidget>
                </>
              );
            default:
              return null;
          }
        })}
      </Question>
      <Question label="Do you own any other real estate?">
        <AddButtonText onClick={addProperty} className="mid-small px-0">
          Add another property
        </AddButtonText>
      </Question>
    </>
  );
};

interface HouseWidgetProps {
  address: string;
  usage: string;
  className?: string;
  balance: number;
  income: number;
}

const HouseWidget: FC<HouseWidgetProps> = ({ address, usage, className, children, balance, income }) => {
  return (
    <Row className={`mb-2 ${className}`}>
      <Col md={12}>
        <h6 className="text-primary mb-0 fw-bold">{address}</h6>
        <em className="d-block super-small fw-light pb-2">{usage}</em>
        <small className="d-block mid-small">
          {!!balance ? (
            <>
              Mortgage balance <em>{getCurrencyFormatted(balance ?? 0)}</em> with{' '}
              {!income ? (
                <>no rental income</>
              ) : (
                <>
                  an expected rental income <em>{getCurrencyFormatted(income)}</em> per month
                </>
              )}
            </>
          ) : !!income ? (
            <>
              Expected rental income <em>{getCurrencyFormatted(income)}</em> per month
            </>
          ) : null}
        </small>
      </Col>
      {children && <Col md={4}>{children}</Col>}
    </Row>
  );
};

export default R0;
