import { useFieldIds } from '@rsl/core/src/hooks/fieldHooks';
import { useField } from 'formik';
import { FC, useRef } from 'react';
import { IMinuteField } from '../../types/form.types';
import MinuteField from './MinuteField';
import { Form } from 'react-bootstrap';

interface ICheckboxField {
  disabled?: boolean;
  horizontal?: boolean;
}

const CheckboxField: FC<IMinuteField<string> & ICheckboxField> = ({ disabled, horizontal, ...props }) => {
  const { name } = useFieldIds(props);
  const [field, , { setValue }] = useField({ name });
  const ref = useRef<null | HTMLInputElement>();

  const onChange = () => {
    setValue(!field.value);
  };

  return (
    <MinuteField {...props} isCheckbox={horizontal} fieldNode={ref.current}>
      <Form.Check
        type="checkbox"
        name={name}
        checked={field.value}
        onChange={onChange as any}
        disabled={disabled}
        ref={ref as any}
        className={ref?.current?.validity.valid ? '' : 'is-invalid'}
        required={props.required}
      />
    </MinuteField>
  );
};

export default CheckboxField;
