import { FC } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import DropFilesZone from '@rsl/core/src/components/drop/DropFilesZone';
import { useFileList } from '@rsl/core/src/hooks/fileManagementHooks';
import { useUploadTaskFilesMutation } from 'features/loan/api/tasksApi';
import { useCurrentLoanId } from 'app/hooks/loanHooks';

interface UploadDocumentModalProps {
  modalShow: boolean;
  size?: 'sm' | 'lg' | 'xl' | undefined;
  title: string;
  onClose: () => void;
  externalTaskId: number;
  loanDisclosureDocumentId: string;
}

const UploadDocumentModal: FC<UploadDocumentModalProps> = ({ modalShow, size, onClose, title, externalTaskId, loanDisclosureDocumentId }) => {
  const currentLoanId = useCurrentLoanId();
  const [uploadFiles, { isLoading }] = useUploadTaskFilesMutation();
  const { currentFiles, setCurrentFiles, addFiles, sendFormFiles, deleteFileButtonAction, deleteAllFiles } = useFileList(uploadFiles, { limit: 1 });

  const handleSave = async (values: any) => {
    const payloadGen = (files: File[]) => {
      return {};
    };

    await sendFormFiles({ loanId: currentLoanId, externalTaskId: externalTaskId, loanDisclosureDocumentId: loanDisclosureDocumentId }, payloadGen);
    setCurrentFiles([]);
    onClose();
  };

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" show={modalShow} onHide={onClose} size={size}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentFiles.map(([name], index) => (
          <Row className="py-1">
            <Col sm={6} className="mx-auto text-center mb-5">
              {name} <i className="far fa-trash-alt cursor-pointer ms-2" data-file-name={name} onClick={deleteFileButtonAction}></i>
            </Col>
          </Row>
        ))}
        {!!currentFiles.length && (
          <Row>
            <Col className="py-2 d-flex justify-content-end">
              <Button
                onClick={() => {
                  onClose();
                  deleteAllFiles();
                }}
                disabled={isLoading}
                variant="outline-primary"
                className="rounded-pill px-3 mx-1">
                CANCEL
              </Button>
              <Button onClick={handleSave} disabled={isLoading} variant="primary-gradient" className="rounded-pill px-3 mx-1">
                UPLOAD {isLoading && <i className="ps-2 fas fa-spinner fa-spin"></i>}
              </Button>
            </Col>
          </Row>
        )}
        {currentFiles.length === 0 && <DropFilesZone onDrop={addFiles} className="mt-4" maxFiles={1} disabled={isLoading} />}
      </Modal.Body>
    </Modal>
  );
};

export default UploadDocumentModal;
