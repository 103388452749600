import { EConsent, DocumentSignTask } from 'app/types';
import { FC, useState } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import Task from './Task';
import EConsentAgreement from 'features/apply-now/pos/components/questions/shared/agreement/EConsentAgreement';
import { eConsentSignTimeStamp, machineStamp } from 'features/apply-now/pos/utils/agreeStamps';
import { useCompleteEConsentTaskMutation } from 'features/loan/api/tasksApi';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import { toast } from 'react-toastify';
import { ExternalTaskStatus } from 'app/types/externalTask.status';
import { useExternalAuth } from 'app/hooks/authHooks';

interface MyTaskProps {
  task: DocumentSignTask<EConsent[]>;
}

const MyEConsentTasks: FC<MyTaskProps> = ({ task }) => {
  const [disableAgree, SetDisableAgree] = useState<boolean>(true);
  const [completeEConsentTask] = useCompleteEConsentTaskMutation();
  const { email } = useExternalAuth();

  const agree = async () => {
    const eConsentTask: EConsent = {
      loanId: `${(task.viewModelJson as any).LoanId}`,
      loanNumber: `${(task.viewModelJson as any).LoanNumber}`,
      borrowerEConsentSignTimeStamp: eConsentSignTimeStamp(
        `${(task.viewModelJson as any).BorrowerFirstName} ${(task.viewModelJson as any).BorrowerLastName}`,
        `${(task.viewModelJson as any).BorrowerEmail}`
      ),
      borrowerEConsentMachineStamp: await machineStamp(),
      borrowerFirstName: `${(task.viewModelJson as any).BorrowerFirstName}`,
      borrowerLastName: `${(task.viewModelJson as any).BorrowerLastName}`,
      borrowerEmail: `${(task.viewModelJson as any).BorrowerEmail}`,
      isBorrower: true,
      externalTaskId: `${task.externalTaskId}`,
    };

    const response = await completeEConsentTask(eConsentTask);
    throwErrorProp(response);
    toast.success('EConsent Agreed!');
    SetDisableAgree(true);
  };

  const isAssignedTask = task.assignedTo.includes(email);

  return (
    <Task name={task.name} status={task.status}>
      <Row className="mt-2">
        <Col xs={12} md={true}>
          <Card.Text className="fw-bold text-tertiary mb-0 d-md-none">
            <small>Document</small>
          </Card.Text>
          <Card.Text className="fw-bold mb-0">EDisclosure Consent</Card.Text>
        </Col>
        <Col xs={12} md={true}>
          <Card.Text className="fw-bold text-tertiary mt-2 mb-0 d-md-none">
            <small>Signer</small>
          </Card.Text>
          <Card.Text className="mb-0">{(task.viewModelJson as any).BorrowerFirstName}</Card.Text>
        </Col>
        <Col xs={12} md={true} className="mt-3 mt-md-0">
          <div key={`radio-econsent-agreement`} className="d-flex justify-content-center">
            {task.status === ExternalTaskStatus.Completed ? (
              <Form.Check
                id={`radio-econsent-agree`}
                type="radio"
                label={`"I Agree" ${(task.viewModelJson as any).BorrowerFirstName} to consent to do business electronically`}
                checked
                disabled
              />
            ) : (
              <Form.Check
                id={`radio-econsent-agree`}
                type="radio"
                label={`"I Agree" ${(task.viewModelJson as any).BorrowerFirstName} to consent to do business electronically`}
                onClick={() => SetDisableAgree(false)}
                disabled={!isAssignedTask}
              />
            )}
          </div>
          <div className="d-flex d-md-block justify-content-center mb-2 mb-md-0">
            <Button variant="primary-gradient" disabled={disableAgree} onClick={agree} className="rounded-pill px-4 my-2">
              AGREE
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <EConsentAgreement />
        </Col>
      </Row>
    </Task>
  );
};

export default MyEConsentTasks;
