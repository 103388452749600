import { useEffect } from 'react';
import Question from '../../Question';
import TextField from '@rsl/core/src/components/form/TextField';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import PlacesAutoComplete, { PlaceType } from '../../../fields/PlacesAutocomplete';
import { IPlaceAutocompleteResponse } from '../../../fields/fields.types';

const R1 = () => {
  const { values, setFieldValue, setValues } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.SharedR0_1 === -1) {
      setFieldValue('SharedR0_1', values.reoProperties.length);
    }
  }, [values.SharedR0_1, setFieldValue, values.reoProperties]);

  return (
    <Question label="Tell us about the real estate you own" labelSize="lg">
      <Row>
        <Col>
          <PlacesAutoComplete
            placeholder="Street Address"
            name="SharedR1_1"
            className="mb-2 places-autocomplete"
            types={[PlaceType.ADDRESS]}
            onChange={(res: IPlaceAutocompleteResponse) =>
              setValues({
                ...values,
                SharedR1_1: res.address ?? values.SharedR1_1,
                SharedR1_2: res.city ?? values.SharedR1_2,
                SharedR1_3: res.state ?? values.SharedR1_3,
                SharedR1_4: res.zipCode ?? values.SharedR1_4,
              })
            }
          />
        </Col>
        <TextField xs={3} className="mb-2" placeholder="Unit" name="SharedR1_5" />
      </Row>
      <Row>
        <TextField className="mb-2" placeholder="City" name="SharedR1_2" required xs={6} md={4} />
        <LookupSelect className="mb-2" placeholder="State" name="SharedR1_3" lookup="states" required enableDefault={false} xs={6} md={4} />
        <TextField className="mb-2" placeholder="Zip" name="SharedR1_4" required xs={6} md={4} />
      </Row>
    </Question>
  );
};

export default R1;
