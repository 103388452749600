import DownloadLink from '@rsl/core/src/components/link/DownloadLink';
import { LoanDocumentType } from '@rsl/core/src/types';
import { useExternalAuth } from 'app/hooks/authHooks';
import { useCallback, FC, ReactNode } from 'react';
interface SidebarDocumentLinkProps {
  useFetchHook: Function;
  to: (data: LoanDocumentType) => string;
  loanId?: string;
  icon?: FC;
  filename: ((data: any) => string) | string;
  editAction?: ReactNode;
  children: ReactNode;
}

const SidebarDocumentLink: FC<SidebarDocumentLinkProps> = ({ useFetchHook, to, loanId = '', icon: Icon, filename, editAction, children }) => {
  const { data } = useFetchHook(loanId, { skip: !loanId });
  const { token } = useExternalAuth();

  // memorize the function for not been regenerated each time
  const toFn = useCallback((data: any) => {
    if (data) {
      return to(data);
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilename = useCallback((data: any) => {
    if (data) {
      if (typeof filename === 'function') {
        return filename(data);
      }
      return filename;
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !!data ? (
    <span className="d-flex align-items-center justify-content-between">
      <DownloadLink href={toFn(data)} filename={getFilename(data)} className="d-flex justify-content-between align-items-center fw-bold" token={token}>
        <span className="d-inline-block pe-2">{Icon && <Icon />}</span>
        {typeof children === 'function' ? children(data) : children}
      </DownloadLink>
      {editAction}
    </span>
  ) : null;
};

export default SidebarDocumentLink;
