import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';

const C5 = () => {
  return (
    <Question label="Are you a current or former member of the US Military?" small="Active US Military and veterans may be elegible for VA loan.">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedC5_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default C5;
