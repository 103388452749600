import classNames from 'classnames';
import styles from './GeneralErrorComponents.module.scss';
import { FC, useEffect } from 'react';
import { getInsights } from 'app/appInsights';

interface GeneralErrorComponentsProps {
  error: any;
  resetErrorBoundary: Function;
}

const GeneralErrorComponents: FC<GeneralErrorComponentsProps> = ({ error, resetErrorBoundary }) => {
  useEffect(() => {
    // track appInsights error
    const { appInsights } = getInsights();
    appInsights.trackException({ error: new Error(`[POS] Critical issue`), properties: error });
    //resetErrorBoundary();
  }, [error]);

  return (
    <div className={classNames('splash active', 'd-flex justify-content-center align-items-center flex-column')}>
      <h1>Something went wrong!. We Will notify to the System Admin, please refresh the page and try again.</h1>

      <i className={classNames('fa fa-bug text-error mt-3', styles.icon)} aria-hidden="true"></i>
    </div>
  );
};

export default GeneralErrorComponents;
