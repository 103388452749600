import { FC } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { IQuestionType } from '@rsl/core/src/types';

const GC20: FC<IQuestionType> = ({ showIf }) => {
  return (
    <>
      <Question label="What type of property did you own?" showIf={showIf}>
        <Row>
          <RadioField
            className="mb-2"
            name="SharedGC20_1"
            optionsClassName="pt-1"
            options={[
              { label: 'Primary Residence', value: 'PrimaryResidence' },
              { label: 'FHA Secondary Residence', value: 'FhaSecondaryResidence' },
              { label: 'Second Home', value: 'SecondaryResidence' },
              { label: 'Investment property', value: 'Investment' },
            ]}
          />
        </Row>
      </Question>
      <Question label="How did you hold title to the property?" showIf={showIf}>
        <Row>
          <RadioField
            className="mb-2"
            name="SharedGC20_2"
            optionsClassName="pt-1"
            options={[
              { label: 'Sole Ownership', value: 'Sole' },
              { label: 'Joint With Spouse', value: 'JointWithSpouse' },
              { label: 'Joint With Other Than Spouse', value: 'JointWithOtherThanSpouse' },
            ]}
          />
        </Row>
      </Question>
    </>
  );
};

export default GC20;
