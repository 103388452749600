import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';

import { IQuestionType, ILoanQuestionsMapType } from '@rsl/core/src/types';
import Question from '../../Question';
import TextField from '@rsl/core/src/components/form/TextField';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import PlacesAutoComplete, { PlaceType } from '../../../fields/PlacesAutocomplete';
import { IPlaceAutocompleteResponse } from '../../../fields/fields.types';

const C1b: FC<IQuestionType> = ({ showIf }) => {
  const { values, setValues } = useFormikContext<ILoanQuestionsMapType>();

  return (
    <Question label="What is your current address?" showIf={showIf}>
      <Row>
        <Col xs={9}>
          <PlacesAutoComplete
            placeholder="Street Address"
            name="SharedC1b_1"
            types={[PlaceType.ADDRESS]}
            className="mb-2 places-autocomplete"
            onChange={(res: IPlaceAutocompleteResponse) =>
              setValues({
                ...values,
                SharedC1b_1: res.address ?? values.SharedC1b_1,
                SharedC1b_2: res.city ?? values.SharedC1b_2,
                SharedC1b_3: res.state ?? values.SharedC1b_3,
                SharedC1b_4: res.zipCode ?? values.SharedC1b_4,
              })
            }
            disabled={values.SharedC1a_1}
          />
        </Col>
        <TextField xs={3} className="mb-2" placeholder="Unit" name="SharedC1b_5" disabled={values.SharedC1a_1} />
      </Row>
      <Row>
        <TextField className="mb-2" placeholder="City" name="SharedC1b_2" required disabled={values.SharedC1a_1} />
        <LookupSelect className="mb-2" placeholder="State" name="SharedC1b_3" lookup="states" required disabled={values.SharedC1a_1} />
        <TextField className="mb-2" placeholder="Zip" name="SharedC1b_4" required disabled={values.SharedC1a_1} />
      </Row>
    </Question>
  );
};

export default C1b;
