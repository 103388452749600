export const breakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};

export type BreakpointType = keyof typeof breakPoints;

export const availBreakPoints: BreakpointType[] = Object.keys(breakPoints) as BreakpointType[];
