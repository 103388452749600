import { useEffect } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';

const B3 = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.isCoborrowerInvite && values.SharedB3_1 !== QuestionValues.Married) {
      setFieldValue('SharedB7_1', QuestionValues.ApplyingMyOwn);
    } else if (values.isCoborrowerInvite && values.SharedB3_1 === QuestionValues.Married && values.SharedB7_1 === QuestionValues.ApplyingMyOwn) {
      setFieldValue('SharedB7_1', undefined);
    }
  }, [values.isCoborrowerInvite, values.SharedB3_1, values.SharedB7_1, setFieldValue]);

  return (
    <Question label="What's your current marital status?">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedB3_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.Married, value: QuestionValues.Married },
            { label: `${QuestionValues.Unmarried} (single, divorced, or widowed)`, value: QuestionValues.Unmarried },
            { label: QuestionValues.Separated, value: QuestionValues.Separated },
          ]}
        />
      </Row>
    </Question>
  );
};

export default B3;
