import Question from '../../Question';
import TextField from '@rsl/core/src/components/form/TextField';
import MaskedField from '@rsl/core/src/components/form/MaskedField';
import { Row } from 'react-bootstrap';

const B1 = () => {
  return (
    <Question label="Tell us about yourself" labelSize="lg">
      <Row>
        <TextField className="mb-2" placeholder="Legal first name" name="SharedB1_1" required />
        <TextField className="mb-2" placeholder="Middle Name" name="SharedB1_2" nullable />
      </Row>
      <Row>
        <TextField className="mb-2" placeholder="Legal last name" name="SharedB1_3" required />
      </Row>
      <Row>
        <MaskedField
          className="mb-2"
          placeholder="Cell Phone"
          name="SharedB1_4"
          mask="(999) 999-9999"
          mutation={/[^0-9]+/g}
          nullable
          inputProps={{
            autocomplete: 'tel-national',
          }}
        />
        <MaskedField
          className="mb-2"
          placeholder="Home Phone"
          name="SharedB1_6"
          mask="(999) 999-9999"
          mutation={/[^0-9]+/g}
          nullable
          inputProps={{
            autocomplete: 'tel-national',
          }}
        />
      </Row>
      <Row>
        <TextField className="mb-2" placeholder="Email" name="SharedB1_5" required disabled />
      </Row>
    </Question>
  );
};

export default B1;
