import { useCallback, useState } from 'react';
import { useIsMobile } from './windowEventHooks';

/**
 * getCollapse handler and value that will work only on mobile devices
 * @returns collapseElement: boolean, setCollapsed: function
 */
export const useCollapseChangeOnMobile = () => {
  const [collapsed, setCollapsed] = useState(true);
  const isMObile = useIsMobile();

  const collapseElement = isMObile ? collapsed : false;

  return {
    isMObile,
    collapsed: collapseElement,
    setCollapsed: useCallback(() => {
      if (isMObile) setCollapsed(!collapsed);
    }, [collapsed, isMObile]),
  };
};
