import { LayoutSettingsState, setLayoutSettings } from 'app/store/slices/layoutSettingsSlice';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';

export const useSetLayoutSettings = (settings: LayoutSettingsState) => {
  const dispatch = useAppDispatch();
  const [cachedSettings] = useState(settings);

  useEffect(() => {
    dispatch(setLayoutSettings(cachedSettings));
    return () => {
      dispatch(
        setLayoutSettings({
          isMainScreen: false,
          subTitle: '',
        })
      );
    };
  }, [cachedSettings, dispatch]);
};

export const useLayoutSettings = () => {
  return useAppSelector((state) => state.layoutSettings);
};
