import { FC, useMemo } from 'react';
import { Form } from 'formik';
import { Card, Col, Container, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import { useCurrentLoan } from 'app/hooks/loanHooks';
import { useFetchPreQualDocumentQuery, useFetchPreQualDtoQuery, useGeneratePreQualMutation } from '../api/loanPreQualLsuApi';
import PreQualOnDemandForm from './PreQualOnDemandForm';
import { ILoanPreQualEdit } from '@rsl/core/src/types/loanDocuments.types';
import ExtendedFormik from '@rsl/core/src/components/form/ExtendedFormik';
import { formatToDateTextField } from '@rsl/core/src/utils/dateUtils';
import SidebarDocumentLink from 'app/components/sidebar/SidebarDocumentLink';
import { getCurrencyFormatted } from '@rsl/core/src/utils/numberUtils';

const PreQualSchema = Yup.object().shape({
  loanId: Yup.string(),
  purchasePrice: Yup.number()
    .required()
    .min(5_000)
    .test({
      name: 'exceeds-max-loan',
      exclusive: true,
      params: {},
      test: function (value, ctx) {
        const { maxLoanAmount, loanAmount } = this.parent;
        const isValid = loanAmount <= maxLoanAmount;

        if (!isValid) {
          return ctx.createError({ message: `Can not exceed max loan amount, limit is ${getCurrencyFormatted(maxLoanAmount)}` });
        }

        return true;
      },
    }),
  downPaymentAmount: Yup.number(),
  loanAmount: Yup.number(),
  maxLoanAmount: Yup.number(),
  loanTerm: Yup.number(),
  interestRate: Yup.number(),
  preQualExpirationDate: Yup.string(),
  monthlyExpensesPrincipleAndInterest: Yup.number(),
  monthlyExpensesOtherFinancingPrincipleAndInterest: Yup.number(),
  monthlyExpensesRealEstateTaxes: Yup.number().max(5).positive(),
  monthlyExpensesRealEstateTaxesAmount: Yup.number(),
  monthlyExpensesHazardInsurance: Yup.number().optional().max(1_000).min(1),
  monthlyExpensesHOADues: Yup.number().optional().max(1_000).min(0).default(0),
  monthlyExpensesMortgageInsurance: Yup.number(),
  monthlyExpensesMortgageInsuranceAmount: Yup.number(),
  monthlyExpensesTotal: Yup.number().test({
    name: 'exceeds-monthly-expenses',
    exclusive: true,
    params: {},
    test: function (value, ctx) {
      const { maxTotalMonthlyExpenses } = this.parent;
      const isValid = (value ?? 0) <= maxTotalMonthlyExpenses;
      if (!isValid) {
        return ctx.createError({ message: `Can not exceed max payment of ${getCurrencyFormatted(maxTotalMonthlyExpenses)}` });
      }
      return true;
    },
  }),
});

const PreQualOnDemand: FC = () => {
  const currentLoan = useCurrentLoan();
  const { data, isLoading } = useFetchPreQualDtoQuery(currentLoan?.loanId ?? '', { skip: !currentLoan });

  const [generate] = useGeneratePreQualMutation();

  const initialData = useMemo(() => {
    return data
      ? ({
          ...data,
          monthlyExpensesHOADues: data.monthlyExpensesHOADues ?? 0,
          preQualExpirationDate: formatToDateTextField(data.preQualExpirationDate),
        } as ILoanPreQualEdit)
      : ({} as ILoanPreQualEdit);
  }, [data]);

  const onGenerate = async (values: ILoanPreQualEdit) => {
    await generate(values);
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <Container>
          <Row>
            <Col>
              <h1 className="pt-4 pb-3">Pre-qualification Editor</h1>
              <ExtendedFormik
                isLoading={isLoading}
                initialValues={initialData}
                validationSchema={PreQualSchema}
                onSubmit={onGenerate}
                lockFormOnChanges={false}
                showCancelButton={false}
                buttonSize="lg"
                saveText="Generate">
                {() => {
                  return (
                    <Form>
                      <Card>
                        <Card.Body>
                          <PreQualOnDemandForm />
                        </Card.Body>
                      </Card>
                    </Form>
                  );
                }}
              </ExtendedFormik>
              <Row className="mx-0 my-4 test">
                <Col xs={12} lg={6}>
                  <SidebarDocumentLink
                    filename={(data) => data.filename}
                    useFetchHook={useFetchPreQualDocumentQuery}
                    to={(data) => `/LoanPreQualLsuExternal/${currentLoan?.loanId}/PreQual/LoanDocuments/${data?.loanDocumentId}/download`}
                    loanId={currentLoan?.loanId}>
                    {({ filename }: any) => (
                      <>
                        <i className="fas fa-paperclip"></i> {filename}
                      </>
                    )}
                  </SidebarDocumentLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PreQualOnDemand;
