/**
 *
 * @param o
 * @param additionalSufixes
 * @returns
 */
export function strEnum<T extends string>(o: Array<T>): { [K in T]: K } {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

export function strSufixEnum<T extends string, T2 extends string>(o: Array<T>, sufixes: Array<T2>): { [K in `${T}${T2}` | T]: K } {
  return o.reduce((res, key) => {
    res[key] = key;
    sufixes.forEach((sufix) => (res[`${key}${sufix}`] = `${key}${sufix}`));
    return res;
  }, Object.create(null));
}
