import { FC } from 'react';
import { useFetchPartnerProfileQuery, useUpdatePartnerMutation } from '../api/partnersApi';
import { IUserPartner } from '../types';
import { throwErrorProp } from '@rsl/core/src/utils/formUtils';
import PartnerForm from './PartnerForm';
import { Spinner } from 'react-bootstrap';

interface IPartnerEdit {}

const PartnerEdit: FC<IPartnerEdit> = () => {
  const { data: partner, isFetching: loadingPartner } = useFetchPartnerProfileQuery();
  const [updatePartner, { isLoading: isSaving }] = useUpdatePartnerMutation();

  const doSavePartner = async (values: IUserPartner) => {
    const response: any = await updatePartner(values);
    throwErrorProp(response);
  };

  return loadingPartner || isSaving ? (
    <div className="d-flex justify-content-center align-items-center w-100 vh-50">
      <Spinner variant="primary" animation="border" />
    </div>
  ) : (
    <PartnerForm initialState={partner} onSubmit={doSavePartner} />
  );
};

export default PartnerEdit;
