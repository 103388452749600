export enum ValidationErrors {
  AtLeastOneLetter = 'Must contain at least one letter',
  DatesInOrder = 'End date must be after start date',
  PastDate = 'Date must be in the future',
  Email = 'Invalid email',
  Entity = 'Must contain only letters and spaces',
  Integer = 'Must be a whole number',
  Max = 'Too long!',
  Min = 'Too short!',
  Name = 'Must contain only letters',
  Nmls = 'Must be between 4 and 12 digits',
  Number = 'Must be a number',
  Phone = 'Must be 10 digits',
  Positive = 'Must be a positive number',
  Required = 'Required field',
  RuleExpression = 'The rule is not valid',
  AtLeastOneChecked = 'Must select at least one option',
  AtLeastOneAndSubChecked = 'You must select at least one option and one sub-option if your option allows it',
  AtLeastOneIncome = 'Must put at least one income',
  AtLeastOneEmployment = 'Must add at least one employment',
  AtLeastOneSelfEmployment = 'Must add at least one self-employment',
  EmptyPassword = 'No password provided.',
  PasswordShort = 'Password is too short - should be 8 chars minimum.',
  PasswordLong = 'Password is too long - should be 15 chars maximum.',
  PasswordFormat = 'Password must contain at least one number and one letter, one special character and one uppercase letter.',
  PasswordMismatch = 'Passwords must match',
  PinEmpty = 'Pin is required',
  VerifyDevice = 'You must verify your device to continue.',
  Zip = 'Must be 5 digits',
  ConfirmPassword = 'Passwords must match.',
  SSN = 'SSN must be exactly 9 characters.',
  LettersNumbersAndSpaces = 'Must contain only letters, numbers and spaces',
}
