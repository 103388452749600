import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { QuestionValues } from '@rsl/core/src/constants';

const W4 = () => {
  return (
    <Question label="What is the property type?">
      <Row>
        <RadioField
          className="mb-2"
          name="SharedW4_1"
          optionsClassName="pt-1"
          options={[
            { label: 'Single Family', value: QuestionValues.DetachedPropertyType },
            { label: 'Townhouse', value: QuestionValues.AttachedPropertyType },
            { label: 'Condo', value: QuestionValues.CondominiumPropertyType },
            { label: '2 to 4 units', value: QuestionValues.CooperativePropertyType }, // Not sure if this option's value is right
            { label: 'Manufactured Housing', value: QuestionValues.ManufacturedHousingPropertyType },
          ]}
        />
      </Row>
    </Question>
  );
};

export default W4;
