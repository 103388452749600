import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_UNSAVED_CHANGES_MESSAGE } from '../../constants/infoMessageConstants';

interface RouteState {
  routeParams: Record<string, string>;
  isLockedRoute: boolean;
  lockedRouteMessage: string;
}

interface MessageConfig {
  message?: string;
  isLockedRoute: boolean;
}

/// this is temporary Hack until find how to connect routes a RTK correctly
const initialState: RouteState = {
  routeParams: {},
  isLockedRoute: false,
  lockedRouteMessage: DEFAULT_UNSAVED_CHANGES_MESSAGE,
};

export const routeSlice = createSlice({
  name: 'route',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRouteParams: (state, { payload }: PayloadAction<Record<string, string>>) => {
      state.routeParams = payload;
    },

    setLockedRoute: (state, { payload }: PayloadAction<MessageConfig>) => {
      state.isLockedRoute = payload.isLockedRoute;
      state.lockedRouteMessage = payload.message ?? DEFAULT_UNSAVED_CHANGES_MESSAGE;
    },
  },
});

export const { setRouteParams, setLockedRoute } = routeSlice.actions;

export default routeSlice.reducer;
