import { ApiResponse } from '@rsl/core/src/types';
import { baseApi } from '@rsl/core/src/api/baseApi';
import { IFollowUp } from '../types';
import { invalidateOnSuccess } from '@rsl/core/src/utils/cacheUtils';
import { ICommunicationLog, ICommunicationLogAttachment, ILoanCommLogRecipientLookup } from '@rsl/core/src/types/communication.types';

export const commLogApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchInbox: build.query<ICommunicationLog[], void>({
      query: () => '/commlogs',
      transformResponse: (response: ApiResponse<ICommunicationLog[]>) => response.data,
      providesTags: ['Communication'],
    }),
    fetchSent: build.query<ICommunicationLog[], void>({
      query: () => '/commlogs/sent',
      transformResponse: (response: ApiResponse<ICommunicationLog[]>) => response.data,
      providesTags: ['Communication'],
    }),
    acknowledgeRecipient: build.mutation<ICommunicationLog, number | null>({
      query: (id) => ({
        url: `/commlogs/${id}/acknowledge`,
        method: 'PATCH',
      }),
      transformResponse: (response: ApiResponse<ICommunicationLog>) => response.data,
      invalidatesTags: invalidateOnSuccess(['Communication', 'Loan']),
    }),
    unreadRecipient: build.mutation<ICommunicationLog, number | null>({
      query: (id) => ({
        url: `/commlogs/${id}/unread`,
        method: 'PATCH',
      }),
      transformResponse: (response: ApiResponse<ICommunicationLog>) => response.data,
      invalidatesTags: invalidateOnSuccess(['Communication', 'Loan']),
    }),
    acknowledgeRecipients: build.mutation<ICommunicationLog[], Omit<number[], 'communicationLogId'>>({
      query: (body) => ({
        url: `/commlogs/acknowledge`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: ApiResponse<ICommunicationLog[]>) => response.data,
      invalidatesTags: invalidateOnSuccess(['Communication', 'Loan']),
    }),
    fetchLoanCommLogRecipientLookup: build.query<ILoanCommLogRecipientLookup[], string | null>({
      query: (id) => `/loans/${id}/communicationlogs/recipientlookup`,
      transformResponse: (response: ApiResponse<ILoanCommLogRecipientLookup[]>) => response.data,
      providesTags: ['Communication'],
    }),
    fetchReplyMessage: build.query<ICommunicationLog, number | null>({
      query: (id) => `/commlogs/${id}/reply`,
      transformResponse: (response: ApiResponse<ICommunicationLog>) => response.data,
      providesTags: ['Communication'],
    }),
    saveCommLog: build.mutation<FormData, Omit<FormData, 'communicationLogId'>>({
      query: (body) => ({
        url: '/commlogs',
        method: 'POST',
        body,
      }),
      transformResponse: (response: ApiResponse<FormData>) => response.data,
      invalidatesTags: invalidateOnSuccess(['Communication', 'Loan']),
    }),
    downloadAttachment: build.mutation<string, Omit<ICommunicationLogAttachment, 'attachmentId'>>({
      query: (body) => ({
        url: `/commlogs/${body.communicationLogId}/${body.communicationLogAttachmentId}/download`,
        method: 'GET',
      }),
      transformResponse: (response: ApiResponse<string>) => response.data,
      invalidatesTags: invalidateOnSuccess(['Communication']),
    }),
    followUpCommLog: build.mutation<IFollowUp, Omit<IFollowUp, 'loanId'>>({
      query: (body) => ({
        url: '/activities',
        method: 'POST',
        body,
      }),
      transformResponse: (response: ApiResponse<IFollowUp>) => response.data,
      invalidatesTags: invalidateOnSuccess(['Communication']),
    }),
    fetchExternalReplyMessage: build.query<ICommunicationLog, string | null>({
      query: (id) => `/commlogs/${id}/reply/external`,
      transformResponse: (response: ApiResponse<ICommunicationLog>) => response.data,
      providesTags: ['Communication'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchInboxQuery,
  useFetchSentQuery,
  useAcknowledgeRecipientMutation,
  useAcknowledgeRecipientsMutation,
  useUnreadRecipientMutation,
  useFetchLoanCommLogRecipientLookupQuery,
  useFetchReplyMessageQuery,
  useSaveCommLogMutation,
  useFollowUpCommLogMutation,
  useFetchExternalReplyMessageQuery,
} = commLogApi;
