import { FormikHelpers } from 'formik';
import { FormStatus } from '../constants';

type FormikCallBackType<T> = (values: T, formikHelpers: FormikHelpers<T>) => void;

/**
 *  Send form data with Files as fprmData
 * @param payload the data object that will be part of the body in the request
 * @param files the list of files that will be sent
 * @param params the params that usually are taken in the route in the mutation route
 * @param mutation  the mutation function
 * @param bodyKey OPTIONAL the key that is used as root in the body
 * @param filesKey OPTIONAL the key that will contain the values in teh request
 */
export const sendFormWithFiles = async (
  payload: Array<any> | Record<any, any>,
  files: File[],
  params: Record<string, any>,
  mutation: Function,
  bodyKey = 'dto',
  filesKey = 'files'
) => {
  const formData = new FormData();
  formData.append(bodyKey, JSON.stringify(payload));
  files.forEach((file) => {
    formData.append(filesKey, file, file.name);
  });
  await mutation({ body: formData, ...params });
};

/**
 * wrapper of the submit handler that will do same post action on the forms after using it
 * @param onSubmit function executed when submit has started
 * @param await onSuccess function executed when submit has finished correctly
 */
export function wrapSubmitCommon<T>(onSubmit: FormikCallBackType<T>, onSuccess?: FormikCallBackType<T>) {
  return async (values: T, formikHelpers: FormikHelpers<T>) => {
    try {
      await onSubmit(values, formikHelpers);
      onSuccess?.(values, formikHelpers);
      formikHelpers.setStatus(FormStatus.Success);
    } catch {
      formikHelpers.setStatus(FormStatus.Error);
    }
  };
}

/**
 * if the given object has a error Prop, then will throw an error
 * @param obj the object to check
 */
export const throwErrorProp = (obj: { error: any } | any) => {
  if (!!obj.error) throw new Error('');
};
