import { ReactComponent as Minutemortgage } from 'app/assets/img/cobranding-page/minutemortgage.svg';
import { ReactComponent as Equalhousingopportunity } from 'app/assets/img/cobranding-page/equalhousingopportunity.svg';

const Footer = () => {
  return (
    <>
      <footer className="navbar-theme py-4 d-flex flex-column bg-dark align-items-center">
        <div className="mb-4">
          <Minutemortgage height="51" />
        </div>
        <p className="text-white fw-light text-center mb-0">
          <small>&copy; 2023 Minute Mortgage | 800.725.8205 | All rights reserved.</small>
        </p>
        <p className="text-white fw-light text-center">
          <small>
            NMLS#1696333 |{' '}
            <a href="https://minutemortgage.com/licensing/" target="_blank" rel="noopener noreferrer">
              Licensing
            </a>{' '}
            |{' '}
            <a href="https://minutemortgage.com/privacy-and-security/" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{' '}
            |{' '}
            <a href="https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/1696333" target="_blank" rel="noopener noreferrer">
              NLMS
            </a>
          </small>
        </p>
        <Equalhousingopportunity height="57" />
      </footer>
    </>
  );
};

export default Footer;
