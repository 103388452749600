import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

let appInsights: ApplicationInsights;
let reactPlugin: ReactPlugin;

const createAppInsights = () => {
  const browserHistory = createBrowserHistory({ basename: '' });
  reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
      extensions: [reactPlugin as any],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();
};

export const getInsights = () => {
  if (!appInsights) {
    createAppInsights();
  }
  return { appInsights, reactPlugin };
};
