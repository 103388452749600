import { FC } from 'react';

import { ILoanNeedsListBorrowerExt } from '@rsl/core/src/types';

interface BorrowersOverviewProps {
  allBorrowers?: ILoanNeedsListBorrowerExt[];
  borrowers?: ILoanNeedsListBorrowerExt[];
}

const BorrowersOverview: FC<BorrowersOverviewProps> = ({ allBorrowers = [], borrowers = [] }) => {
  return (
    <span>
      {borrowers
        ?.map(({ loanNeedsListBorrowerId }) => {
          const theBorrower = allBorrowers?.find((b) => b.loanNeedsListBorrowerId === loanNeedsListBorrowerId);
          return theBorrower?.name;
        })
        .join(', ')}
    </span>
  );
};

export default BorrowersOverview;
