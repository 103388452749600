import DashboardMilestoneItem from './DashboardMilestoneItem';
import { LoanSummaryExternal } from 'app/types';
import { FC } from 'react';

interface DashboardMilestoneProps {
  loan: LoanSummaryExternal;
}

const DashboardMilestone: FC<DashboardMilestoneProps> = ({ loan }) => {
  return (
    <section className="d-flex align-items-center text-white bg-primary-gradient rounded-pill ps-1 pe-4 py-1 overflow-auto flex-nowrap">
      <h3 className="bg-white rounded-pill fs-3 fw-bold py-3 px-5 m-0 h-100 d-none d-lg-block">Milestones</h3>
      <ul className="d-flex w-100 py-3 py-md-0">
        <DashboardMilestoneItem title="Application Submitted" checked={!!loan.milestoneStartedDate} dotOnEnd />
        <DashboardMilestoneItem title="Rate Lock" checked={!!loan.lockDate} dotOnEnd dotOnStart />
        <DashboardMilestoneItem title="Approval" checked={!!loan.underwritingApprovalDate} dotOnEnd dotOnStart />
        <DashboardMilestoneItem title="Signing Scheduled" checked={!!loan.documentSigningDate} dotOnEnd dotOnStart />
        <DashboardMilestoneItem title="Close of Escrow" checked={!!loan.disbursementDate} dotOnStart />
      </ul>
    </section>
  );
};

export default DashboardMilestone;
