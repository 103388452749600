import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef } from 'react';
import Dynamsoft from 'mobile-web-capture';
import { WebTwain } from 'mobile-web-capture/dist/types/WebTwain';
import { ThumbnailViewer } from 'mobile-web-capture/dist/types/WebTwain.Viewer';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useUploadFilesMutation } from 'features/fileshare/api/fileshareApi';
import { useFileList } from '@rsl/core/src/hooks/fileManagementHooks';
import { useAppSelector } from 'app/hooks/hooks';

interface props {
  license?: string;
  width?: string | number;
  height?: string | number;
  scan?: boolean;
}

let DWObject: WebTwain | undefined;
let thumbnail: ThumbnailViewer | undefined;

const Scanner: ForwardRefRenderFunction<unknown, props> = (props: props, ref) => {
  const containerID = 'dwtcontrolContainer';
  const [uploadFiles] = useUploadFilesMutation();
  const { borrowersToShareWith: borrowers, loanId, loanNeedsListId } = useAppSelector((state) => state.fileshare);
  const { setCurrentFiles, addFiles, sendFormFiles, currentFiles } = useFileList(uploadFiles, { limit: 4 });
  const history = useHistory();

  const container = useRef<HTMLDivElement>(null);

  const handleSave = async () => {
    const borrowerIds = borrowers.map((id: string) => parseInt(id));
    const payloadGen = (files: File[]) => {
      const sharedToBorrowers = files.map(() => borrowerIds);
      return { sharedToBorrowers, loanId, loanNeedsListId };
    };

    await sendFormFiles({ loanId }, payloadGen);
    setCurrentFiles([]);
    toast.success('Files uploaded successfully!');
    history.goBack();
  };

  useEffect(() => {
    if (currentFiles.length > 0) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFiles]);

  const OnWebTWAINReady = () => {
    DWObject = Dynamsoft.DWT.GetWebTwain(containerID);
    if (container.current) {
      if (props.height) {
        DWObject.Viewer.height = props.height;
        container.current.style.height = props.height as string;
      }
      if (props.width) {
        DWObject.Viewer.width = props.width;
        container.current.style.width = props.width as string;
      }
    }

    let thumbnailViewerSettings = {
      location: 'left',
      size: '100%',
      columns: 2,
      rows: 3,
      scrollDirection: 'vertical', // 'horizontal'
      pageMargin: 10,
      background: 'rgb(255, 255, 255)',
      border: '',
      allowKeyboardControl: true,
      allowPageDragging: true,
      allowResizing: true,
      showPageNumber: true,
      pageBackground: 'transparent',
      pageBorder: '1px solid rgb(238, 238, 238)',
      hoverBackground: 'rgb(239, 246, 253)',
      hoverPageBorder: '1px solid rgb(238, 238, 238)',
      placeholderBackground: 'rgb(251, 236, 136)',
      selectedPageBorder: '1px solid rgb(125,162,206)',
      selectedPageBackground: 'rgb(199, 222, 252)',
      showCheckbox: true,
    };
    thumbnail = DWObject.Viewer.createThumbnailViewer(thumbnailViewerSettings);
    thumbnail.show();
  };

  useEffect(() => {
    if (props.scan === true) {
      if (DWObject) {
        let cameraContainer = document.createElement('div');
        cameraContainer.className = 'fullscreen';
        document.body.appendChild(cameraContainer);

        const funcConfirmExit = (bExistImage: boolean): boolean => {
          cameraContainer.remove();
          return true;
        };
        let showVideoConfigs: any = {
          // ScanConfiguration
          element: cameraContainer,
          scannerViewer: {
            autoDetect: {
              enableAutoDetect: false,
            },
            funcConfirmExit: funcConfirmExit,
            continuousScan: {
              visibility: false,
              enableContinuousScan: false,
            },
          },
          filterViewer: {
            exitDocumentScanAfterSave: false,
          },
        };
        DWObject.Addon.Camera.scanDocument(showVideoConfigs).then(
          function () {
            console.log('OK');
          },
          function (error) {
            console.log(error.message);
          }
        );
      }
    }
  }, [props.scan]);

  useEffect(() => {
    Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
      OnWebTWAINReady();
    });
    if (props.license) {
      Dynamsoft.DWT.ProductKey = props.license;
    }
    Dynamsoft.DWT.UseLocalService = false;
    Dynamsoft.DWT.Containers = [
      {
        WebTwainId: 'dwtObject',
        ContainerId: containerID,
        Width: '300px',
        Height: '400px',
      },
    ];
    Dynamsoft.DWT.Load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    export() {
      const pageIndexes = new Array(DWObject?.PageSize).map((_, i) => i);
      DWObject?.ConvertToBlob(
        pageIndexes,
        Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
        function (result, indices, type) {
          const newFile = new File([result], 'My Scanned Documents.pdf', { type: 'application/pdf' });
          addFiles([newFile]);
        },
        function (errorCode, errorString) {
          console.log(errorString);
        }
      );
    },
    delete() {
      DWObject?.RemoveAllSelectedImages();
    },
  }));

  return <div ref={container} id={containerID} className="flex-grow-1"></div>;
};

export default forwardRef(Scanner);
