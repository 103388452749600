import { ApiResponse } from '@rsl/core';
import { baseApi } from '@rsl/core/src/api/baseApi';
import { IProfileImageType } from '../types/profileImage.types';

export const userProfileImageApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchProfileImage: build.query<IProfileImageType, string>({
      query: (email) => `/externalUserProfileImage/${email}`,
      transformResponse: (response: ApiResponse<IProfileImageType>) => response.data,
      providesTags: (_result, _error, email) => [{ type: 'UserProfileImage', id: email }],
    }),
    fetchProfileImagePartner: build.query<IProfileImageType, string>({
      query: (email) => `/externalUserProfileImage/${email}/partner`,
      transformResponse: (response: ApiResponse<IProfileImageType>) => response.data,
      providesTags: (_result, _error, email) => [{ type: 'UserProfileImage', id: email }],
    }),
  }),
});

export const { useFetchProfileImageQuery, useFetchProfileImagePartnerQuery } = userProfileImageApi;
