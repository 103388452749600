import { MiddlewareAPI, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { setLogoutState } from 'app/store/slices/authSlice';
import { setIntendedRoute } from 'app/store/slices/routeManagerSlice';
import { pinActionRouteMap } from 'features/pin/constants/pinActionsConstants';
import { PinActionEnum } from 'features/pin/types';
import { toast } from 'react-toastify';
import { getInsights } from 'app/appInsights';

export const AuthMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401) {
      console.warn('We got a rejected action!');
      const { pathname } = window.location;

      if (action.payload.data) {
        const actionName = action.payload.data.data?.Action;

        if (actionName) {
          const newRoute = pinActionRouteMap(actionName);
          if (newRoute !== pathname) {
            api.dispatch(setIntendedRoute(`${newRoute}?callback=${pathname}`));
          }
        } else {
          api.dispatch(setLogoutState());
          api.dispatch(setIntendedRoute(`${pinActionRouteMap(PinActionEnum.Login)}?callback=${pathname}`));
        }
      } else {
        api.dispatch(setIntendedRoute(`${pinActionRouteMap(PinActionEnum.Login)}?callback=${pathname}`));
      }
    } else if (action.payload.status !== 404) {
      const data = action.payload?.data;
      const inArrayMessage = data?.errorMessages?.[0];
      const message = inArrayMessage ?? data?.message ?? data?.Message ?? 'Something went wrong, please contact the Loan Officer!';
      toast.error(message);
      const properties = { action, state: api.getState() };
      const { appInsights } = getInsights();
      appInsights.trackException({ error: new Error(`[POS] Api Call ${action.payload.status} error`), properties });
    }
  }

  return next(action);
};
