import { useEffect, useState } from 'react';
import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';

const A1i = () => {
  const { values, setFieldValue } = useFormikContext<ILoanQuestionsMapType>();
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (
      values.ShowSharedA1a_ ||
      values.ShowSharedA1b_ ||
      values.ShowSharedA1c_ ||
      values.ShowSharedA1d_ ||
      values.ShowSharedA1e_ ||
      values.ShowSharedA1f_ ||
      values.ShowSharedA1g_
    ) {
      setDisabled(true);
      setFieldValue('ShowSharedA1i_', false);
    } else {
      setDisabled(false);
    }
  }, [
    values.ShowSharedA1a_,
    values.ShowSharedA1b_,
    values.ShowSharedA1c_,
    values.ShowSharedA1d_,
    values.ShowSharedA1e_,
    values.ShowSharedA1f_,
    values.ShowSharedA1g_,
    setFieldValue,
  ]);

  return (
    <div className="border-bottom py-2 border-gray-dark">
      <Row>
        <CheckboxField className="fw-bold lg-label" name="ShowSharedA1i_" label="None" horizontal={true} disabled={disabled} />
      </Row>
    </div>
  );
};

export default A1i;
