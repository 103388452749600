import { useEffect } from 'react';
import Question from '../../Question';
import TextField from '@rsl/core/src/components/form/TextField';
import MaskedField from '@rsl/core/src/components/form/MaskedField';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';
import LookupSelect from '@rsl/core/src/components/form/LookupSelect';
import { Row, Col } from 'react-bootstrap';
import PlacesAutoComplete, { PlaceType } from '../../../fields/PlacesAutocomplete';
import { IPlaceAutocompleteResponse } from '../../../fields/fields.types';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import { shortTimestamp } from '@rsl/core/src/utils/dateUtils';
import MessageIndicator from '../../../shared/MessageIndicator';

const E1 = () => {
  const { values, setFieldValue, setValues } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.SharedB6a_1 === -1) {
      setFieldValue('SharedB6a_1', values.employment.length);
    }
  }, [values.SharedB6a_1, setFieldValue, values.employment]);

  return (
    <>
      <Question label="Tell us about your current employment" labelSize="lg">
        <Row className="mb-2 align-items-center">
          <TextField placeholder="Employer Name" name="SharedE1_1" required />
        </Row>
        <Row>
          <Col xs={9}>
            <PlacesAutoComplete
              placeholder="Street Address"
              name="SharedE1_2"
              types={[PlaceType.STREET_ADDRESS]}
              className="mb-2"
              onChange={(res: IPlaceAutocompleteResponse) =>
                setValues({
                  ...values,
                  SharedE1_2: res.address ?? values.SharedE1_2,
                  SharedE1_4: res.city ?? values.SharedE1_4,
                  SharedE1_5: res.state ?? values.SharedE1_5,
                  SharedE1_6: res.zipCode ?? values.SharedE1_6,
                })
              }
            />
          </Col>
          <TextField xs={3} className="mb-2" placeholder="Unit" name="SharedE1_3" />
        </Row>
        <Row>
          <TextField className="mb-2" placeholder="City" name="SharedE1_4" required />
          <LookupSelect className="mb-2" placeholder="State" name="SharedE1_5" lookup="states" required enableDefault={false} />
          <TextField className="mb-2" placeholder="Zip" name="SharedE1_6" required />
        </Row>
        <Row>
          <MaskedField
            className="mb-2"
            placeholder="Employer phone"
            name="SharedE1_7"
            mask="(999) 999-9999"
            mutation={/[^0-9]+/g}
            required
            inputProps={{
              autocomplete: 'tel-national',
            }}
          />
        </Row>
        <Row>
          <TextField className="mb-2" placeholder="Job Title" name="SharedE1_8" required />
        </Row>
      </Question>
      <Question label="When did you start working here?">
        <Row>
          <TextField className="mb-2" readOnly={true} type="date" name="SharedE1_9" placeholder="MM/DD/YYYY" max={shortTimestamp()} required />
        </Row>
      </Question>
      <Question label="Number of years in line of work?">
        <Row>
          <TextField className="mb-2" type="number" placeholder="#" name="SharedE1_10" required />
        </Row>
      </Question>
      <Question label="Number of months in line of work?">
        <Row>
          <TextField className="mb-2" type="number" placeholder="#" name="SharedE1_15" required />
        </Row>
      </Question>
      <Question label="What is your monthly income before taxes?">
        <Row>
          <CurrencyField className="mb-2" name="SharedE1_11" placeholder="$" />
        </Row>
      </Question>
      <Row className="mt-2">
        <Col className="mt-2">
          <MessageIndicator>Do you receive any additional monthly income from this position, such as overtime, commission or bonuses?</MessageIndicator>
        </Col>
      </Row>
      <Question label="Overtime (optional)?">
        <Row>
          <CurrencyField className="mb-2" name="SharedE1_12" placeholder="$" />
        </Row>
      </Question>
      <Question label="Commission (optional)?">
        <Row>
          <CurrencyField className="mb-2" name="SharedE1_13" placeholder="$" />
        </Row>
      </Question>
      <Question label="Bonus Pay (optional)?">
        <Row>
          <CurrencyField className="mb-2" name="SharedE1_14" placeholder="$" />
        </Row>
      </Question>
    </>
  );
};

export default E1;
