// TODO: move to it's own reports module
import { strEnum } from '@rsl/core/src/utils/enumUtils';

export enum CustomReportSourceTypes {
  LoanPipeline = 1,
  LoanActivity = 2,
  MyActivities = 3,
}

export const ReportTaxonomyType = strEnum(['Company', 'Personal']);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ReportTaxonomyType = keyof typeof ReportTaxonomyType;
