import { FC, useEffect } from 'react';
import Question from '../../Question';
import { Row } from 'react-bootstrap';
import RadioField from '@rsl/core/src/components/form/RadioField';
import { useFormikContext } from 'formik';
import { IQuestionType, ILoanQuestionsMapType } from '@rsl/core/src/types';
import { QuestionValues } from '@rsl/core/src/constants';

const P4: FC<IQuestionType> = ({ showIf }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<ILoanQuestionsMapType>();

  useEffect(() => {
    if (values.SharedP4_1 === QuestionValues.No) {
      setFieldValue('SharedP4a_1', undefined);
      setFieldValue('SharedP4a_2', undefined);
      setFieldValue('SharedP4a_3', undefined);
      setFieldValue('SharedP4a_4', undefined);

      setFieldTouched('SharedP4a_1', false);
      setFieldTouched('SharedP4a_2', false);
      setFieldTouched('SharedP4a_3', false);
      setFieldTouched('SharedP4a_4', false);
    }
  }, [values.SharedP4_1, setFieldValue, setFieldTouched]);

  return (
    <Question label={`Are you currently making monthly payments on ${values.SharedB1b_1}?`} showIf={showIf}>
      <Row>
        <RadioField
          className="mb-2"
          name="SharedP4_1"
          optionsClassName="pt-1"
          options={[
            { label: QuestionValues.Yes, value: QuestionValues.Yes },
            { label: QuestionValues.No, value: QuestionValues.No },
          ]}
        />
      </Row>
    </Question>
  );
};

export default P4;
