import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import RslExternalApp from 'app/RslExternalApp';
import LockedRouteMessageContainer from '@rsl/core/src/components/router/LockedRouteMessageContainer';

import './window';

function App() {
  return (
    <>
      <ToastContainer />
      <LockedRouteMessageContainer />
      <RslExternalApp />
    </>
  );
}

export default App;
