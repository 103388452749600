import { useAppParams } from '@rsl/core/src/hooks/routeHooks';
import { useFetchExternalLoanQuery } from 'app/api/externalSessionApi';
import { currentLoanSelector } from 'app/store/selectors/loanSelector';
import { useExternalAuth } from './authHooks';
import { useAppSelector } from './hooks';
import { MAX_DISPLAY } from 'app/constants';

export const useCurrentLoan = () => {
  const { loanId: currentLoanId } = useAppParams<{ loanId: string }>();
  const { isLoggedIn } = useExternalAuth();
  useFetchExternalLoanQuery({ take: MAX_DISPLAY, currentLoanId }, { skip: !isLoggedIn || !currentLoanId });
  const currentLoan = useAppSelector(currentLoanSelector);
  return currentLoan ?? null;
};

export const useCurrentLoanId = () => {
  const { loanId } = useAppParams<{ loanId: string }>();
  return loanId;
};
