import CheckboxField from '@rsl/core/src/components/form/CheckboxField';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ILoanQuestionsMapType } from '@rsl/core/src/types';
import CurrencyField from '@rsl/core/src/components/form/CurrencyField';
import { ValidationErrors } from '@rsl/core/src/constants/errors';

const B6c = () => {
  const { values, setFieldValue, errors } = useFormikContext<ILoanQuestionsMapType>();

  const onChange = (event: any) => {
    if (!event.target.checked) {
      setFieldValue('SharedB6c_1', '');
      setFieldValue('SharedB6c_2', '');
      setFieldValue('SharedB6c_3', '');
    }
  };

  return (
    <div className="border-bottom py-2 border-gray-dark">
      <Row>
        <CheckboxField className="fw-bold lg-label" name="ShowSharedB6c_" label="Social security, pension, or disability" horizontal={true} onChange={onChange} />
      </Row>
      {!!values.ShowSharedB6c_ && (
        <Row>
          <Col>
            {errors.atLeastOneB6cIncome && (
              <div className="text-danger mb-3">
                <small>{ValidationErrors.AtLeastOneIncome}</small>
              </div>
            )}
          </Col>
          <CurrencyField className="mb-2" label="Monthly social security" name="SharedB6c_1" nullable isInlineLabel placeholder="$0" xs={12} md={6} />
          <CurrencyField className="mb-2" label="Monthly pension" name="SharedB6c_2" nullable isInlineLabel placeholder="$0" xs={12} md={6} />
          <CurrencyField label="Monthly disability" name="SharedB6c_3" nullable isInlineLabel placeholder="$0" xs={12} md={6} />
        </Row>
      )}
    </div>
  );
};

export default B6c;
