import { setRouteParams } from '../store/slices/routeSlice';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

export const useAppParams = <T>() => {
  const { pathname } = useLocation();
  const params = useParams<Record<string, string> | T>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteParams(params as Record<string, string>));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, setRouteParams]);

  return params as T;
};
